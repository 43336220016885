<template>
    <div :ref="'parent-item-' + id"
         :class="{'disabled-stock-select': isDisabled}"
         @mouseover="$emit('mouseover')"
         @mouseleave="$emit('mouseleave')"
         :style="{top: topForContext + 'px'}"
         class="stock-select"
         tabindex="-1">

         <template v-if="!isForContext">
           <div  class="stock-select__selected" @click="showDropdown" @focus="showDropdown" tabindex="0" :class="`stock-select-${id}`" v-if="!showOptions"> {{ item.label }} </div>
           <input
               v-else
               ref="statusInput"
               class="form-control stock-select__selected"
               v-model="inputValue"
               @keydown.up="incrementIndex($event, -1)"
               @keydown.down="incrementIndex($event, 1)"
               @keydown.right="handleArrowKey($event, 'onKeyPressRight')"
               @keydown.left="handleArrowKey($event, 'onKeyPressLeft')"
               @keydown.esc="showOptions = false"
               @keydown.enter="handleSelectOption"
               @blur="onBlur"
               @focus="$emit('focus')"
               :placeholder="placeholder">
           <i class='stock-select__dropdown-icon bx bxs-chevron-down' @click="toggleDropdown" :class="{'active': showOptions}"></i>
         </template>

        <div class="stock-select__select"
           :class="{'active': showOptions || isForContext}"
           :style="{top: getCountForContext() + 'px'}"
           :ref="`stock-select-${id}`"
           tabindex="-1">
        <button :id="`${option.textValue}-${id}`"
                :ref="`${option.textValue}-${id}`"
                :key="index"
                v-for="(option, index) in filteredOptions"
                class="stock-select__option"
                :class="{'active': index === currentIndex, 'revert-stock-option': isLeft()}"
                @click="handleSelectOption"
                @mouseover="handleMouseOver(index)"
                tabindex="-1">
          {{ option.label }}
          <i class="bx bx-chevron-right text-right" v-if="inStockHasComment(option.textValue)"></i>
        </button>
      </div>

        <div v-show="commentVisible || isForContext" class="">
          <div
              v-if="isNeedDateRange && optionInFocus && (optionInFocus.value === 4 || optionInFocus.value === 6)"
              :ref="`comment-${id}`"
              :id="`comment-${id}`"
              :style="{top: getCountForCommentContextTop() + 'px !important', left: getCountForCommentContextLeft() + 'px !important', height: isPartOrderPage ? 167 + 'px' : 197 + 'px'}"
              class="stock-select__comment stock-comment context-level-three">
            <div class="">

              <div class="stock-comment__header mb-2" v-if="presets && presets.length">
                <b-button v-for="(preset, index) in presets"
                          class="stock-comment__preset font-11 mr-2 text-left"
                          :key="index"
                          tabindex="0"
                          @click.stop.prevent="presetSelect2($event, index)">
                  {{ preset }}
                </b-button>
              </div>

              <div class="d-flex align-items-center mb-2">
                <div class="font-weight-bold mr-2">Select a Date</div>
                <date-picker v-model="commentDate" @onChange="$emit('onDatePickerChange')" style="width: 146px;"></date-picker>
                <b-button @click="addComment" class="p-2 select-date-btn" style="height: 32px; margin-left: 12px;">Add</b-button>
              </div>

              <div class="context-level-three-line mb-2"></div>

              <div class="mb-2" @keydown.enter="handleSelectOption" @click="clickOnPlaceholder">
                <div v-if="!comment.length && ((optionInFocus.value === 4 && isShowBackOrderPlaceholder) || (optionInFocus.value === 6 && isShowExPlaceholder))" style="position: absolute; height: 17px; width: 110px;" class="stock-text">Add ETA Comment</div>
                <highlightable-input
                    class="stock-text context-level-three-textarea"
                    :limit="50"
                    v-model="comment"
                    ref="hInput"
                    @onHighlight="v =>handleHighlight(v || (comment && comment.length > 50))"
                />
              </div>
              <p class="no-comment-block-context-limit" :class="{'limit-over': comment.length > 50}">
                Limit of 50 characters
              </p>
              <i @click="onChooseDate" :class="{'icon-display-none': comment.length > 50}" class="bx bx-save credit-cancel-save-icon clickable"></i>
            </div>
          </div>
          <stock-comment
              v-else
              @onFocus="showOptions = true"
              :id="`stock-comment-${id}`"
              :ref="`stock-comment-${id}`"
              :isForContext="isForContext"
              :isStockAll="isStockAll"
              :option="optionInFocus"
              @on-up="incrementIndex($event, -1)"
              @on-down="incrementIndex($event, 1)"
              @on-esc="handleCancelCommenting"
              @on-empty-left="handleCancelCommenting"
              @on-empty-right="handleCancelCommenting"
              @on-enter="handleSelectOption"
              @on-blur="commentVisible = false"
              v-model="comment"
              @onHighlight="handleHighlight"
              @presetSelect="presetSelect"
          >
          </stock-comment>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import StockComment from './StockComment';
import { createPopper } from '@popperjs/core';
import datePicker from './datePicker';
import HighlightableInput from './HighlightableInput'
import {mapGetters} from "vuex";

export default {
    name: 'StockSelect',
    props: {
      isForContext: {
        type: Boolean,
        default: false,
      },
      isPartOrderPage: {
        type: Boolean,
        default: false,
      },
        id: {
            type:  [Number, String],
            required: true
        },
       isStockAll: {
         type: Boolean,
         default: false,
       },
        options: {
            type: Array,
            required: true
        },
        value:{
            type: Object,
            required: true
        },
        isDisabled:  {
          type: Boolean,
          default: false,
        },
        isNeedDateRange: {
          type: Boolean,
          default: false,
        },
    },
    components: {
        'stock-comment': StockComment,
        datePicker,
        HighlightableInput
    },
    data() {
        return {
            topForContext: 0,
            isShowBackOrderPlaceholder: true,
            isShowExPlaceholder: true,
            commentDate: null,
            lastCommentDate: null,
            showOptions: false,
            item: {},
            isHighlight: false,
            comment: '',
            currentIndex: 0,
            inputValue: '',
            event: null,
            commentVisible: false,
            items: [],
            scrollPositionGroup: 1,
        };
    },
    // directives: {
    //     'click-outside': {
    //         bind(el, binding, vnode) {
    //             el.clickOutsideEvent = function (event) {
    //                 if (!(el === event.target || el.contains(event.target) || event.target.classList.contains('stock-text')
    //                     || event.target.classList.contains('stock-select-'+this.id)
    //                     || event.target.classList.contains('stock-select__selected'))) {
    //                     vnode.context[binding.expression](event);
    //                 }
    //             };
    //             document.body.addEventListener('click', el.clickOutsideEvent);
    //         },
    //         unbind(el) {
    //             document.body.removeEventListener('click', el.clickOutsideEvent);
    //         }
    //     }
    // },

    mounted() {
        this.item = this.value;
        this.items = _.map(this.options, (option) => {
            let opt = JSON.parse(JSON.stringify(option));
            opt.comment = '';
            if(this.item.value === opt.value) opt.comment = this.item.comment;
            return opt;
        });
        this.restoreComments();
    },
    computed: {
      ...mapGetters({
        getterSettings: 'settings/getSettings'
      }),
      getSettings () {
        return _.cloneDeep(this.getterSettings)
      },
      presets () {
        if (this.optionInFocus) {
          if (this.isStockAll) {
            if (this.getSettings.rfq.stockStatusQuickComments.allEnabled) {
              return this.getSettings.rfq.stockStatusQuickComments.all[this.optionInFocus.value]
            }
          } else {
            return this.getSettings.rfq.stockStatusQuickComments.single[this.optionInFocus.value]
          }
        }
        return []
      },
        filteredOptions() {
            if (this.inputValue !== '') {
                return this.items.filter(option => {
                    // console.log(option.label.toLowerCase(), this.inputValue.toLowerCase(), option.label.toLowerCase().includes(this.inputValue.toLowerCase()));
                    return _.includes(_.toLower(option.label), _.toLower(this.inputValue));
                })
            } else {
                return this.items;
            }
        },
        optionInFocus() {
            return this.filteredOptions[this.currentIndex];
        },
        placeholder() {
            return this.optionInFocus ? this.optionInFocus.label : "Select status";
        }
    },
    methods: {
      clickOnPlaceholder() {
        if (this.optionInFocus.value === 4) {
          if (!this.isShowBackOrderPlaceholder) {
            return;
          }
          this.isShowBackOrderPlaceholder = false
        } else if (this.optionInFocus.value === 6) {
          if (!this.isShowExPlaceholder) {
            return;
          }
          this.isShowExPlaceholder = false
        }
        this.focusInEXorBackComment()
      },
      presetSelect2 (e, index) {
        if (this.isStockAll) {
          if (this.getSettings.rfq.stockStatusQuickComments.allEnabled) {
            this.comment += this.getSettings.rfq.stockStatusQuickComments.all[this.optionInFocus.value][index]
          }
        } else {
          this.comment += this.getSettings.rfq.stockStatusQuickComments.single[this.optionInFocus.value][index]
        }

        this.focusInEXorBackComment()

        this.isShowBackOrderPlaceholder = false;
        this.isShowExPlaceholder = false;
      },
      focusInEXorBackComment () {
        this.$nextTick(() => {
          setTimeout(() => {
            let element = this.$refs[`hInput`].$refs['inputText'].$el;
            element.focus()
            window.getSelection().selectAllChildren(element)
            window.getSelection().collapseToEnd()
          }, 0);
        })
      },
      presetSelect () {
        const commentInput = this.$refs[`stock-comment-${this.id}`]
        this.$nextTick(() => {
          let element = commentInput.$refs[`hInput`].$refs['inputText'].$el;
          element.focus()
          window.getSelection().selectAllChildren(element)
          window.getSelection().collapseToEnd()
        })
      },
      getCountForCommentContextTop () {
        if (this.optionInFocus?.value === 6) {
          if (this.getCountForContext() === -254 && !this.isForContext) {
            return -181
          }
          return 104
        } else {
          if (this.getCountForContext() === -254 && !this.isForContext) {
            return -74
          }
        }
        return 213
      },
      getTopForContext() {
        if (this.$refs[`stock-select-${this.id}`] && this.isForContext) {
          let comment = this.$refs[`stock-select-${this.id}`]
          if ( window.innerHeight - comment?.getBoundingClientRect()?.bottom <= 15) {
            this.topForContext = -200
            return
          }
        }
        this.topForContext = 0
      },
      getCountForCommentContextLeft () {
        if (this.isLeft()) {
          return -367
        }
        if (this.$refs[`parent-item-${this.id}`]) {
          let comment = this.$refs[`parent-item-${this.id}`]
          return comment.clientWidth
        }

        return 138
      },
        addComment() {
          if (!_.isEmpty(this.commentDate)) {
            if (!_.isEmpty(this.lastCommentDate) && this.comment.includes(this.lastCommentDate)) {
              this.comment = this.comment.replace(this.lastCommentDate, this.commentDate);
            } else {
              this.comment += ' ETA ' + this.commentDate + ' ';
            }
            this.lastCommentDate = this.commentDate
            this.focusInEXorBackComment()
          }
        },
        getCountForContext () {
          if (this.$refs[`parent-item-${this.id}`]) {
            let comment = this.$refs[`parent-item-${this.id}`]
            // console.log(window.innerHeight - comment?.getBoundingClientRect()?.bottom, comment)
            if (window.innerHeight - comment?.getBoundingClientRect()?.bottom < 250) {
              return -254
            } else {
              return 33
            }
          }
          return 33
        },
        onChooseDate() {
          if (this.isHighlight) {
            return
          }
          this.select(this.optionInFocus);
          this.commentVisible = false;
          this.commentDate = '';
          this.onBlur();
        },
        onBlur() {
          this.hideDropdown();
          this.$emit('blur');
        },
        focusOnInput() {
          this.showDropdown();
        },
        selectNoStockOption() {
          let optionIndex = _.findIndex(this.items, (opt) => {
            return opt.value === 3;
          });
          if (optionIndex !== -1) {
            this.items[optionIndex].comment = 'NOT APPLICABLE';
          }
        },
        selectNoneStockOption() {
          let optionIndex = _.findIndex(this.items, (opt) => {
            return opt.value === 0;
          });
          if (optionIndex !== -1) {
            this.items[optionIndex].comment = '';
          }
        },
        storeComments(){
            return;
            let stockComments = JSON.parse(localStorage.getItem('stockComments'));
            let items = JSON.parse(JSON.stringify(this.items));
            if(_.isEmpty(stockComments)) stockComments = {};
            stockComments[this.id] = _.map(items, item => {
                return item.comment;
            });
            localStorage.setItem('stockComments', JSON.stringify(stockComments));
        },
        restoreComments(){
            return;
            let stockComments = JSON.parse(localStorage.getItem('stockComments'));
            if(!_.isEmpty(stockComments)) {
                if(!_.isEmpty(stockComments[this.id])){
                    _.forEach(stockComments[this.id], (value, key) => {
                        this.items[key].comment = value;
                    });
                }
            }
        },
        handleHighlight(isHighlight){
            this.isHighlight = isHighlight;
            this.$emit('onHighlight', isHighlight);
            // console.log('isHighlight', isHighlight);
        },
        hideDropdown() {
            const closeAll = () => {
                setTimeout(() => {
                    this.showOptions = false;
                    this.commentVisible = false;
                    this.inputValue = '';
                }, 150);
            }
            if (!this.commentVisible) {
                closeAll()
            }
        },
        showDropdown(){
            if (this.isDisabled) {
              return;
            }
            this.showOptions = true;
            this.commentVisible = false;
            this.$nextTick( () => {
              if (!this.isForContext) {
                this.$refs.statusInput.focus();
              }
            })
        },
        toggleDropdown(){
            if (this.isDisabled) {
              return;
            }
            this.showOptions = !this.showOptions;
            this.commentVisible = false;
        },
        select(option) {
            _.forEach(this.items, (itm) => {
              if (itm.value !== option.value) {
                itm.comment = '';
              }
            })
            this.item = option;
            this.item.comment = this.comment;
            option.comment = this.comment;
            option.isHighlight = this.isHighlight;
            if(!this.isHighlight) this.$emit("input", option);
            this.$emit('onSelected', option);
        },
        inStockHasComment(value) {
          switch (value) {
            case "InStock":
            case "LowStock":
            case "NoStock":
            case "OnBackOrder":
            case 'ExStock':
              return true;
            default:
              return false;
          }
        },
        scroll(increment, to) {
            const currentOption = this.$refs[`${this.optionInFocus.textValue}-${this.id}`][0];
            if (currentOption) {
                let optionPosition = currentOption.offsetTop;
                const optionHeight = currentOption.offsetHeight;
                optionPosition = optionPosition + optionHeight;
                const scrollContainer = this.$refs[`stock-select-${this.id}`];
                const contains = Math.floor(scrollContainer.offsetHeight/currentOption.offsetHeight);

                if (to === 'top' || optionPosition === 0) {
                    scrollContainer.scrollTo(0,0);
                    this.scrollPositionGroup = 1;
                    return;
                }

                if (to === 'bottom' || optionPosition === optionHeight * (this.filteredOptions.length - 1)) {
                    scrollContainer.scrollTo(0, optionHeight * (this.filteredOptions.length - 1))
                    this.scrollPositionGroup = this.filteredOptions.length % contains;
                    return;
                }

                const breakPointBottom = optionHeight * contains * this.scrollPositionGroup - 1;
                //36 * (4 + 1) * 2 - 1
                const breakPointTop = optionHeight * (contains - 2) * (this.scrollPositionGroup - 1);
                // console.log(`increment: ${increment}, position: ${optionPosition}, breakPointBottom: ${breakPointBottom}, breakPointTop: ${breakPointTop}`)

                if (optionPosition > breakPointBottom && increment === 1) {
                    scrollContainer.scrollTo(0, optionPosition)
                    this.scrollPositionGroup += 1;
                } else if (optionPosition <= breakPointTop && increment === -1) {
                    scrollContainer.scrollTo(0, -1 * optionPosition)
                    this.scrollPositionGroup -= 1;
                }
            }
        },
        incrementIndex(e, value) {
            e.preventDefault();
            e.stopPropagation();

            if (this.currentIndex + value < 0) {
                this.currentIndex = this.filteredOptions.length - 1;
                this.scroll(value, 'bottom');
                if (!this.isForContext) {
                  this.$refs.statusInput.focus();
                }
                return;
            } else if (this.currentIndex + value > this.filteredOptions.length - 1) {
                this.currentIndex = 0;
                this.scroll(value, 'top');
                if (!this.isForContext) {
                  this.$refs.statusInput.focus();
                }
                return;
            }
            this.currentIndex = this.currentIndex + value;
            this.scroll(value)
              if (!this.isForContext) {
                this.$refs.statusInput.focus();
              }
        },
        handleMouseOver(index) {
            this.currentIndex = index;
        },
        showComment(index) {
            if (!index) {
                index = this.currentIndex;
            }
            if(this.currentIndex!==index) {
              this.items[this.currentIndex].comment = this.comment;
              this.comment = this.items[index].comment;
            }

            this.items[this.currentIndex].comment = this.comment;

            if (this.inStockHasComment(this.filteredOptions[this.currentIndex].textValue)) {
                this.$nextTick(() => {
                this.commentVisible = true;
                this.popComment(this.optionInFocus, this.id);
                })
            } else {
                this.commentVisible = false;
            }
        },
       isLeft () {
         if (this.$refs[`parent-item-${this.id}`]) {
           let comment = this.$refs[`parent-item-${this.id}`]
           const count = this.isPartOrderPage ? 375 : 310;
           if (window.innerWidth - comment?.getBoundingClientRect()?.right < count) {
             return true
           }
         }
         return false
       },
        popComment(option, id) {
            this.isShowBackOrderPlaceholder = true;
            this.isShowExPlaceholder = true;

            if (this.isNeedDateRange && this.optionInFocus && (this.optionInFocus.value === 4 || this.optionInFocus.value === 6)) {
              let r = '';

              _.forIn(this.comment, (s, ind) => {
              if (s === '/') {

                ind = Number(ind);

                if (this.comment[ind - 1] && this.comment[ind - 1] !== '/' && this.comment[ind - 2] && this.comment[ind - 2] !== '/' &&
                      this.comment[ind + 1] && this.comment[ind + 1] !== '/' && this.comment[ind + 2] && this.comment[ind + 2] !== '/' &&
                      this.comment[ind + 3] === '/' &&
                      this.comment[ind + 4] && this.comment[ind + 4] !== '/' && this.comment[ind + 5] && this.comment[ind + 5] !== '/' &&
                      this.comment[ind + 6] && this.comment[ind + 6] !== '/' && this.comment[ind + 7] && this.comment[ind + 7] !== '/'
                  ) {

                    r = this.comment[ind - 2] + this.comment[ind - 1] + '/' + this.comment[ind + 1] + this.comment[ind + 2] + '/' + this.comment[ind + 4] + this.comment[ind + 5] + this.comment[ind + 6] + this.comment[ind + 7];
                  }
                }
              })
              if (r) {
                this.commentDate = r;
                this.lastCommentDate = r;
              }
              if (this.comment) {
                this.focusInEXorBackComment()
                this.isShowBackOrderPlaceholder = false;
                this.isShowExPlaceholder = false;
              }

              let comment = this.$refs[`comment-${this.id}`]
              if (comment) {
                comment.style.opacity = 1
              }

              return
            }
            const target = this.$refs[`${option.textValue}-${id}`][0];
            let comment = this.$refs[`stock-comment-${id}`]?.$el;

            let isLeft = this.isLeft();

            if (isLeft) {
              let x = -175;
              let y = -108;
              if (this.isPartOrderPage) {
                x = -173
              } else if (this.isStockAll) {
                x = -178
                y = -110;
              }
              createPopper(target, comment, {
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [x, y],
                    },
                  },
                ],
              })
            } else {
              createPopper(target, comment, {
                placement: 'right'
              })
            }

          const commentInput = this.$refs[`stock-comment-${this.id}`]
          if (commentInput?.$el?.style) {
            commentInput.$el.style.opacity = 1
          }
          this.$nextTick(() => {
            setTimeout(() => {
              let element = commentInput.$refs[`hInput`].$refs['inputText'].$el;
              element.focus()
              window.getSelection().selectAllChildren(element)
              window.getSelection().collapseToEnd()
            }, 0);
          })
        },
        handleSelectOption() {
            if (this.isHighlight) {
              return
            }
            const {label, textValue, value} = this.optionInFocus;
            console.log('handleSelectOption', {label, textValue, value}, this.comment)
            this.commentVisible = false;
            this.select(this.optionInFocus);
            this.hideDropdown();
        },
        handleArrowKey(e, emitName) {
            e.preventDefault();
            const commentInput = this.$refs[`stock-comment-${this.id}`]
            commentInput.setVisible(e);
            setTimeout(() => {
              if (!this.commentVisible && (this.filteredOptions[this.currentIndex].textValue === 'None' || this.filteredOptions[this.currentIndex].textValue === 'InStock')) {
                this.$emit(emitName)
              }
                commentInput.$refs[`hInput`].$refs['inputText'].$el.focus();
            }, 100);
        },
        handleCancelCommenting(e) {
            e.preventDefault();
            if (!this.isForContext) {
              this.$refs.statusInput.focus();
            }
        }
    },
    watch: {
        value: {
          handler: function (val) {
            this.item = val;
            this.items = _.map(this.options, (option) => {
              let opt = JSON.parse(JSON.stringify(option));
              opt.comment = '';
              // console.log(this.item, opt)
              if(this.item.value === opt.value) opt.comment = this.item.comment;
              return opt;
            });
          },
          deep: true,
        },
        comment(val){
            this.items[this.currentIndex].comment = val;
            this.storeComments();
        },
        commentVisible(val) {
           if (!val) {
                this.$nextTick( () => {
                    if (this.$refs.statusInput) {
                      if (!this.isForContext) {
                        this.$refs.statusInput.focus();
                      }
                    }
                })
            } else {
                this.comment = this.items[this.currentIndex].comment || '';
            }
        },
        currentIndex(index) {
          this.comment = this.items?.[index]?.comment?.trim() || '';
          this.showComment(index);
        }
    }
};
</script>

<style scoped>
    .revert-stock-option {
      text-align: right !important;
    }
    .revert-stock-option i {
      right: 114px !important;
      transform: rotate(180deg);
    }
    .stock-select__selected {
        white-space: nowrap;
    }

    .context-level-three {
        position: absolute;
        left: 129px !important;
        top: 213px !important;
        padding: 10px;
        width: 367px;
    }

    .stock-comment__preset {
      width: 110px;
    }

    .stock-comment__preset:hover,
    .select-date-btn:hover {
      background-color: rgba(41, 187, 193, 0.1);
      color: #79B1BB;
      font-weight: bold;
    }

    .stock-comment__preset:last-child {
      margin-right: 0 !important;
    }

    .context-level-three-line {
      width: 100%;
      height: 3px;
      background-color: rgba(27, 30, 56, 0.12)
    }

    .context-level-three-textarea {
      border: none;
      resize: none;
      width: 100%;
      height: 75px;
    }

    .bx-save {
      font-size: 24px;
      position: absolute;
      right: 10px;
      bottom: 5px;
      cursor: pointer;
      opacity: 0.7;
    }

    .bx-save:hover {
      opacity: 1;
    }

    .no-comment-block-context-limit {
      color: #35495E;
      margin-bottom: 0px;
      opacity: 0.5;
    }

    .limit-over {
      color: red !important;
    }

    .icon-display-none {
      display: none;
    }
</style>

<!--<style lang="scss">-->
<!--    @import "../styles/style-views.scss";-->
<!--</style>-->
