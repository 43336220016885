<template>
  <div class="page invite-repairer">
    <div class="container">
      <div class="auth-header">
        <div class="logo">
          <div @click.prevent="goToHome" class="logo-text">
            <img src="@/assets/logos/partssearch-logo-white-backgroud.svg" class="auth-logo-svg"/>
          </div>
        </div>
      </div>
      <div class="invite-repairer-form text-center">
        <div class="intro-text">
          <div class="form">
            <div class="steps-line">
              <ul>
                <li class="done" style="cursor: pointer" @click="$router.push({path: '/register/repairer/find-business'})">
                  <span class="numb">
                    <i class="bx bxs-check-circle" style="color:#29bbc1"></i>
                  </span>
                  <span class="step-title">Your Business</span>
                </li>
                <li class="line active"></li>
                <li class="done" style="cursor: pointer" @click="$router.push({path: '/register/repairer/create-business-profile'})">
                  <span class="numb">
                    <i class="bx bxs-check-circle" style="color:#29bbc1"></i>
                  </span>
                  <span class="step-title">Business Profile</span>
                </li>
                <li class="line active"></li>
                <li class="active">
                  <span class="numb">3</span>
                  <span class="step-title">Your Details</span>
                </li>
              </ul>
            </div>
            <br />
            <br />
            <h1>Add your personal user account details</h1>
            <br />
            <form method="POST" @submit.prevent="login">
              <div class="form-group">
                <label for="Username">Username</label>
                <input
                  autocomplete="off"
                  type="text"
                  class="form-control input-field"
                  name="Username"
                  placeholder="Create a Unique Name to Log In With"
                  v-model="username"
                  @change="checkUsername"
                  @keyup="checkUsername"
                />
                <div v-if="isShowSpinner" class="register-spinner" style="position: absolute; left: 611px; top: 171px">
                  <b-spinner :variant="'info'"></b-spinner>
                </div>
                <div class="w-100 text-right" v-if="!isCheckUsername && username !== '' && !isShowSpinner">
                  <span class="error-text">
                    <i class="bx bxs-error" style="color:#FF5E5E"></i>&nbsp;This username is already in use
                  </span>
                </div>
              </div>

              <div class="form-group">
                <label for="firstname">First Name</label>
                <input
                  autocomplete="off"
                  type="text"
                  class="form-control input-field"
                  name="firstname"
                  placeholder="First Name"
                  v-model="firstName"
                />
                <div class="w-100 text-right" v-show="false">
                  <span class="error-text">
                    <i class="bx bxs-error" style="color:#FF5E5E"></i>&nbsp;Any error text
                  </span>
                </div>
              </div>
              <div class="form-group">
                <label for="lastname">Last Name</label>
                <input
                  autocomplete="off"
                  type="text"
                  class="form-control input-field"
                  name="lastname"
                  placeholder="Last Name"
                  v-model="lastName"
                />
                <div class="w-100 text-right" v-show="false">
                  <span class="error-text">
                    <i class="bx bxs-error" style="color:#FF5E5E"></i>&nbsp;Any error text
                  </span>
                </div>
              </div>
              <div class="form-group">
                <label for="location">Location</label>
                <multiselect
                  :options="locations"
                  :showLabels="false"
                  :max-height="203"
                  :close-on-select="true"
                  placeholder="Select Location"
                  v-model="location"
                />
              </div>
              <div class="form-group">
                <label for="workphone">Work Phone</label>
                <input
                  autocomplete="off"
                  type="text"
                  class="form-control input-field"
                  name="workphone"
                  placeholder="Work Phone"
                  v-model="workPhone"
                />
                <div class="w-100 text-right" v-show="false">
                  <span class="error-text">
                    <i class="bx bxs-error" style="color:#FF5E5E"></i>&nbsp;Any error text
                  </span>
                </div>
              </div>
              <div class="form-group">
                <label for="mobilephone">Mobile Phone</label>
                <input
                  autocomplete="off"
                  type="text"
                  class="form-control input-field"
                  name="mobilephone"
                  placeholder="Mobile Phone"
                  v-model="mobilePhone"
                />
                <div class="w-100 text-right" v-show="false">
                  <span class="error-text">
                    <i class="bx bxs-error" style="color:#FF5E5E"></i>&nbsp;Any error text
                  </span>
                </div>
              </div>
              <div class="form-group">
                <label for="role">User Account Role</label>
                <input
                  autocomplete="off"
                  type="text"
                  class="form-control input-field"
                  name="role"
                  placeholder="User Account Role"
                  v-model="accountRole"
                />
                <div class="w-100 text-right" v-show="false">
                  <span class="error-text">
                    <i class="bx bxs-error" style="color:#FF5E5E"></i>&nbsp;Any error text
                  </span>
                </div>
              </div>
              <div class="form-group">
                <button
                  type="button"
                  class="btn input-btn"
                  @click="register"
                  :disabled="!nextAllowed"
                >Confirm</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
  @import "../../../../styles/repairer/invite/style.css";
</style>
<style lang="css" scoped>
form {
  max-width: 522px;
  margin: 0 auto;
}
.form {
  max-width: 771px;
  width: 100%;
  margin: 0 auto;
}
.steps-line {
  max-width: 652px;
  width: 100%;
  margin: 0 auto;
}
.form h1 {
  text-align: center;
  margin-bottom: 0px;
}
.invite-repairer-form {
  justify-content: flex-start;
  margin-top: 15px;
}
.form .input-btn {
  display: table;
  margin: 0 auto;
  margin-top: 30px;
}
.form .post-code {
  width: 109px;
}
.form .another-location {
  margin-top: 15px;
  font-size: 13px;
  font-weight: 600;
  display: flex;
  align-items: center;
  background: none;
  border: 0;
  padding: 0;
}
.form .another-location i {
  font-size: 23px;
  margin-right: 5px;
  line-height: 0;
}
</style>

<script>
import Multiselect from "vue-multiselect";
import Axios from "axios";
export default {
  name: "InviteRepairerAddPersonalUserAccount",
  components: {
    multiselect: Multiselect
  },
  data(){
    return {
      username: '',
      firstName: '',
      lastName: '',
      location: '',
      workPhone: '',
      mobilePhone: '',
      accountRole: 'Administrator',
      locations: [],
      registrationData: {},
      isCheckUsername: true,
      isShowSpinner: false,
    };
  },
  mounted() {
    if(localStorage.getItem('registrationData')){
      try {
        this.registrationData = JSON.parse(localStorage.getItem('registrationData'));
      } catch (e) {
        localStorage.removeItem('registrationData');
      }
    }
    if(this.registrationData.locations && this.registrationData.locations.length > 0){
      this.registrationData.locations.forEach(l => {
        this.locations.push(l.name);
      })
    }
    this.scrollToTop()
  },
  methods: {
    checkUsername: function() {
      this.isShowSpinner = true;
      let username = this.username;
      let self = this;
      self.isCheckPassword = true;

      Axios({
        url: "/ir/auth/check/username",
        data: { username: username },
        method: "POST"
      })
          .then(resp => {
            if (resp && resp.data && resp.data.status == false) {
              self.isCheckUsername = true;
            } else {
              self.isCheckUsername = false;
            }
            this.isShowSpinner = false;
          })
          .catch(err => {
            console.log(err);
          });
    },
    register(){

      let account = {};

      account.username    = this.username;
      account.firstName   = this.firstName;
      account.lastName    = this.lastName;
      account.location    = this.location;
      account.workPhone   = this.workPhone;
      account.mobilePhone = this.mobilePhone;
      account.accountRole = this.accountRole;

      this.registrationData.account = account;

      localStorage.registrationData = JSON.stringify(this.registrationData);

      Axios.post(
          '/ir/auth/repairer/registration',
          this.registrationData
      ).then(res => {
        console.log(res.data);
        this.$router.push({name: 'RepairerAccountCreated'});
      }).catch(e => {
        this.$router.push({name: 'RepairerFoundProblem'});
        console.log(e);
      })

    },
    goToHome() {
      document.location.replace(appConfig.homeURL + '/');
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    },
  },
  computed: {
    nextAllowed() {
      return !!(
          this.username
          && this.firstName
          && this.lastName
          && this.location
          && this.workPhone
          && this.mobilePhone
          && this.accountRole
      );
    },
    getOptions() {
      return ["Location 1", "Location 2"];
    }
  }
};
</script>
