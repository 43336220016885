<template>
  <div class="dashboard-panel card" v-bind:style="{'max-width':width || '100%'}">
    <div class="card-body">
      <div class="card-title">
        <h4 class="title-text">Request For Quotes</h4>
        <div class="search grid">
          <div class="input-text">
            <search-filter
                type="estimates"
                v-model="searchFilter"
                :filters="computedSearchFilters"
                :limitLength="50"
                :filterValue="filter"
                @change="onSearchFilterChange"
                @updateToken="updateToken"
                :additionalMenu="additionalMenu"
                :debug="false"
                @selectAdditional="redirectToRfqView"
                @onEnter="onEnter"
                height="41px"
                aria-expanded="false"
                data-toggle="dropdown"
            />
          </div>
          <button @click="submitQoute" class="btn btn-primary btn-submit-quote"
                  style="vertical-align: middle;"
                  v-b-tooltip.hover="{customClass: 'ps-tooltip', title:'Start your Request for a Parts quote', placement:'leftbottom'}">
            <i class="bx bx-plus" style="font-size: 17px; vertical-align: middle;"></i>
            <span style="vertical-align: middle;">Submit Quote</span>
          </button>
        </div>
      </div>
      <div class="cards-list">
        <div class="card-item">
          <div class="header-item">
            Received
            <i @click="goToReceivedRFQ" class="bx bx-link-external clickable"></i>
          </div>
          <div class="body-item">
            <div @click="goToReceivedRFQ" class="list-values ">
              <div class="list-values-header orange-bg clickable">
                <span class="numb">{{ countReceivedRFQs }}</span> New RFQ's
              </div>
            </div>
          </div>
        </div>
        <div class="card-item">
          <div class="header-item">
            Submitted
            <i @click="goToSubmittedRFQ()" class="bx bx-link-external clickable"></i>
          </div>
          <div class="body-item">
            <div class="list-values">
              <div @click="goToSubmittedRFQ()" class="list-values-header orange-bg clickable">
                <span class="numb">{{ countSubmittedRFQs }}</span> Submitted Quotes
              </div>
              <ul class="cursor-ptr">
                <li @click="goToSubmittedRFQ({filter: 'upto 25%'})">
                  <i class="bx bx-check-circle" style="color:#2DCA73"></i>
                  <div class="value">
                    <strong>{{ getCompletedCountByPercentRange(0, 25) }}</strong>
                    25 % Completed
                  </div>
                </li>
                <li @click="goToSubmittedRFQ({filter: 'upto 50%'})">
                  <i class="bx bx-check-circle" style="color:#2DCA73"></i>
                  <div class="value">
                    <strong>{{ getCompletedCountByPercentRange(26, 50) }}</strong>
                    50 % Completed
                  </div>
                </li>
                <li @click="goToSubmittedRFQ({filter: 'upto 75%'})">
                  <i class="bx bx-check-circle" style="color:#2DCA73"></i>
                  <div class="value">
                    <strong>{{ getCompletedCountByPercentRange(51, 75) }}</strong>
                    75 % Completed
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="card-item">
          <div class="header-item">
            Priced
            <i @click="goToPricedRFQ()" class="bx bx-dollar clickable" style="color:#2DCA73"></i>
          </div>
          <div class="body-item">
            <div class="list-values">
              <div @click="goToPricedRFQ()" class="list-values-header orange-bg clickable">
                <span class="numb">{{ countPricedRFQs }}</span> Priced Quotes
              </div>
              <ul class="cursor-ptr">
                <li @click="goToPricedRFQ({filter: 'Recently Priced'})">
                  <i class="bx bx-dollar-circle" style="color:#2DCA73"></i>
                  <div class="value">
                    <strong>{{ getPricedCountByTimeOldRange(0, 8) }}</strong>
                    Recently Priced
                  </div>
                </li>
                <li @click="goToPricedRFQ({filter: '1 day old'})">
                  <i class="bx bx-time" style="color:#79B1BB"></i>
                  <div class="value">
                    <strong>{{ getPricedCountByTimeOldRange(24, 48) }}</strong>
                    1 day old
                  </div>
                </li>
                <li @click="goToPricedRFQ({filter: 'Over 2 days old'})">
                  <i class="bx bx-time-five" style="color:#79B1BB"></i>
                  <div class="value">
                    <strong>{{ getPricedCountByTimeOldRange(49) }}</strong>
                    Over 2 days old
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
@import url(../../../../styles/dashboard/style.css);

.list-values .clickable:hover {
  color: #79B1BB;
}
</style>

<script>
import {mapActions, mapGetters} from 'vuex';
import dayjs from "dayjs";
import Vue from 'vue';
import SearchFilter from '../../../../components/utility/search-filter/search-filter'
import _ from "lodash";

export default {
  name: "RequestForQuotes",
  props: {
    width: String
  },
  data() {
    return {
      filter: '',
      searchFilter: '',
    }
  },
  methods: {
    ...mapActions({
      setFilter: 'repairer/rfq/setFilter'
    }),
    redirectToRfqView(itm) {
      this.setFilter('')
      if (itm.isJobView) {
        this.$router.push({name: 'RepairerJobView', params: {job_id: itm.value}});
      } else {
        this.$router.push({ name: 'RepairerRFQView', params: { rfq_id: itm.value } })
      }
    },
    onEnter() {
      setTimeout(() => {
        let rfq =  _.find(this.additionalMenu, (r) => {
          return r.isJobView
        })

        if (rfq && !_.isEmpty(this.searchFilter)) {
          this.setFilter('')
          this.$router.push({name: 'RepairerJobView', params: {job_id: rfq.value}});
         // this.$router.push({ name: 'RepairerRFQView', params: { rfq_id: rfq.id } })
        } else {
          this.$router.push({name: 'RepairerRFQs'}).catch(err => err)
        }
      }, 500)

    },
    filterRfqs (data) {
      data = _.cloneDeep(data)
      console.log(data, this.searchFilterObj)
      if (this.searchFilterObj.partNumber) {
        let formattedFilter = this.searchFilterObj.partNumber.toLowerCase();
        return _.filter(data, rfq => {
          return rfq.partsNumbers && _.includes(_.toLower(rfq.partsNumbers), formattedFilter)
        })
      }
      if (this.searchFilterObj.make) {
        let formattedFilter = this.searchFilterObj.make.toLowerCase();
        return _.filter(data, rfq => {
          return rfq.make && _.includes(_.toLower(rfq.make), formattedFilter)
        })
      }
      if (this.searchFilterObj.rego) {
        let formattedFilter = this.searchFilterObj.rego.toLowerCase();
        return _.filter(data, rfq => {
          return rfq.rego && _.includes(_.toLower(rfq.rego), formattedFilter)
        })
      }
      if (this.searchFilterObj.orderNumber) {
        let formattedFilter = this.searchFilterObj.orderNumber.toLowerCase();
        return _.filter(data, rfq => {
          return  rfq.orderNumbers && rfq.orderNumbers.length && _.includes(_.toLower(_.join(rfq.orderNumbers, ' ')), formattedFilter)
        })
      }
      if (this.searchFilterObj.quotingPackageNbr) {
        let formattedFilter = this.searchFilterObj.quotingPackageNbr.toLowerCase();
        return _.filter(data, rfq => {
          return rfq.QPNumber && _.includes(_.toLower(rfq.QPNumber), formattedFilter)
        })
      }
      return data
    },
    getTab () {
      let tab = null;
      let receivedRfqs = this.filterRfqs(this.gettersReceivedRFQs);
      let submittedRfqs = this.filterRfqs(this.gettersSubmittedRFQs);
      let pricedRfqs = this.filterRfqs(this.gettersPricedRFQs);
      let orderedRfqs = this.filterRfqs(this.gettersOrderedRFQs);
      let cancelledRfqs = this.filterRfqs(this.gettersCancelledRFQs);

      if (!_.isEmpty(receivedRfqs)) {
        tab = '#received'
      } else if (!_.isEmpty(submittedRfqs)) {
        tab = '#submitted'
      } else if (!_.isEmpty(pricedRfqs)) {
        tab = '#priced'
      } else if (!_.isEmpty(orderedRfqs)) {
        tab = '#ordered'
      } else if (!_.isEmpty(cancelledRfqs)) {
        tab = '#cancelled'
      }
      console.log('tab', tab)
      return tab
    },
    updateToken (value) {
      setTimeout(() => {
        console.log('updateToken', value)
        if (value && value.length >= 2) {
          if (value[0].id !== 'partNumber') {
            this.$store.commit('repairer/rfq/setToken', [{
              ...value[0]
            }])
            this.setFilter(value[1].label);
          } else {
            this.$store.commit('repairer/rfq/setToken', value)
          }
          this.$router.push({name: 'RepairerRFQs',  params: { activeTab: this.getTab() }},).catch(err => err)
        }
      }, 10)
    },
    onSearchFilterChange(filters) {
      console.log(filters)
      this.$store.commit('repairer/rfq/repairerSetSearchFilterObj', filters);
      if (filters && filters.search) {
        this.setFilter(filters.search);
        // this.filter = filters.search;
      } else {
        this.setFilter('');
        // this.filter = '';
      }
      // console.log('onSearchFilterChange', filters);
    },
    submitQoute() {
      this.$router.push({name: 'RepairerRFQAdd'});
    },
    goToReceivedRFQ() {
      this.$router.push({path: '/r/rfqs#received'}); //ToDo needs to change to :to attr
    },
    goToSubmittedRFQ(params = {}) {
      this.$router.push({path: '/r/rfqs#submitted', query: params}); //ToDo needs to change to :to attr
    },
    goToPricedRFQ(params = {}) {
      this.$router.push({path: '/r/rfqs#priced', query: params}); //ToDo needs to change to :to attr
    },
    getCompletedCountByPercentRange(percentFrom = 0, percentTo = 25) {
      return _.filter(this.submittedRFQs, rfq => {
        let percent = parseInt(rfq.quoteCompletion);
        return percent >= percentFrom && percent <= percentTo;
      }).length;
    },
    getPricedCountByTimeOldRange(hoursFrom = 0, hoursTo = false) {
      //Recently Priced value = number of priced quotes in the last 2 hours
      //1 day old value = number of priced quotes that are from 4 hr to 24 hrs old
      //Over 2 days old value = number of priced quotes that are over 48 hours old.
      return _.filter(this.pricedRFQs, rfq => {

        let ct = (Vue.prototype.$http.defaults.headers.common['CompanyTimeOffset'] || 0) * 1;
        let dp = dayjs(rfq.datePriced).unix();
        let dn = dayjs().unix();
        dp = Math.floor((dn - dp) / 3600 + ct);
        return dp >= hoursFrom && (!hoursTo || dp <= hoursTo);

      }).length;
    },
  },
  computed: {
    ...mapGetters({
      submittedRFQs: 'repairer/rfq/getSubmittedRFQs',
      pricedRFQs: 'repairer/rfq/getPricedRFQs',
      countReceivedRFQs: 'repairer/rfq/countReceivedRFQs',
      countSubmittedRFQs: 'repairer/rfq/countSubmittedRFQs',
      countPricedRFQs: 'repairer/rfq/countPricedRFQs',
      gettersAllRFQs: 'repairer/rfq/getAllRFQs',
      gettersReceivedRFQs: 'repairer/rfq/getReceivedRFQs',
      gettersSubmittedRFQs: 'repairer/rfq/getSubmittedRFQs',
      gettersPricedRFQs: 'repairer/rfq/getPricedRFQs',
      gettersOrderedRFQs: 'repairer/rfq/getOrderedRFQs',
      gettersCancelledRFQs: 'repairer/rfq/getCancelledRFQs',
      getterSearchFilterObj: 'repairer/rfq/getSearchFilterObj',
    }),
    additionalMenu() {
      let data = _.filter(this.gettersAllRFQs, (r) => {
        return _.startsWith(_.trim(_.toLower(r.QPNumber)), _.trim(_.toLower(this.searchFilter)))
      })
      let result = [];
      _.forEach(data, (itm) => {
        let label = itm.QPNumber
        if (itm.rego) {
          label +=  ' - '
        }

        if (itm.rego) {
          label += itm.rego + ' '
        }

        result.push({
          value: itm.id,
          label: label,
          QPNumber: itm.QPNumber,
          isSupplement: itm.isSupplement,
          jobViewId: itm.jobViewId
        })
      })
      result = _.orderBy(result, ['label'], ['asc']).slice(0, 5)
      let addedJobViewIds=[]
      let jobViewOptions = []
      _.forEach(result, (r) => {
        if (!_.includes(addedJobViewIds, r.jobViewId)) {
          let l = 'JOB VIEW'
          if (r.QPNumber) {
            l = r.QPNumber + ' - JOB VIEW'
          }
          if (r.isSupplement || r.value != r.jobViewId) {
            if (result.length == 1) {
              jobViewOptions.push({
                value: r.value,
                label: l,
                isJobView: true
              })
              addedJobViewIds.push(r.jobViewId)
            }
          } else {
            jobViewOptions.push({
              value: r.value,
              label: l,
              isJobView: true
            })
            addedJobViewIds.push(r.jobViewId)
          }
        }
      })
      _.forEach(jobViewOptions, (j) => {
        let index = _.findIndex(result, (r) => {
          return Number(r.jobViewId) === Number(j.value)
        })
        if (index !== -1) {
          result.splice(index, 0, j)
        }
      })
      return result
    },
    searchFilterObj() {
      return this.getterSearchFilterObj;
    },
    computedSearchFilters() {
      let regos = {};
      let vins = {};
      let orderNumbers = {};
      let qpNumbers = {};
      let partNumbers = {};
      let makes = {};

      let data = this.gettersAllRFQs.slice()

      data.forEach(rfq => {
        if (rfq.rego) {
          regos[rfq.rego.trim().toLowerCase()] = {label: rfq.rego.trim()}
        }
      })

      data.forEach(rfq => {
        if (rfq.make) {
          makes[rfq.make.trim().toLowerCase()] = {label: rfq.make.trim()}
        }
      })


      data.forEach(rfq => {
        if (rfq.vin) {
          vins[rfq.vin.trim().toLowerCase()] = {label: rfq.vin.trim()}
        }
      })

      data.forEach(rfq => {
        if (rfq.partsNumbers) {
          let numbers = rfq.partsNumbers.split(';');
          _.forEach(numbers, n => {
            partNumbers[n.toLowerCase().trim()] = {label: n.toLowerCase().trim()}
          })
        }
      })

      data.forEach(rfq => {
        if (rfq.orderNumbers) {
          _.forEach(rfq.orderNumbers, n => {
            orderNumbers[n.toLowerCase().trim()] = {label: n.toLowerCase().trim()}
          })
        }
      })

      data.forEach(rfq => {
        if (rfq.QPNumber) {
          qpNumbers[rfq.QPNumber.trim().toLowerCase()] = {label: rfq.QPNumber.trim()}
        }
      })

      regos = _.orderBy(Object.values(regos), ['label'], ['asc'])
      vins = _.orderBy(Object.values(vins), ['label'], ['asc'])
      orderNumbers = _.orderBy(Object.values(orderNumbers), ['label'], ['asc'])
      qpNumbers = _.orderBy(Object.values(qpNumbers), ['label'], ['asc'])
      partNumbers = _.orderBy(Object.values(partNumbers), ['label'], ['asc'])
      makes = _.orderBy(Object.values(makes), ['label'], ['asc'])

      // Create searchFilter filters
      return [
        {
          id: 'partNumber',
          label: 'Part Number',
          iconClass: 'bx-user',
          options: [
              partNumbers
          ],
        },
        {
          id: 'make',
          label: 'Make',
          iconClass: 'bx-user',
          options: [
              makes
          ]
        },
        {
          id: 'rego',
          label: 'Rego',
          iconClass: 'bx-user',
          options: [
            regos
          ]
        },
        {
          id: 'vin',
          label: 'VIN',
          iconClass: 'bx-user',
          options: [
            vins
          ]
        },
        {
          id: 'orderNumber',
          label: 'Order Number',
          iconClass: 'bxs-user-badge',
          options: [
            orderNumbers
          ]
        },
        {
          id: 'quotingPackageNbr',
          label: 'Quoting Package Nbr',
          iconClass: 'bx-car',
          options: [
            qpNumbers
          ]
        },
      ];
    },
  },
  components: {
    SearchFilter
  }
};
</script>
