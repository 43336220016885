<template>
  <div class="pagination-block" style="z-index: 99">
    <div class="pagination-block-showing form-inline">
      <span>Showing {{ pageAll.from }} to {{pageAll.to }} of {{ pageAll.total_filtered }} {{role}}</span>
      <span v-if="filteredRowsCount < rowsCount" style="margin-left: 5px"> &#40;filtered from {{pageAll.total_unfiltered }} total {{role}}&#41;</span>
      <span style="margin:auto 5px;"> || </span>
      <span>Show Entries </span>
      <number-formatter
          type="text"
          class="form-control"
          v-model="perPage2"
          format="0"
          @changedValue="updatePerPage"
          :isCanBeZero="false"
          :isCanBeNegative="false"
      />
    </div>
    <div class="pagination-block-paginator">
      <b-pagination align="right"
                    :total-rows="filteredRowsCount"
                    v-model="currentPage2"
                    @input="updateCurrentPage(currentPage2)"
                    :per-page="computedPerPage"></b-pagination>
    </div>
  </div>
</template>

<script>
import NumberFormatter from './number-formatter'
export default {
  name: "block-pagination",
  components: {
    NumberFormatter
  },
  data() {
    return {
      currentPage2: this.currentPage,
      perPage2: this.perPage,
    }
  },
  props: {
    role: {
      type: String,
      default: '',
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default: 0,
    },
    perPageForMultipleTables: {
      type: Number,
      default: 0,
    },
    countFiltered: {
      type: Number,
      default() {
        return 0
      },
    },
    count: {
      type: Number,
      default() {
        return 0
      },
    }
  },
  methods: {
    updatePerPage(value) {
      this.perPage2 = value
      this.$emit('changedPerPage', Number(value))
    },
    updateCurrentPage(value) {
      this.$emit('changedCurrentPage', Number(value))
    },
    updateDebouncedPerPage(perPage) {
      if (perPage < 1 || perPage > 1000) {
        this.perPage2 = 50;
      } else {
        this.perPage2 = perPage;
      }
    },
  },
  computed: {
    computedPerPage: {
      get() {
        return this.perPage2;
      },
      set(v) {
        this.debouncedUpdatePerPage(v);
      }
    },
    rowsCount: function () {
      return this.count
    },
    filteredRowsCount: function () {
      return this.countFiltered
    },
    pageAll: function () {
      let status = [];
      if (this.filteredRowsCount > 0) {
        status["from"] = (this.currentPage2 - 1) * this.computedPerPage + 1;
        status["to"] = this.currentPage2 * this.computedPerPage;
      } else {
        status["from"] = 0;
        status["to"] = 0;
      }

      if (this.filteredRowsCount % this.computedPerPage > 0 && this.currentPage2  > this.filteredRowsCount / this.computedPerPage) {
        status["to"] = this.filteredRowsCount;
      }

      status["total_filtered"] = this.filteredRowsCount;
      status["total_unfiltered"] = this.rowsCount;

      return status;

    },
  },
  watch: {
    countFiltered: function() {
      if (this.countFiltered !== -1) {
        return;
      }

      if (this.countFiltered < this.count) {
        this.updateCurrentPage(1)
        this.currentPage2 = 1;
      }
    },
    perPageForMultipleTables: function(value) {
      this.perPage2 = value;
    },
    perPage: {
      handler(val) {
        this.perPage2 = val;
      },
      immediate: true,
    },
  },
  created() {
    this.debouncedUpdatePerPage = _.debounce(function (v) {
      this.updateDebouncedPerPage(v);
    }, 700);
  }
}
</script>

<style scoped>
  .form-control {
    width: 45px !important;
  }

  @media screen and (max-width: 660px) {
    .pagination-block{
      display: flex;
      flex-direction: column;
      margin-top: 70px;
    }
    .pagination-block-showing{
      position: static;
      width: 100%;
    }
    .pagination-block-paginator{
      position: static;
      margin-top: 15px;
    }
    .pagination-block-paginator >>> .justify-content-end{
      justify-content: start !important;
    }
  }
</style>
