<template>
  <div class="partname-menu" role="listbox" tabindex="0">
    <template v-for="(menuItem, ind) in menu">
      <button
          v-if="menuItem.visible"
          :key="menuItem.id"
          :ref="'option-lv1-' + menuItem.id"
          :id="getId(ind) ? currentPart.id : ''"
          role="option"
          class="partname-menu__item"
          :disabled="menuItem.disabled"
          :class="{'has-sub': menuItem.hasSubmenu}"
          v-b-tooltip.hover="{
           customClass: 'ps-tooltip nill-supply-tooltip',
           html: true,
           title: menuItem.disabled && menuItem.tooltip ? menuItem.tooltip: '',
           placement:'rightbottom'
          }"
          @click.prevent.stop="handleClick($event, menuItem)"
          @mouseover="handleMouseOver($event, menuItem)">
                <span
                      :class="{'context-with-not-applicable-comment': currentPart.isNotApplicable}"
                      @focus="handleFirstMenuFocus(menuItem)"
                      href="#" tabindex="-1"
                      class="partname-menu__link"
                >
                    {{ menuItem.label }}
                    <i v-if="menuItem.hasSubmenu" class="bx bx-chevron-right"></i>
                </span>
        <div v-if="menuItem.hasSubmenu && menu" @mouseover="disableOverflow(menuItem)" @mouseleave="enableOverflow"
             class="partname-submenu" :style="computedPosition" tabindex="-1" role="combobox">
          <template v-if="menuItem.value === 'setCondition'">
            <div class="condition-context-menu">
              <button @click.prevent.stop="handleClick($event, {...menuItem, subValue: 'As New'})"
                      class="partname-menu__item">As New
              </button>
              <button @click.prevent.stop="handleClick($event, {...menuItem, subValue: 'Fair'})"
                      class="partname-menu__item">Fair
              </button>
              <button @click.prevent.stop="handleClick($event, {...menuItem, subValue: 'RECO Req'})"
                      class="partname-menu__item">RECO Req
              </button>
            </div>
          </template>
          <template v-else-if="menuItem.value === 'editNotApplicableComment'">
            <div class="not-applicable-comment-context-menu">
              <textarea
                  v-model="currentPart.stockComment"
                  @keydown.enter="saveComment($event, menuItem)"
                  ref="not-applicable-comment"
                  class="not-applicable-comment"
              >
              </textarea>
              <div class="d-flex justify-content-between position-relative">
                <span :class="{'text-danger': currentPart.stockComment.length > 50}" class="stock-comment__info">Limit of 50 characters</span>
                <i @click="saveComment($event, menuItem)" class="bx bx-save save-comment-icon"></i>
              </div>
            </div>
          </template>
          <template v-else-if="menuItem.value === 'viewPhoto'">
            <div class="part-name-view-img-context">
              <img :src="currentPart.photoForPart.src" class="context-img" style="cursor: default" >
<!--              <img class="context-img" @click="openModalViewPhoto" :src="currentPart.photoForPart.src">-->
            </div>
          </template>
          <template v-else>
            <input v-model="filter"
                   v-if="!menuItem.noSearch && !isNeedChangePosition()"
                   :ref="menuItem.inputRef"
                   role="textbox"
                   class="partname-submenu__input"
                   placeholder="Search"
                   tabindex="0"
                   @keyup.esc="$parent.close()"
                   @keyup.down.stop="handleInputKeyDown"
            />
            <ul ref="list-box"
                class="partname-submenu__list"
                role="listbox">
              <template v-for="(item, index) in filteredSubmenuItems">
                <li class="partname-submenu__item"
                    :class="`list-box-${index}`"
                    ref="option"
                    role="option"
                    aria-selected="false"
                    tabindex="-1"
                    @keyup="handleKeyUp($event, menuItem, item, index)"
                    @click.prevent="handleClick($event, menuItem, item, index)"
                    :key="`submenu-${index}`">
                  <a class="partname-menu__link" href="#">
                    {{ item.name }}
                  </a>
                </li>
              </template>
            </ul>
            <input v-model="filter"
                   v-if="!menuItem.noSearch && isNeedChangePosition()"
                   :ref="menuItem.inputRef"
                   role="textbox"
                   class="partname-submenu__input"
                   placeholder="Search"
                   tabindex="0"
                   @keyup.esc="$parent.close()"
                   @keyup.down.stop="handleInputKeyDown"
            />
          </template>
        </div>
      </button>
    </template>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters } from 'vuex'

const VK_LEFT = 37
const VK_UP = 38
const VK_RIGHT = 39
const VK_DOWN = 40

export default {
  props: {
    currentPart: {
      type: Object,
      required: true,
    },
    parts: {
      type: Array,
      required: true
    },
    isOEM: {
      type: Boolean,
      default: false,
    },
    isDirectOrder: {
      type: Boolean,
      default: false,
    },
    properties: {
      type: Object,
      default() {
        return {}
      }
    },
    isCanNonReturnable: {
      type: Boolean,
      default: false,
    },
    isUSED: {
      type: Boolean,
      default: false,
    },
    isRECO: {
      type: Boolean,
      default: false,
    },

    isAFTM: {
      type: Boolean,
      default: false,
    },

  },
  components: {},
  data () {
    return {
      filter: '',
      submenuItems: [],
      currentMenuItem: null,
      selected: 0,
      menu: [
        {
          id: '323',
          label: 'Exclude from Direct Order',
          value: 'excludeFromDirectOrder',
          hasSubmenu: false,
          visible: false
        },
        {
          id: '324',
          label: 'Include to Direct Order',
          value: 'includeToDirectOrder',
          hasSubmenu: false,
          visible: false
        },
        {
          id: '31',
          label: 'Add To Nil Supply List',
          value: 'addToNilSupplyList',
          hasSubmenu: false,
          visible: false
        },
        {
          id: '32',
          label: 'Remove from Nil Supply List',
          value: 'removeToNilSupplyList',
          hasSubmenu: false,
          disabled: false,
          tooltip: '',
          visible: false
        },
        {
          id: '01',
          label: 'Link w/no Cost',
          value: 'link',
          linkType: 'withNoCost',
          hasSubmenu: true,
          submenu: this.getFilteredParts(this.parts),
          inputRef: 'search-input-1',
          visible: false
        },
        {
          id: '02',
          label: 'Unlink w/no Cost',
          value: 'unlink',
          linkType: 'withNoCost',
          hasSubmenu: false,
          visible: false
        },
        {
          id: '13',
          label: 'Link w/Cost',
          value: 'link',
          linkType: 'withCost',
          hasSubmenu: true,
          submenu: this.getFilteredParts(this.parts),
          inputRef: 'search-input-2',
          visible: false
        },
        {
          id: '14',
          label: 'Unlink w/Cost',
          value: 'unlink',
          linkType: 'withCost',
          hasSubmenu: false,
          visible: false
        },
        {
          id: '03',
          label: 'Add Alt Part Name',
          value: 'altPartName',
          hasSubmenu: false,
          visible: true
        },
        {
          id: '11',
          label: 'Mark Not Applicable',
          value: 'markNotApplicable',
          hasSubmenu: false,
          visible: true
        },
        {
          id: '12',
          label: 'UnMark Not Applicable',
          value: 'unMarkNotApplicable',
          hasSubmenu: false,
          visible: true
        },
        {
          id: '40',
          label: 'Mark as Non-Returnable',
          value: 'markAsNonReturnable',
          hasSubmenu: false,
          visible: true
        },
        {
          id: '41',
          label: 'Remove Non-Returnable',
          value: 'removeNonReturnable',
          hasSubmenu: false,
          visible: true
        },
        {
          id: '15',
          label: 'Mark as Freight',
          value: 'markAsFreight',
          hasSubmenu: false,
          visible: true
        },
        {
          id: '04',
          label: 'Edit Alt Part Name',
          value: 'editPartName',
          hasSubmenu: false,
          visible: false,
        },
        {//4
          id: '05',
          label: 'Remove Alt Part Name',
          value: 'removePartName',
          hasSubmenu: false,
          visible: false
        },
        {//5
          id: '06',
          label: 'Add Optional Part',
          value: 'addOptionalPart',
          hasSubmenu: false,
          visible: true,
        },

        {//5
          id: '16',
          label: 'Set Condition',
          value: 'setCondition',
          hasSubmenu: true,
          visible: true,
        },
        {//5
          id: '17',
          label: 'Remove Condition',
          value: 'removeCondition',
          hasSubmenu: false,
          visible: true,
        },
        {//5
          id: '18',
          label: 'Add Photo',
          value: 'addPhoto',
          hasSubmenu: false,
          visible: true,
        },
        {//5
          id: '19',
          label: 'View Photo',
          value: 'viewPhoto',
          hasSubmenu: true,
          visible: true,
        },
        {//5
          id: '20',
          label: 'Remove Photo',
          value: 'removePhoto',
          hasSubmenu: false,
          visible: true,
        },

        {//6
          id: '07',
          label: 'Add Optional Part Price',
          value: 'addOptionalPartPrice',
          hasSubmenu: false,
          visible: true,
        },
        {//7
          id: '08',
          label: 'Set Optional Part Type',
          value: 'setOptionalPartType',
          hasSubmenu: true,
          submenu: [{ name: 'OEM' }, { name: 'USED' }, { name: 'RECO' }],
          noSearch: true,
          visible: true
        },
        {//8
          id: '09',
          label: 'Set Optional Part Type',
          value: 'setOptionalPartType',
          hasSubmenu: true,
          submenu: [{ name: 'OEM' }, { name: 'AFTM' }, { name: 'RECO' }],
          noSearch: true,
          visible: true
        },
        {//9
          id: '10',
          label: 'Remove Optional Part',
          value: 'removeOptionalPart',
          hasSubmenu: false,
          noSearch: true,
          visible: true
        },
        {
          id: '30',
          label: 'Edit NOT APPLICABLE Comment',
          value: 'editNotApplicableComment',
          hasSubmenu: true,
          noSearch: true,
          inputRef: 'not-applicable-comment',
          visible: true
        },
      ]
    }
  },
  mounted () {
    // this.setSubmenuItems();
    this.setMenuVisible(this.currentPart);
  },
  computed: {
    ...mapGetters({
      allParts: 'partList/getParts',
    }),
    computedPosition () {
      if (this.currentMenuItem && this.currentMenuItem.id === '19') {
        return {
          top: '-171px',
        }
      }

      if (this.currentMenuItem && this.currentMenuItem.id === '30') {
        console.log(this.$refs[`option-lv1-${this.currentMenuItem.id}`], 'comment')
        if (this.$refs[`option-lv1-${this.currentMenuItem.id}`]?.[0]) {
          let comment = this.$refs[`option-lv1-${this.currentMenuItem.id}`][0]
          if (window.innerHeight - comment?.getBoundingClientRect()?.bottom < 250) {
            return {
              top: '-108px',
            }
          }
        }
      }

      let length = this.filteredSubmenuItems.length
      if (this.filteredSubmenuItems.length > 5) {
        length = 5
      }
      let top = length * -40
      top -= 10
      if (this.isNeedChangePosition()) {
        return {
          top: `${top}px`,
        }
      }
      return {}
    },
    filteredSubmenuItems () {
      if (this.filter.length > 0 && this.submenuItems.length > 0) {
        return this.submenuItems.filter(item => {
          return _.includes(_.toLower(item.name), _.toLower(this.filter))
        })
      } else {
        return this.submenuItems
      }

    },
    options () {
      return this.$refs['option']
    }
  },
  methods: {
    setMenuVisible(currentPart) {
      this.menu[0].visible = this.isDirectOrder && !currentPart.isExcludeFromDirectOrder
      this.menu[1].visible = this.isDirectOrder && currentPart.isExcludeFromDirectOrder
      this.menu[2].visible = currentPart.isOriginalPart && Number(currentPart.stock !== 7) && this.properties.isEnableNilSupply && !currentPart.isExcludeFromDirectOrder  //#500 visible add to nil supply only for original part from repairer

      this.menu[3].visible = Number(currentPart.stock == 7) && this.properties.isEnableNilSupply && currentPart.isOriginalPart && !currentPart.isExcludeFromDirectOrder;
      this.menu[3].disabled = !_.isEmpty(currentPart.stockNilSupply);
      this.menu[3].tooltip = `The Nil Supply set by multiple combinations of word ${currentPart.stockNilSupply}`;

      this.menu[4].visible = !currentPart.isLinked && this.isOEM && !currentPart.isHasLinked && !currentPart.isExcludeFromDirectOrder// !currentPart.linkId && this.isOEM && !this.isPartHasLinkedParts(currentPart.id);
      this.menu[5].visible = currentPart.isLinked && this.isOEM && currentPart.linkType === 'withNoCost' && !currentPart.isHasLinked && !currentPart.isExcludeFromDirectOrder
      this.menu[6].visible = !currentPart.isLinked && this.isOEM && !currentPart.isHasLinked && !currentPart.isExcludeFromDirectOrder// !currentPart.linkId && this.isOEM && !this.isPartHasLinkedParts(currentPart.id);
      this.menu[7].visible = currentPart.isLinked && this.isOEM && currentPart.linkType === 'withCost' && !currentPart.isHasLinked && !currentPart.isExcludeFromDirectOrder
      this.menu[8].visible = currentPart.altPartName === '' && currentPart.isOriginalPart && !currentPart.isExcludeFromDirectOrder
      this.menu[9].visible = !currentPart.isNotApplicable && !currentPart.isExcludeFromDirectOrder
      this.menu[10].visible = currentPart.isNotApplicable && !currentPart.isExcludeFromDirectOrder
      this.menu[11].visible = !currentPart.nonReturnable && this.isCanNonReturnable && !currentPart.isExcludeFromDirectOrder
      this.menu[12].visible = currentPart.nonReturnable && this.isCanNonReturnable && !currentPart.isExcludeFromDirectOrder
      this.menu[13].visible = currentPart.type === 'manual' && !currentPart.isExcludeFromDirectOrder
      this.menu[14].visible = currentPart.altPartName !== '' && currentPart.isOriginalPart && !currentPart.isExcludeFromDirectOrder
      this.menu[15].visible = currentPart.altPartName !== '' && currentPart.isOriginalPart && !currentPart.isExcludeFromDirectOrder
      this.menu[16].visible = (this.isUSED || this.isAFTM) && !currentPart.isOptional && !currentPart.isExcludeFromDirectOrder

      this.menu[17].visible = (this.isUSED || this.isRECO) && !currentPart.condition && !currentPart.isExcludeFromDirectOrder
      this.menu[18].visible = (this.isUSED || this.isRECO) && currentPart.condition && !currentPart.isExcludeFromDirectOrder
      this.menu[19].visible = (this.isUSED || this.isRECO) && !(currentPart.photoForPart && currentPart.photoForPart.id) && !currentPart.isExcludeFromDirectOrder
      this.menu[20].visible = (this.isUSED || this.isRECO) && currentPart.photoForPart && currentPart.photoForPart.id && !currentPart.isExcludeFromDirectOrder
      this.menu[21].visible = (this.isUSED || this.isRECO) && currentPart.photoForPart && currentPart.photoForPart.id && !currentPart.isExcludeFromDirectOrder

      this.menu[22].visible = false
      this.menu[23].visible = currentPart.isOptional && this.isAFTM && !currentPart.isExcludeFromDirectOrder
      this.menu[24].visible = currentPart.isOptional && this.isUSED && !currentPart.isExcludeFromDirectOrder
      this.menu[25].visible = currentPart.isOptional && !currentPart.isExcludeFromDirectOrder
      this.menu[26].visible = currentPart.isNotApplicable && !currentPart.isExcludeFromDirectOrder
    },
    openModalViewPhoto () {
      const photoUrl = this.currentPart.photoForPart.url
      const isPartName = this.currentPart.name
      const isPartNumber = this.currentPart.partNumber
      this.$emit('photoUrlData', photoUrl, isPartName, isPartNumber)
    },
    disableOverflow (v) {
      console.log(v, 'v')
      if (v.id != '01' && v.id != '13') {
        return
      }
      let body = document.getElementsByTagName('body')
      let nav = document.getElementById('nav-collapse')
      body[0].style.overflow = 'hidden'
      body[0].style['padding-right'] = '7px'
      nav.style['padding-right'] = '7px'
    },
    enableOverflow () {
      let body = document.getElementsByTagName('body')
      let nav = document.getElementById('nav-collapse')
      body[0].style.overflow = 'auto'
      body[0].style['padding-right'] = '0px'
      nav.style['padding-right'] = '0px'
    },
    isNeedChangePosition () {
      if (this.currentMenuItem && this.currentMenuItem.id !== '01' && this.currentMenuItem.id !== '13') {
        return false
      }

      let element = document.getElementById(this.currentPart.id)

      if (!element) {
        return false
      }
      let domRect = element.getBoundingClientRect()
      let spaceBelow = window.innerHeight - domRect.bottom
      //console.log(spaceBelow, 'spaceBelow')
      return !!(spaceBelow < 180)
    },
    getId (ind) {
      return _.findLastIndex(this.menu, (m) => {
        return m.visible
      }) === ind
    },
    getFilteredParts (parts) {
      return _.filter(parts, (prt) => {
        return prt.type !== 'delivery' && !Number(prt.linkId > 0)
      })
    },
    isPartHasLinkedParts (partId) {
      let linkedPart = _.find(this.parts, part => {
        return part.linkId === partId
      })
      return !_.isEmpty(linkedPart)
    },
    setSubmenuItems () {
      this.submenuItems = []
      _.forEach(this.parts, part => {
        if (part.id !== this.currentPart.id && !part.isLinked) {
          this.submenuItems.push(part)
        }
      })
    },
    handleMouseOver (e, item) {
      e.target.focus()
      this.currentMenuItem = item
      if (item.value === 'editNotApplicableComment') {
        this.$nextTick(() => {
          if (this.$refs?.['not-applicable-comment']?.[0]) {
            this.$refs['not-applicable-comment'][0].focus();
          }
        })
      }
    },
    saveComment (event, menu, submenu, index) {
      if (this.currentPart.stockComment.length > 50) {
        this.$toast.error('Limit of 50 characters')
        return
      }
      this.$emit('select', this.currentPart, { menu, submenu, index })
    },
    handleClick (event, menu, submenu, index) {
      this.enableOverflow()
      event.target.focus()
      if (menu.hasSubmenu && !submenu && !index && this.$refs[menu.inputRef]) {
        const searchInput = this.$refs[menu.inputRef][0]
        if (searchInput) {
          searchInput.focus()
        }
      } else {
        this.$emit('select', this.currentPart, { menu, submenu, index })
      }
    },
    handleInputKeyDown (e) {
      const listbox = e.target.nextElementSibling.querySelector('[role="option"]')
      if (!listbox) {
        console.log('no part to select')
        return
      }
      listbox.setAttribute('aria-selected', true)
      listbox.setAttribute('tabindex', 0)
      this.selected = 0
      listbox.focus()
    },
    deselectOption (index) {
      this.options[index].setAttribute('tabindex', -1)
      this.options[index].setAttribute('aria-selected', false)
    },
    selectOption (index) {
      this.options[index].setAttribute('aria-selected', true)
      this.options[index].setAttribute('tabindex', 0)
      this.$nextTick(() => {
        this.options[index].focus()
      })
    },
    handleKeyUp (e, menuItem) {
      e.preventDefault()
      e.stopPropagation()
      // console.log('-------------------')
      // console.log('handleKeyUp', e.key, e.keyCode)
      switch (e.keyCode) {
        case VK_UP:
        case VK_LEFT: {
          if (this.selected === 0) {
            // go to bottom
            this.deselectOption(this.selected)
            this.selected = this.options.length - 1
            this.selectOption(this.selected)
          } else {
            this.deselectOption(this.selected)
            this.selected += -1
            this.selectOption(this.selected)
          }
          break
        }

        case VK_DOWN:
        case VK_RIGHT: {
          if (this.selected === this.filteredSubmenuItems.length - 1) {
            // go to bottom
            this.deselectOption(this.selected)
            this.selected = 0
            this.selectOption(this.selected)
          } else {
            this.deselectOption(this.selected)
            this.selected += 1
            this.selectOption(this.selected)
          }
          break
        }
          // enter
        case 13: {
          break
        }
        case 27: {
          this.$parent.close()
          break
        }
        default: {
          // e.preventDefault();
          this.$nextTick(() => {
            const excludedCode = [9, 16, 27, 20] // tab, shift, capslock
            if (!excludedCode.includes(e.keyCode)) {
              this.filter += e.key
            }
            if (this.$refs[menuItem.inputRef][0]) {
              this.$refs[menuItem.inputRef][0].focus()
            }
          })
        }
      }
    },
    handleFirstMenuFocus (menu) {
      this.filter = ''
      if (menu.submenu) {
        this.submenuItems = menu.submenu
      }
    }
  },
  watch: {
    currentPart: {
      handler (val) {
        // this.setSubmenuItems();
        this.setMenuVisible(val);
      },
      immediate: true,
    },
    parts: {
      handler (val) {
        this.menu[0].submenu = this.getFilteredParts(val)
        this.menu[2].submenu = this.getFilteredParts(val)
      },
      immediate: true,
    }
  }
}
</script>
<style>
.context-with-not-applicable-comment {
  padding-right: 9px !important;
}

.context-with-not-applicable-comment .bx-chevron-right {
  margin-left: 5px !important;
}

.part-name-view-img-context {
  width: 315px;
  padding: 7px;
}

.part-name-view-img-context .context-img {
  max-width: 300px;
  max-height: 300px;
}
</style>
<style scoped>
.not-applicable-comment-context-menu {
  width: 150px;
  height: 150px;
  border: none !important;
}
.not-applicable-comment {
  width: 200px;
  height: 150px;
  resize: none;
  box-sizing: border-box;
  border: 1px solid #bdbdbd;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border-radius: 3px;
  color: #1C1F39;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  padding: 6px;
}

.save-comment-icon {
  position: absolute;
  right: -41px;
  bottom: 8px;
  color: #35495E;
  opacity: 0.7;
  font-size: 25px;
}

.condition-context-menu {
  width: 100px;
}

.condition-context-menu > .partname-menu__item {
  width: 100%;
  height: 42px;
  text-align: left;
  padding-left: 15px;
}
.partname-menu__link{
  color: #363636 !important;
}
.partname-menu__item:hover {
  background-color: rgb(233, 248, 249) !important;
  color: #070707 !important;
}

.partname-menu__link:hover {
  background-color: rgb(233, 248, 249) !important;
  color: #070707 !important;
}

.stock-comment__info {
  color: #35495E;
  font-weight: bold;
  opacity: 0.5;
}
</style>

<!--<style lang="scss">-->
<!--    @import "../../../../styles/style-components";-->
<!--</style>-->
