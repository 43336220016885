<template>
  <div class="d-flex">
    <div v-for="field in fields2" :key="field.key" class="table-cell td-class" :class="['table-cell', 'td-class', field.tdClass]">
      <div v-if="field.key !== 'make'">
        <multiselect
            :options="getSuppliersByType(field.label, item, field.key)"
            :showLabels="false"
            :option-height="29"
            track-by="id"
            v-model="item[field.key]"
            label="name"
            :max-height="203"
            @input="$emit('changedTierItem', item)"
            :close-on-select="true"
            placeholder="Supplier"
        />
      </div>
      <div v-else>{{ item[field.key] }}
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import _ from "lodash";
import {mapGetters} from "vuex";
export default {
  name: "supplier-tier-line",
  components: {
    Multiselect
  },
  props: {
    item: {
      type: Object,
      default: {}
    },
  },
  data() {
    return {
      fields2: [
        { label: 'VCH Make', key: 'make', sortable: true, tdClass: 'font-weight-bold tier-suppliers-table-make', thClass: 'tier-suppliers-table-make' },
        { label: 'OEM-GEN', key: 'oemGen', sortable: false, tdClass: 'clickable ', thClass: '' },
        { label: 'OEM-PAR', key: 'oemPar1', sortable: false, tdClass: 'clickable ', thClass: '' },
        { label: 'OEM-PAR', key: 'oemPar2', sortable: false, tdClass: 'clickable ', thClass: '' },
        { label: 'OEM-PAR', key: 'oemPar3', sortable: false, tdClass: 'clickable ', thClass: '' },
        { label: 'OEM-PAR', key: 'oemPar4', sortable: false, tdClass: 'clickable ', thClass: '' },
        { label: 'OEM-ALT', key: 'oemAlt1', sortable: false, tdClass: 'clickable ', thClass: '' },
        { label: 'OEM-ALT', key: 'oemAlt2', sortable: false, tdClass: 'clickable ', thClass: '' },
        { label: 'OEM-ALT', key: 'oemAlt3', sortable: false, tdClass: 'clickable ', thClass: '' },
        { label: 'OEM-ALT', key: 'oemAlt4', sortable: false, tdClass: 'clickable ', thClass: '' },
        { label: 'CERT-AFTM', key: 'certAftm1', sortable: false, tdClass: 'clickable ', thClass: '' },
        { label: 'CERT-AFTM', key: 'certAftm2', sortable: false, tdClass: 'clickable ', thClass: '' },
        { label: 'AFTM', key: 'aftm1', sortable: false, tdClass: 'clickable ', thClass: '' },
        { label: 'AFTM', key: 'aftm2', sortable: false, tdClass: 'clickable ', thClass: '' },
        { label: 'USED', key: 'used1', sortable: false, tdClass: 'clickable ', thClass: '' },
        { label: 'USED', key: 'used2', sortable: false, tdClass: 'clickable ', thClass: '' },
        { label: 'USED', key: 'used3', sortable: false, tdClass: 'clickable ', thClass: '' },
        { label: 'USED', key: 'used4', sortable: false, tdClass: 'clickable ', thClass: '' },
      ],
    }
  },
  methods: {
    getSuppliersByType(type, item, field) {
      console.log('getSuppliersByType')
      let data = _.filter(this.computedSuppliersForTier1, (s) => {
        if (!_.includes(s.types, type) || !_.includes(s.brands, _.toUpper(item.make))) {
          return false
        }
        if (type === 'OEM-PAR') {
          if (field === 'oemPar1') {
            return this.checkId(s.id, item, ['oemPar2', 'oemPar3', 'oemPar4'])
          } else if (field === 'oemPar2') {
            return this.checkId(s.id, item, ['oemPar1', 'oemPar3', 'oemPar4'])
          } else if (field === 'oemPar3') {
            return this.checkId(s.id, item, ['oemPar1', 'oemPar2', 'oemPar4'])
          } else if (field === 'oemPar4') {
            return this.checkId(s.id, item, ['oemPar1', 'oemPar2', 'oemPar3'])
          }
        } else if (type === 'OEM-ALT') {
          if (field === 'oemAlt1') {
            return this.checkId(s.id, item, ['oemAlt2', 'oemAlt3', 'oemAlt4'])
          } else if (field === 'oemAlt2') {
            return this.checkId(s.id, item, ['oemAlt1', 'oemAlt3', 'oemAlt4'])
          } else if (field === 'oemAlt3') {
            return this.checkId(s.id, item, ['oemAlt1', 'oemAlt2', 'oemAlt4'])
          } else if (field === 'oemAlt4') {
            return this.checkId(s.id, item, ['oemAlt1', 'oemAlt2', 'oemAlt3'])
          }
        } else if (type === 'AFTM') {
          if (field === 'aftm1') {
            return this.checkId(s.id, item, ['aftm2'])
          } else if (field === 'aftm2') {
            return this.checkId(s.id, item, ['aftm1'])
          }
        } else if (type === 'CERT-AFTM') {
          if (field === 'certAftm1') {
            return this.checkId(s.id, item, ['certAftm2'])
          } else if (field === 'certAftm2') {
            return this.checkId(s.id, item, ['certAftm1'])
          }
        } else if (type === 'USED') {
          if (field === 'used1') {
            return this.checkId(s.id, item, ['used2', 'used3', 'used4'])
          } else if (field === 'used2') {
            return this.checkId(s.id, item, ['used1', 'used3', 'used4'])
          } else if (field === 'used3') {
            return this.checkId(s.id, item, ['used1', 'used2', 'used4'])
          } else if (field === 'used4') {
            return this.checkId(s.id, item, ['used1', 'used2', 'used3'])
          }
        } else {
          return true
        }
      })

      return _.map(data, (s) => {
        return {
          name: s.name,
          id: s.id,
          types: s.types
        }
      })
    },
    checkId(id, item, fields) {
      return !_.some(fields, (f) => {
        return Number(item?.[f]?.id) === Number(id)
      })
    },
  },
  computed: {
    ...mapGetters({
      gettersPreferedSuppliers: 'repairer/supplier/getPreferedSuppliers',
    }),
    computedSuppliersForTier1() {
      let data = _.sortBy(_.cloneDeep(this.gettersPreferedSuppliers), [function (sp) {
        return _.trim(_.toLower(sp.name))
      }])
      return _.map(data, (s) => {
        return {
          brands: _.map(s.brands, function (n) {return _.toUpper(n)}),
          make: s.make,
          name: s.name,
          id: s.id,
          types: s.types,
        }
      })
    },
  }
}
</script>

<style scoped>
.table-cell {
  flex: 1;
  padding: 8px;
  border-bottom: 1px solid rgba(27, 30, 56, 0.25);
}
.td-class {
  min-width: 190px;
  height: 48px;
}
.tier-suppliers-table-make {
  display: flex;
  align-items: center;
  position: sticky;
  left: 0;
  background-color: #fff;
  z-index: 99;
  min-width: 120px !important;
  max-width: 120px;
  width: 120px;
}


</style>
