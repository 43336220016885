<template>
  <div class="parts-order-view-page">
    <div class="page-header" v-if="isOrderLoaded">
      <div class="d-flex justify-content-between">
        <div class="">
          <h4>{{ pageTitle }}</h4>
          <ol class="breadcrumbs">
            <li class="breadcrumb-item home">
              <router-link to="/s">Dashboard</router-link>
            </li>
            <li class="breadcrumb-item">
              <span @click="viewOrders" style="cursor: pointer">View Parts Order</span>
              <!--                    <router-link :to="'/s/orders'">View Parts Order</router-link>-->
            </li>
            <li class="breadcrumb-item active">
              <span class="d-inline-block d-lg-none">View Parts {{ order.status | maxFour }}</span>
              <span class="d-none d-lg-inline-block">View Parts {{ order.status }} </span>
            </li>
          </ol>
        </div>
        <div class="search-filter-in-header d-flex">
          <window-notification></window-notification>
          <search-filter
              type="estimates"
              v-model="searchFilter"
              :filterValue="searchFilter"
              :placeholder="'Search'"
              :isSearch="true"
              @selectAdditional="redirectToRfqView"
              @onEnter="onEnter"
              :additionalMenu="additionalMenu"
              height="41px"
              aria-expanded="false"
              data-toggle="dropdown"
          />
        </div>
      </div>

      <!-- mobile options -->
      <div class="page-header-nav-options dropdown-actions">
        <b-dropdown id="parts-order-view-options-mobile" right text="Options" class="dropdown-options"
                    :class="mobileOptionsClass" no-flip>
          <template v-slot:button-content>Active
            <span class="dropdown-arrow">
                            <i class='bx bx-down-arrow'></i>
                        </span>
          </template>
          <template v-if="isOrderLoaded">
            <template v-if="order && order.id && order.isProcessing()">
              <b-dropdown-item v-if="!isImportPDFInvoice" @click="importPDFInvoice">Import PDF Invoice
              </b-dropdown-item>
              <b-dropdown-item @click="onClickedChangeDelivery">Change Delivery ETA</b-dropdown-item>
              <b-dropdown-item :class="{'disabled-button': isDisabledMarkPartsShipped}" :disabled="isDisabledMarkPartsShipped" @click="markPartsShipped">Mark Parts Shipped</b-dropdown-item>
            </template>
            <template v-if="order && order.id && order.isOpen()">
              <b-dropdown-item @click="onClickedChangeDelivery">Change Delivery ETA</b-dropdown-item>
            </template>
            <template v-if="order && order.id && order.isDenied()">
              <b-dropdown-item  @click="$refs['reinstate-order-modal'].show();">Reinstate Denied Order</b-dropdown-item>
            </template>
            <template v-if="order && order.id && order.isCancelled()">
              <b-dropdown-item @click="reinstateOrder(false)">Reinstate Cancelled Order</b-dropdown-item>
            </template>
            <template v-if="order && order.id && order.isShipped()">
              <b-dropdown-item @click="markPartsUndoShipped">Undo Parts Shipped</b-dropdown-item>
              <b-dropdown-item @click="onClickedChangeDelivery">Change Delivery ETA</b-dropdown-item>
            </template>
            <template v-if="order && order.id && order.isDelivered()">
              <b-dropdown-item @click="onClickedChangeDelivery">Change Delivery ETA</b-dropdown-item>
            </template>
            <template v-if="order && order.id && order.isOpen()">
              <b-dropdown-item @click="markAsProcessing">Mark as Processing</b-dropdown-item>
            </template>
            <template v-if="order && order.id && (order.isOpen() || order.isProcessing())">
              <b-dropdown-item @click="denyOrderShowModal">Deny Order</b-dropdown-item>
              <b-dropdown-item @click="$refs['cancel-order-modal'].show()">Cancel Order</b-dropdown-item>
            </template>
            <b-dropdown-item v-if="order && order.id && order.isDelivered()" @click="markOrderCompleted">
              Mark Order Completed
            </b-dropdown-item>
          </template>

        </b-dropdown>
      </div>
    </div>
    <div class="header-scroll-position-fixed" v-if="isShowStickyHeader">
      <div>
        <vin-date
            class="header-scroll-position-fixed-vin-date"
            v-if="isOrderLoaded"
            :isEdit="isEditOrder || (order && order.id && order.isOpen())"
            :isShowOrderNbr="true"
            :isShowInvoiceNbr="true"
            :order-number="order.number"
            :invoice-number="order.invoiceNumber"
            :code="order.trackingCode"
            :vin="order.vehicle.vin"
            :status="order.status"
            :isShowRFQNbr="false"
            :isShowQuoteNbr="true"
            :quoteNumber="order && order.id ? order.dmsQuoteNumber : ''"
            :rfq-number="order && order.rfq && order.rfq.number ? order.rfq.number : ''"
            @goToRfq="goToRfq"
            :date="order.vehicle.dom ? order.vehicle.dom: ''"
            @onChangeCode="updateCode"
            @onChangeInvoiceNbr="onChangeInvoiceNbr"
        ></vin-date>
      </div>
      <div>
        <sticky-header
            class="position-sticky-header"
            :isEditOrder="isEditOrder"
            :isCheckedAll="isCheckedAllValue"
            @selectedAllStock="selectedAllStock"
            @onChangedCheckedAll="onChangedCheckedAll"
            @sortBy="sortBy"
        ></sticky-header>
      </div>
    </div>
    <div class="parts-order-view-body">
      <tabs
          :ref="'part-order-view-tabs'"
          @changed="changedTab"
          :options="{useUrlFragment: false, defaultTabHash: 'ordered-parts'}"
          :isNeedSaveInStorage="false"
          :custom-counts="computedCustomCountForTab">
        <tab name="Details" id="ordered-details" :class-name="'parts-order-view-tab-1'">
          <template v-if="isOrderLoaded">
            <div class="order-parts-headerinformation">
              <div class="part-list-qoute-pricing-progress">
                <OrderProgress v-if="isOrderLoaded"
                               :steps="steps"
                               :step="currentQPPStep"
                               title="Order Progress">
                </OrderProgress>
              </div>

              <div class="w-100 d-block d-xl-none"></div>

              <div>
                <template v-if="isOrderLoaded">
                  <div class="delivery-required-time ml-4">
                    <ps-label-value label="Order Placed">{{
                        formatDateTime(order.dateCreated)
                      }}
                    </ps-label-value>
                    <ps-label-value label="Parts Delivery Required">
                      {{
                        order.dateDue && order.dateDue !== 'null' ? order.dateDue : 'Next available run'
                      }}
                    </ps-label-value>
                    <template  v-if="getSupplierDeliveryDate || isClickedChangeDeliveryDate">
                      <div v-if="!isEditDeliveryDate" class="ps-label-value" :class="{'new-delivery-blinker': isCanBlinking}">
                        <span class="ps-label-value__label" style="color: orange">New Delivery ETA:</span>
                        <span class="ps-label-value__value">
                        <span style="color: orange">{{getSupplierDeliveryDate}}</span>
                      </span>
                      </div>
                      <div v-else class="ps-label-value">
                        <span class="ps-label-value__label " :class="{'new-delivery-blinker': isCanBlinking}" style="color: orange">New Delivery ETA:</span>
                        <span class="ps-label-value__value">
                          <date-picker v-if="isEditDeliveryDate" v-model="order.supplierDeliveryDate" @onChange="debouncedDeliveryDateChanged"></date-picker>
                        </span>
                      </div>
                    </template>


                    <div class="ps-label-value">
                      <span class="ps-label-value__label">Special Instruction:</span>
                      <span class="ps-label-value__value">
                            <div v-if="!isCanEditSupplierInstruction" :class="{'direct-order-blinker': isCanBlinking && order.properties.isSentAsDirectOrder}" class="ps-scroll font-inter--bold p-0 special-instruction-block editable-text-area" v-html="orderInstruction"></div>
                            <div v-else class="ps-scroll font-inter--bold p-0 special-instruction-block" style="border-radius: 3px; border: 1px solid rgba(28, 31, 57, 0.25)">
                              <div style="padding: 0px 5px" class="editable-text-area editable-text-area-2" v-html="orderInstructionWithoutSupplier"></div>
                              <textarea @change="saveSupplierInstruction" ref="supplier-instruction-details"  class="comment-textarea" style="height: 80px; padding: 0px 5px;" v-model="order.deliveryTo.supplierInstruction"></textarea>
                            </div>
                        </span>
                    </div>
                  </div>
                </template>
              </div>

              <div>
                <customer-account-deliver v-if="isOrderLoaded"
                                          :customer="order.customer.name"
                                          :estimator="order.estimator"
                                          account=""
                                          :delivery-to="order.deliveryTo">
                </customer-account-deliver>
              </div>
            </div>
            <div class="wizard__divider"></div>
            <div v-if="isOrderLoaded" class="vehicle-details-block block">
              <div class="block-header bg-navyblue">
                Vehicle Details
              </div>
              <div class="block-body bg-white">
                <div v-if="order && order.id && order.isProcessing()" class="form-group row">
                  <label class="col col-form-label">Repairer Purchase Order Nbr</label>
                  <div class="col-8">
                    <input type="text" class="form-control" v-model="order.number" :disabled="true">
                  </div>
                </div>
                <div v-else class="form-group row">
                  <label class="col col-form-label">Repairer Quoting Package Nbr</label>
                  <div class="col-8">
                    <input type="text" class="form-control" v-model="order.QPNumber"
                           :disabled="true">
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col col-form-label">Repairer Name</label>
                  <div class="col-8">
                    <input type="text" class="form-control" placeholder=""
                           v-model="order.customer.name"
                           :disabled="true">
                  </div>
                </div>


                <div class="form-group row">
                  <label class="col col-form-label">Rego</label>
                  <div class="col-8">
                    <input type="text" class="form-control" placeholder=""
                           v-model="order.vehicle.rego"
                           :disabled="true">
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col col-form-label">VIN Number</label>
                  <div class="col-8">
                    <input type="text" class="form-control" v-model="order.vehicle.vin"
                           :disabled="true">
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col col-form-label">Build Date</label>
                  <div class="col-8">
                    <input type="text" class="form-control" v-model="order.vehicle.dom"
                           :disabled="true">
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col col-form-label">Make</label>
                  <div class="col-8">
                    <input type="text" class="form-control" v-model="order.vehicle.make"
                           :disabled="true">
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col col-form-label">Model</label>
                  <div class="col-8">
                    <input type="text" class="form-control" v-model="order.vehicle.model"
                           :disabled="true">
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col col-form-label">Series</label>
                  <div class="col-8">
                    <input type="text" class="form-control" placeholder=""
                           v-model="order.vehicle.series" :disabled="true">
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col col-form-label">Colour</label>
                  <div class="col-8">
                    <input type="text" class="form-control" placeholder=""
                           v-model="order.vehicle.colour" :disabled="true">
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col col-form-label">Body</label>
                  <div class="col-8">
                    <input type="text" class="form-control" placeholder=""
                           v-model="order.vehicle.body"
                           :disabled="true">
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col col-form-label">Transmission</label>
                  <div class="col-8">
                    <select class="form-control" v-model="order.vehicle.transmission"
                            placeholder="Select vehicle transmission" :disabled="true">
                      <option value="Automatic">Automatic</option>
                      <option value="Manual">Manual</option>
                    </select>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col col-form-label">Odometer</label>
                  <div class="col-8">
                    <input type="text" class="form-control" placeholder=""
                           v-model="order.vehicle.odometer" :disabled="true">
                  </div>
                </div>
                <div class="form-group row" style="padding-bottom: 30px">
                  <label class="col col-form-label">Creation Date - Time</label>
                  <div class="col-8">
                    <input type="text" class="form-control" placeholder=""
                           :value="formatDateTime(order.dateCreated)"
                           :disabled="true">
                  </div>
                </div>
              </div>
            </div>
          </template>
        </tab>
        <tab name="Part List" id="ordered-parts" :class-name="'parts-order-view-tab-2 '">
          <template v-if="isOrderLoaded">
            <div class="order-parts-headerinformation"
                 :class="{'import-pdf-additional-block': isImportPDFInvoice}">
              <template v-if="!isImportPDFInvoice">
                <div class="part-list-qoute-pricing-progress">
                  <OrderProgress v-if="isOrderLoaded"
                                 :steps="steps"
                                 :step="currentQPPStep"
                                 title="Order Progress">
                  </OrderProgress>
                </div>

                <div class="w-100 d-block d-xl-none"></div>

                <div>
                  <template v-if="isOrderLoaded">
                    <div class="delivery-required-time ml-4">
                      <ps-label-value label="Order Placed">
                        {{ formatDateTime(order.dateCreated) }}
                      </ps-label-value>
                      <ps-label-value label="Parts Delivery Required">
                        {{
                          order.dateDue && order.dateDue !== 'null' ? order.dateDue : 'Next available run'
                        }}
                      </ps-label-value>
                      <template  v-if="getSupplierDeliveryDate || isClickedChangeDeliveryDate">
                        <div v-if="!isEditDeliveryDate" class="ps-label-value " :class="{'new-delivery-blinker': isCanBlinking}">
                          <span class="ps-label-value__label" style="color: orange">New Delivery ETA:</span>
                          <span class="ps-label-value__value">
                        <span style="color: orange">{{getSupplierDeliveryDate}}</span>
                      </span>
                        </div>
                        <div v-else class="ps-label-value">
                          <span class="ps-label-value__label " :class="{'new-delivery-blinker': isCanBlinking}" style="color: orange">New Delivery ETA:</span>
                          <span class="ps-label-value__value">
                          <date-picker v-if="isEditDeliveryDate" v-model="order.supplierDeliveryDate" @onChange="debouncedDeliveryDateChanged"></date-picker>
                        </span>
                        </div>
                      </template>

                      <div class="ps-label-value">
                        <span class="ps-label-value__label">Special Instruction:</span>
                        <span class="ps-label-value__value">
                            <div v-if="!isCanEditSupplierInstruction" :class="{'direct-order-blinker': isCanBlinking && order.properties.isSentAsDirectOrder}" class="ps-scroll font-inter--bold p-0 special-instruction-block editable-text-area" v-html="orderInstruction"></div>
                            <div v-else class="ps-scroll font-inter--bold p-0 special-instruction-block" style="border-radius: 3px; border: 1px solid rgba(28, 31, 57, 0.25)">
                              <div style="padding: 0px 5px" class="editable-text-area editable-text-area-2" v-html="orderInstructionWithoutSupplier"></div>
                              <textarea @change="saveSupplierInstruction" ref="supplier-instruction-part-list"  class="comment-textarea" style="height: 80px; padding: 0px 5px;" v-model="order.deliveryTo.supplierInstruction"></textarea>
                            </div>
                        </span>
                      </div>
                    </div>
                  </template>
                </div>

                <div>
                  <customer-account-deliver v-if="isOrderLoaded"
                                            :customer="order.customer.name"
                                            :estimator="order.estimator"
                                            account=""
                                            :delivery-to="order.deliveryTo">
                  </customer-account-deliver>
                </div>
              </template>
              <div
                  v-else-if="isImportPDFInvoice && !isLoadingInvoiceParts && !isLoadPartsForImporting && !isStartImportingParts"
                  style="text-align: center" class="col-12 col-lg-9 col-lg ml-lg-0 m-lg-0">
                <p style="padding-top: 15px; font-weight: bold; font-size: 14px">Import DMS Invoice
                  PDF</p>
                <p><strong>Recommendation:</strong> Your Order part list order must match that of
                  the<br/> RFQ from witch the Order was created from. Changes, like Qtys and<br/>
                  stocks levels can be done after import.</p>
              </div>
              <div v-else-if="isImportPDFInvoice && isLoadingInvoiceParts"
                   style="text-align: center; height: 120px; padding-top: 50px;"
                   class="col-12 col-lg-9 col-lg ml-lg-0 m-lg-0">
                <p style="font-size: 14px; font-weight: bold">Importing and Preparing your Invoice</p>
              </div>
              <div
                  v-else-if="isImportPDFInvoice && !isLoadingInvoiceParts && isLoadPartsForImporting && !isStartImportingParts"
                  style="text-align: center; height: 120px; padding-top: 34px;"
                  class="col-12 col-lg-9 col-lg ml-lg-0 m-lg-0">
                <p style="font-size: 14px; font-weight: bold">Imported Invoice Check</p>
                <p>Confirm the Invoice information below is correct</p>
              </div>
              <div
                  v-else-if="isImportPDFInvoice && !isLoadingInvoiceParts && isLoadPartsForImporting && isStartImportingParts"
                  style="text-align: center; height: 120px; padding-top: 50px;"
                  class="col-12 col-lg-9 col-lg ml-lg-0 m-lg-0">
                <p style="font-size: 14px; font-weight: bold">Importing Your Quote</p>
              </div>
            </div>
            <div class="wizard__divider"></div>

            <div v-if="!isImportPDFInvoice" class="">
              <div class="" id="additional-info">
                <vin-date
                    v-if="isOrderLoaded"
                    :isEdit="isEditOrder || (order && order.id && order.isOpen())"
                    :isShowOrderNbr="true"
                    :isShowInvoiceNbr="true"
                    :order-number="order.number"
                    :invoice-number="order.invoiceNumber"
                    :code="order.trackingCode"
                    :vin="order.vehicle.vin"
                    :status="order.status"
                    :isShowRFQNbr="false"
                    :isShowQuoteNbr="true"
                    :quoteNumber="order && order.id ? order.dmsQuoteNumber : ''"
                    :rfq-number="order && order.rfq && order.rfq.number ? order.rfq.number : ''"
                    @goToRfq="goToRfq"
                    :date="order.vehicle.dom ? order.vehicle.dom: ''"
                    @onChangeCode="updateCode"
                    @onChangeInvoiceNbr="onChangeInvoiceNbr"
                ></vin-date>
              </div>

              <div class="supplier-order-view-table">
              <b-table
                    responsive
                    hover
                    selectable
                    ref="orderTable"
                    :select-mode="'range'"
                    :items="orderItems"
                    :fields="orderFields"
                    :sort-by.sync="sortByTable"
                    :sort-desc.sync="sortDesc"
                    :id="'order-table'"
                    :primary-key="'_index'"
                    :tbody-tr-class="rowClass"
                    style="scrollbar-width: auto !important;"
                    @row-selected="handleRowSelected"
                    @row-clicked="handleRowClicked"
                    @row-contextmenu="openContext"
                    class="mb-15 mt-15 order-part-list parts-order-margin-table">

                  <template v-slot:table-colgroup="scope">
                    <col class="parts-order-view__row parts-order-view__sticky" width="1%">
                    <col class="parts-order-view__row parts-order-view__h45px">
                    <col class="parts-order-view__row parts-order-view__row--xs">
                    <col class="parts-order-view__row parts-order-view__row--lg">
                    <col class="parts-order-view__row parts-order-view__row--sm">
                    <col class="parts-order-view__row" width="1%">
                    <col class="parts-order-view__row" width="1%">
                    <col class="parts-order-view__row" width="1%">
                    <col class="parts-order-view__row parts-order-view__row--sm">
                    <col class="parts-order-view__row parts-order-view__row--sm">
                    <col class="parts-order-view__row" width="1%">
                    <col class="parts-order-view__row parts-order-view__row--sm">
                    <col class="parts-order-view__row parts-order-view__row--sm">
                    <col class="parts-order-view__row parts-order-view__row--sm">
                  </template>

                  <template v-slot:head(stock)="data">
                    <div v-if="!isEditOrder">
                      <p class="m-0">Stock Status</p>
                    </div>
                    <div v-else>
                      <p class="m-0">Stock Status</p>
                      <template>
                        <stock-select
                            :id="0"
                            :options="stockOptions"
                            v-model="stockForAll"
                            :isNeedDateRange="true"
                            :isStockAll="true"
                            @onSelected="selectedAllStock"
                            class="stock-select-header"
                            style="top: auto !important; margin-top: 7px;"
                        >
                        </stock-select>
                      </template>
                    </div>
                  </template>

                  <template v-slot:head(line)="data">
                    <div class="d-flex">
                      <label class="form-check-inline checkbox">
                        <input class="form-check-input"
                               type="checkbox"
                               id="all-line-check"
                               v-model="isCheckedAll"
                        >
                        <span class="icon clickable"><i
                            class='bx bx-check'></i></span>
                      </label>
                      <span style="line-height: 29px">Line</span>
                    </div>
                  </template>

                  <template v-slot:cell(line)="row">
                    <div class="d-flex">
                      <label class="form-check-inline checkbox">
                        <input class="form-check-input"
                               type="checkbox"
                               @change="onCheckboxChange(row.item, row.item.isChecked)"
                               v-model="row.item.isChecked"
                        >
                        <span class="icon clickable"><i
                            class='bx bx-check'></i></span>
                      </label>
                      <span class="part-list-line-number text-center">{{ row.index + 1 }}</span>
                    </div>
                  </template>


                  <!-- <template v-slot:head(price)="data">
                    <div class='table-sorting'>
                      <div class="supplier-icon text-center">
                        <img v-if="companyInfo.logoImgPath" width="20" :src="companyInfo.logo">
                        <span v-else class='bx bx-car'></span>
                      </div>
                      {{ companyInfo.businessName }}
                      <i></i>
                    </div>
                  </template> -->

                  <template v-slot:cell(part_list)="row">
                    <template v-if="isEditOrder && row.item.isCanDelete">
                      <div class="d-flex flex-row align-items-center">
                        <b-form-input @focus="onFocus('partName-' + row.item.id)" @blur="onBlur(row.item, row.item.type === 'delivery' ? 'deliveryName' : 'name')" @change="autoSavePart(row.item)" :ref="getRef(row.item)" v-model="row.item.part_list"></b-form-input>
                        <i
                            v-if="row.item.isCanDelete && isEditOrder"
                            style="font-size: 24px;"
                            @click="deletePart(row.item)"
                            class='bx bx-x-circle font-20  mr-2 remove-part ml-2'
                            v-b-tooltip.hover="{customClass: 'ps-tooltip ps-tooltop--table', html: true, title: 'Remove', placement:'leftbottom'}">
                        </i>
                      </div>
                    </template>
                    <template v-else>
                      <div>
                      <span style="color: #85868e">
                        {{ row.item.part_list }}
                      </span>
                        <br/>
                        <span>
                        {{row.item.altPartName}}
                      </span>
                        <div>
                          <p v-if="row.item.cancelStatus === 'CancelledByCustomer'"
                             v-b-tooltip.hover="{customClass: 'ps-tooltip reinstate-cancel-tooltip', html: true, title: row.item.cancelComment ? `Comment: <br>${row.item.cancelComment}` : '', placement:'leftbottom'}"
                             class="text-danger py-2 m-0 d-inline-block">
                            <span v-if="order.status === 'Denied'">Denied</span>
                            <span v-else>Cancelled</span> by Customer</p>
                          <p v-else-if="row.item.cancelStatus === 'CancelledBySupplier'"
                             v-b-tooltip.hover="{customClass: 'ps-tooltip reinstate-cancel-tooltip', html: true, title: row.item.cancelComment ? `Comment: <br>${row.item.cancelComment}` : '', placement:'leftbottom'}"
                             class="text-danger py-2 m-0 d-inline-block">
                            <span v-if="order.status === 'Denied'">Denied</span>
                            <span v-else>Cancelled</span> by Supplier</p>
                          <p v-else-if="row.item.cancelStatus === 'ReinstatedBySupplier' || row.item.cancelStatus === 'ReinstatedByCustomer'"
                             class="text-success py-2 m-0 d-inline-block"
                             v-b-tooltip.hover="{customClass: 'ps-tooltip reinstate-cancel-tooltip', html: true, title: row.item.reinstateComment ? `Comment: <br>${row.item.reinstateComment}` : '', placement:'leftbottom'}">
                            <span v-if="row.item.cancelStatus === 'ReinstatedBySupplier'">Reinstate by Supplier</span>
                            <span v-else>Reinstate by Customer</span>
                          </p>
                        </div>
                      </div>
                    </template>
                  </template>

                  <template v-slot:cell(part_number)="row">
                    <template v-if="isEditOrder && row.item.isCanDelete">
                      <b-form-input @focus="onFocus('partNumber-' + row.item.id)" @blur="onBlur(row.item, 'partNumber')" @change="autoSavePart(row.item)" v-model="row.item.part_number"></b-form-input>
                    </template>
                    <template v-else>
                      {{ row.item.part_number }}
                    </template>

                    <div v-if="isHasShortComment(row.item)"
                         class="part-number-stock-gray"
                         style="text-align: center; padding-left: 0px"
                         :class="{
                        'part-number-stock-orange': row.item.stock === 6 && isCanBlinking,
                        'part-number-stock-red': row.item.stock === 4 && isCanBlinking,
                       }">
                      {{ getShortComment(row.item) }}
                    </div>
                  </template>

                  <template v-slot:cell(customer_order)="row">
                    {{ order.number }}
                  </template>

                  <!--                <template v-slot:top-row="row">-->
                  <!--                  <b-td class="" colspan="5"></b-td>-->
                  <!--                  <b-td class="subheader subheader-p-l text-right" colspan="6">Pricing Completed-->
                  <!--                  </b-td>-->
                  <!--                  <b-td class="p-c-value text-left pl-4" colspan="1">{{-->
                  <!--                      order.pricingCompletedPercent-->
                  <!--                    }}%-->
                  <!--                  </b-td>-->
                  <!--                </template>-->

                  <template v-slot:cell(status_id)="row">
                    <div class="d-flex ">
                      <template v-if="row.item.type !== 'delivery'">
                        <a v-if="row.item.part_status === 'Shipped'" href="#"
                           @click.prevent.stop="handleIconClicked(row)">
                          <i class="bx bxs-truck text-primary"
                             style="font-size: 24px;"
                             v-b-tooltip.hover="{customClass: 'ps-tooltip-status-icon ps-tooltip-status-icon-changed', title: 'Shipped', placement:'leftbottom', html: true}"></i>
                        </a>
                        <a v-else-if="row.item.part_status === 'IncorrectPartOrdered'" href="#"
                           @click.prevent.stop="handleIconClicked(row)">
                          <i class="bx bxs-flag-alt"
                             style="font-size: 24px; color: red;"
                             v-b-tooltip.hover="{customClass: 'ps-tooltip-status-icon ps-tooltip-status-icon-changed', title: getTooltip(row.item, 'Incorrect Part Ordered'), placement:'leftbottom', html: true}"></i>
                        </a>
                        <a v-else-if="row.item.part_status === 'DamagedPartExWarehouse'" href="#"
                           @click.prevent.stop="handleIconClicked(row)">
                          <i class="bx bxs-factory text-warning"
                             style="font-size: 24px; color: red;"
                             v-b-tooltip.hover="{customClass: 'ps-tooltip-status-icon ps-tooltip-status-icon-changed', title: getTooltip(row.item, 'Damaged Part Ex Warehouse'), placement:'leftbottom', html: true}"></i>
                        </a>
                        <a v-else-if="row.item.part_status === 'NoLongerNeeded'" href="#"
                           @click.prevent.stop="handleIconClicked(row)">
                          <i class="bx bxs-flag-alt text-warning"
                             style="font-size: 24px;"
                             v-b-tooltip.hover="{customClass: 'ps-tooltip-status-icon ps-tooltip-status-icon-changed', title: getTooltip(row.item, 'No Longer Needed'), placement:'leftbottom', html: true}"></i>
                        </a>
                        <a v-else-if="row.item.part_status === 'ChangeInRepairMethod'" href="#"
                           @click.prevent.stop="handleIconClicked(row)">
                          <i class="bx bxs-flag-alt text-warning"
                             style="font-size: 24px;"
                             v-b-tooltip.hover="{customClass: 'ps-tooltip-status-icon ps-tooltip-status-icon-changed', title: getTooltip(row.item, 'Change in Repair Method'), placement:'leftbottom', html: true}"></i>
                        </a>
                        <a v-else-if="row.item.part_status === 'IncorrectPartDelivered'" href="#"
                           @click.prevent.stop="handleIconClicked(row)">
                          <i class="bx bxs-flag-alt text-warning"
                             style="font-size: 24px;"
                             v-b-tooltip.hover="{customClass: 'ps-tooltip-status-icon ps-tooltip-status-icon-changed', title: getTooltip(row.item, 'Incorrect Part Delivered') , placement:'leftbottom', html: true}"></i>
                        </a>
                        <a v-else-if="row.item.part_status === 'ReturnDamagedPart'" href="#"
                           @click.prevent.stop="handleIconClicked(row)">
                          <i class="bx bx-transfer-alt text-warning"
                             style="font-size: 24px;"
                             v-b-tooltip.hover="{customClass: 'ps-tooltip-status-icon ps-tooltip-status-icon-changed', title: getTooltip(row.item, 'Damaged Part in Transport'), placement:'leftbottom', html: true}"></i>
                        </a>
                        <a v-else-if="row.item.stock === 4 && row.item.part_status !== 'CancelPart'" href="#" @click.prevent.stop="handleIconClicked(row)">
                          <i class="bx bx-purchase-tag-alt processing-icon-orange"
                             style="font-size: 24px"
                             v-b-tooltip.hover="{customClass: 'ps-tooltip', title: 'On Back Order' , placement:'leftbottom'}"></i>
                        </a>
                        <a v-else-if="order.status === 'Processing' && (row.item.part_status === 'Created' || row.item.part_status === 'New' || row.item.part_status === 'RequestCredit')"
                           href="#" @click.prevent.stop="handleIconClicked(row)">
                          <i class="bx bx-package"
                             style="font-size: 24px"
                             :class="{
                         'gray-icon': row.item.stock !== 6 && row.item.stock !== 1,
                         'processing-icon-orange': row.item.stock === 6,
                         'text-success': row.item.stock === 1,
                       }"
                             v-b-tooltip.hover="{customClass: 'ps-tooltip', title: 'Order Processed' , placement:'leftbottom'}"></i>
                        </a>
                        <a v-else href="#" @click.prevent.stop="handleIconClicked(row)">
                          <i :class="statusList[row.value].iconClass"
                             v-b-tooltip.hover="{customClass: 'ps-tooltip', title: statusList[row.item.status_id].name , placement:'leftbottom'}"></i>
                        </a>

                        <i @click.prevent.stop="handleIconClicked(row)" v-if="showRequestCredit(row.item)"
                           v-b-tooltip.hover="{customClass: 'ps-tooltip-status-icon ps-tooltip-status-icon-changed', title: getIconTooltipForCredit(row.item), placement:'leftbottom', html: true}"
                           :class="{
                       'red-icon': row.item && row.item.credit && (row.item.credit.creditPartStatus === 'Denied' || row.item.credit.creditPartStatus === 'Cancelled'),
                       'orange-icon':  row.item && row.item.credit && row.item.credit.creditPartStatus === 'UnderReview',
                       'green-icon':  row.item && row.item.credit && row.item.credit.creditPartStatus === 'Approved',
                     }"
                           class="ml-1 bx bx-dollar-circle"></i>

                      <i @click.prevent.stop="showModal1(row.item)" v-if="isHasOrder(row.item)"
                         v-b-tooltip.hover="{customClass: 'ps-tooltip-status-icon ps-tooltip-status-icon-changed', title: 'Back Order Acceptance created' + '<br/>' + formatDateTime(getMainOrder(row.item) ? getMainOrder(row.item).createdOn : ''), placement:'leftbottom', html: true}"
                         class='bx bx-detail text-warning' style="font-size: 24px;"></i>

                      <i  v-if="isOrderSent(row.item)"
                          @click.prevent.stop="showModal1(row.item)"
                          v-b-tooltip.hover="{customClass: 'ps-tooltip-status-icon ps-tooltip-status-icon-changed', title: 'Back Order Acceptance Sent' + '<br/>' + formatDateTime(getSentOrder(row.item) ? getSentOrder(row.item).sentOn : '') + '<br/><b>' + (getSentOrder(row.item) && getSentOrder(row.item).status === 'sent' ? 'Authorisation Pending' : 'Authorised') + '</b>', placement:'leftbottom', html: true}"
                          class='bx bx-mail-send text-warning' style="font-size: 24px;"></i>

<!--                      <i-->
                        <!--                          v-if="row.item.stockComment && row.item.stockComment.trim()"-->
                        <!--                          @click.prevent.stop="handleIconClicked(row)"-->
                        <!--                          class="bx bx-note text-warning"-->
                        <!--                          style="font-size: 24px;"-->
                        <!--                          v-b-tooltip.hover="{customClass: 'ps-tooltip', title: row.item.stockComment, placement:'leftbottom'}">-->
                        <!--                      </i>-->
                      </template>
                    </div>
                  </template>

                  <template v-slot:cell(credit)="row">
                    {{ row.item.credit | creditStatus }}
                  </template>


                  <template v-slot:cell(supplierDeliveryDate)="row">
                    <template v-if="row.item.isCanEditDelivery">
                      <date-picker
                          style="width: 95px;"
                          v-model="row.item.supplierDeliveryDate"
                          @focus="onFocus('deliveryDate-' + row.item.id)"
                          @blur="onBlur(row.item, 'supplierDeliveryDate')"
                          @onChange="savePartDeliveryDate(row.item)"></date-picker>
                    </template>
                    <template v-else>
                      <template v-if="row.item.supplierDeliveryDate">
                        <span class="" :class="{'new-delivery-blinker': isCanBlinking}" style="color: orange">{{ row.item.supplierDeliveryDate }}</span>
                      </template>
                      <template v-else-if="order.supplierDeliveryDate">
                        <span class="" :class="{'new-delivery-blinker': isCanBlinking}" style="color: orange">{{ order.supplierDeliveryDate}}</span>
                      </template>
                      <template v-else-if="order.repairerDeliveryDate">
                        {{ order.repairerDeliveryDate}}
                      </template>
                      <template v-else>
                        Next available run
                      </template>
                    </template>
                  </template>

                  <template v-slot:cell(qtyRcv)="row">
                    <span class="red-cell" v-if="isCreditApprovedOrUnderReview(row)">
                      {{ row.item.qtyRcv - row.item.qty_order }}
                    </span>
                    <template v-else-if="row.item.type !== 'delivery'">
                      {{row.item.qtyRcv}}
                    </template>
                  </template>

                  <template v-slot:cell(qty_order)="row">
                    <template v-if="row.item.type !== 'delivery'">
                    <span
                        v-if="!isEditOrder"
                        :class="{
                          'green-cell': Number(row.item.originalQty) < Number(row.item.qty_order),
                          'red-cell': Number(row.item.originalQty) > Number(row.item.qty_order),
                        }"
                        v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title: getTooltipForPart('Qty', row.item), placement:'leftbottom'}"
                    >{{ row.item.qty_order }}</span>
                      <number-formatter
                          v-else
                          :ref="'qty-' + row.item.id"
                          v-model="row.item.qty_order"
                          @changedValue="value => row.item.qty_order = value"
                          @onKeyPressRight="event => debounceOnKeyPressRight('qty-' + row.item.id, event)"
                          @onKeyPressLeft="event => debounceOnKeyPressLeft('qty-' + row.item.id, event)"
                          @focus="onFocus('qty-' + row.item.id)"
                          @blur="onBlur(row.item, 'qty', 200)"
                          @onKeyPressUp="debounceOnKeyPressUp('qty-' + row.item.id)"
                          @onKeyPressDown="debounceOnKeyPressDown('qty-' + row.item.id)"
                          :start-value="row.item.qty_order_copy"
                          :isCanOnlyIncrease="true"
                          :isCanBeZero="false"
                          :class="{
                          'green-cell': Number(row.item.originalQty) < Number(row.item.qty_order),
                          'red-cell': Number(row.item.originalQty) > Number(row.item.qty_order),
                        }"
                          v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title: getTooltipForPart('Qty', row.item), placement:'leftbottom'}"
                          class="form-control numeric"
                          format="0"></number-formatter>
                    </template>
                  </template>

                                <template v-slot:cell(stock)="row">
                                    <template v-if="row && row.item && row.item.type && row.item.type !== 'delivery'">
                                      <template v-if="!isEditOrder">
                        <div class="d-flex align-items-center">
                        <span style="margin-right: 5px">                {{ row.item.stockValue.label }}
                                      </span><div class="d-flex justify-content-between">
                          <i class="font-20"
                             v-if="row.item.stockValue && (row.item.stockValue.value > 0 || row.item.stockValue.value <= 7)"
                             :class="{
                            // In Stock
                            'bx bx-check-circle green-icon-2': row.item.stockValue.value === 1,
                            // EX Warehouse
                            'bx bx-check-circle orange-icon-2': row.item.stockValue.value === 6,
                            'bx bx-check-circle icon-orange-blinking': row.item.stockValue.value === 6 && row.item.stockValue.comment && isCanBlinking,
                            // Low Stock
                            'bx bx-check-circle blue-icon-2': row.item.stockValue.value === 2,
                            // No Stock, No Longer Available, Not Applicable
                            'bx bx-minus-circle gray-icon-2': row.item.stockValue.value === 3 || row.item.stockValue.value === 5 || row.item.stockValue.value === 7,
                            // Back Order
                            'bx bx-check-circle red-icon': row.item.stockValue.value === 4,
                            'bx bx-check-circle red-icon icon-red-blinking': row.item.stockValue.value === 4 && row.item.stockValue.comment && isCanBlinking,
                          }"
                             v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title: row.item.stockValue.comment ? row.item.stockValue.comment : '', placement:'leftbottom'}"
                          >
                          </i>
                          <i v-if="row.item.isNonReturnable" @click="showModal"  v-b-tooltip.hover="{customClass: 'ps-tooltip order-non-returnable-tooltip', html: true, title: getNRTooltip(row.item), placement:'leftbottom'}" class='bx bx-error font-20 orange-icon'></i>
                        </div>

                      </div>
                                        <div class="" style="text-align: left;" v-if="row.item.isNonReturnable">
                                          Non-Returnable Accepted
                                        </div>
                                        <div class="" style="text-align: left;" v-if="getSentOrder(row.item) && (getSentOrder(row.item).status === 'accepted' || getSentOrder(row.item).status === 'declined')">
                                          Back Order <span v-if="(getSentOrder(row.item).status === 'accepted')">Accepted</span><span v-else>Declined</span>
                                        </div>
                    </template>
                                        <stock-select
                                            v-else
                                            :id="row.item.id"
                                            :options="stockOptions"
                                            v-model="row.item.stockValue"
                                            @onSelected="selectStockStatus($event, row.item, true)"
                                            @onKeyPressRight="debounceOnKeyPressRight('stock-select-' + row.item.id)"
                                            @onKeyPressLeft="debounceOnKeyPressLeft('stock-select-' + row.item.id, $event)"
                                            :isNeedDateRange="true"
                                            :isPartOrderPage="true"
                        :ref="'stock-select-' + row.item.id"
                                        >
                                        </stock-select>
                                    </template>
                                </template>

                  <template v-slot:cell(rrp)="row">
                    <template v-if="row.item.type !== 'delivery'">
                    <span
                        v-if="!isEditOrder"
                        :class="{'green-cell': row.item.isGreenUnitList, 'red-cell': row.item.isRedUnitList}">
                    {{row.item.rrp | formatMoney }}
                  </span>
                      <number-formatter
                          v-else
                          :ref="'rrp-' + row.item.id"
                          :isCanBeZero="false"
                          v-model="row.item.rrp"
                          @focus="onFocus('rrp-' + row.item.id)"
                          @blur="onBlur(row.item, 'rrp')"
                          :original-value="row.item.originalRrp"
                          @onKeyPressRight="event => debounceOnKeyPressRight('rrp-' + row.item.id, event)"
                          @onKeyPressLeft="event => debounceOnKeyPressLeft('rrp-' + row.item.id, event)"
                          @onKeyPressUp="debounceOnKeyPressUp('rrp-' + row.item.id)"
                          @onKeyPressDown="debounceOnKeyPressDown('rrp-' + row.item.id)"
                          @changedValue="v => inputRRP(v, row.item)"
                          :class="{'green-cell': row.item.isGreenUnitList, 'red-cell': row.item.isRedUnitList}"
                          class="form-control numeric money-input"
                          format="$0,0.00"></number-formatter>
                    </template>
                  </template>

                  <template v-slot:cell(price)="row">
                      <span v-if="row.item && row.item.credit && row.item.credit.id && row.item.credit.status == 'Approved'"  class="red-cell">
                      {{ 0 | formatMoney }}
                    </span>
                    <template v-else-if="isCreditApprovedOrUnderReview(row)">
                      <span class="red-cell">{{getRtnUnitNet(row.item)}}</span>
                    </template>
                                    <span v-else-if="!isEditOrder || row.item.part_status === 'CancelPart'"
                                          :class="{'green-cell': row.item.isGreenUnitNet, 'red-cell': row.item.isRedUnitNet}">{{
                                        row.item.price | formatMoney
                                      }}</span>
                    <number-formatter
                        v-else
                        :isCanBeZero="false"
                        @focus="onFocus('price-' + row.item.id)"
                        @blur="onBlur(row.item, 'price')"
                        @changedValue="v => inputPrice(v, row.item)"
                        @onKeyPressRight="event => debounceOnKeyPressRight('price-' + row.item.id, event)"
                        @onKeyPressLeft="event => debounceOnKeyPressLeft('price-' + row.item.id, event)"
                        @onKeyPressUp="debounceOnKeyPressUp('price-' + row.item.id)"
                        @onKeyPressDown="debounceOnKeyPressDown('price-' + row.item.id)"
                        :ref="'price-' + row.item.id"
                        v-model="row.item.price"
                        :original-value="row.item.originalPrice"
                        class="form-control numeric money-input"
                        :class="{'green-cell': row.item.isGreenUnitNet, 'red-cell': row.item.isRedUnitNet}"
                        format="$0,0.00"
                    ></number-formatter>
                  </template>

                  <template v-slot:cell(gst)="row">
                    <span v-if="row.item && row.item.credit && row.item.credit.id && row.item.credit.status == 'Approved'">
                      {{ 0 | formatMoney }}
                    </span>
                    <span v-else>{{ round(Number(row.item.price) * 0.1) | formatMoney }}</span>
                  </template>

                  <template v-slot:cell(total)="row">
                      <span v-if="row.item && row.item.credit && row.item.credit.id && row.item.credit.status == 'Approved'">
                      {{ 0 | formatMoney }}
                    </span>

                  <span
                      v-else
                      :class="{
                          'green-cell': Number(row.item.originalQty) < Number(row.item.qty_order) && Number(round((Number(row.item.price) + round(Number(row.item.price) * 0.1)) * Number(row.item.qty_order))) != 0,
                          'red-cell': Number(row.item.originalQty) > Number(row.item.qty_order) && Number(round((Number(row.item.price) + round(Number(row.item.price) * 0.1)) * Number(row.item.qty_order))) != 0,
                        }"
                      v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title: getTooltipForPart('Total', row.item), placement:'leftbottom'}"
                  >
                        {{
                      round((Number(row.item.price) + round(Number(row.item.price) * 0.1)) * Number(row.item.qty_order)) | formatMoney
                    }}
                  </span>
                  </template>

                  <template v-slot:cell(part_status)="row">
                    <span :class="statusList[row.item.status_id].nameClass">{{ row.value }}</span>
                    <div v-if="row.item._status_subtitle" class="font-italic">
                      {{ row.item._status_subtitle }}
                    </div>
                  </template>
                  <template v-slot:head()="data">
                    <div v-if="!data.field.sortable">{{ data.label }}</div>
                    <div v-if="data.field.sortable" class="table-sorting">
                      {{ data.label }}<i></i>
                    </div>
                  </template>
                  <template v-slot:row-details="row">
                    <div
                        style="display: block; border-left: 2px solid #e3e4e7; padding: 0px 0px 20px 17px; margin-left: 10px;"
                        class="parts-order-details d-flex">
                      <div class="" style="width: 300px">
                        <template v-if="row.item.comment">
                          <div class="parts-order-details__item font-12" style="flex-direction: column">
                            <label class="parts-order-details__label font-inter--bold" style="display: block;">
                                                  <span v-if="row.item.part_status === 'IncorrectPartDelivered'">
                                                    Comment for - Incorrect Part Delivered:
                                                  </span>
                              <span v-else-if="row.item.part_status === 'ReturnDamagedPart'">
                                                    Comment for - Damaged Part in Transport:
                                                  </span>
                              <span v-else>Comment: </span>
                            </label>
                            <b-textarea
                                style="margin: 6px 0px"
                                v-model="row.item.comment"
                                :disabled="true"
                                max-rows="10"
                                class="ps-scroll adaptive-input-textarea"
                            >
                            </b-textarea>
                            <!--                                             <span class="order-comment" style="display: block; margin: 0px; text-align: justify"> {{ row.item.comment }} </span>-->
                          </div>
                        </template>
                        <template v-if="row.item.commentDate">
                          <div class="parts-order-details__item font-12 d-flex"
                               :class="'align-items-center'">
                            <label class="parts-order-details__label font-inter--bold">Added on:</label>
                            <span class="order-comment">{{ formatDateTime(row.item.commentDate) }} </span>
                          </div>
                        </template>
                        <template v-if="row.item.commentByName">
                          <div class="parts-order-details__item font-12 d-flex"
                               :class="'align-items-center'">
                            <label class="parts-order-details__label font-inter--bold">Added by:</label>
                            <span class="order-comment">{{ row.item.commentByName }} </span>
                          </div>
                        </template>

                        <template v-if="partCanRequestCredit(row.item) && row.item.credit && row.item.credit.id > 0">
                          <template v-if="row.item.credit.reason">
                            <div class="parts-order-details__item font-12"
                                 style="flex-direction: column; margin-top: 20px;">
                              <label class="parts-order-details__label font-inter--bold" style="display: block;">
                                                  <span>
                                                    Reason for credit:
                                                  </span>
                              </label>
                              <span class="order-comment" style="display: block; margin: 0px; text-align: justify">
                                                     <b-textarea
                                                         v-model="row.item.credit.reason"
                                                         :disabled="true"
                                                         style="margin: 6px 0px"
                                                         max-rows="10"
                                                         class="ps-scroll adaptive-input-textarea"
                                                     >
                                                    </b-textarea>
                                              </span>
                            </div>
                          </template>
                          <template v-if="row.item.credit.number">
                            <div class="parts-order-details__item font-12 d-flex"
                                 :class="'align-items-center'">
                              <label class="parts-order-details__label font-inter--bold">Credit ref:</label>
                              <span class="order-comment"><a @click.prevent="goToCredit(row.item)"
                                                             :href="'#'">{{ row.item.credit.number }}</a> </span>
                            </div>
                          </template>
                          <template v-if="row.item.credit.creditDate">
                            <div class="parts-order-details__item font-12 d-flex"
                                 :class="'align-items-center'">
                              <label class="parts-order-details__label font-inter--bold">Added on:</label>
                              <span class="order-comment"> {{ formatDateTime(row.item.credit.creditDate) }} </span>
                            </div>
                          </template>
                          <template v-if="row.item.credit.creditByName">
                            <div class="parts-order-details__item font-12 d-flex"
                                 :class="'align-items-center'">
                              <label class="parts-order-details__label font-inter--bold">Added by:</label>
                              <span class="order-comment"> {{ row.item.credit.creditByName }} </span>
                            </div>
                          </template>
                        </template>
                      </div>

<!--                      removed by #656.4-->
<!--                      <div-->
<!--                          v-if="row.item.credit && row.item.credit.id && row.item.qty_order !== row.item.qtyRcv && row.item.qtyRcv - row.item.qty_order !== 0"-->
<!--                          class="d-flex" style="width:100%; justify-content: flex-end;">-->
<!--                        <div :style="{'marginRight': computedMarginRightForDetailCeil1 + 'px'}">-->
<!--                          &lt;!&ndash;                      <b-form-input&ndash;&gt;-->
<!--                          &lt;!&ndash;                          :disabled="true"&ndash;&gt;-->
<!--                          &lt;!&ndash;                          :value="getRtnCost(row.item)"&ndash;&gt;-->
<!--                          &lt;!&ndash;                          :style="{'marginLeft': computedTableWidth + 'px', 'marginRight': computedTableMinCellWidth + 'px'}"&ndash;&gt;-->
<!--                          &lt;!&ndash;                          style="width: 80px; color: red;">&ndash;&gt;-->
<!--                          &lt;!&ndash;                      </b-form-input>&ndash;&gt;-->
<!--                          <b-form-input-->
<!--                              :value="row.item.qtyRcv - row.item.qty_order"-->
<!--                              :disabled="true"-->
<!--                              style="width: 40px; color: red; text-align: right"></b-form-input>-->
<!--                        </div>-->
<!--                        <div :style="{'marginRight': computedMarginRightForDetailCeil2 + 'px'}">-->
<!--                          <b-form-input-->
<!--                              :value="getRtnUnitNet(row.item)"-->
<!--                              :disabled="true"-->
<!--                              style="width: 85px;  color: red; text-align: right">-->
<!--                          </b-form-input>-->
<!--                        </div>-->
<!--                      </div>-->
                    </div>
                  </template>
                  <template v-slot:custom-foot="row">
                    <b-tr v-if="isEditOrder">
                      <b-td :colspan="orderFields.length" style="padding-left: 150px; font-weight: 700;">
                        <a @click.prevent="addPart(false)" href="#" class="add-part" style="color: #29bbc1;">
                          <i class='bx bx-plus'></i>
                          <span>Add Part</span>
                        </a>
                      </b-td>
                    </b-tr>
                    <b-tr v-if="isEditOrder">
                      <b-td :colspan="orderFields.length" style="padding-left: 150px; font-weight: 700;">
                        <a @click.prevent="addPart(true)" href="#" class="add-part" style="color: #29bbc1;">
                          <i class='bx bx-plus'></i>
                          <span>Add Freight</span>
                        </a>
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-td class="border-bottom-0" colspan="10"></b-td>
                      <b-td class="subheader subheader-p-l text-right" colspan="2"
                            style="padding-left: 0px !important">Parts in Stock Today
                      </b-td>
                      <b-td class="p-c-value text-left pl-4" colspan="2" style="padding-left:20px;">{{
                          order.partsInStock
                        }} %
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-td class="border-bottom-0" colspan="10"></b-td>
                      <b-td class="subheader subheader-p-l text-right" colspan="2"
                            style="padding-left: 0px !important">Parts Delivery ETA
                      </b-td>
                      <b-td class="p-c-value text-left pl-4" colspan="2">
                                            <span style="padding-left:5px;"> {{
                                                order.dateDue && order.dateDue !== 'null' ? formatDate(order.dateDue) : 'Next available run'
                                              }}</span>
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-td class="border-bottom-0" colspan="10"></b-td>
                      <b-td class="subheader subheader-p-l text-right" colspan="2"
                            style="padding-left: 0px !important">Discount Applied
                      </b-td>
                      <b-td class="p-c-value text-left pl-4" colspan="2">
                        <span style="padding-left:5px;">{{ order.discountsApplied }}%</span>
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-td class="border-bottom-0" colspan="10"></b-td>
                      <b-td class="subheader subheader-p-l-b text-right" colspan="2"
                            style="padding-left: 0px !important">Sub Total
                      </b-td>
                      <b-td class="p-c-value b-value text-left pl-4 font-weight-bold" colspan="2">
                        <template v-if="isOrderCancelled">{{ 0 | formatMoney }}</template>
                        <template v-else>
                          {{ Math.round(order.subtotal * 100) / 100 | formatMoney }}
                        </template>
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-td class="border-bottom-0" colspan="10"></b-td>
                      <b-td class="subheader subheader-p-l-b text-right" colspan="2"
                            style="padding-left: 0px !important">GST
                      </b-td>
                      <b-td class="p-c-value b-value text-left pl-4 font-weight-bold" colspan="2">
                        <template v-if="isOrderCancelled">{{ 0 | formatMoney }}</template>
                        <template v-else>
                          {{
                            Math.round(order.subtotal * 110) / 100 - Math.round(order.subtotal * 100) / 100 | formatMoney
                          }}
                        </template>
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-td class="border-bottom-0" colspan="10"></b-td>
                      <b-td class="subheader subheader-p-l-b text-right" colspan="2"
                            style="padding-left: 0px !important">Grand Total
                      </b-td>
                      <b-td class="p-c-value b-value text-left pl-4 font-weight-bold" colspan="2">
                        <template v-if="isOrderCancelled">{{ 0 | formatMoney }}</template>
                        <template v-else>
                          {{ Math.round(order.subtotal * 110) / 100 | formatMoney }}
                        </template>
                      </b-td>
                    </b-tr>
                    <b-tr id="last-table-line">
                      <b-td class="border-bottom-0" colspan="10"></b-td>
                      <b-td class="subheader subheader-p-l-b text-right" colspan="2"
                            style="padding-left: 0px !important">Customer Rewards
                      </b-td>
                      <b-td class="p-c-value b-value text-left pl-4 part-values" colspan="2">
                        -
                      </b-td>
                    </b-tr>
                  </template>

                </b-table>


                <div
                    class="topscroll"
                    :style="{
                      width: getTopScrollWidth()
                    }"
                    style="bottom: 0px; position: fixed; width: 600px; height: 5px; overflow-x: scroll; z-index: 5; background: white;transform: translateX(15px);"
                >
                  <div style="height: 1px" class="fake"></div>
                </div>
              </div>

            </div>
            <div v-else class="">
              <parts-orders-import-p-d-f
                  :vin="order.vehicle.vin"
                  :build-date="order.vehicle.dom ? order.vehicle.dom: ''"
                  :repairer-parts="orderItems"
                  :is-parts-order-view-page="true"
                  :params="{
                    orderId: order.id
                  }"
                  @loadParts="isLoadPartsForImporting = true;"
                  @changedLoading="(value) => isLoadingInvoiceParts = value"
                  @startImporting="(value) => isStartImportingParts = value"
                  @confirmedImport="confirmedImport"
                  @addImportedPdf="addImportedPdf"
                  @addDMSNumber="addDMSNumber"
                  @closeWizard="isImportPDFInvoice = false;
                                      isLoadingInvoiceParts = false;
                                      isLoadPartsForImporting = false;
                                      isStartImportingParts = false;">
              </parts-orders-import-p-d-f>
            </div>
          </template>
        </tab>
        <tab name="Files" id="ordered-files" :class-name="'parts-order-view-tab-3 tab-content-table'">
          <template v-if="isOrderLoaded">
            <template v-if="!isShowPdf">
              <div class="order-parts-headerinformation">
                <div class="part-list-qoute-pricing-progress">
                  <OrderProgress v-if="isOrderLoaded"
                                 :steps="steps"
                                 :step="currentQPPStep"
                                 title="Order Progress">
                  </OrderProgress>
                </div>

                <div class="w-100 d-block d-xl-none"></div>

              <div>
                <template v-if="isOrderLoaded">
                  <div class="delivery-required-time ml-4">
                    <ps-label-value label="Order Placed">{{
                        formatDateTime(order.dateCreated)
                      }}
                    </ps-label-value>
                    <ps-label-value label="Parts Delivery Required">
                      {{
                        order.dateDue && order.dateDue !== 'null' ? order.dateDue : 'Next available run'
                      }}
                    </ps-label-value>
                    <template  v-if="getSupplierDeliveryDate || isClickedChangeDeliveryDate">
                      <div v-if="!isEditDeliveryDate" class="ps-label-value " :class="{'new-delivery-blinker': isCanBlinking}">
                        <span class="ps-label-value__label" style="color: orange">New Delivery ETA:</span>
                        <span class="ps-label-value__value">
                        <span style="color: orange">{{getSupplierDeliveryDate}}</span>
                      </span>
                        </div>
                        <div v-else class="ps-label-value">
                          <span class="ps-label-value__label " :class="{'new-delivery-blinker': isCanBlinking}" style="color: orange">New Delivery ETA:</span>
                          <span class="ps-label-value__value">
                        <date-picker v-if="isEditDeliveryDate" v-model="order.supplierDeliveryDate" @onChange="debouncedDeliveryDateChanged"></date-picker>
                      </span>
                        </div>
                      </template>

                      <div class="ps-label-value">
                        <span class="ps-label-value__label">Special Instruction:</span>
                        <span class="ps-label-value__value">
                            <div v-if="!isCanEditSupplierInstruction" :class="{'direct-order-blinker': isCanBlinking && order.properties.isSentAsDirectOrder}" class="ps-scroll font-inter--bold p-0 special-instruction-block editable-text-area" v-html="orderInstruction"></div>
                            <div v-else class="ps-scroll font-inter--bold p-0 special-instruction-block" style="border-radius: 3px; border: 1px solid rgba(28, 31, 57, 0.25)">
                              <div style="padding: 0px 5px" class="editable-text-area editable-text-area-2" v-html="orderInstructionWithoutSupplier"></div>
                              <textarea @change="saveSupplierInstruction" ref="supplier-instruction-files"  class="comment-textarea" style="height: 80px; padding: 0px 5px;" v-model="order.deliveryTo.supplierInstruction"></textarea>
                            </div>
                        </span>
                      </div>
                    </div>
                  </template>
                </div>

                <div>
                  <customer-account-deliver v-if="isOrderLoaded"
                                            :customer="order.customer.name"
                                            :estimator="order.estimator"
                                            account=""
                                            :delivery-to="order.deliveryTo">
                  </customer-account-deliver>
                </div>
              </div>
              <div class="wizard__divider"></div>
              <vin-date
                  v-if="isOrderLoaded"
                  :isShowOrderNbr="true"
                  :code="order.trackingCode"
                  :isEdit="isEditOrder || (order && order.id && order.isOpen())"
                  :vin="order.vehicle.vin"
                  :order-number="order.number"
                  :invoice-number="order.invoiceNumber"
                  @onChangeCode="updateCode"
                  @onChangeInvoiceNbr="onChangeInvoiceNbr"
                  :status="order.status"
                  :isShowInvoiceNbr="true"
                  :isShowRFQNbr="false"
                  :isShowQuoteNbr="true"
                  :quoteNumber="order && order.id ? order.dmsQuoteNumber : ''"
                  :rfq-number="order && order.rfq && order.rfq.number ? order.rfq.number : ''"
                  @goToRfq="goToRfq"
                  :date="order.vehicle.dom ? order.vehicle.dom: ''">
              </vin-date>
            </template>
            <div class="files-tab-order">
              <file-upload
                  v-if="isOrderLoaded"
                  :object-id="order.id"
                  type="file"
                  :isSelectable="true"
                  :documentHeight="documentHeight"
                  @openPDF="openPdf"
                  :params="{
                    orderId: order.id
                  }"
                  @removedFiles="revomedImgs"
                  :api-urls="{uploadFile: '/ir/supplier/order/upload-file', main: '/ir/supplier/order/'}"
                  :files-accepted="'application/pdf'"
                  v-model="order.files">
              </file-upload>
            </div>
          </template>
        </tab>
        <template slot="nav-item-right">
          <!-- <div class="tabs-nav-right-btn mr-4">
               <a class="btn btn-primary clickable d-none d-lg-inline-block"
                  v-if="isEditable"
                  style=" vertical-align: middle; padding-left:25px; padding-right:25px;">
                   Save
               </a>
           </div>-->
          <!-- desktop options -->
          <div class="tabs-nav-right-options">
            <chat v-if="true" @closeChat="onCloseChat" :isShowChatInit="!!$route.params.isNeedOpenChat" :userName="order && order.customer && order.customer.name ? order.customer.name : ''" :messages="chatMessages" :is-chatter-online="chatRepairerIsOnline" :typers="chatTypers" @onChatMessageSubmit="onChatMessageSubmit" @onTyping="onChatInputTyping"></chat>
            <b-dropdown id="parts-order-view-options-desktop" text="Options"
                        class="dropdown-options supplier-parts-order-view-options dropdown-actions">
              <template v-slot:button-content>
                Actions
                <span class="dropdown-arrow"><i class='bx bx-down-arrow'></i></span>
              </template>
              <template v-if="isOrderLoaded">
                <template v-if="order && order.id && order.isProcessing()">
                  <b-dropdown-item v-if="!isImportPDFInvoice" @click="importPDFInvoice">Import PDF
                    Invoice
                  </b-dropdown-item>
                  <b-dropdown-item @click="onClickedChangeDelivery">Change Delivery ETA</b-dropdown-item>
                  <b-dropdown-item :class="{'disabled-button': isDisabledMarkPartsShipped}" :disabled="isDisabledMarkPartsShipped" @click="markPartsShipped">Mark Parts Shipped</b-dropdown-item>
                </template>
                <template v-if="order && order.id && order.isDenied()">
                  <b-dropdown-item @click="$refs['reinstate-order-modal'].show();">Reinstate Denied Order</b-dropdown-item>
                </template>
                <template v-if="order && order.id && order.isCancelled()">
                  <b-dropdown-item @click="reinstateOrder(false)">Reinstate Cancelled Order</b-dropdown-item>
                </template>
                <template v-if="order && order.id && order.isOpen()">
                  <b-dropdown-item @click="onClickedChangeDelivery">Change Delivery ETA</b-dropdown-item>
                </template>
                <template v-if="order && order.id && order.isShipped()">
                  <b-dropdown-item @click="markPartsUndoShipped">Undo Parts Shipped</b-dropdown-item>
                  <b-dropdown-item @click="onClickedChangeDelivery">Change Delivery ETA</b-dropdown-item>
                </template>
                <template v-if="order && order.id && order.isDelivered()">
                  <b-dropdown-item @click="onClickedChangeDelivery">Change Delivery ETA</b-dropdown-item>
                </template>
                <template v-if="order && order.id && order.isOpen()">
                  <b-dropdown-item @click="markAsProcessing">Mark as Processing</b-dropdown-item>
                </template>
                <template v-if="order && order.id && (order.isOpen() || order.isProcessing())">
                  <b-dropdown-item @click="denyOrderShowModal">Deny Order</b-dropdown-item>
                  <b-dropdown-item @click="$refs['cancel-order-modal'].show()">Cancel Order</b-dropdown-item>
                </template>
                <b-dropdown-item v-if="order && order.id && order.isDelivered()"
                                 @click="markOrderCompleted">Mark Order Completed
                </b-dropdown-item>
              </template>
            </b-dropdown>
          </div>
        </template>
      </tabs>
      <vue-context ref="menu"
                   :closeOnScroll="false">
        <b-button-toolbar key-nav aria-label="Toolbar with button groups">
          <b-button-group vertical class="parts-order-view__context-menu"
                          v-if="!isCurrentTabVehicleDetails && !isCurrentTabPartList">
            <b-button class="text-left font-12 font-inter--semibold context-menu-focus" variant="outline-primary" block
                      @click="handleCancelPartButton">Cancel Part
            </b-button>
            <b-button class="text-left font-12 font-inter--semibold" variant="outline-primary" block>Return Part
            </b-button>
            <b-button class="text-left font-12 font-inter--semibold" variant="outline-primary" block>
              Request Credit
            </b-button>
            <b-button class="text-left font-12 font-inter--semibold" variant="outline-primary" block>Mark Incorrect Part
            </b-button>
            <b-button class="text-left font-12 font-inter--semibold" variant="outline-primary" block>Mark Parts Received
            </b-button>
            <b-button class="text-left font-12 font-inter--semibold" variant="outline-primary" block>Mark Order Completed
            </b-button>
          </b-button-group>
        </b-button-toolbar>
      </vue-context>

      <vue-context ref="partMenu"
                   :close-on-click="false"
                   @close="closePartContext"
                   :closeOnScroll="true">
        <b-button-toolbar slot-scope="item" key-nav aria-label="Toolbar with button groups" v-if="item && item.data && item.data.item">
          <template v-if="checkedParts().length > 1">
            <b-button-group v-if="isEditOrder" vertical class="parts-order-view__context-menu"
                            style="width: 275px">

              <div>
                <b-button @click="changeDeliveryFromContext(item.data.item)" class="text-left font-12 font-inter--semibold context-menu-focus" style="width: 275px" variant="outline-primary" block>
                  Change Delivery ETA
                </b-button>
              </div>


              <b-button
                  @click="markPartsShipped"
                  v-if="order.status === 'Shipped' || order.status === 'Processing'"
                  class="text-left font-12 font-inter--semibold context-menu-focus"
                  style="width: 275px"
                  :class="{'disabled-button': isDisabledMarkPartsShippedForContext(checkedParts())}"
                  variant="outline-primary"
                  block>
                Mark Parts Shipped
              </b-button>

              <div
                  class="" @mouseover="openStockSelectContext(true, item.data.item.id)" @mouseleave="openStockSelectContext(false, item.data.item.id)">
                <b-button class="text-left font-12 font-inter--semibold context-menu-focus" variant="outline-primary" block
                          style="width: 275px;" :class="{ active: isContextMenuActive == isOpenContextLevelTwo}">
                  Set Stock Status <i style="right: 5px;"
                                      class="part-context-arrow-icon bx bx-chevron-right text-right"></i>
                </b-button>
                <div v-if="isOpenContextLevelTwo">
                  <div class="parts-order-view__context-menu context-level-two context-stock-select" style="border: none">
                    <stock-select
                        :id="item.data.item.id"
                        :options="stockOptions"
                        v-model="item.data.item.stockValue"
                        @onSelected="selectStockStatus($event, item.data.item)"
                        @onDatePickerChange="isClickedOnDatePicker = true"
                        :isNeedDateRange="true"
                        :isPartOrderPage="true"
                        :isForContext="true"
                        :ref="'stock-select-context-' + item.data.item.id"
                        class="context-stock-select-height"
                        style="top: 40px"
                    >
                    </stock-select>
                  </div>
                </div>
              </div>

              <div
                  class="w-100"
                  @mouseover="isOpenContextLevelTwo2 = true"
                  @mouseleave="isOpenContextLevelTwo2 = false">
                <b-button class="text-left font-12 font-inter--semibold context-menu-focus w-100" variant="outline-primary" block
                          style="width: 275px;" :class="{ active: isContextMenuActive == isOpenContextLevelTwo2}">
                  Set Invoice Number <i style="font-size: 18px; transform: translateY(-1px); right: 5px;"
                                        class="part-context-arrow-icon bx bx-chevron-right text-right"></i>
                </b-button>
                <div
                    v-if="isOpenContextLevelTwo2"
                    class="part-sub-context-menu invoice-number-context supplier-invoice-number-context-2">
                  <b-form-input @change="saveContextInvoiceNumber" v-model="contextInvoiceNumber"></b-form-input>
                  <div class="invoice-number-text">Invoice Number</div>
                </div>
              </div>

              <div
                  class="w-100"
                  @mouseover="isOpenContextLevelTwo3 = true"
                  @mouseleave="isOpenContextLevelTwo3 = false">
                <b-button class="text-left font-12 font-inter--semibold context-menu-focus w-100" variant="outline-primary" block
                          style="width: 275px;" :class="{ active: isContextMenuActive == isOpenContextLevelTwo3}">
                  Set Invoice Date <i style="font-size: 18px; transform: translateY(-1px); right: 5px;"
                                        class="part-context-arrow-icon bx bx-chevron-right text-right"></i>
                </b-button>
                <div
                    v-if="isOpenContextLevelTwo3"
                    class="part-sub-context-menu invoice-number-context supplier-invoice-date-context-2">
                  <date-picker @onChange="saveContextInvoiceDate" v-model="contextInvoiceDate"></date-picker>
                  <div class="invoice-number-text">Invoice Date</div>
                </div>
              </div>

              <div>
                <b-button v-if="isCanUndoDeliveryParts" @click="undoDeliveryFromContext(item.data.item)" class="text-left font-12 font-inter--semibold context-menu-focus" style="width: 275px" variant="outline-primary" block>
                  Undo Change Delivery ETA
                </b-button>
              </div>

              <template v-if="order.properties.isEnableBackOrder && isHasOnBackOrderParts">
                <div
                    v-if="isHasPartsWithoutBackOrder || (isHasCreatedBackOrder && isCanAddPartsToBackOrder)"
                    @mouseover="isOpenSpecialOrderContext = true"
                    @mouseleave="isOpenSpecialOrderContext = false"
                    class="">
                  <div>

                    <div>
                      <b-button class="text-left font-12 font-inter--semibold context-menu-focus"
                                style="width: 275px" variant="outline-primary" block
                                :class="{ active: isContextMenuActive == isOpenSpecialOrderContext}">
                        Mark Back Order Acceptance Req
                        <i style="right: 5px"
                           class="part-context-arrow-icon bx bx-chevron-right text-right context-menu-focus"></i>
                      </b-button>
                    </div>

                  </div>

                  <div
                      v-if="isOpenSpecialOrderContext"
                      class="context4-level-two"
                      :style="{'top': getTop2(item.data.item) + 'px'}"
                      @mouseover="isOpenSpecialOrderContext2 = true"
                      @mouseleave="isOpenSpecialOrderContext2 = false"
                  >
                    <b-button class="text-left font-12 font-inter--semibold context-menu-focus"
                              style="width: 275px" variant="outline-primary" block
                              :class="{ active: isContextMenuActive == isOpenSpecialOrderContext2}">
                      Send Back Order Acceptance - Customer
                      <i style="left:250px" class="part-context-arrow-icon bx bx-chevron-right text-right context-menu-focus"></i>
                    </b-button>


                    <div v-if="isOpenSpecialOrderContext && isOpenSpecialOrderContext2 && !_isEmpty(order.customer.publicUsers)">
                      <b-button-group
                          vertical
                          class="parts-order-view__context-menu context3-level-two" style="top: 0px">
                        <b-button
                            v-if = "order?.customer?.publicUsers?.length>1"
                            @click="createBackOrder(null, -1)"
                            class="text-left font-12 font-inter--semibold text-nowrap" variant="outline-primary"
                            block>ALL</b-button>
                        <b-button
                            v-for="user in order.customer.publicUsers"
                            :key="user.id"
                            @click="createBackOrder(null, user.id)"
                            class="text-left font-12 font-inter--semibold text-nowrap" variant="outline-primary"
                            block>{{user.name}}</b-button>
                      </b-button-group>
                    </div>
                    <div v-else-if="isOpenSpecialOrderContext && isOpenSpecialOrderContext2" style="top: 0px" class="parts-order-view__context-menu context3-level-two no-customers-text" >
                      Not Users for send on the Customer side
                    </div>
                  </div>


                </div>

                <div v-if="isCanCancelPartsFromBackOrders">
                  <b-button @click="cancelBackOrders2(getBackOrdersForCancelling2() ? getBackOrdersForCancelling2() : null, getPartsForCancelling())" class="text-left font-12 font-inter--semibold" style="width: 275px" variant="outline-primary" block>
                    Undo Back Order Acceptance
                  </b-button>
                </div>

                <div  v-if="isCanCancelBackOrder">
                  <b-button @click="cancelBackOrders(getBackOrdersForCancellingIds() ? getBackOrdersForCancellingIds() : null)" class="text-left font-12 font-inter--semibold" style="width: 275px" variant="outline-primary" block>
                    Undo Back Order Acceptance
                  </b-button>
                </div>
              </template>

              <template v-if="isOrderLoaded">
                <template v-if="order && order.id && order.isProcessing()">
                  <b-button v-if="!isImportPDFInvoice" @click="importPDFInvoice" class="text-left font-12 font-inter--semibold"  style="width: 275px" variant="outline-primary" block>Import PDF Invoice
                  </b-button>
                  <!--                  <b-button class="text-left font-12 font-inter&#45;&#45;semibold"  style="width: 275px" variant="outline-primary" block :disabled="isDisabledMarkPartsShipped" @click="markPartsShipped">Mark Part Shipped</b-button>-->
                </template>
                <template v-if="order && order.id && order.isDenied()">
                  <b-button class="text-left font-12 font-inter--semibold"  style="width: 275px" variant="outline-primary" block @click="$refs.partMenu.close(); $refs['reinstate-order-modal'].show();">Reinstate Denied Order</b-button>
                </template>
                <template v-if="order && order.id && order.isCancelled()">
                  <b-button class="text-left font-12 font-inter--semibold"  style="width: 275px" variant="outline-primary" block @click="reinstateOrder(false)">Reinstate Cancelled Order</b-button>
                </template>
                <template v-if="order && order.id && order.isShipped()">
                  <b-button class="text-left font-12 font-inter--semibold"  style="width: 275px" variant="outline-primary" block @click="markPartsUndoShipped">Undo Parts Shipped</b-button>
                </template>
                <template v-if="order && order.id && order.isOpen()">
                  <b-button class="text-left font-12 font-inter--semibold"  style="width: 275px" variant="outline-primary" block @click="markAsProcessing">Mark as Processing</b-button>
                </template>
                <template v-if="order && order.id && (order.isOpen() || order.isProcessing())">
                  <b-button class="text-left font-12 font-inter--semibold"  style="width: 275px" variant="outline-primary" block @click="denyOrderShowModal">Deny Order</b-button>
                  <b-button class="text-left font-12 font-inter--semibold"  style="width: 275px" variant="outline-primary" block @click="$refs.partMenu.close(); $refs['cancel-order-modal'].show()" :disabled="isDisabledCancelOrder">Cancel Order</b-button>
                </template>
                <b-button class="text-left font-12 font-inter--semibold"  style="width: 275px" variant="outline-primary" block v-if="order && order.id && order.isDelivered()"
                          @click="markOrderCompleted">Mark Order Completed
                </b-button>
              </template>
            </b-button-group>

            <b-button-group v-else vertical class="parts-order-view__context-menu"
                            style="width: 275px">

              <div>
                <b-button @click="changeDeliveryFromContext(item.data.item)" class="text-left font-12 font-inter--semibold context-menu-focus" style="width: 275px" variant="outline-primary" block>
                  Change Delivery ETA
                </b-button>
                <b-button v-if="isCanUndoDeliveryParts" @click="undoDeliveryFromContext(item.data.item)" class="text-left font-12 font-inter--semibold context-menu-focus" style="width: 275px" variant="outline-primary" block>
                  Undo Change Delivery ETA
                </b-button>
                <b-button
                    @click="markPartsShipped"
                    v-if="order.status === 'Shipped' || order.status === 'Processing'"
                    class="text-left font-12 font-inter--semibold context-menu-focus"
                    style="width: 275px"
                    :class="{'disabled-button': isDisabledMarkPartsShippedForContext(checkedParts())}"
                    variant="outline-primary"
                    block>
                  Mark Parts Shipped
                </b-button>

                <template v-if="order.properties.isEnableBackOrder && isHasOnBackOrderParts">
                  <div
                      v-if="isHasPartsWithoutBackOrder || (isHasCreatedBackOrder && isCanAddPartsToBackOrder)"
                      @mouseover="isOpenSpecialOrderContext = true"
                      @mouseleave="isOpenSpecialOrderContext = false"
                      class="">
                    <div>

                      <div>
                        <b-button class="text-left font-12 font-inter--semibold context-menu-focus"
                                  style="width: 275px" variant="outline-primary" block>
                          Mark Back Order Acceptance Req
                        </b-button>
                      </div>

                    </div>

                    <div
                        v-if="isOpenSpecialOrderContext"
                        class="context4-level-two"
                        :style="{'top': getTop4(item.data.item) + 'px'}"
                        @mouseover="isOpenSpecialOrderContext2 = true"
                        @mouseleave="isOpenSpecialOrderContext2 = false"
                    >
                      <b-button class="text-left font-12 font-inter--semibold context-menu-focus"
                                style="width: 275px" variant="outline-primary" block>
                        Send Back Order Acceptance - Customer
                        <i style="left:250px" class="part-context-arrow-icon bx bx-chevron-right text-right context-menu-focus"></i>
                      </b-button>


                      <div v-if="isOpenSpecialOrderContext && isOpenSpecialOrderContext2 && !_isEmpty(order.customer.publicUsers)">
                        <b-button-group
                            vertical
                            class="parts-order-view__context-menu context3-level-two" style="top: 0px">
                          <b-button
                              v-if = "order?.customer?.publicUsers?.length>1"
                              @click="createBackOrder(null, -1)"
                              class="text-left font-12 font-inter--semibold text-nowrap" variant="outline-primary"
                              block>ALL</b-button>
                          <b-button
                              v-for="user in order.customer.publicUsers"
                              :key="user.id"
                              @click="createBackOrder(null, user.id)"
                              class="text-left font-12 font-inter--semibold text-nowrap" variant="outline-primary"
                              block>{{user.name}}</b-button>
                        </b-button-group>
                      </div>
                      <div v-else-if="isOpenSpecialOrderContext && isOpenSpecialOrderContext2" style="top: 0px" class="parts-order-view__context-menu context3-level-two no-customers-text" >
                        Not Users for send on the Customer side
                      </div>
                    </div>


                  </div>

                  <div v-if="isCanCancelPartsFromBackOrders">
                    <b-button @click="cancelBackOrders2(getBackOrdersForCancelling2() ? getBackOrdersForCancelling2() : null, getPartsForCancelling())" class="text-left font-12 font-inter--semibold" style="width: 275px" variant="outline-primary" block>
                      Undo Back Order Acceptance
                    </b-button>
                  </div>

                  <div  v-if="isCanCancelBackOrder">
                    <b-button @click="cancelBackOrders(getBackOrdersForCancellingIds() ? getBackOrdersForCancellingIds() : null)" class="text-left font-12 font-inter--semibold" style="width: 275px" variant="outline-primary" block>
                      Undo Back Order Acceptance
                    </b-button>
                  </div>
                </template>

                <template v-if="isOrderLoaded">
                  <template v-if="order && order.id && order.isProcessing()">
                    <b-button v-if="!isImportPDFInvoice" @click="importPDFInvoice" class="text-left font-12 font-inter--semibold"  style="width: 275px" variant="outline-primary" block>Import PDF Invoice
                    </b-button>
                    <!--                  <b-button class="text-left font-12 font-inter&#45;&#45;semibold"  style="width: 275px" variant="outline-primary" block :disabled="isDisabledMarkPartsShipped" @click="markPartsShipped">Mark Part Shipped</b-button>-->
                  </template>
                  <template v-if="order && order.id && order.isDenied()">
                    <b-button class="text-left font-12 font-inter--semibold"  style="width: 275px" variant="outline-primary" block @click="$refs.partMenu.close(); $refs['reinstate-order-modal'].show();">Reinstate Denied Order</b-button>
                  </template>
                  <template v-if="order && order.id && order.isCancelled()">
                    <b-button class="text-left font-12 font-inter--semibold"  style="width: 275px" variant="outline-primary" block @click="reinstateOrder(false)">Reinstate Cancelled Order</b-button>
                  </template>
                  <template v-if="order && order.id && order.isShipped()">
                    <b-button class="text-left font-12 font-inter--semibold"  style="width: 275px" variant="outline-primary" block @click="markPartsUndoShipped">Undo Parts Shipped</b-button>
                  </template>
                  <template v-if="order && order.id && order.isOpen()">
                    <b-button class="text-left font-12 font-inter--semibold"  style="width: 275px" variant="outline-primary" block @click="markAsProcessing">Mark as Processing</b-button>
                  </template>
                  <template v-if="order && order.id && (order.isOpen() || order.isProcessing())">
                    <b-button class="text-left font-12 font-inter--semibold"  style="width: 275px" variant="outline-primary" block @click="denyOrderShowModal">Deny Order</b-button>
                    <b-button class="text-left font-12 font-inter--semibold"  style="width: 275px" variant="outline-primary" block @click="$refs.partMenu.close(); $refs['cancel-order-modal'].show()" :disabled="isDisabledCancelOrder">Cancel Order</b-button>
                  </template>
                  <b-button class="text-left font-12 font-inter--semibold"  style="width: 275px" variant="outline-primary" block v-if="order && order.id && order.isDelivered()"
                            @click="markOrderCompleted">Mark Order Completed
                  </b-button>
                </template>
              </div>
            </b-button-group>
          </template>
          <template v-else>
            <b-button-group v-if="isEditOrder" vertical class="parts-order-view__context-menu"
                            style="width: 275px">

              <div>
                <b-button @click="changeDeliveryFromContext(item.data.item)" class="text-left font-12 font-inter--semibold context-menu-focus" style="width: 275px" variant="outline-primary" block>
                  Change Delivery ETA
                </b-button>
              </div>

              <div
                  v-if="item.data && item.data.item && item.data.item.type !== 'delivery' && item.data.item.cancelStatus !== 'CancelledByCustomer' && item.data.item.cancelStatus !== 'CancelledBySupplier'"
                  class="" @mouseover="openStockSelectContext(true, item.data.item.id)"
                  @mouseleave="openStockSelectContext(false, item.data.item.id)">
                <b-button class="text-left font-12 font-inter--semibold context-menu-focus" variant="outline-primary" block
                          style="width: 275px;" :class="{ active: isContextMenuActive == isOpenContextLevelTwo}">
                  Set Stock Status <i style="right: 5px"
                                      class="part-context-arrow-icon bx bx-chevron-right text-right"></i>
                </b-button>
                <div v-if="isOpenContextLevelTwo">
                  <div class="parts-order-view__context-menu context-level-two context-stock-select" style="border: none">
                    <stock-select
                        :id="item.data.item.id"
                        :options="stockOptions"
                        v-model="item.data.item.stockValue"
                        @onSelected="selectStockStatus($event, item.data.item)"
                        @onDatePickerChange="isClickedOnDatePicker = true"
                        :isNeedDateRange="true"
                        :isPartOrderPage="true"
                        :isForContext="true"
                        :ref="'stock-select-context-' + item.data.item.id"
                        class="context-stock-select-height"
                    >
                    </stock-select>
                  </div>
                </div>
              </div>

              <div v-if="item.data && item.data.item && isShowMarkAsProcessed && item.data.item.part_status === 'Shipped'">
                <b-button @click="selectProcessed(item.data.item)" class="text-left font-12 font-inter--semibold context-menu-focus" style="width: 275px" variant="outline-primary" block>
                  Mark as Processed
                </b-button>
              </div>

              <div
                  v-if="item.data && item.data.item && item.data.item.part_status !== 'Shipped' && isNeededStock(Number(item.data.item.stock)) && (order.status === 'Shipped' || order.status === 'Processing')">
                <b-button @click="selectShipped(item.data.item)"
                          class="text-left font-12 font-inter--semibold context-menu-focus" style="width: 275px" variant="outline-primary"
                          block>
                  Mark Shipped
                </b-button>
              </div>

              <div
                  class="w-100"
                  @mouseover="isOpenContextLevelTwo2 = true"
                  @mouseleave="isOpenContextLevelTwo2 = false">
                <b-button class="text-left font-12 font-inter--semibold context-menu-focus w-100" variant="outline-primary" block
                          style="width: 232px;" :class="{ active: isContextMenuActive == isOpenContextLevelTwo2}">
                  Set Invoice Number <i style="font-size: 18px; transform: translateY(-1px); right: 5px"
                                        class="part-context-arrow-icon bx bx-chevron-right text-right"></i>
                </b-button>
                <div
                    v-if="isOpenContextLevelTwo2"
                    :class="{
                      'supplier-invoice-number-context-2':
                      !(item.data && item.data.item && ((isShowMarkAsProcessed && item.data.item.part_status === 'Shipped') || ( item.data.item.part_status !== 'Shipped' && isNeededStock(Number(item.data.item.stock)) && (order.status === 'Shipped' || order.status === 'Processing')))) ||
                      !(item.data && item.data.item && item.data.item.type !== 'delivery' && item.data.item.cancelStatus !== 'CancelledByCustomer' && item.data.item.cancelStatus !== 'CancelledBySupplier')
                    }"
                    class="part-sub-context-menu invoice-number-context supplier-invoice-number-context">
                  <b-form-input @change="saveInvoiceNumber(item.data.item)" v-model="item.data.item.invoiceNumber"></b-form-input>
                    <div class="invoice-number-text">Invoice Number</div>
                </div>
              </div>

              <div
                  class="w-100"
                  @mouseover="isOpenContextLevelTwo3 = true"
                  @mouseleave="isOpenContextLevelTwo3 = false">
                <b-button class="text-left font-12 font-inter--semibold context-menu-focus w-100" variant="outline-primary" block
                          style="width: 232px;" :class="{ active: isContextMenuActive == isOpenContextLevelTwo3}">
                  Set Invoice Date <i style="font-size: 18px; transform: translateY(-1px); right: 5px"
                                        class="part-context-arrow-icon bx bx-chevron-right text-right"></i>
                </b-button>
                <div
                    v-if="isOpenContextLevelTwo3"
                    :class="{
                      'supplier-invoice-date-context-2':
                      !(item.data && item.data.item && ((isShowMarkAsProcessed && item.data.item.part_status === 'Shipped') || ( item.data.item.part_status !== 'Shipped' && isNeededStock(Number(item.data.item.stock)) && (order.status === 'Shipped' || order.status === 'Processing')))) ||
                      !(item.data && item.data.item && item.data.item.type !== 'delivery' && item.data.item.cancelStatus !== 'CancelledByCustomer' && item.data.item.cancelStatus !== 'CancelledBySupplier')
                    }"
                    class="part-sub-context-menu invoice-number-context supplier-invoice-date-context">
                  <date-picker @onChange="saveInvoiceDate(item.data.item)" v-model="item.data.item.invoiceDate"></date-picker>
                  <div class="invoice-number-text">Invoice Date</div>
                </div>
              </div>

              <div
                  v-if="item.data && item.data.item && (item.data.item.cancelStatus === 'CancelledByCustomer' || item.data.item.cancelStatus === 'CancelledBySupplier')"
                  @mouseover="isShowReinstateComment = true"
                  @mouseleave="isShowReinstateComment = false"
                  class="w-100">
                <b-button class="text-left font-12 font-inter--semibold context-menu-focus"
                          variant="outline-primary" block
                          :class="{ active: isContextMenuActive == isShowReinstateComment}">
                  Reinstate Part <i style="right: 5px" class="part-context-arrow-icon bx bx-chevron-right text-right context-menu-focus"></i>
                </b-button>
                <div v-if="isShowReinstateComment" class="reinstate-context">
                  <b-form-textarea v-model="item.data.item.reinstateComment" class="reinstate-textarea" placeholder="Comment Req...">

                  </b-form-textarea>
                  <i @click="saveReinstate(item.data.item)" class='bx bx-save save-reinstate-icon'></i>
                </div>
              </div>

              <div
                  v-if="item.data && item.data.item && item.data.item.cancelStatus !== 'CancelledByCustomer' && item.data.item.cancelStatus !== 'CancelledBySupplier'"
                  class="w-100"
                  @mouseover="isOpenContext2LevelTwo = true"
                  @mouseleave="isOpenContext2LevelTwo = false">
                <b-button class="text-left font-12 font-inter--semibold context-menu-focus" variant="outline-primary" block
                          @click="handleCancelPartButton":class="{ active: isContextMenuActive == isOpenContext2LevelTwo}">
                  Cancel Part <i style="right: 5px"
                                                                         class="part-context-arrow-icon bx bx-chevron-right text-right context-menu-focus"></i>
                </b-button>
                <div v-if="isOpenContext2LevelTwo">
                  <b-button-group
                      vertical
                      :class="{
                      'context2-level-two-2': !(item.data && item.data.item && item.data.item.type !== 'delivery' && item.data.item.cancelStatus !== 'CancelledByCustomer' && item.data.item.cancelStatus !== 'CancelledBySupplier') &&
                      !(item.data && item.data.item && item.data.item.part_status !== 'Shipped' && isNeededStock(Number(item.data.item.stock)) && (order.status === 'Shipped' || order.status === 'Processing')),
                      'context2-level-two-3': (item.data && item.data.item ) && ((item.data.item.part_status !== 'Shipped' && isNeededStock(Number(item.data.item.stock)) && (order.status === 'Shipped' || order.status === 'Processing')) || (isShowMarkAsProcessed && item.data.item.part_status === 'Shipped'))
                    }"
                      class="parts-order-view__context-menu context2-level-two">
                    <div
                        @mouseover="isOpenContextLevelThree = true"
                        @mouseleave="isOpenContextLevelThree = false"
                        class="">
                      <b-button
                          v-if="item.data && item.data.item && item.data.item.cancelStatus !== 'CancelledByCustomer'"
                          class="text-left font-12 font-inter--semibold text-nowrap" variant="outline-primary"
                          block>
                        Request By Customer
                      </b-button>

                      <div v-if="isOpenContextLevelThree" class="reinstate-context" style="left: 157px">
                        <b-form-textarea v-model="item.data.item.cancelComment" class="reinstate-textarea" placeholder="Comment Req...">

                        </b-form-textarea>
                        <i @click="cancelPart(item.data.item, 'customer')" class='bx bx-save save-reinstate-icon'></i>
                      </div>

                    </div>
                    <div
                        @mouseover="isOpenContextLevelThree2 = true"
                        @mouseleave="isOpenContextLevelThree2 = false"
                        class="">
                      <b-button
                          v-if="item.data && item.data.item && item.data.item.cancelStatus !== 'CancelledBySupplier'"
                          @click="cancelPart(item.data.item, 'supplier')"
                          style="width: 156px"
                          class="text-left font-12 font-inter--semibold text-nowrap" variant="outline-primary"
                          block>
                        Request By Supplier
                      </b-button>

                      <div v-if="isOpenContextLevelThree2" class="reinstate-context" style="left: 157px; top: 41px;">
                        <b-form-textarea v-model="item.data.item.cancelComment" class="reinstate-textarea" placeholder="Comment Req...">

                      </b-form-textarea>
                      <i @click="cancelPart(item.data.item, 'supplier')" class='bx bx-save save-reinstate-icon'></i>
                    </div>
                  </div>
                </b-button-group>
              </div>
            </div>

              <div>
                <b-button v-if="item && item.data && item.data.item.isCanUndoDeliveryETA" @click="undoDeliveryFromContext(item.data.item)" class="text-left font-12 font-inter--semibold context-menu-focus" style="width: 275px" variant="outline-primary" block>
                  Undo Change Delivery ETA
                </b-button>
              </div>

              <template v-if="order.properties.isEnableBackOrder && isHasOnBackOrderParts">

                <div
                    v-if="isHasPartsWithoutBackOrder || (isHasCreatedBackOrder && item && item.data && !isPartInCreatedBackOrder(item.data.item) && (!isExistInOrder(item.data.item) || isExistInCancelledOrder(item.data.item)))"
                    @mouseover="isOpenSpecialOrderContext = true"
                    @mouseleave="isOpenSpecialOrderContext = false"
                    class="">
                  <div>

                    <div>
                      <b-button class="text-left font-12 font-inter--semibold context-menu-focus"
                                style="width: 275px" variant="outline-primary" block
                                :class="{ active: isContextMenuActive == isOpenSpecialOrderContext}">
                        Mark Back Order Acceptance Req
                        <i style="right: 5px"
                           class="part-context-arrow-icon bx bx-chevron-right text-right context-menu-focus"></i>
                      </b-button>
                    </div>

                  </div>

                  <div
                      v-if="isOpenSpecialOrderContext"
                      class="context4-level-two"
                      :style="{'top': getTop(item.data.item) + 'px'}"
                      @mouseover="isOpenSpecialOrderContext2 = true"
                      @mouseleave="isOpenSpecialOrderContext2 = false"
                      >
                    <b-button class="text-left font-12 font-inter--semibold context-menu-focus"
                              style="width: 275px" variant="outline-primary"
                              :class="{ active: isContextMenuActive == isOpenSpecialOrderContext2}">
                      Send Back Order Acceptance - Customer
                      <i style="left:250px" class="part-context-arrow-icon bx bx-chevron-right text-right context-menu-focus"></i>
                    </b-button>


                    <div v-if="isOpenSpecialOrderContext && isOpenSpecialOrderContext2 && !_isEmpty(order.customer.publicUsers)">
                      <b-button-group
                          vertical
                          class="parts-order-view__context-menu context3-level-two" style="top: 0px">
                        <b-button
                            v-if = "order?.customer?.publicUsers?.length>1"
                            @click="createBackOrder(item.data.item, -1)"
                            class="text-left font-12 font-inter--semibold text-nowrap" variant="outline-primary"
                            block>ALL</b-button>
                        <b-button
                            v-for="user in order.customer.publicUsers"
                            :key="user.id"
                            @click="createBackOrder(item.data.item, user.id)"
                            class="text-left font-12 font-inter--semibold text-nowrap" variant="outline-primary"
                            block>{{user.name}}</b-button>
                      </b-button-group>
                    </div>
                    <div v-else-if="isOpenSpecialOrderContext && isOpenSpecialOrderContext2" style="top: 0px" class="parts-order-view__context-menu context3-level-two no-customers-text" >
                      Not Users for send on the Customer side
                    </div>
                  </div>


                </div>

                <div v-if="isCanCancelPartsFromBackOrders">
                  <b-button @click="cancelBackOrders2(getBackOrdersForCancelling2() ? getBackOrdersForCancelling2() : null, getPartsForCancelling())" class="text-left font-12 font-inter--semibold" style="width: 275px" variant="outline-primary" block>
                    Undo Back Order Acceptance
                  </b-button>
                </div>

                <div
                    v-if="isCanCancelBackOrder">
                  <b-button @click="cancelBackOrders(getBackOrdersForCancellingIds() ? getBackOrdersForCancellingIds() : null)" class="text-left font-12 font-inter--semibold" style="width: 275px" variant="outline-primary" block>
                    Undo Back Order Acceptance
                  </b-button>
                </div>
              </template>

              <template v-if="isOrderLoaded">
                <template v-if="order && order.id && order.isProcessing()">
                  <b-button v-if="!isImportPDFInvoice" @click="importPDFInvoice" class="text-left font-12 font-inter--semibold"  style="width: 275px" variant="outline-primary" block>Import PDF Invoice
                  </b-button>
<!--                  <b-button class="text-left font-12 font-inter&#45;&#45;semibold"  style="width: 275px" variant="outline-primary" block :disabled="isDisabledMarkPartsShipped" @click="markPartsShipped">Mark Part Shipped</b-button>-->
                </template>
                <template v-if="order && order.id && order.isDenied()">
                  <b-button class="text-left font-12 font-inter--semibold"  style="width: 275px" variant="outline-primary" block @click="$refs.partMenu.close(); $refs['reinstate-order-modal'].show();">Reinstate Denied Order</b-button>
                </template>
                <template v-if="order && order.id && order.isCancelled()">
                  <b-button class="text-left font-12 font-inter--semibold"  style="width: 275px" variant="outline-primary" block @click="reinstateOrder(false)">Reinstate Cancelled Order</b-button>
                </template>
                <template v-if="order && order.id && order.isShipped()">
                  <b-button class="text-left font-12 font-inter--semibold"  style="width: 275px" variant="outline-primary" block @click="markPartsUndoShipped">Undo Part Shipped</b-button>
                </template>
                <template v-if="order && order.id && order.isOpen()">
                  <b-button class="text-left font-12 font-inter--semibold"  style="width: 275px" variant="outline-primary" block @click="markAsProcessing">Mark as Processing</b-button>
                </template>
                <template v-if="order && order.id && (order.isOpen() || order.isProcessing())">
                  <b-button class="text-left font-12 font-inter--semibold"  style="width: 275px" variant="outline-primary" block @click="denyOrderShowModal">Deny Order</b-button>
                  <b-button class="text-left font-12 font-inter--semibold"  style="width: 275px" variant="outline-primary" block @click="$refs.partMenu.close(); $refs['cancel-order-modal'].show()" :disabled="isDisabledCancelOrder">Cancel Order</b-button>
                </template>
                <b-button class="text-left font-12 font-inter--semibold"  style="width: 275px" variant="outline-primary" block v-if="order && order.id && order.isDelivered()"
                          @click="markOrderCompleted">Mark Order Completed
                </b-button>
              </template>
          </b-button-group>
          <b-button-group v-else vertical class="parts-order-view__context-menu"
                          style="width: 275px">
            <div>
              <b-button @click="changeDeliveryFromContext(item.data.item)" class="text-left font-12 font-inter--semibold context-menu-focus" style="width: 275px" variant="outline-primary" block>
                Change Delivery ETA
              </b-button>
              <b-button v-if="item && item.data && item.data.item.isCanUndoDeliveryETA" @click="undoDeliveryFromContext(item.data.item)" class="text-left font-12 font-inter--semibold context-menu-focus" style="width: 275px" variant="outline-primary" block>
                Undo Change Delivery ETA
              </b-button>
              <div v-if="item.data && item.data.item && item.data.item.part_status !== 'Shipped' && isNeededStock(Number(item.data.item.stock)) && (order.status === 'Shipped' || order.status === 'Processing')">
                  <b-button @click="selectShipped(item.data.item)"
                            class="text-left font-12 font-inter--semibold context-menu-focus" style="width: 275px" variant="outline-primary"
                            block>
                    Mark Shipped
                  </b-button>
                </div>

              <template v-if="order.properties.isEnableBackOrder && isHasOnBackOrderParts">
                  <div
                    v-if="isHasPartsWithoutBackOrder || (isHasCreatedBackOrder && item && item.data && !isPartInCreatedBackOrder(item.data.item) && (!isExistInOrder(item.data.item) || isExistInCancelledOrder(item.data.item)))"
                    @mouseover="isOpenSpecialOrderContext = true"
                    @mouseleave="isOpenSpecialOrderContext = false"
                    class="">
                  <div>

                    <div>
                      <b-button class="text-left font-12 font-inter--semibold context-menu-focus" style="width: 275px"
                                variant="outline-primary" block>
                        Mark Back Order Acceptance Req
                      </b-button>
                    </div>

                  </div>

                  <div
                      v-if="isOpenSpecialOrderContext"
                      class="context4-level-two"
                      :style="{'top': getTop3(item.data.item) + 'px'}"
                      @mouseover="isOpenSpecialOrderContext2 = true"
                      @mouseleave="isOpenSpecialOrderContext2 = false"
                  >
                    <b-button class="text-left font-12 font-inter--semibold context-menu-focus" style="width: 275px" variant="outline-primary" block>
                      Send Back Order Acceptance - Customer
                      <i style="left:250px" class="part-context-arrow-icon bx bx-chevron-right text-right context-menu-focus"></i>
                    </b-button>


                    <div v-if="isOpenSpecialOrderContext && isOpenSpecialOrderContext2 && !_isEmpty(order.customer.publicUsers)">
                      <b-button-group
                          vertical
                          class="parts-order-view__context-menu context3-level-two" style="top: 0px">
                        <b-button
                            v-if = "order?.customer?.publicUsers?.length>1"
                            @click="createBackOrder(item.data.item, -1)"
                            class="text-left font-12 font-inter--semibold text-nowrap" variant="outline-primary"
                            block>ALL</b-button>
                        <b-button
                            v-for="user in order.customer.publicUsers"
                            :key="user.id"
                            @click="createBackOrder(item.data.item, user.id)"
                            class="text-left font-12 font-inter--semibold text-nowrap" variant="outline-primary"
                            block>{{user.name}}</b-button>
                      </b-button-group>
                    </div>
                    <div v-else-if="isOpenSpecialOrderContext && isOpenSpecialOrderContext2" style="top: 0px" class="parts-order-view__context-menu context3-level-two no-customers-text" >
                      Not Users for send on the Customer side
                    </div>
                  </div>


                </div>


                <div v-if="isCanCancelPartsFromBackOrders">
                  <b-button @click="cancelBackOrders2(getBackOrdersForCancelling2() ? getBackOrdersForCancelling2() : null, getPartsForCancelling())" class="text-left font-12 font-inter--semibold" style="width: 275px" variant="outline-primary" block>
                    Undo Back Order Acceptance
                  </b-button>
                </div>

                <div
                    v-if="isCanCancelBackOrder">
                  <b-button @click="cancelBackOrders(getBackOrdersForCancellingIds() ? getBackOrdersForCancellingIds() : null)" class="text-left font-12 font-inter--semibold" style="width: 275px" variant="outline-primary" block>
                    Undo Back Order Acceptance
                  </b-button>
                </div>
              </template>

              <template v-if="isOrderLoaded">
                <template v-if="order && order.id && order.isProcessing()">
                  <b-button v-if="!isImportPDFInvoice" @click="importPDFInvoice" class="text-left font-12 font-inter--semibold"  style="width: 275px" variant="outline-primary" block>Import PDF Invoice
                  </b-button>
<!--                  <b-button class="text-left font-12 font-inter&#45;&#45;semibold"  style="width: 275px" variant="outline-primary" block :disabled="isDisabledMarkPartsShipped" @click="markPartsShipped">Mark Part Shipped</b-button>-->
                </template>
                <template v-if="order && order.id && order.isDenied()">
                  <b-button class="text-left font-12 font-inter--semibold"  style="width: 275px" variant="outline-primary" block @click="$refs.partMenu.close(); $refs['reinstate-order-modal'].show();">Reinstate Denied Order</b-button>
                </template>
                <template v-if="order && order.id && order.isCancelled()">
                  <b-button class="text-left font-12 font-inter--semibold"  style="width: 275px" variant="outline-primary" block @click="reinstateOrder(false)">Reinstate Cancelled Order</b-button>
                </template>
                <template v-if="order && order.id && order.isShipped()">
                  <b-button class="text-left font-12 font-inter--semibold"  style="width: 275px" variant="outline-primary" block @click="markPartsUndoShipped">Undo Part Shipped</b-button>
                </template>
                <template v-if="order && order.id && order.isOpen()">
                  <b-button class="text-left font-12 font-inter--semibold"  style="width: 275px" variant="outline-primary" block @click="markAsProcessing">Mark as Processing</b-button>
                </template>
                <template v-if="order && order.id && (order.isOpen() || order.isProcessing())">
                  <b-button class="text-left font-12 font-inter--semibold"  style="width: 275px" variant="outline-primary" block @click="denyOrderShowModal">Deny Order</b-button>
                  <b-button class="text-left font-12 font-inter--semibold"  style="width: 275px" variant="outline-primary" block @click="$refs.partMenu.close(); $refs['cancel-order-modal'].show()" :disabled="isDisabledCancelOrder">Cancel Order</b-button>
                </template>
                <b-button class="text-left font-12 font-inter--semibold"  style="width: 275px" variant="outline-primary" block v-if="order && order.id && order.isDelivered()"
                          @click="markOrderCompleted">Mark Order Completed
                </b-button>
              </template>
              </div>
          </b-button-group>
          </template>
        </b-button-toolbar>
      </vue-context>
    </div>

    <b-modal ref="non-returnable-example" id="non-returnable-modal-supplier-order" size="custom-non-returnable-modal" :hide-footer="true"
             title="Non-Returnable - Part Acceptance Agreement">
      <template v-slot:modal-backdrop class="my-backdrop"></template>

      <div class="modal-mark-as-returned non-returnable-supplier-modal form-container" style="padding-bottom: 20px">
        <form v-if="order.nonReturnable">
          <div class="form-group row pr-3">
            <label class="col col-form-label">{{companyInfo.businessName}} <span class="font-weight-normal">requires you to read and accept the following Terms and Conditions for the
            supply of the following part/s with your order.</span></label>
          </div>
          <div class="form-group row pr-3">
            <label class="col col-form-label"><span class="font-weight-normal">Select the part/s in the list below to accept.</span></label>
          </div>
          <div class="d-flex justify-content-beetwen pr-3 pl-3 pt-3">
            <div class="d-flex col-6 pl-0">
              <p class="font-weight-bold pr-2">Quote Number: </p>
              <p class="">{{order.dmsQuoteNumber}}</p>
            </div>
          </div>
          <div class="d-flex pr-3 pl-3">
            <div class="d-flex col-6 pl-0">
              <p class="font-weight-bold pr-2">Part/s: </p>
              <p class=""></p>
            </div>
            <div class="d-flex col-6 pl-0">
              <p class="font-weight-bold pr-2">Make: </p>
              <p class="">{{order.vehicle.make}}</p>
            </div>
          </div>
          <div class="form-group row pr-3 pl-3" v-for="part in order.nonReturnable.parts">
            <!--            <input-->
            <!--                type="text"-->
            <!--                class="col-12 form-control"-->
            <!--            />-->
            <div class="col-12 col-md-1" style="padding: 0px">
              <label class="input-checkbox col-form-label font-weight-bold pl-5">
                <input
                    name="part"
                    type="checkbox"
                    :disabled="true"
                    :checked="true"
                />
                <div class="checkmark"></div>
              </label>
            </div>

            <div style="height: 32px; padding-top: 7px;" class="col-12 col-md-11 form-control">
              <b>Part: </b> {{part.partDescription}}<span v-if="part.partNumber"> | {{part.partNumber}}</span><span v-if="part.qty"> | QTY {{part.qty}}</span><span v-if="part.buyPrice"> | {{part.buyPrice | formatMoney}}</span>
            </div>
          </div>
          <div class="form-group row pr-3 pl-3" v-if="modalSettings.tics">
            <label class="col col-form-label pl-0">Terms & Conditions</label>
            <div class="disabled-vue-editor-non-returnable">
              <vue-editor
                  v-model="order.nonReturnable.tics"
                  :disabled="true"
                  :editorToolbar="editorToolbar"></vue-editor>
            </div>
            <!--            <div v-html="settings.acceptance" class="col-12 form-control non-returnable-text-message" type="text" disabled></div>-->
          </div>
          <div v-if="modalSettings.acceptanceOfTics" class="w-100 text-right row p-3">
            <label class="input-checkbox col-form-label font-weight-bold pl-5">
              <input
                  name="remember"
                  type="checkbox"
                  :checked="true"
                  :disabled="true"
              />
              <div class="checkmark"></div>I have read & accept the suppliers Terms and Conditions
            </label>
          </div>

          <div v-if="modalSettings.eSign" class="form-group row p-3 position-relative">
            <label class="col col-form-label pl-0">E-Signature</label>
            <template>
              <div class="col-12 p-0 non-returnable-signature-block non-returnable-signature-canvas" ref="nonReturnableSignatureBlock">
                <img :src="order.nonReturnable.eSign" alt="">
              </div>
            </template>
          </div>
          <template v-if="modalSettings.acceptedBy && modalSettings.acceptedByPosition">
            <div class="form-group row pr-3 pl-3">
              <label class="col-4 col-form-label pl-0">Accepted By: </label>
              <input
                  type="text"
                  v-model="order.nonReturnable.acceptedBy"
                  readonly
                  class="col-8 form-control"
              />
            </div>
            <div class="form-group row pr-3 pl-3">
              <label class="col-4 col-form-label pl-0">Position Held: </label>
              <input
                  type="text"
                  v-model="order.nonReturnable.acceptedByPosition"
                  class="col-8 form-control"
                  readonly
              />
            </div>
          </template>

        </form>
      </div>
    </b-modal>

    <b-modal ref="back-order-alert" centered size="invoice-number-required-modal">
      <template #modal-header="{ close }">
        <h5 class="modal-title">Back Order - Part Acceptance Alert</h5>
        <b-button class="button-close-modal-header" @click="close()">×</b-button>
      </template>
      <div class="modal-invite-customer form-container">
        <p class="pl-3 pr-3">The part/s you are choosing to mark as requiring customer <b>Back Order Acceptance</b>, have already been accepted at the time the customer made the order.</p>
        <p class="pl-3 pr-3">View the <b>Files</b> section for a copy of the non-returnable acceptance.</p>
      </div>
      <template v-slot:modal-footer="{ cancel, yes }">
        <div class="d-flex w-100 justify-content-end mb-3">
          <button class="input-btn modal-undo-part-line-change-btn-no" style="color: white !important; background-color: #29BBC1 !important;" @click="cancel()">Close</button>
        </div>
      </template>
    </b-modal>

    <b-modal ref="invoice-number-required-modal" centered size="invoice-number-required-modal">
      <template #modal-header="{ close }">
        <h5 class="modal-title">Invoice Number Required</h5>
        <b-button class="button-close-modal-header" @click="close()">×</b-button>
      </template>
      <div class="modal-invite-customer form-container">
        <p class="pl-3 pr-3">An <b>Invoice Number</b> is missing with this order.</p>
        <p class="pl-3 pr-3">Please enter your invoice number before <b>{{invoiceRequiredText}}</b></p>
      </div>
      <template v-slot:modal-footer="{ cancel, yes }">
        <div class="d-flex w-100 justify-content-end mb-3">
          <button class="input-btn modal-undo-part-line-change-btn-no" @click="cancel()">Close</button>
        </div>
      </template>
    </b-modal>

    <b-modal ref="deny-order-modal" centered size="deny-order-modal">
      <template #modal-header="{ close }">
        <h5 class="mb-0">Deny Order {{order.number}}</h5>
        <b-button class="button-close-modal-header" @click="close()">×</b-button>
      </template>
      <div class="modal-invite-customer form-container mt-3">
        <p class="pl-3 pr-3">You about to Deny Order <b>{{order.number}}</b> for <b>{{order.customer.name}}</b></p>
        <p class="pl-3 pr-3">Provide a reason below to notify them of this decision.</p>
        <b-textarea v-model="denyReason" class="deny-order-modal-textarea">
        </b-textarea>
      </div>
      <template v-slot:modal-footer="{ cancel, yes }">
        <div class="d-flex w-100 justify-content-end mb-3">
          <button class="input-btn modal-undo-part-line-change-btn-no" @click="cancel()">Cancel</button>
          <button :disabled="!denyReason.length" class="input-btn modal-undo-part-line-change-btn-yes" @click="denyOrder">Confirm
          </button>
        </div>
      </template>
    </b-modal>

    <b-modal ref="cancel-order-modal" centered size="deny-order-modal">
      <template #modal-header="{ close }">
        <h5 class="mb-0">Cancel Order {{order.number}}</h5>
        <b-button class="button-close-modal-header" @click="close()">×</b-button>
      </template>
      <div class="modal-invite-customer form-container mt-3">
        <p class="pl-3 pr-3">You about to Cancel Order <b>{{order.number}}</b> for <b>{{order.customer.name}}</b></p>
        <p class="pl-3 pr-3">Provide a reason below to notify them of this decision.</p>
        <b-textarea v-model="cancelReason" class="deny-order-modal-textarea">
        </b-textarea>
      </div>
      <template v-slot:modal-footer="{ cancel, yes }">
        <div class="d-flex w-100 justify-content-end mb-3">
          <button class="input-btn modal-undo-part-line-change-btn-no" @click="cancel()">Cancel</button>
          <button :disabled="!cancelReason.length" class="input-btn modal-undo-part-line-change-btn-yes" @click="cancelOrder">Confirm
          </button>
        </div>
      </template>
    </b-modal>


    <b-modal ref="reinstate-order-modal" centered size="reinstate-order-modal">
      <template #modal-header="{ close }">
        <h5 class="mb-0">Reinstate Order {{order.number}}</h5>
        <b-button class="button-close-modal-header" @click="close()">×</b-button>
      </template>
      <div class="modal-invite-customer form-container">
        <p class="pl-3 pr-3">You about to <b>Reinstate</b> Order <b>{{order.number}}</b> for <b>{{order.customer.name}}</b></p>
        <p class="pl-3 pr-3">Denied on: {{order.lastChangedStatusOn | formatDate }}</p>
        <p class="pl-3 pr-3">Provide a reason below to notify them of this decision.</p>
        <b-textarea v-model="reinstateReason" class="deny-order-modal-textarea">
        </b-textarea>
      </div>
      <template v-slot:modal-footer="{ cancel, yes }">
         <div class="d-flex w-100 justify-content-end mb-3">
          <button class="input-btn modal-undo-part-line-change-btn-no" @click="cancel()">Cancel</button>
          <button :disabled="!reinstateReason.length" class="input-btn modal-undo-part-line-change-btn-yes" @click="reinstateOrder">Confirm
          </button>
        </div>
      </template>
    </b-modal>

    <b-modal ref="special-order-example" id="special-order-modal" size="custom-special-order-modal custom-special-order-modal-2" @hide="onCloseModal"
             title="Back Order - Part Acceptance Agreement">
      <template v-slot:modal-backdrop class="my-backdrop"></template>

      <div class="modal-mark-as-returned special-supplier-order-modal form-container">
        <form>
          <div class="form-group row pr-3">
            <label class="col col-form-label">{{selectedBackOrder.data.description.supplier}} <span class="font-weight-normal">requires you to read and accept the following Terms and Conditions for the supply of the following part/s with your order.</span></label>
          </div>
          <div class="d-flex justify-content-beetwen pr-3 pl-3 pt-3">
            <div class="d-flex col-6 pl-0">
              <p class="font-weight-bold pr-2">Order Number: </p>
              <p class="">{{selectedBackOrder.data.description.orderNumber}}</p>
            </div>
            <div class="d-flex col-6 pl-0">
              <p class="font-weight-bold pr-2">Invoice Number: </p>
              <p class="">{{selectedBackOrder.data.description.invoiceNumber}}</p>
            </div>
          </div>
          <div class="d-flex pr-3 pl-3">
            <div class="d-flex col-6 pl-0">
              <p class="font-weight-bold pr-2">Part/s: </p>
              <p class=""></p>
            </div>
            <div class="d-flex col-6 pl-0">
              <p class="font-weight-bold pr-2">Make: </p>
              <p class="">{{order.vehicle.make}}</p>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-12 d-flex" v-for="(prt, id) in selectedBackOrder.data.parts" :key="prt.orderPartId" style="margin-top: 10px">
              <div class="col-12 col-md-1" style="padding: 0px">
                <label class="input-checkbox col-form-label font-weight-bold pl-5">
                  <input
                      name="part"
                      type="checkbox"
                      :checked="selectedBackOrder.status === 'accepted' || selectedBackOrder.status === 'declined' ? true : false"
                      disabled
                  />
                  <div class="checkmark"></div>
                </label>
              </div>
              <div style="height: 32px; padding-top: 7px;" class="col-12 col-md-11 form-control">
                <b>Part: </b> {{prt.partDescription}} <span v-if="prt.partNumber">| {{prt.partNumber}}</span> | QTY {{prt.qty}} | {{prt.buyPrice | formatMoney}}
              </div>
            </div>
          </div>
          <div class="form-group row pr-3 pl-3" v-if="selectedBackOrder.data.settings.displayOrderAcceptanceTICs">
            <label class="col col-form-label pl-0">Terms & Conditions</label>
            <div class="disabled-vue-editor-supp-order">
              <vue-editor
                  :disabled="true"
                  v-model="selectedBackOrder.data.settings.termsAndConditions"
                  :editorToolbar="[]"></vue-editor>
            </div>
            <!--            <div v-html="settings.acceptance" class="col-12 form-control special-order-text-message" type="text" disabled></div>-->
          </div>
          <div class="w-100 text-right row p-3" v-if="selectedBackOrder.data.settings.requireAcceptanceOfOrderTICs">
             <label class="input-checkbox col-form-label font-weight-bold pl-5">
              <input type="checkbox" :checked="selectedBackOrder.status === 'accepted' || selectedBackOrder.status === 'declined' ? true : false" disabled>
              <div class="checkmark"></div>
              <span class="text">I have read & accept the suppliers Terms and Conditions</span>
            </label>
          </div>
          <div v-if="selectedBackOrder.data.settings.requireESignature" class="form-group row p-3 position-relative" @mouseover="checkSignature">
            <label class="col col-form-label pl-0">E-Signature</label>
            <template>
              <div class="col-12 p-0 special-order-signature-block" ref="specialOrderBlock">
<!--                <VPerfectSignature :stroke-options="strokeOptions" ref="signaturePad"-->
<!--                                   class="special-order-signature-canvas"/>-->
                <div class="special-order-signature-canvas"> <img :src="selectedBackOrder.eSignature" alt=""></div>
              </div>
<!--              <a-->
<!--                  class="control-elem-1__item"-->
<!--                  @click.prevent="clearSignature"-->
<!--                  v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title:'Clear this signature', placement:'leftbottom'}">-->
<!--                <i class="bx bx-trash"></i>-->
<!--              </a>-->
            </template>
          </div>
          <template v-if="selectedBackOrder.data.settings.requireSigneeToAddNameOrJob">
            <div class="form-group row pr-3 pl-3">
              <label class="col-4 col-form-label pl-0">Accepted By: </label>
              <input
                  type="text"
                  v-model="selectedBackOrder.signedBy"
                  readonly
                  class="col-8 form-control"
              />
            </div>
            <div class="form-group row pr-3 pl-3">
              <label class="col-4 col-form-label pl-0">Position Held: </label>
              <input
                  type="text"
                  readonly
                  v-model="selectedBackOrder.signedByPositionHeld"
                  class="col-8 form-control"
              />
            </div>
          </template>
        </form>
      </div>
      <template v-slot:modal-footer="{ confirm, cancel }">
        <template>
          <b-button class="input-btn cancel mr-2" @click="cancel()">Close</b-button>
        </template>
      </template>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable */
import { VueContext } from 'vue-context'
import {VueEditor} from 'vue2-editor'
import Axios from 'axios'
import Order from '../../../components/order/models/Order'
import OrderProgress from '@/components/order/supplier/OrderProgress'
import vinDate from '../../../components/VinDate'
import CustomerAccountDeliver from '../../../components/CustomerAccountDeliver'
import _ from 'lodash'
import { mapGetters } from 'vuex'
import FileUpload from '../../../components/order/supplier/FileUpload'
import psLabelValue from '../../../components/ps-label-value.vue'
import * as dayjs from 'dayjs'
import datePicker from '../../../components/datePicker'
import partsOrdersImportPDF from '../rfq/rfq-view/rfq-import-quote-pdf'
import NumberFormatter from '../../../components/utility/number-formatter'
import { States } from '../../../store/states'
import StockSelect from '../../../components/StockSelect'
import HighlightableInput from '../../../components/HighlightableInput'
import expiringStorage from '../../../components/rs-tabs/expiringStorage'
import accounting from 'accounting'
import DatePicker from '../../../components/datePicker'
import {VPerfectSignature} from "v-perfect-signature";
import Chat from '../../../components/utility/chat/chat'
import {subscribeToOrderChannel, subscribeToRfqChannel} from "@/centrifuge";
import StickyHeader from './sticky-header'
import SearchFilter from '../../../components/utility/search-filter/search-filter'
import WindowNotification from "@/components/utility/window-notification.vue";

export default {
  name: 'SuppliersPartsOrderView',
  props: {
    orderId: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      isContextMenuActive: true,
      sorting: {
        type: '',
        direction: 'asc'
      },
      editorToolbar: [[{
        header: [false, 1, 2, 3, 4, 5, 6]
      }], ["bold", "italic", "underline", "strike"], // toggled buttons
        [{
          align: ""
        }, {
          align: "center"
        }, {
          align: "right"
        }, {
          align: "justify"
        }], [{
          list: "ordered"
        }, {
          list: "bullet"
        },], [{
          indent: "-1"
        }, {
          indent: "+1"
        }], // outdent/indent
        [{
          color: []
        }],
        ["clean"] // remove formatting button
      ],
      _chat: null,
      chatMessages: [],
      backOrders: [],
      chatTypers: {},
      onlineStatusIntervalId: null,
      interval: null,
      chatRepairerIsOnline: false,
      isShowStickyHeader: false,
      denyReason: '',
      cancelReason: '',
      originalParts: [],
      reinstateReason: '',
      invoiceRequiredText: '',
      resizer: 0,
      openedRows: [],
      stockOptions: States.stockOptions,
      isOpenContextLevelTwo: false,
      isClickedOnDatePicker: false,
      isOpenContext2LevelTwo: false,
      isOpenContextLevelThree: false,
      isOpenContextLevelThree2: false,
      isOpenContextLevelTwo2: false,
      isOpenContextLevelTwo3: false,
      isOpenSpecialOrderContext: false,
      isOpenSpecialOrderContext2: false,
      isEmptySignature: true,
      isOpenModal: false,
      selectedBackOrder: {
        "id": "",
        "createdBy": "",
        "data": {
          "parts": {
          },
          "settings": {
            "requireESignature": false,
            "termsAndConditions": null,
            "sendOrderAcceptanceBy": null,
            "displayOrderAcceptanceTICs": false,
            "requireSigneeToAddNameOrJob": false,
            "requireAcceptanceOfOrderTICs": false
          },
          "description": {
            "supplier": "",
            "orderNumber": "",
            "invoiceNumber": null
          }
        },
        "createdOn": "",
        "status": "",
        "sentTo": {
          "id": "",
          "name": ""
        },
        "sentOn": "",
        "sentBy": {
          "id": "",
          "name": ""
        }
      },
      strokeOptions: {
        size: 4,
        thinning: 0.75,
        smoothing: 0.5,
        streamline: 0.5,
      },
      signature: '',
      isShowReinstateComment: false,
      isImportPDFInvoice: false,
      isEditOrder: false,
      contextInvoiceNumber: '',
      contextInvoiceDate: '',
      stockForAll: { 'label': 'Set All', 'textValue': 'selectAll', 'value': -1, 'comment': '' },
      isClickedChangeDeliveryDate: false,
      isCanEditSupplierInstruction: false,
      isCanBlinking: true,
      focusedElement: null,
      isEditDeliveryDate: false,
      isLoadPartsForImporting: false,
      isStartImportingParts: false,
      isLoading: false,
      isHoldCtrl: false,
      isHoldShift: false,
      isSelectedManualy: false,
      isCheckedAllValue: false,
      callComputed: 0,
      documentHeight: 0,
      callComputed2: 0,
      searchFilter: '',
      order: {
        id: 0,
        dmsQuoteNumber: null,
        status: '',
        lastChangedStatusOn: null,
        statusId: 0,
        number: '',
        dateCreated: '',
        dateDue: '',
        amount: 0,
        trackingCode: '',
        subtotal: 0,
        total: 0,
        gst: 0,
        invoiceNumber: null,
        supplierDeliveryDate: null,
        repairerDeliveryDate: null,
        supplierOrderPartsDeliveryETA: null,
        paymentTerms: 'credit',
        rfq: {
          id: null,
          name: '',
          directOrderInstruction: null,
        },
        properties: {
          isSentAsDirectOrder: false,
          isEnableBackOrder: false,
        },
        deliveryTo: {
          address: '',
          postcode: '',
          state: '',
          suburb: '',
          repairerInstruction: '',
          supplierInstruction: '',
          street: '',
          unitLevelLot: '',
          instruction: ''
        },
        customer: {
          name: 'AAA Smash Repair'
        },
        vehicle: {
          rego: '',
          vin: '',
          dom: '',
          make: '',
          model: '',
          colour: '',
          transmission: '',
          series: '',
          odometer: '',
          body: '',
        },
        QPNumber: '',
        files: [],
        estimator: '',
        parts: []
      },
      activeTab: '',
      isEditable: true,
      isLoadedOrder: false,
      isShowPdf: false,
      isLoadingInvoiceParts: false,
      steps: [
        { label: 'Order Received' },
        { label: 'Processing' },
        { label: 'Shipped' },
        { label: 'Partial Delivery' },
        { label: 'Parts Delivered' },
        { label: 'Order Completed' },
      ],
      testStockValue: { 'label': 'In Stock', 'textValue': 'InStock', 'value': 1, 'comment': '' },
      statusList: [
        {
          id: 'New',
          name: 'Ordered',
          nameClass: 'text-success',
          color: 'info',
          iconClass: 'bx bxs-check-circle text-success'
        },
        {
          id: 'Created',
          name: 'Created',
          nameClass: 'text-success',
          color: 'info',
          iconClass: 'bx bxs-check-circle text-success'
        },
        {
          id: 'RequestCredit',
          name: 'Request Credit',
          nameClass: 'text-success',
          color: 'info',
          iconClass: 'bx bxs-check-circle text-success'
        },
        // {
        //   id: 'Shipped',
        //   name: 'Shipped',
        //   nameClass: 'text-primary',
        //   color: 'warning',
        //   iconClass: 'bx icon-truck-transit text-primary'
        // },
        {
          id: 'Received',
          name: 'Received',
          nameClass: 'text-success',
          color: 'success',
          iconClass: 'bx bx-buildings'
        },
        {
          id: 'CancelPart',
          name: 'Cancel Part',
          nameClass: 'text-danger',
          color: 'danger',
          iconClass: 'bx bxs-flag-alt text-danger'
        },
        {
          id: 'Return',
          name: 'Returned',
          nameClass: 'text-secondary',
          color: 'danger',
          iconClass: 'bx bx-block text-secondary'
        },
        {
          id: '',
          name: 'Returned',
          description: '"Returned" with credit status pending',
          nameClass: 'text-secondary',
          color: 'danger',
          iconClass: 'bx bx-block text-secondary'
        },
        {
          id: '',
          name: 'Returned',
          description: '"Returned" with credit status approved',
          nameClass: 'text-secondary',
          color: 'danger',
          iconClass: 'bx bx-block text-secondary'
        },
        {
          id: '',
          name: 'Returned',
          description: '"Returned" with credit status denied',
          nameClass: 'text-secondary',
          color: 'danger',
          iconClass: 'bx bx-block text-secondary'
        },
        {
          id: 'IncorrectPart',
          name: 'Incorrect',
          nameClass: 'text-danger',
          color: 'danger',
          iconClass: 'bx bxs-flag-alt text-danger'
        },
        {
          id: 'DidNotArrive',
          name: 'Did Not Arrive',
          nameClass: 'text-danger',
          color: 'danger',
          iconClass: 'bx bxs-flag-alt text-danger'
        },
        {
          id: '',
          name: 'Did Not Arrive',
          description: '"Did Not Arrive" with credit status pending',
          nameClass: 'text-danger',
          color: 'danger',
          iconClass: 'bx bxs-flag-alt text-danger'
        },
        {
          id: '',
          name: 'Did Not Arrive',
          description: '"Did Not Arrive" with credit status approved',
          nameClass: 'text-danger',
          color: 'danger',
          iconClass: 'bx bxs-flag-alt text-danger'
        },
        {
          id: '',
          name: 'Did Not Arrive',
          description: '"Did Not Arrive" with credit status denied',
          nameClass: 'text-danger',
          color: 'danger',
          iconClass: 'bx bxs-flag-alt text-danger'
        },
      ],
      sortByTable: '',
      sortDesc: '',
      orderFields: [
        {
          label: 'Line',
          key: 'line',
          sortable: false,
          tdClass: 'font-12 clickable',
          thClass: 'font-13 position-sticky line-column',
          thStyle: 'border: 0 !important; background-color: #e3e4e7 !important;',
          class: 'part-list-line text-center font-inter font-inter--bold',
          stickyColumn: true
        },
        {
          label: 'Part Name',
          key: 'part_list',
          sortable: true,
          tdClass: 'font-12 clickable order-number part-list-position-left',
          thClass: 'font-13 position-sticky part-list-position-left part-list-table-sticky part-name-column',
          class: 'parts-order-th--part-list font-inter font-inter--bold',
          stickyColumn: true
        },
        {
          label: '',
          key: 'status_id',
          sortable: false,
          tdClass: 'font-20 font-inter--semibold clickable',
          thClass: 'font-13 font-inter--bold position-relative status-column',
          class: 'font-inter'
        },
        {
          label: 'Part Nbr',
          key: 'part_number',
          sortable: true,
          tdClass: 'font-12 font-inter--semibold clickable text-center',
          thClass: 'font-13 font-inter--bold text-center part-nbr-column',
          class: 'font-inter'
        },
        // {
        //   label: 'Customer Order Nbr',
        //   key: 'customer_order',
        //   sortable: true,
        //   tdClass: 'font-12 font-inter--semibold clickable text-center',
        //   thClass: 'font-13 font-inter--bold text-center',
        //   class: 'font-inter'
        // },
        {
          label: 'Stock Status',
          key: 'stock',
          sortable: true,
          tdClass: 'font-12 font-inter--semibold clickable text-center',
          thClass: 'font-13 font-inter--bold text-center stock-status-column',
          class: 'font-inter parts-order-th--in-stock'
        },
        // {
        //   label: 'Cost',
        //   key: 'total',
        //   sortable: true,
        //   tdClass: 'font-12 font-inter--semibold clickable text-right money-cost',
        //   thClass: 'font-13 font-inter--bold money-cost',
        //   class: 'font-inter'
        // },
        {
          label: 'Qty Order',
          key: 'qty_order',
          sortable: true,
          tdClass: 'font-12 font-inter--semibold clickable text-center qty-order-rcvd',
          thClass: 'font-13 font-inter--bold qty-order-rcvd qty-column',
          class: 'font-inter'
        },
        {
          label: 'Qty Rcvd',
          key: 'qtyRcv',
          sortable: true,
          tdClass: 'font-12 font-inter--semibold clickable text-center qty-order-rcvd',
          thClass: 'font-13 font-inter--bold qty-order-rcvd qty-rcv-column',
          class: 'font-inter'
        },
        {
          label: 'Credit Amt',
          key: 'credit',
          sortable: true,
          tdClass: 'font-12 font-inter--semibold clickable ',
          thClass: 'font-13 font-inter--bold text-center credit-amt credit-column',
          class: 'font-inter'
        },
        {
          label: 'Unit List',
          key: 'rrp',
          sortable: true,
          tdClass: 'font-12 font-inter--semibold clickable text-right pr-5',
          thClass: 'font-13 font-inter--bold text-center unit-list-column',
          class: 'font-inter'
        },
        {
          label: 'Unit Net',
          key: 'price',
          sortable: true,
          tdClass: 'font-12 font-inter--semibold clickable text-right pr-5',
          thClass: 'font-13 font-inter--bold text-center unit-net-column',
          class: 'font-inter'
        },
        {
          label: 'GST',
          key: 'gst',
          sortable: true,
          tdClass: 'font-12 font-inter--semibold clickable text-right pr-5',
          thClass: 'font-13 font-inter--bold text-center gst-col gst-column',
          class: 'font-inter'
        },
        {
          label: 'Total',
          key: 'total',
          sortable: true,
          tdClass: 'font-12 font-inter--semibold clickable text-right pr-5',
          thClass: 'font-13 font-inter--bold text-center total-column',
          class: 'font-inter'
        },
        {
          label: 'Delivery Date',
          key: 'supplierDeliveryDate',
          sortable: true,
          tdClass: 'font-12 font-inter--semibold clickable text-right pr-5',
          thClass: 'font-13 font-inter--bold text-center delivery-column',
          class: 'font-inter'
        },
        {
          label: 'Invoice',
          key: 'invoiceNumber',
          sortable: true,
          tdClass: 'font-12 clickable  column-credit font-weight-bold',
          thClass: 'font-13 font-inter--bold column-credit invoice-column',
          class: 'font-inter'
        },
        {
          label: 'Invoice Date',
          key: 'invoiceDate',
          sortable: true,
          tdClass: 'font-12 clickable  column-credit font-weight-bold',
          thClass: 'font-13 font-inter--bold column-credit invoice-column',
          class: 'font-inter text-center'
        },
      ],
      selectedRow: [], // can be multi select
      selectedRowDetail: null, // row detail that open -- object
      input: {
        cancelReason: '',
        returnReason: '',
        didNotArrive: {
          RepairerReason: 'Part did not arrive on time sourced from another supplier ',
          SupplierReason: 'The part has been sent our courier company has provided photo evidence of delivery'
        }
      }
    }
  },
  watch: {
    ordersFromUpdate: {
      handler (val) {
        let orderUpdate = _.find(val, (itm) => {
          return Number(itm.id) === Number(this.orderId)
        })
        if (orderUpdate) {
          this.loadOrder(this.orderId, (data) => {
            _.forEach(data.order.parts, prt => {
              let stock = this.getStockById(Number(prt.stock))
              prt.stockValue = {
                'label': stock.label ? stock.label : '',
                'textValue': stock.textValue ? stock.textValue : '',
                'value': Number(prt.stock),
                'comment': prt.orderStockComment  ? prt.orderStockComment  : '',
              }
            })

            this.originalParts = data.order.parts.slice();
            _.forEach(data.order.parts, (part) => {
              let originalPart = _.find(this.orderItems, (prt) => {
                return Number(prt.id) === Number(part.id)
              })

              if (this.isInFocusElement('qty-' + part.id)) {
                part.qty = originalPart.qty_order
              }
              if (this.isInFocusElement('deliveryDate-' + part.id)) {
                part.supplierDeliveryDate = originalPart.supplierDeliveryDate
              }
              if (originalPart.isCanEditDelivery) {
                part.isCanEditDelivery = true
              }

              if (this.isInFocusElement('partNumber-' + part.id)) {
                part.partNumber = originalPart.part_number
              }
              part.isChecked = originalPart.isChecked
              if (this.isInFocusElement('partName-' + part.id)) {
                if (part.type === 'delivery') {
                  part.deliveryName = originalPart.part_list
                } else {
                  part.name = originalPart.part_list
                }
              }
              if (this.isInFocusElement('rrp-' + part.id)) {
                part.rrp = originalPart.rrp
              }
              if (this.isInFocusElement('price-' + part.id)) {
                part.price = originalPart.price
              }

            })
            this.order.parts = data.order.parts

            this.order.invoiceNumber = data.order.invoiceNumber
            if (this.order.supplierOrderPartsDeliveryETA !== data.order.supplierOrderPartsDeliveryETA) {
              this.order.supplierOrderPartsDeliveryETA = data.order.supplierOrderPartsDeliveryETA
            }

            if (this.order.status !== data.order.status) {
              this.order.status = data.order.status
            }
            if (this.order.statusId !== data.order.statusId) {
              this.order.statusId = data.order.statusId
            }

            if (!_.isEqual(this.order.files, data.order.files)) {
              this.order.files = data.order.files
            }

            if (!_.isEqual(this.order.customer, data.order.customer)) {
              this.order.customer = data.order.customer
            }

            if (!_.isEqual(this.backOrders, data.backOrders)) {
              this.backOrders =  this.correctBackOrders(data.backOrders)
            }

            if (this.order.subtotal !== data.order.subtotal) {
              this.order.subtotal = data.order.subtotal
            }

            this.openRowDetails()
            // this.order.parts.splice(0, this.order.parts.length);
            //
            // _.forEach(data.order.parts.slice(), (prt) => {
            //   this.order.parts.push(prt);
            // })
            this.isEditOrder = this.order.isProcessing() || this.order.isShipped() || this.order.isDelivered()
          })
        }

        this.isCanBlinking = false
        setTimeout(() => {
          this.isCanBlinking = true
        }, 200)
      },
      deep: true,
    }
  },
  computed: {
    ...mapGetters({
      companyInfo: 'currentCompany/getDetails',
      currentUser: 'currentUser/getCurrentUserInfo',
      ordersFromUpdate: 'supplier/order/ordersFromUpdate',
      gettersAllOrders: 'supplier/order/getAllOrders',
      getterMultipleBrandIds: 'currentUser/getMultipleBrandIds',
      getterSettings: 'settings/getSettings',
      isMultipleLocations: 'settings/isMultipleLocations',
    }),
    additionalMenu() {
      if (_.isEmpty(this.searchFilter)) {
        return []
      }
      let data = _.filter(this.gettersAllOrders, (r) => {
        if (_.includes(this.searchFilter, ' -')) {
          return _.startsWith(_.trim(_.toLower(r.repairerQuoteNumber)), _.trim(_.toLower(this.searchFilter.split(' -')?.[0]))) &&  _.startsWith(_.trim(_.toLower(r.customerName)), _.trim(_.toLower(this.searchFilter.split(' -')?.[1])))
        }
        return _.startsWith(_.trim(_.toLower(r.repairerQuoteNumber)), _.trim(_.toLower(this.searchFilter)))
      })

      data = _.orderBy(data, [(itm) => Number(itm.id)], ['asc'])
      data = _.uniqBy(data, itm => {return Number(itm.repairerMainRfqId)})

      let result = [];
      _.forEach(data, (itm) => {
        let label = itm.repairerQuoteNumber
        if (itm.customerName) {
          label +=  ' - ' + itm.customerName
        }

        result.push({
          value: itm.repairerMainRfqId,
          number: itm.repairerQuoteNumber,
          label: label,
        })
      })
      result = _.orderBy(result, ['label'], ['asc']).slice(0, 5)
      return result
    },
    isDisabledCancelOrder() {
      return _.filter(this.orderItems, prt => {
        return prt.isChecked
      })?.length !== this.orderItems?.length
    },
    isHasCreatedBackOrder() {
      return _.some(this.backOrders, (o) => {
        return o.status == 'created'
      })
    },
    isHasPartsWithoutBackOrder() {
          return _.some(this.orderItems, (prt) => {
        return prt.isChecked && (!this.isExistInOrder(prt) || this.isExistInCancelledOrder(prt))
      }) && !this.isHasCreatedBackOrder
    },
    isCanAddPartsToBackOrder() {
      return _.some(this.orderItems, (prt) => {
        return prt.isChecked && (!this.isExistInOrder(prt) || this.isExistInCancelledOrder(prt)) && !this.isPartInCreatedBackOrder(prt) && (prt.stock == 4 || prt.stock == 6)
      })
    },
    isHasOnBackOrderParts () {
      return _.some(this.orderItems, (prt) => {
        return prt.isChecked && (prt.stock == 4 || prt.stock == 6)
      })
      // return _.some(this.checkedParts(), (prt) => {
      //   return prt.stock == 4
      // })
    },
    isCanSendBackOrder() {
      return _.some(this.orderItems, (prt) => {
        return prt.isChecked && this.isPartInCreatedBackOrder(prt)
      })
    },
    isCanCancelBackOrder() {
      return _.some(this.orderItems, (prt) => {
        return prt.isChecked && (this.isPartInCreatedBackOrder(prt) || this.isOrderOnlySent(prt))
      }) && !_.isEmpty(this.getBackOrdersForCancelling())
    },
    isCanCancelPartsFromBackOrders() {
      return _.some(this.orderItems, (prt) => {
        return prt.isChecked && (this.isPartInCreatedBackOrder(prt) || this.isOrderOnlySent(prt))
      }) && !_.isEmpty(this.getPartsForCancelling())
    },
    getSupplierDeliveryDate() {
      if (_.isEmpty(this.order.supplierDeliveryDate)) {
        return this.order.supplierOrderPartsDeliveryETA
      }
      if (_.isEmpty(this.order.supplierOrderPartsDeliveryETA)) {
        return this.order.supplierDeliveryDate
      }
      let d1 = dayjs(this.order.supplierDeliveryDate,'DD/MM/YYYY')
      let d2 = dayjs(this.order.supplierOrderPartsDeliveryETA,'DD/MM/YYYY')
      console.log('d1',d1)
      console.log('d2',d2)
      if (d1.isBefore(d2)) {
        return this.order.supplierOrderPartsDeliveryETA
      } else if (d1.isAfter(d2)) {
        return this.order.supplierDeliveryDate
      }
      return this.order.supplierOrderPartsDeliveryETA
    },
    isCanUndoDeliveryParts() {
      return _.some(this.order.parts, (prt) => {
        return prt.isCanUndoDeliveryETA
      })
    },
    settings() {
      if (!_.isEmpty(this.getterSettings.multipleBrands)) {
        let brand = _.find(this.getterSettings.multipleBrands, (b) => {
          return Number(b.id) === Number(this.order.supplierId)
        })
        if (brand && brand.settings) {
          return brand.settings
        }
      }
    },
    modalSettings() {
      if (_.isEmpty(this.order?.nonReturnable)) {
        return {}
      }
      return {
        acceptedBy: this.order.nonReturnable.hasOwnProperty('acceptedBy'),
        acceptedByPosition: this.order.nonReturnable.hasOwnProperty('acceptedByPosition'),
        eSign: this.order.nonReturnable.hasOwnProperty('eSign'),
        tics: this.order.nonReturnable.hasOwnProperty('tics'),
        acceptanceOfTics: this.order.nonReturnable.hasOwnProperty('acceptanceOfTics'),
      }
    },
    unviewedMessagesIds() {
      let ids = [];
      for(let m of this.chatMessages) {
        if (!_.isEmpty(this.getterMultipleBrandIds)) {

          let isNotMyMessage = !_.includes(this.getterMultipleBrandIds, m.companyId)


          if(m.messageId && !m.viewed && isNotMyMessage) {
            ids.push(m.messageId)
          }
        } else {
          if(m.messageId && !m.viewed && m.companyId != this.companyInfo.id) {
            ids.push(m.messageId)
          }
        }
      }
      return ids;
    },
    isCheckedAll: {
      get: function () {
        this.callComputed2++;
        if (this.order.parts.length == 0) {
          return false
        }
        let result = _.filter(this.order.parts, function (item) {
          return item.isChecked == false
        })

        if (result && result.length > 0) {
          return false
        }
        return true
      },
      set: function (check) {
        this.isCheckedAllValue = check
        _.forEach(this.order.parts, (prt) => {
          prt.isChecked = check
          this.callComputed++;
        })
      }
    },
    isShowMarkAsProcessed() {
      return this.order.status === 'Shipped' || this.order.status === 'Processing'
    },
    isOrderCancelled() {
      return this.order.status === 'Cancelled'
    },
    isDisabledMarkPartsShipped() {
      return _.filter(this.order.parts, (prt) => {
        return prt.status === 'Shipped'
      }).length === this.order.parts.length
    },
    // isDisabledMarkPartsShippedForContext() {
    //   return !_.some(this.orderItems, (prt) => {
    //     return prt.status !== 'Shipped' && prt.isChecked
    //   })
    // },
    computedCustomCountForTab () {
      let counts = {
        'ordered-parts"': 0,
        'ordered-files': 0,
      }
      counts['ordered-parts'] = this.orderItems.length
      counts['ordered-files'] = this.order.files.length
      return counts
    },
    computedMarginRightForDetailCeil2 () {
      if (!this.resizer) {
        return 0
      }
      return this.$refs?.orderTable?.$children?.[0]?.$children?.[0]?.$el?.children?.[10]?.offsetWidth
          + this.$refs?.orderTable?.$children?.[0]?.$children?.[0]?.$el?.children?.[9]?.offsetWidth
          + 19
    },
    computedMarginRightForDetailCeil1 () {
      if (!this.resizer) {
        return 0
      }
      console.log('computedTableMinCellWidth2', this.$refs?.orderTable?.$children?.[0]?.$children?.[0]?.$el?.children)
     // let count = document.body.clientWidth < 1658 ? 0 : (this.$refs?.orderTable?.$children?.[0]?.$children?.[0]?.$el?.children?.[9]?.offsetWidth / 4)
      return this.$refs?.orderTable?.$children?.[0]?.$children?.[0]?.$el?.children?.[6]?.offsetWidth
          + this.$refs?.orderTable?.$children?.[0]?.$children?.[0]?.$el?.children?.[7]?.offsetWidth
         + (this.$refs?.orderTable?.$children?.[0]?.$children?.[0]?.$el?.children?.[8]?.offsetWidth - 90)
    },
    computedAvailableRefs () {
      let result = []
      _.forEach(this.order.parts, (part) => {
        let cells = ['stock-select-' + part.id, 'qty-' + part.id,  'rrp-' + part.id, 'price-' + part.id]
        result.push(cells)
      })
      return result
    },
    orderInstructionWithoutSupplier () {
      let r = ''
      if (this.order?.properties?.isSentAsDirectOrder) {
        if (this.order?.rfq?.directOrderInstruction) {
          r += this.order.rfq.directOrderInstruction
        }
      } else if (this.order?.deliveryTo?.repairerInstruction) {
        r += this.order.deliveryTo.repairerInstruction
      }
      return r
    },
    orderInstruction () {
      let r = ''
      if (this.order?.properties?.isSentAsDirectOrder) {
        if (this.order?.rfq?.directOrderInstruction) {
          r += this.order.rfq.directOrderInstruction
        }
      } else if (this.order?.deliveryTo?.repairerInstruction) {
        r += this.order.deliveryTo.repairerInstruction
      }

      if (this.order?.deliveryTo?.supplierInstruction) {
        r += this.order.deliveryTo.supplierInstruction.replace(/(?:\r\n|\r|\n)/g, '<br />')
      }
      return r
    },
    isCurrentTabVehicleDetails: function () {
      return this.activeTab === 'Details'
    },
    isCurrentTabPartList: function () {
      return this.activeTab === 'Part List'
    },
    isOrderLoaded () {
      return this.isLoadedOrder
    },
    pageTitle () {
      return (this.isOrderLoaded && this.order && this.order.id) ? `Part Order - ${this.order.number} | ${this.order.vehicle.make} ${this.order.vehicle.model}` : ''
    },
    currentQPPStep () {
      return this.order.statusId
    },
    computedDueDate () {
      return (this.order.dateDue !== 'null') ? this.order.dateDue : -1
    },
    mobileOptionsClass () {
      if (this.pageTitle.length >= 62 && this.pageTitle.length <= 69) {
        return 'top-15'
      }
      return ''
    },
    orderItems () {
      this.callComputed++

      return _.map(this.order.parts, (part, index) => {
        let statusId = 0,
            gst = this.round(Number(part.price) * 0.1)

        _.forEach(this.statusList, (item, index) => {
          if (item.id === part.status) {
            statusId = index
          }
        })
        let stock = this.getStockById(Number(part.stock))

        let isGreenUnitList = false;
        let isRedUnitList = false;
        let isGreenUnitNet = false
        let isRedUnitNet = false

        if (!((part.type === 'delivery' || part.type === 'manual') && part.originalRrp === null)) {
          if (Number(part.rrp) > Number(part.originalRrp)) {
            isGreenUnitList = true
          } else if (Number(part.rrp) < Number(part.originalRrp)) {
            isRedUnitList = true
          }
        }

        if (!((part.type === 'delivery' || part.type === 'manual') && part.originalPrice === null)) {
          if (Number(part.price) > Number(part.originalPrice)) {
            isGreenUnitNet = true
          } else if (Number(part.price) < Number(part.originalPrice)) {
            isRedUnitNet = true
          }
        }

        return {
          id: part.id,
          part_list: part.type === 'delivery' ? part.deliveryName : part.name,
          status_id: statusId,
          part_number: part.partNumber,
          altPartName: part.altPartName,
          qty_order: part.qty,
          qty_order_copy: part.qty_order,
          stock: part.stock,
          invoiceNumber: part.invoiceNumber,
          invoiceDate: part.invoiceDate,
          type: part.type,
          price: Number(part.price),
          originalPrice: Number(part.originalPrice),
          originalRrp: Number(part.originalRrp),
          originalQty: Number(part.originalQty),
          gst: gst,
          isChecked: part.isChecked,
          nonReturnableAcceptedBy: part.nonReturnableAcceptedBy,
          nonReturnableAcceptedOn: part.nonReturnableAcceptedOn,
          isNonReturnable: part.isNonReturnable,
          isCanUndoDeliveryETA: part.isCanUndoDeliveryETA,
          reinstateComment: part.reinstateComment,
          cancelComment: part.cancelComment,
          originalPartId: part.originalPartId,
          cancelStatus: part.cancelStatus,
          stockComment: part.orderStockComment ,
          stockValue: {
            'label': stock.label ? stock.label : '',
            'textValue': stock.textValue ? stock.textValue : '',
            'value': Number(part.stock),
            'comment': part.orderStockComment  ? part.orderStockComment  : '',
          },
          total: this.round((Number(part.price) + gst) * Number(part.qty)),
          rrp: Number(part.rrp),
          isCanDelete: part.isCanDelete,
          comment: part.comment,
          qtyRcv: Number(part.qtyRcv),
          credit: part.credit,
          part_status: part.status,
          _index: index,
          _statusId: statusId,
          _showDetails: part._showDetails,
          _status_subtitle: part.credit,
          commentByName: part.commentByName,
          commentDate: part.commentDate,
          lastChangeStatusBy: part.lastChangeStatusBy,
          lastChangeStatusDate: part.lastChangeStatusDate,
          isGreenUnitList: isGreenUnitList,
          isRedUnitList: isRedUnitList,
          isGreenUnitNet: isGreenUnitNet,
          isRedUnitNet: isRedUnitNet,
          isCanEditDelivery: part.isCanEditDelivery,
          supplierDeliveryDate: part.supplierDeliveryDate,
        }
      })
    },
  },
  methods: {
    redirectToRfqView(itm) {
      this.$router.push({ name: 'RefView', params: { rfqId: itm.value, number: itm.number } })
    },
    onEnter() {
      setTimeout(() => {
        let item =  this.additionalMenu[0]

        if (item && !_.isEmpty(this.searchFilter)) {
          this.$router.push({ name: 'RefView', params: { rfqId: item.value, number: item.number } })
        }
      }, 500)
    },
    isCreditApprovedOrUnderReview(row) {
      return row?.item?.credit?.id && (row?.item?.credit?.status === 'Approved' || row?.item?.credit?.status === 'UnderReview')
    },
    sortBy (type) {
      this.sortByTable = ''
      this.sorting.type = type
      if (this.sorting.direction === 'asc') this.sorting.direction = 'desc'
      else this.sorting.direction = 'asc'

      this.$set(this.order, 'parts', _.orderBy(this.order.parts, [(itm) => {
        let gst = this.round(Number(itm.price) * 0.1)
        if (type == 'part_list') {
          if (itm.type === 'delivery') {
            return itm.deliveryName
          } else {
            return itm.name
          }
        } else if (type == 'gst') {
          return gst
        } else if (type == 'total') {
          return this.round((Number(itm.price) + gst) * Number(itm.qty))
        }
        return itm[type]
      }], [this.sorting.direction]))
      this.callComputed++
    },
    onChangedCheckedAll() {
      let el = document.getElementById('all-line-check')
      if (el) {
        el.click()
      }
    },
    _isEmpty(v) {
      return _.isEmpty(v)
    },
    openPdf(v) {
      this.isShowPdf = v;
      this.handleResize()
    },
    getNRTooltip(item) {
      let r = '<b>Non-Returnable part Accepted</b>'
      if (item.nonReturnableAcceptedBy) {
        r += '<br/> by ' + item.nonReturnableAcceptedBy
      }
      if (item.nonReturnableAcceptedOn) {
        r += '<br/> ' + this.formatDateTime(item.nonReturnableAcceptedOn)
      }
      return r
    },
    getCheckedIds() {
      let ids = [];
      _.forEach(this.checkedParts(), (p) => {
        if (this.isPartInCreatedBackOrder(p) && (p.stock == 4 || p.stock == 6)) {
          ids.push(p.id)
        }
      })
      return ids
    },
    getTop(item) {
      let count = 2 + item.isCanUndoDeliveryETA + (item.cancelStatus !== 'CancelledByCustomer' && item.cancelStatus !== 'CancelledBySupplier') +
          (item.cancelStatus === 'CancelledByCustomer' || item.cancelStatus === 'CancelledBySupplier') + (item.part_status !== 'Shipped' && this.isNeededStock(Number(item.stock)) && (this.order.status === 'Shipped' || this.order.status === 'Processing')) +
          (this.isShowMarkAsProcessed && item.part_status === 'Shipped') +
          ( item.type !== 'delivery' && item.cancelStatus !== 'CancelledByCustomer' && item.cancelStatus !== 'CancelledBySupplier')
      return count * 42
    },
    getTop2() {
      let count = 3 + this.isCanSendBackOrder +
          this.isCanUndoDeliveryParts +
          (this.order.status === 'Shipped' || this.order.status === 'Processing')
      return count * 42
    },
    getTop4() {
      let count =  1 +
          (this.order.status === 'Shipped' || this.order.status === 'Processing') +
          this.isCanUndoDeliveryParts
      return count * 42
    },
    getTop3(item) {
      let count = 1 +
          (item.part_status !== 'Shipped' && this.isNeededStock(Number(item.stock)) && (this.order.status === 'Shipped' || this.order.status === 'Processing')) +
          (item.isCanUndoDeliveryETA)
      return count * 42
    },
    isExistInOrder(itm) {
      return !!_.find(this.backOrders, (o) => {
        return o?.data?.parts && _.find(o.data.parts, (p) => {return Number(p.orderPartId) == Number(itm.id)})
      })
    },
    isExistInCancelledOrder(itm) {
      return !!_.find(this.backOrders, (o) => {
        return o?.data?.parts && _.find(o.data.parts, (p) => {return Number(p.orderPartId) == Number(itm.id)})  && o.status == 'canceled'
      }) && !_.find(this.backOrders, (o) => {
        return o?.data?.parts && _.find(o.data.parts, (p) => {return Number(p.orderPartId) == Number(itm.id)})  && o.status !== 'canceled'
      })
    },
    isPartInCreatedBackOrder(itm) {
      return !!_.find(this.backOrders, (o) => {
        return o?.data?.parts && _.find(o.data.parts, (p) => {return Number(p.orderPartId) == Number(itm.id)}) && o.status == 'created'
      })
    },
    isHasOrder(itm) {
      return !!_.find(this.backOrders, (o) => {
        return o?.data?.parts && _.find(o.data.parts, (p) => {return Number(p.orderPartId) == Number(itm.id)}) && (o.status == 'created' || o.status == 'sent' || o.status === 'declined' || o.status == 'accepted')
      })
    },
    isOrderOnlySent(itm) {
      return !!_.find(this.backOrders, (o) => {
        return o?.data?.parts && _.find(o.data.parts, (p) => {return Number(p.orderPartId) == Number(itm.id)}) && (o.status == 'sent')
      })
    },
    isOrderSent(itm) {
      return !!_.find(this.backOrders, (o) => {
        return o?.data?.parts && _.find(o.data.parts, (p) => {return Number(p.orderPartId) == Number(itm.id)}) && (o.status == 'sent' || o.status === 'declined' || o.status == 'accepted')
      })
    },
    getOrderId(itm) {
      return _.find(this.backOrders, (o) => {
        return o?.data?.parts && _.find(o.data.parts, (p) => {return Number(p.orderPartId) == Number(itm.id)}) && (o.status == 'created' || o.status == 'sent')
      })?.id
    },
    getMainOrder(itm) {
      return _.find(this.backOrders, (o) => {
        return o?.data?.parts && _.find(o.data.parts, (p) => {return Number(p.orderPartId) == Number(itm.id)}) && (o.status == 'created' || o.status == 'sent' || o.status === 'declined' || o.status == 'accepted')
      })
    },
    getSentOrder(itm) {
      return _.find(_.orderBy(this.backOrders, ['sentOn'], ['desc']), (o) => {
        return o?.data?.parts && _.find(o.data.parts, (p) => {return Number(p.orderPartId) == Number(itm.id)}) && (o.status == 'sent' || o.status === 'declined' || o.status == 'accepted')
      })
    },
    getCreatedOrder() {
      return _.find(this.backOrders, (o) => {
        return o.status == 'created'
      })
    },
    getCreatedOrSentOrder() {
      let r = _.filter(this.backOrders, (o) => {
        let b = _.some(this.orderItems, (prt) => {
          return prt.isChecked && _.find(o.data.parts, (p) => {return Number(p.orderPartId) === Number(prt.id)})
        })
        return b && (o.status == 'created' || o.status == 'sent')
      })
      if (r.length == 1) {
        return [r[0].id]
      } else if (r.length > 1) {
        let arr = []
        _.forEach(r, (i) => {
          arr.push(i.id)
        })
        return arr
      }
      return []
    },
    sendBackOrder(specialOrderId, customerId) {
      this.$refs.partMenu.close()
      NProgress.start()
      Axios.post('/ir/supplier/order/' + this.order.id + '/send-back-order', {
        specialOrderId: specialOrderId,
        customerId: customerId
      })
          .then(response => {
            if (response.data._status) {
              let index = _.findIndex(this.backOrders, (o) => {
                return Number(o.id) === Number(specialOrderId)
              })
              if (index !== -1) {
                this.backOrders[index].sentOn = new Date()
                this.backOrders[index].status = 'sent'
              }
            }
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            NProgress.done()
          })
    },
    cancelBackOrders(ids) {
      this.$refs.partMenu.close()
      _.forEach(ids, (id, ind) => {
        setTimeout(() => {
          this.cancelBackOrder(id)
        }, 100 * ind)
      })
    },
    cancelBackOrders2(ids, partsIds) {
      this.$refs.partMenu.close()
      _.forEach(ids, (id, ind) => {
        let backOrder = _.find(this.backOrders, (o) => {
          return Number(o.id) === Number(id)
        })
        let partsIdsFiltered = _.filter(partsIds, (prtId) => {
          return !! _.find(backOrder?.data?.parts, (ordP) => {
            return Number(prtId) === Number(ordP.orderPartId)
          })
        })
        setTimeout(() => {
          this.cancelBackOrder(id, partsIdsFiltered)
        }, 100 * ind)
      })
    },
    cancelBackOrder(specialOrderId, ids) {
      this.$refs.partMenu.close()
      NProgress.start()
      Axios.post('/ir/supplier/order/' + this.order.id + '/cancel-back-order', {
        specialOrderId: specialOrderId,
        orderPartsId: ids ? ids : []
      })
          .then(response => {
            if (response.data._status) {
              let index = _.findIndex(this.backOrders, (o) => {
                return Number(o.id) === Number(specialOrderId)
              })
              if (index !== -1) {
                // this.backOrders[index].parts = _.filter(this.backOrders[index].parts, (p) => {
                //   return !_.includes(ids, p.orderPartId)
                // })

                if (_.isEmpty(ids)) {
                  this.backOrders[index].status = 'canceled'
                } else {
                  this.backOrders.splice(index, 1)
                  let parts = [];
                  _.forIn(response.data.backOrder.data.parts, (p) => {
                    parts.push(p)
                  })
                  response.data.backOrder.data.parts =  parts
                  this.backOrders.push(response.data.backOrder)
                }
              }
            }
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            NProgress.done()
          })
    },
    isDisabledMarkPartsShippedForContext(parts) {
      return !_.some(parts, (prt) => {
        return prt.status !== 'Shipped' && prt.isChecked
      })
    },
    getTopScrollWidth() {
      return document.getElementsByClassName('supplier-order-view-table')?.[0]?.clientWidth - 23 + 'px'
    },
    setFakeScroll() {
      let container = $('.order-part-list');
      let topscroll = $('.topscroll');

      $('.fake').width($('#order-table').width());
      $('.topscroll').width($('.supplier-order-view-table').width() - 23)

      if (document.getElementById('last-table-line')?.getBoundingClientRect()?.bottom <= window.innerHeight) {
        $('.topscroll').hide()
      } else {
        $('.topscroll').show()
      }

      topscroll.scroll(function(e){
        container.scrollLeft($(this).scrollLeft());
        let el = document.getElementById("order-view-thead-wrapper");
        let stockAll = document.getElementById("stock-all-wrapper");
        if (stockAll) {
          stockAll.style.transform = `translate(-${el.scrollLeft}px, 8px)`
        }
      });
      container.scroll(function(e){
        topscroll.scrollLeft($(this).scrollLeft());
      });
    },
    onScroll() {
      this.setFakeScroll()
      if (this.activeTab !== 'Part List') {
        return
      }
      let element = document.getElementById('additional-info')
      if (!element){
        return
      }
      let rect =  element.getBoundingClientRect()
      // console.log('rect', rect?.top)
      if (rect.top <= 150) {

        this.isShowStickyHeader = true
        setTimeout(() => {
          let el = document.getElementsByClassName("order-part-list")[0];
          let stockAll = document.getElementById("stock-all-wrapper");
          if (stockAll) {
            stockAll.style.transform = `translate(-${el.scrollLeft}px, 8px)`
          }
        }, 0)

        this.onTableScroll()
      } else {
        this.isShowStickyHeader = false
      }
    },
    onTableScroll() {
      this.$nextTick(() => {
        let el = document.getElementsByClassName("order-part-list")[0];
        let el2 = document.getElementById("order-view-thead-wrapper");
        let topscrollEl = document.getElementsByClassName('topscroll')?.[0]

        if (el2 && el) {
          el2.scrollLeft = el.scrollLeft;
          if (topscrollEl) {
            topscrollEl.scrollLeft = el2.scrollLeft;
          }
        }
      })
    },
    showModal1 (item) {
      let order = this.getMainOrder(item)
      if (order ) {
        this.selectedBackOrder = order
        this.$refs['special-order-example'].show();
        this.isOpenModal = true;
      }
    },
    correctBackOrders(data) {
      _.forEach(data, (d) => {
        let parts = [];
        _.forIn(d.data.parts, (p) => {
          parts.push(p)
        })
        d.data.parts =  parts
      })
      return data
    },
    createBackOrder(part, userId) {
      this.$refs.partMenu.close()
      let parts = [part?.id]
      if (part?.isNonReturnable) {
        this.$refs['back-order-alert'].show();
        return
      }
      if (!part) {
        parts = [];
        _.forEach(this.order.parts, (prt) => {
          if (prt.isChecked && !this.isPartInCreatedBackOrder(prt) && (!this.isExistInOrder(prt) || this.isExistInCancelledOrder(prt)) && (prt.stock == 4 || prt.stock == 6)) {
            if (prt?.isNonReturnable) {
              this.$refs['back-order-alert'].show();
              return
            }
            parts.push(prt.id)
          }
        })
      }
      parts = _.uniq(parts)
      NProgress.start()
      Axios.post('/ir/supplier/order/' + this.order.id + '/create-back-order', {
        orderPartsId: parts,
        userId: userId
      })
          .then(response => {
            if (response.data._status && response.data.backOrder) {
              let parts = [];
              _.forIn(response.data.backOrder.data.parts, (p) => {
                parts.push(p)
              })
              response.data.backOrder.data.parts =  parts
              let index = _.findIndex(this.backOrders, (o) => {
                return Number(o.id) === Number(response.data.backOrder.id)
              })
              if (index !== -1) {
                this.backOrders.splice(index, 1)
              }
              this.backOrders.push(response.data.backOrder)
            }
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            NProgress.done()
          })
    },
    onCloseChat() {
      if(this.unviewedMessagesIds.length > 0){
        this.markMessagesAsViewed(this.unviewedMessagesIds);
        _.forEach(this.unviewedMessagesIds, (id) => {
          let index = _.findIndex(this.chatMessages, (m) => {
            return m.messageId === id
          })
          if (index !== -1) {
            this.$nextTick(() => {
              let obj = _.cloneDeep(this.chatMessages[index])
              obj.viewed = true
              this.$set(this.chatMessages, index, obj)
            })
          }
        })
      }
    },
    onChatMessage (data) {
      if(data.type === 'newMessage'){
        this.chatMessages.push(data)
        if (!_.isEmpty(this.getterMultipleBrandIds)) {

          let isNotMyMessage = !_.includes(this.getterMultipleBrandIds, data.companyId)

          if(data.messageId && isNotMyMessage) {
            this.markMessagesAsViewed([data.messageId]);
          }
        } else {
          if(data.messageId && data.companyId != this.companyInfo.id) {
            this.markMessagesAsViewed([data.messageId]);
          }
        }
      }
      if(data.type === 'repairerIsOnline'){
        this.chatRepairerIsOnline = true;
        this.repairerLeavedTheChat();
      }
      if(data.type === 'typingInChat' && data.id !== this.currentUser.id){
        this.chatTypers[data.id] = {
          name: data.name,
          microtime: Date.now(),
        }
      }
    },
    sendChatMessage(text){
      if(!text || !text.trim()){
        return new Promise((resolve) => {
          resolve(false)
        });
      }
      return Axios.post('/centrifuge/send-message-to-order', {
        orderId: this.orderId,
        message: text.trim(),
      })
    },
    onChatMessageSubmit({message,resolve}){
      if(this.unviewedMessagesIds.length > 0){
        this.markMessagesAsViewed(this.unviewedMessagesIds);
        _.forEach(this.unviewedMessagesIds, (id) => {
          let index = _.findIndex(this.chatMessages, (m) => {
            return m.messageId === id
          })
          if (index !== -1) {
            this.$nextTick(() => {
              let obj = _.cloneDeep(this.chatMessages[index])
              obj.viewed = true
              this.$set(this.chatMessages, index, obj)
            })
          }
        })
      }
      this.sendChatMessage(message).then(res => {
        resolve(res.data?._status)
      }).catch(() => {
        resolve(false)
      })
    },
    markMessagesAsViewed(messageIds = []){
      return Axios.post('/centrifuge/mark-chat-messages-as-viewed', {
        messageIds
      })
    },
    onChatInputTyping(){
      this._chat.publish({
        type: 'typingInChat',
        id: this.currentUser.id,
        name: this.currentUser.firstName,
      })
    },
    saveContextInvoiceNumber () {
      this.$refs.partMenu.close()
      let data = []

      _.forEach(this.order.parts, (prt, index) => {
        if (prt.isChecked) {
          this.$set(this.order.parts[index], 'invoiceNumber', this.contextInvoiceNumber)
          data.push({
            id: prt.id,
            invoiceNumber: this.contextInvoiceNumber
          })
        }
      })

      this.autoSave({
        parts: data
      })

      this.contextInvoiceNumber = ''
    },
    saveContextInvoiceDate () {
      this.$refs.partMenu.close()
      let data = []

      _.forEach(this.order.parts, (prt, index) => {
        if (prt.isChecked) {
          this.$set(this.order.parts[index], 'invoiceDate', this.contextInvoiceDate)
          data.push({
            id: prt.id,
            invoiceDate: this.contextInvoiceDate
          })
        }
      })

      this.autoSave({
        parts: data
      })

      this.contextInvoiceDate = ''
    },
    saveInvoiceNumber (part) {
      this.$refs.partMenu.close()
      this.autoSave({
        parts: [{
          id: part.id,
          invoiceNumber: part.invoiceNumber
        }]
      })
    },
    saveInvoiceDate (part) {
      this.$refs.partMenu.close()
      this.autoSave({
        parts: [{
          id: part.id,
          invoiceDate: part.invoiceDate
        }]
      })
    },
    getTooltipForPart(text, item) {
      if (text == 'Total' && Number(this.round((Number(item.price) + this.round(Number(item.price) * 0.1)) * Number(item.qty_order)) == 0)) {
        return ''
      }
      if (Number(item.originalQty) < Number(item.qty_order)) {
        return text + ' Increased'
      } else if (Number(item.originalQty) > Number(item.qty_order)) {
        return text + ' Decreased'
      }
      return ''
    },
    onCheckboxChange(item, value) {
      let index = _.findIndex(this.order.parts, (prt) => {
        return Number(prt.id) == Number(item.id)
      })
      if (index !== -1) {
        this.$nextTick(() => {
          this.$set(this.order.parts[index], 'isChecked', value)
          this.callComputed++
          this.callComputed2++
        })
      }
    },
    checkedParts() {
      return _.filter(this.order.parts, (prt) => {
        return prt.isChecked
      })
    },
    getBackOrdersForCancelling() {
      return _.filter(this.backOrders, (b) => {
        return !_.isEmpty(this.checkedParts()) && b.status == 'sent' &&  _.filter(this.checkedParts(), (chP) => {
          return _.find(b.data.parts, (ordP) => {
            return Number(chP.id) === Number(ordP.orderPartId)
          })
        })?.length == b.data.parts.length
      })
    },
    getBackOrdersForCancelling2() {
      let backOrders = _.filter(this.backOrders, (b) => {
        return !_.isEmpty(this.checkedParts()) && b.status == 'sent' &&  _.filter(this.checkedParts(), (chP) => {
          return _.find(b.data.parts, (ordP) => {
            return Number(chP.id) === Number(ordP.orderPartId)
          })
        })?.length < b.data.parts.length
      })
      let r = [];
      _.forEach(backOrders, (o) => {
        r.push(o.id)
      })
      return r
    },
    getPartsForCancelling() {
      let backOrders = _.filter(this.backOrders, (b) => {
        return !_.isEmpty(this.checkedParts()) && b.status == 'sent' &&  _.filter(this.checkedParts(), (chP) => {
          return _.find(b.data.parts, (ordP) => {
            return Number(chP.id) === Number(ordP.orderPartId)
          })
        })?.length < b.data.parts.length
      })
      let r = [];
      _.forEach(this.checkedParts(), (itm) => {
        let backOrdPrt = _.find(backOrders, (o) => {
          return o?.data?.parts && _.find(o.data.parts, (p) => {return Number(p.orderPartId) == Number(itm.id)})
        })
        if (backOrdPrt) {
          r.push(itm.id)
        }
      })
      return r
    },
    getBackOrdersForCancellingIds() {
      let data = []
      _.forEach(this.getBackOrdersForCancelling(), (o) => {
        data.push(o.id)
      })
      return data
    },
    checkedPartsForRemoving() {
      return _.filter(this.order.parts, (prt) => {
        return prt.isChecked && this.isPartInCreatedBackOrder(prt)
      })
    },
    rowClass(item) {
      if (item.isChecked) {
        return 'drag-selectable checked-row'
      }
      return 'drag-selectable'
    },
    checkCtrlAndShift(e) {
      if (e.which == 17) {
        this.isHoldCtrl = true
      } else if (e.which == 16) {
        this.isHoldShift = true
      }
    },
    removeCtrlAndShift() {
      this.isHoldCtrl = false
      this.isHoldShift = false
    },
    selectedAllStock (value) {
      let data = []
      _.forEach(this.order.parts, (part) => {
        let index = _.findIndex(this.order.parts, (p) => {
          return Number(p.id) === Number(part.id)
        })
        this.$set(this.order.parts[index], 'stock', value.value)
        this.$set(this.order.parts[index], 'stockComment', value.comment)
        part.stock = value.value
        part.orderStockComment = value.comment
        this.callComputed++

        let resultPart = {
          rrp: Number(part.rrp),
          price: Number(part.price),
          id: Number(part.id),
          qty: Number(part.qty_order),
          stock: Number(part.stock),
          stockComment: value.comment,
          partNumber: part.part_number,
        }
        if (part.type === 'delivery') {
          resultPart.deliveryName = part.part_list;
        } else {
          resultPart.name = part.part_list;
        }
        data.push(resultPart)
      })

      NProgress.start()

      this.autoSave({
        parts: data
      })
      NProgress.done()
    },
    confirmEditOrder() {
      this.$refs.orderTable.clearSelected();
      this.isEditOrder = false
      this.isCanBlinking = false;
      setTimeout(() => {
        this.isCanBlinking = true
      }, 200)
    },
    changeDeliveryFromContext(item) {
      this.$refs.partMenu.close()
      if (this.checkedParts().length) {
        _.forEach(this.order.parts, (prt) => {
          if (prt.isChecked) {
            prt.isCanEditDelivery = true
          }
        })
      } else {
        item.isCanEditDelivery = true
      }
      this.callComputed++
    },
    undoDeliveryFromContext(item) {
      this.$refs.partMenu.close()
      let ids = [];
      if (this.checkedParts().length) {
        _.forEach(this.order.parts, (prt) => {
          if (prt.isCanUndoDeliveryETA && prt.isChecked) {
            ids.push(prt.id)
          }
        })
      } else {
        ids.push(item.id)
      }

      NProgress.start()
      Axios.post('/ir/supplier/order/' + this.order.id, {
        undoDeliveryPartsId: ids
      })
          .then(response => {
            if (response.data._status && response.data.parts) {
              _.forEach(response.data.parts, (prt) => {
                let index = _.findIndex(this.order.parts, (p) => {
                  return Number(p.id) == Number(prt.partId)
                })
                if (index !== -1) {
                  this.$set(this.order.parts[index], 'supplierDeliveryDate', prt.deliveryDate)
                  this.$set(this.order.parts[index], 'isCanUndoDeliveryETA', prt.isCanUndoDeliveryETA)
                  this.callComputed++
                }
              })
            }
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            NProgress.done()
          })
    },
    savePartDeliveryDate(item) {
      item.isCanEditDelivery = false

      let index = _.findIndex(this.order.parts, (p) => {
        return Number(p.id) == Number(item.id)
      })
      if (index !== -1) {
        this.$set(this.order.parts[index], 'isCanEditDelivery', false)
        this.$set(this.order.parts[index], 'supplierDeliveryDate', item.supplierDeliveryDate)
        this.$set(this.order.parts[index], 'isCanUndoDeliveryETA', true)
        this.callComputed++
      }

      this.isCanBlinking = false
      setTimeout(() => {
        this.isCanBlinking = true
      }, 200)
      NProgress.start()
      Axios.post('/ir/supplier/order/' + this.order.id, {
        deliveryParts: [{
          id: item.id,
          date: item.supplierDeliveryDate
        }]
      })
          .then(response => {
            if (response.data._status) {
              if (index !== -1) {
                this.$set(this.order.parts[index], 'isCanUndoDeliveryETA', true)
                this.$set(this.order.parts[index], 'isCanEditDelivery', false)
                this.$set(this.order.parts[index], 'supplierDeliveryDate', item.supplierDeliveryDate)
                this.callComputed++
              }
            }
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            NProgress.done()
          })
    },
    saveSupplierInstruction() {
      NProgress.start()
      Axios.post('/ir/supplier/order/' + this.order.id, {
        supplierInstruction: this.order.deliveryTo.supplierInstruction,
      })
          .then(response => {
            if (response.data._status) {
            }
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            NProgress.done()
          })
    },
    deliveryDateChanged() {
      this.isCanBlinking = false
      setTimeout(() => {
        this.isCanBlinking = true
      }, 200)
      NProgress.start()
      Axios.post('/ir/supplier/order/' + this.order.id, {
        supplierDeliveryDate: this.order.supplierDeliveryDate
      })
          .then(response => {
            if (response.data._status) {
              this.isCanEditSupplierInstruction = true
              this.$nextTick(() => {
                if (this.activeTab === 'Part List') {
                  this.$refs['supplier-instruction-part-list'].focus()
                } else if (this.activeTab === 'Details') {
                  this.$refs['supplier-instruction-details'].focus()
                } else {
                  this.$refs['supplier-instruction-files'].focus()
                }
              })
              this.isEditDeliveryDate = false;
              if (_.isEmpty(this.order.supplierDeliveryDate)) {
                this.isClickedChangeDeliveryDate = false
              }
            }
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            NProgress.done()
          })
    },
    onClickedChangeDelivery() {
      this.isEditDeliveryDate = true
      this.isClickedChangeDeliveryDate = true
      this.isCanBlinking = false
      setTimeout(() => {
        this.isCanBlinking = true
      }, 200)
    },
    isHasShortComment (part) {
      return (part.stock === 4 || part.stock === 6) && _.trim(part?.stockComment)
    },
    getShortComment (part) {
      let r = ''
      let stockComment = _.trim(part?.stockComment)

      if (stockComment) {
        r += stockComment.substr(0, 15)
        if (stockComment.length > 15) {
          r += '...'
        }
      }
      return r
    },
    openStockSelectContext(v, id) {
      if (this.isClickedOnDatePicker && !v) {
        this.isOpenContextLevelTwo = true;
        this.isClickedOnDatePicker = false
        return
      }
      let isOpenContextLevelTwoCopy = _.cloneDeep(this.isOpenContextLevelTwo)
      this.isOpenContextLevelTwo = v;

      setTimeout(() => {
        if (this.$refs[`stock-select-context-${id}`] && v && !isOpenContextLevelTwoCopy) {
          this.$refs[`stock-select-context-${id}`]?.getTopForContext()
        }
      }, 10)
    },
    deletePart(part) {
      let index = _.findIndex(this.order.parts, (prt) => {
        return Number(part.id) === Number(prt.id);
      })
      NProgress.start()
      Axios.post('/ir/supplier/order/' + this.order.id, {
        removeOrderPartId: part.id,
      })
          .then(response => {
            if (response.data._status) {
              this.order.parts.splice(index, 1)
            }
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        NProgress.done()
      })
      this.$refs.partMenu.close()
    },
    getRef(item) {
      if (item.type === 'delivery') {
        return 'deliveryName-' + item.id
      } else if (item.type === 'manual') {
        return 'manual-name-' + item.id
      }
      return ''
    },
    addPart(isDelivery = false) {
      let part = {
        id: null,
        cancelStatus: null,
        comment: null,
        commentByName: null,
        commentDate: null,
        credit: -1,
        isCanDelete: true,
        isManual: true,
        lastChangeStatusBy: null,
        lastChangeStatusDate: null,
        listOrderNumber: 999,
        name: null,
        originalPartId: "0",
        originalPrice: null,
        originalQty: "0",
        originalRrp: null,
        partNumber: "",
        partStock: "none",
        partStockComment: "",
        orderStockComment: '',
        price: null,
        qty: 1,
        qtyRcv: 0,
        reinstateComment: null,
        rrp: null,
        status: "Created",
        stock: 0,
        stockComment: "",
        type: "manual",
      }
      let payload = {}
      if (isDelivery) {
        part.type = 'delivery'
        payload.addDelivery = true;
      } else {
        part.isManual = true;
        part.type = 'manual'
        payload.addPart = true;
      }

      NProgress.start()
      Axios.post('/ir/supplier/order/' + this.order.id, payload)
          .then(response => {
            if (response.data._status && response.data.manualOrderPartId && response.data.manualOrderPartId > 0) {
              part.id = response.data.manualOrderPartId;
              this.order.parts.push(part)
              this.originalParts.push(part)

              setTimeout(() => {
                if (isDelivery) {
                  this.focusElement('deliveryName-' + part.id)
                } else {
                  this.focusElement('manual-name-' + part.id)
                }
              }, 500)
            }
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            NProgress.done()
          })
    },
    focusElement (ref) {
      console.log(ref, this.$refs[ref])
      if (this.$refs[ref]) {
        this.$refs[ref].focus()
      }
    },
    isNeededStock (value) {
      return [1, 2, 4, 6].includes(value)
    },
    revomedImgs (ids) {
      this.order.files = _.filter(this.order.files, (file) => {
        return !ids.includes(file.id)
      })
    },
    showModal () {
      this.$refs['non-returnable-example'].show();
    },
    onCloseModal () {
      this.isOpenModal = false;
    },
    checkSignature () {
      if (this?.$refs?.signaturePad) {
        this.isEmptySignature = this.$refs.signaturePad.isEmpty()
      }
    },
    clearSignature () {
      let signaturePad = this.$refs.signaturePad
      if (signaturePad) {
        signaturePad.clear()
      }
    },
    handleResize () {
      this.resizer++
      this.onScroll()
      this.setFakeScroll()
      if (this.isOpenModal) {
        setTimeout(() => {
          let signatureCanvas = document.getElementsByClassName('special-order-signature-canvas')
          if (signatureCanvas && signatureCanvas[0]) {
            let width = this.$refs.specialOrderBlock.offsetWidth;
            signatureCanvas[0].width = width
            signatureCanvas[0].style.width = width + 'px'
            signatureCanvas[0].height = 100
            signatureCanvas[0].style.height = '100px'
          }
          if (this.$refs.signaturePad) {
            this.$refs.signaturePad.inputPointsHandler()
          }
        }, 100)
      }
      this.documentHeight =  window.innerHeight - document.getElementsByClassName('site-header')?.[0]?.clientHeight -
          document.getElementsByClassName('page-header')?.[0]?.clientHeight -
          document.getElementsByClassName('tabs-component')?.[0]?.clientHeight - 14
      if (this.isOpenModal) {
        setTimeout(() => {
          let signatureCanvas = document.getElementsByClassName('special-order-signature-canvas')
          if (signatureCanvas && signatureCanvas[0]) {
            let width = this.$refs.specialOrderBlock.offsetWidth;
            signatureCanvas[0].width = width
            signatureCanvas[0].style.width = width + 'px'
            signatureCanvas[0].height = 100
            signatureCanvas[0].style.height = '100px'
          }
          if (this.$refs.signaturePad) {
            this.$refs.signaturePad.inputPointsHandler()
          }
        }, 100)
      }
    },
    getRtnCost (prt) {
      return '-' + accounting.formatMoney(parseFloat(Math.abs(prt.price * Math.abs(prt.qtyRcv - prt.qty_order) * 1.1).toFixed(2)))
    },
    getRtnUnitNet (prt) {
      return '-' + accounting.formatMoney(parseFloat(Math.abs(prt.price * Math.abs(prt.qtyRcv - prt.qty_order)).toFixed(2)))
    },
    viewOrders () {
      let filters = this.$route.params.filters
      let hash = this.$route.params.hash
      expiringStorage.set(`vue-tabs-component.cache.${window.location.host}` + '/s/orders', '#all', 500000)
      this.$router.push({ name: 'SuppliersPartsOrders', hash: hash })
      if (!_.isEmpty(filters)) {
        if (filters.orderNumber) {
          setTimeout(() => {
            this.$store.commit('supplier/order/setSearchFilterObj', {
              0: {
                search: '',
              },
              1: {
                search: '',
                customer: filters.customer,
              },
              2: {
                search: '',
              },
              3: {
                search: '',
              },
              4: {
                search: '',
              },
              5: {
                search: '',
              },
              6: {
                search: '',
              },
            })
          }, 2000)
          this.$store.dispatch('supplier/order/setFilter', filters.orderNumber, { root: true })
          let customers = {}
          this.gettersAllOrders.forEach(order => {
            if (order.customerName) {
              customers[order.customerName.trim().toLowerCase()] = { label: order.customerName.trim() }
            }
          })
          customers = _.orderBy(Object.values(customers), [(itm) => itm.label.toLowerCase().trim()], ['asc'])

          this.$store.commit('supplier/order/setTokens', {
            0: [
              {
                id: 'customer',
                label: 'Customers',
                iconClass: 'bx-user',
                options: [
                  customers
                ],
                isCompleted: true,
                originalIndex: 0,
              },
              {
                label: filters.customer
              }
            ],
            1: [],
            2: [],
            3: [],
            4: [],
            5: [],
            6: [],
          })
        }
      }
    },
    getIconTooltipForCredit: function (item) {
      let text = 'Credit Request'
      if (text == '') {
        return text
      }
      text = '<p>' + text + '</p>'
      if (item.credit.creditDate) {
        text += '<p style="white-space: nowrap !important;">Changed on: ' + dayjs(item.credit.creditDate).format('DD/MM/YYYY - hh:mmA') + '</p>'
      }
      if (item.credit.creditByName) {
        text += '<p>Changed by: ' + item.credit.creditByName + '</p>'
      }
      return text
    },
    partCanRequestCredit (part) {
      return part.part_status === 'CancelPart'
          || part.part_status === 'ReturnDamagedPart'
          || part.part_status === 'IncorrectPartOrdered'
          || part.part_status === 'IncorrectPartDelivered'
          || part.part_status === 'DamagedPartExWarehouse'
          || part.part_status === 'NoLongerNeeded'
          || part.part_status === 'ChangeInRepairMethod'
    },
    showRequestCredit (part) {
      return this.partCanRequestCredit(part) && part.credit && part.credit.id
    },
    goToRfq () {
      if (!(this.order && this.order.rfq && this.order.rfq.id)) {
        return
      }
      // window.open(window.location.origin + '/s/rfq/' + this.order.rfq.id, '_blank')
      this.$router.push({ name: 'SupplierRFQView', params: { rfq_id: this.order.rfq.id } })
    },
    goToCredit (item) {
      if (!item.credit.id) {
        return
      }
      this.$router.push({ name: 'SuppliersCreditView', params: { creditId: item.credit.id } })
    },
    getTooltip (item, text) {
      if (text == '') {
        return text
      }
      text = '<p>' + text + '</p>'
      if (item.lastChangeStatusDate) {
        text += '<p style="white-space: nowrap;">Changed on: ' + dayjs(item.lastChangeStatusDate).format('DD/MM/YYYY - hh:mmA') + '</p>'
      }
      if (item.lastChangeStatusBy) {
        text += '<p>Changed by: ' + item.lastChangeStatusBy + '</p>'
      }
      return text
    },
    inputPrice (val, item) {
      item.price = val;
      let originalItem = _.find(this.order.parts, (p) => {
        return Number(p.id) === Number(item.id)
      })
      if ((item.type === 'delivery' || item.type === 'manual') && originalItem.originalPrice === null) {
        item.isGreenUnitNet = false
        item.isRedUnitNet = false
        return;
      }
      item.isGreenUnitNet = false
      item.isRedUnitNet = false
      if (Number(val) > Number(item.originalPrice)) {
        item.isGreenUnitNet = true
      } else if (Number(val) < Number(item.originalPrice)) {
        item.isRedUnitNet = true
      }
    },
    inputRRP (val, item) {
      item.rrp = val;
      let originalItem = _.find(this.order.parts, (p) => {
        return Number(p.id) === Number(item.id)
      })
      if ((item.type === 'delivery' || item.type === 'manual') && originalItem.originalRrp === null) {
        item.isGreenUnitList = false
        item.isRedUnitList = false
        return;
      }
      item.isGreenUnitList = false
      item.isRedUnitList = false
      if (Number(val) > Number(item.originalRrp)) {
        item.isGreenUnitList = true
      } else if (Number(val) < Number(item.originalRrp)) {
        item.isRedUnitList = true
      }
    },
    closePartContext () {
      this.isOpenContextLevelTwo = false
      this.isOpenSpecialOrderContext2 = false
      this.isOpenSpecialOrderContext = false
      this.isOpenContextLevelThree = false
      this.isOpenContextLevelThree2 = false
      this.isOpenContext2LevelTwo = false
    },
    selectProcessed (item) {
      this.$refs.partMenu.close()
      NProgress.start()
      let status = this.order.status === 'Processing' ? 'undo-shipped' : 'processing'
      Axios.post('/ir/supplier/order/' + this.order.id + '/' + status, {
        partIds: [item.id]
      })
          .then(() => {
            this.loadOrder(this.order.id)
          })
          .catch(error => {
            console.log(error)
            this.$toast.error('An error occurred in submitting request')
            NProgress.done()
          }).finally(() => {
        NProgress.done()
      })
    },
    selectShipped (item) {
      this.$refs.partMenu.close()
      NProgress.start()
      Axios.post('/ir/supplier/order/' + this.order.id + '/shipped', {
        partIds: [item.id]
      })
          .then(() => {
            this.loadOrder(this.order.id)
          })
          .catch(error => {
            console.log(error)
            this.$toast.error('An error occurred in submitting request')
            NProgress.done()
          }).finally(() => {
            NProgress.done()
          })
    },
    addDMSNumber (nbr) {
      this.order.invoiceNumber = nbr
      setTimeout(() => {
        this.onChangeInvoiceNbr(nbr)
      }, 2000)
    },
    onChangeInvoiceNbr (nbr) {
      NProgress.start()
      this.order.invoiceNumber = nbr;
      this.autoSave({
        invoiceNumber: nbr,
      })
      NProgress.done()
    },
    updateCode (code) {
      NProgress.start()
      this.order.trackingCode = code;
      this.autoSave({
        trackingCode: code,
      })
      NProgress.done()
    },
    cancelPart (part, type) {
      if (_.isEmpty(part.cancelComment)) {
        this.$toast.error('Comment is empty')
        return
      }
      this.$refs.partMenu.close()
      NProgress.start()
      if (type === 'supplier') {
        part.cancelStatus = 'CancelledBySupplier'
      } else if (type === 'customer') {
        part.cancelStatus = 'CancelledByCustomer'
      }

      let index = _.findIndex(this.order.parts, (p) => {
        return Number(p.id) === Number(part.id)
      })
      if (index !== -1) {
        this.$set(this.order.parts[index], 'status', 'CancelPart')
        this.$set(this.order.parts[index], 'cancelStatus',  part.cancelStatus)
        this.$set(this.order.parts[index], 'price', 0)
      }

      part.price = 0
      this.autoSave({
        parts: [{
          rrp: Number(part.rrp),
          price: 0,
          id: Number(part.id),
          qty: Number(part.qty_order),
          stock: Number(part.stock),
          stockComment: part.stockValue.comment,
        }]
      })

      setTimeout(() => {
        this.autoSave({
          cancelParts: [{
            id: Number(part.id),
            cancelledBy: type,
            cancelComment: part.cancelComment,
            reinstateComment: '',
          }]
        }, (data) => {
          console.log('part.cancelPart', part)
            part.reinstateComment = ''
        })
      }, 200)

      NProgress.done()
    },
    denyOrder () {
      this.$refs['deny-order-modal'].hide();
      NProgress.start()
      Axios.post('/ir/supplier/order/' + this.order.id, { 'denyOrder': true, reasonDeny: this.denyReason })
          .then(response => {
            if (response.data._status) {

              this.$toast.success('Order ' + this.order.number + ' has been denied')
              this.loadOrder(this.order.id)
              this.denyReason = '';
            }
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            NProgress.done()
          })
    },
    reinstateOrder (isNeedReason = true) {
      this.$refs['reinstate-order-modal'].hide();
      this.$refs.partMenu.close()
      let data = {
        reinstateOrder: true,
      };
      if (isNeedReason) {
        data.reinstateReason = this.reinstateReason;
      }
      NProgress.start()
      Axios.post('/ir/supplier/order/' + this.order.id, data)
          .then(response => {
            if (response.data._status) {

              this.$toast.success('Order ' + this.order.number + ' has been reinstated')
              this.loadOrder(this.order.id)
              this.reinstateReason = ''
            }
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            NProgress.done()
          })
    },
    denyOrderShowModal() {
      this.$refs['deny-order-modal'].show();
      this.$refs.partMenu.close()
    },
    cancelOrder () {
      this.$refs['cancel-order-modal'].hide();
      NProgress.start()
      Axios.post('/ir/supplier/order/' + this.order.id, { 'cancelOrder': true, 'reasonCanceled': this.cancelReason })
          .then(response => {
            if (response.data._status) {
              this.$toast.success('Order ' + this.order.number + ' has been cancelled')
              this.loadOrder(this.order.id)
              this.cancelReason = ''
            }
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            NProgress.done()
          })
    },
    getStockById (value) {
      if (value === null) {
        return ''
      }
      let status = _.find(States.stockOptions.slice(), (itm) => {
        return Number(itm.value) === Number(value)
      })
      if (status) {
        return status
      }
      return ''
    },
    getCaretPosition: function (ctrl) {
      // IE < 9 Support
      if (document.selection) {
        ctrl.focus()
        var range = document.selection.createRange()
        var rangelen = range.text.length
        range.moveStart('character', -ctrl.value.length)
        var start = range.text.length - rangelen
        return start
      }
      // IE >=9 and other browsers
      else if (ctrl.selectionStart || ctrl.selectionStart == '0') {
        return ctrl.selectionEnd
      } else {
        return 0
      }
    },
    onKeyPressUp (ref) {
      console.log('up', ref)
      let indexOfCurrentRow = _.findIndex(this.computedAvailableRefs, (row) => {
        return row.includes(ref)
      })
      let indexOfCurrentElement = _.findIndex(this.computedAvailableRefs[indexOfCurrentRow], (r) => {
        return r === ref
      })
      let resultRef = null
      if (indexOfCurrentRow === 0) {
        resultRef = this.computedAvailableRefs[this.computedAvailableRefs.length - 1][indexOfCurrentElement]
      } else {
        resultRef = this.computedAvailableRefs[indexOfCurrentRow - 1][indexOfCurrentElement]
      }
      let element = this.getElement(resultRef)

      if (!element || element.isDisabled || element.disabled) {
        this.onKeyPressUp(resultRef)
        return
      }

      if (resultRef.includes('stock-select')) {
        element.focusOnInput()
      } else {
        // this.$refs[resultRef][0].focus()
        element.focus()
      }
    },
    onKeyPressDown (ref) {
      console.log('down')
      let indexOfCurrentRow = _.findIndex(this.computedAvailableRefs, (row) => {
        return row.includes(ref)
      })
      let indexOfCurrentElement = _.findIndex(this.computedAvailableRefs[indexOfCurrentRow], (r) => {
        return r === ref
      })
      let resultRef = null
      if (indexOfCurrentRow + 1 === this.computedAvailableRefs.length) {
        resultRef = this.computedAvailableRefs[0][indexOfCurrentElement]
      } else {
        resultRef = this.computedAvailableRefs[indexOfCurrentRow + 1][indexOfCurrentElement]
      }
      let element = this.getElement(resultRef)

      if (!element || element.isDisabled || element.disabled) {
        this.onKeyPressDown(resultRef)
        return
      }

      if (resultRef.includes('stock-select')) {
        element.focusOnInput()
      } else {
        // this.$refs[resultRef][0].focus()
        element.focus()
      }
    },
    onKeyPressLeft (ref, e) {
      if (e) {
        var elem = e.srcElement
        let pos = this.getCaretPosition(elem)
        if (pos > 0) {
          return
        }
      }
      let indexOfCurrentRow = _.findIndex(this.computedAvailableRefs, (row) => {
        return row.includes(ref)
      })
      let indexOfCurrentElement = _.findIndex(this.computedAvailableRefs[indexOfCurrentRow], (r) => {
        return r === ref
      })
      let resultRef = null
      if (indexOfCurrentElement === 0) {
        let resultRow = []
        if (indexOfCurrentRow === 0) {
          resultRow = this.computedAvailableRefs[this.computedAvailableRefs.length - 1]
        } else {
          resultRow = this.computedAvailableRefs[indexOfCurrentRow - 1]
        }
        let resultRowLastIndex = resultRow.length - 1
        resultRef = resultRow[resultRowLastIndex]
        let element = this.getElement(resultRef)
        if (!element || element.isDisabled || element.disabled) {
          this.onKeyPressLeft(resultRef)
          return
        }
      } else {
        resultRef = this.computedAvailableRefs[indexOfCurrentRow][indexOfCurrentElement - 1]
        let element = this.getElement(resultRef)
        if (!element || element.isDisabled || element.disabled) {
          this.onKeyPressLeft(resultRef)
          return
        }
      }

      let element = this.getElement(resultRef)
      if (!element) {
        return
      }
      if (resultRef.includes('stock-select')) {
        element.focusOnInput()
      } else {
        // this.$refs[resultRef][0].focus()
        element.focus()
      }
    },
    onKeyPressRight (ref, e) {
      console.log('ref', ref)
      if (e) {
        var elem = e.srcElement
        let pos = this.getCaretPosition(elem)
        if (pos < elem.value.length) {
          return false
        }
      }
      let indexOfCurrentRow = _.findIndex(this.computedAvailableRefs, (row) => {
        return row.includes(ref)
      })
      let indexOfCurrentElement = _.findIndex(this.computedAvailableRefs[indexOfCurrentRow], (r) => {
        return r === ref
      })
      console.log(indexOfCurrentElement, indexOfCurrentRow, 'indexes')
      let resultRef = null

      if (indexOfCurrentElement === this.computedAvailableRefs[indexOfCurrentRow].length - 1) {
        if (indexOfCurrentRow === this.computedAvailableRefs.length - 1) {
          resultRef = this.computedAvailableRefs[0][0]
        } else {
          resultRef = this.computedAvailableRefs[indexOfCurrentRow + 1][0]
        }
        console.log('result ref', resultRef)
        let element = this.getElement(resultRef)
        console.log('element', element)
        if (!element || element.isDisabled || element.disabled) {
          this.onKeyPressRight(resultRef)
          return
        }
      } else {
        resultRef = this.computedAvailableRefs[indexOfCurrentRow][indexOfCurrentElement + 1]
        console.log('result ref', resultRef)
        let element = this.getElement(resultRef)
        console.log('element', element)
        if (!element || element.isDisabled || element.disabled) {
          this.onKeyPressRight(resultRef)
          return
        }
      }
      let element = this.getElement(resultRef)
      if (!element) {
        return
      }
      if (resultRef.includes('stock-select')) {
        element.focusOnInput()
      } else {
        // this.$refs[resultRef][0].focus()
        element.focus()
      }
    },
    getElement (ref) {
      let element = null
      if (this.$refs[ref] && this.$refs[ref][0]) {
        element = this.$refs[ref][0]
      } else if (this.$refs[ref] && this.$refs[ref]._isVue) {
        element = this.$refs[ref]
      }
      return element
    },
    onFocus (ref) {
      this.focusedElement = ref
    },
    isInFocusElement (ref) {
      if (ref === this.focusedElement) {
        return true
      }
      return false
    },
    onBlur (part, field, time = 0) {
      setTimeout(() => {
        let updatedPart = _.find(this.originalParts, prt => {
          return Number(prt.id) === Number(part.id);
        })
        console.log(part, field, updatedPart)
        if (updatedPart) {
          if (updatedPart[field] !== part[field]) {
            this.autoSavePart(part)
          }
        }
        this.focusedElement = '';
      }, time)
    },
    selectStockStatus ({ comment, label, textValue, value, isHighlight }, part, isFromTabel = false) {
      if (this.checkedParts().length > 1 && !isFromTabel) {
        NProgress.start()
        let data = []
        _.forEach(this.order.parts, (part) => {
          if (part.isChecked) {
            let index = _.findIndex(this.order.parts, (p) => {
              return Number(p.id) === Number(part.id)
            })
            this.$set(this.order.parts[index], 'stock', value)
            this.$set(this.order.parts[index], 'stockComment', comment)
            part.stock = value
            part.orderStockComment = comment
            this.callComputed++

            let resultPart = {
              rrp: Number(part.rrp),
              price: Number(part.price),
              id: Number(part.id),
              qty: Number(part.qty_order),
              stock: Number(part.stock),
              stockComment: comment,
              partNumber: part.part_number,
            }
            if (part.type === 'delivery') {
              resultPart.deliveryName = part.part_list;
            } else {
              resultPart.name = part.part_list;
            }
            data.push(resultPart)
          }
        })

        this.autoSave({
          parts: data
        })
        NProgress.done()
      } else {
        let index = _.findIndex(this.order.parts, (p) => {
          return Number(p.id) === Number(part.id)
        })
        this.$set(this.order.parts[index], 'stock', value)
        this.$set(this.order.parts[index], 'stockComment', comment)
        part.stock = value
        part.orderStockComment = comment
        this.callComputed++
        this.autoSavePart(part)
      }
      this.$refs.partMenu.close()
    },
    addImportedPdf (fileId) {
      Axios.post('/ir/supplier/order/' + this.order.id, { fileIds: [fileId] })
          .then(response => {
            if (response.data.status) {
              this.$set(this.order, 'files', response.data.order.files)
            } else {
              this.$toast.error('Cannot add Pdf in the File section')
            }
          })
          .catch(error => {
            this.$toast.error('Cannot add Pdf in the File section')
            console.log(error)
          })
    },
    saveReinstate (part) {
      if (_.isEmpty(part.reinstateComment)) {
        this.$toast.error('Comment is empty')
        return
      }
      let type = ''
      if (_.includes(_.toLower(part.cancelStatus), 'supplier')) {
        type = 'supplier'
      } else if (_.includes(_.toLower(part.cancelStatus), 'repairer')) {
        type = 'repairer'
      }
      let index = _.findIndex(this.order.parts, (prt) => {
        return Number(prt.id) === Number(part.id)
      })

      this.autoSave({
        reinstateParts: [
          {
            id: Number(part.id),
            reinstatedBy: type,
            reinstateComment: part.reinstateComment,
            cancelComment: '',
          }
        ]
      }, (data) => {
        let p = _.find(data.parts, (prt) => {
          return Number(prt.id) === Number(part.id)
        })

        console.log(part, 'part')
        if (p) {
          if (index !== -1) {
            this.$set(this.order.parts[index], 'status', p.status)
            this.$set(this.order.parts[index], 'cancelStatus', p.cancelStatus)
            this.$set(this.order.parts[index], 'cancelComment', '')
          }

          part.cancelStatus = p.cancelStatus
          part.status = p.status
          part.cancelComment = ''
        }
      })
      part.price = part.originalPrice
      if (index !== -1) {
        this.$set(this.order.parts[index], 'price', part.originalPrice)
      }

      setTimeout(() => {
        this.autoSave({
          parts: [{
            rrp: Number(part.rrp),
            price: Number(part.originalPrice),
            id: Number(part.id),
            qty: Number(part.qty_order),
            stock: Number(part.stock),
            stockComment: part.stockValue.comment,
          }]
        })
      }, 200)
      this.$refs.partMenu.close()
    },
    autoSavePart (part) {
      NProgress.start()
      let resultPart = {
        rrp: Number(part.rrp),
        price: Number(part.price),
        id: Number(part.id),
        qty: Number(part.qty_order),
        stock: Number(part.stock),
        stockComment: part.stockValue.comment,
        partNumber: part.part_number,
      }
      if (part.type === 'delivery') {
        resultPart.deliveryName = part.part_list;
      } else {
        resultPart.name = part.part_list;
      }
      this.autoSave({
        parts: [resultPart]
      })
      NProgress.done()
    },
    autoSave (data, callback = false) {
      Axios.post('/ir/supplier/order/' + this.order.id, data)
          .then(response => {
            if (response.data.status) {
              this.order.subtotal = response.data.orderAmount
              if (callback) {
                callback(response.data)
                return
              }
            }
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            // NProgress.done()
          })
    },
    addNewParts (parts) {
      this.autoSave({ addParts: parts }, (data) => {
        _.forEach(data.newParts, (prt) => {
          this.order.parts.push(prt)
        })
      })
    },
    updateParts (parts) {
      NProgress.start()
      this.autoSave({ parts: parts })
      NProgress.done()
    },
    confirmedImport (data) {
      let newParts = []
      let updatedParts = []
      console.log(data)
      _.forEach(data, (itm) => {
        if (itm.isNew) {
          newParts.push({
            qty: Number(itm.qty),
            price: Number(itm.price),
            rrp: Number(itm.rrp),
            number: itm.number,
            name: itm.name,
          })
        } else {
          let originalPart = _.find(this.order.parts, (p) => {
            return Number(p.id) === Number(itm.id)
          })
          if (originalPart && itm.isNeedUpdate) {
            let obj = {
              id: originalPart.id,
            }
            if (itm.qty !== null) {
              obj.qty = Number(itm.qty)
              originalPart.qty = itm.qty
            }
            if (itm.price !== null) {
              obj.price = Number(itm.price)
              originalPart.price = itm.price
            }
            if (itm.rrp !== null) {
              obj.rrp = Number(itm.rrp)
              originalPart.rrp = itm.rrp
            }
            updatedParts.push(obj)

          }
          console.log(this.order.parts, 'parts')
        }
      })
      console.log('parttsss', newParts, updatedParts)
      if (!_.isEmpty(newParts)) {
        this.addNewParts(newParts)
      }
      if (!_.isEmpty(updatedParts)) {
        this.updateParts(updatedParts)
      }
    },
    editOrder () {
      //removed by #512.1
      // if (_.isEmpty(this.order.invoiceNumber)) {
      //   this.invoiceRequiredText = 'finalising changes.'
        // this.$refs['invoice-number-required-modal'].show();
        // return
      // }
      this.isEditOrder = true
      this.$refs['part-order-view-tabs'].selectTab('#ordered-parts')
    },
    importPDFInvoice () {
      this.$refs.partMenu.close()
      this.isImportPDFInvoice = true
      this.$refs['part-order-view-tabs'].selectTab('#ordered-parts')
    },
    formatDateTime (val, parseFormat=false) {
      if (!val){
        return '';
      }
      let date = (parseFormat)?dayjs(val,parseFormat):dayjs(val)
      return date.format('DD/MM/YYYY || hh:mmA')
    },
    formatDate (val, parseFormat=false) {
      if (!val){
        return '';
      }
      let date = (parseFormat)?dayjs(val,parseFormat):dayjs(val)
      return date.format('DD/MM/YYYY')
    },
    round (num) {
      return Math.round(num * 100) / 100
    },
    getSelectedIds () {
      let data = []
      if (!_.isEmpty(this.checkedParts())) {
        _.forEach(this.checkedParts(), part => {
          if (part.isChecked) {
            data.push(part.id)
          }
        })
      }
      return data
    },
    markParts (status = 'shipped') {
      let data = this.getSelectedIds(),
          text = (status === 'shipped') ? 'Shipped' : 'Processing',
          params = { partIds: data }
      if (this.isLoading) return
      if (_.isEmpty(data)) {
        params = { partsAll: true }
      } else {
        this.$refs.orderTable.clearSelected()
      }
      this.selectedRow = []
      Axios.post('/ir/supplier/order/' + this.order.id + '/' + status, params)
          .then(response => {
            NProgress.done()
            if (response.data.status) {
              this.$toast.success('Parts marked as ' + text)
            }
            this.loadOrder(this.order.id)
          }).catch(error => {
        console.log(error)
        this.$toast.error('An error occurred in submitting request')
        NProgress.done()
      }).finally(() => {
        this.isLoading = false
      })
    },
    markPartsUndoShipped () {
      this.$refs.partMenu.close()
      this.markParts('undo-shipped')
    },
    markPartsShipped () {
      // removed by #512.1
      // if (_.isEmpty(this.order.invoiceNumber)) {
      //   this.invoiceRequiredText = 'Marking parts Shipped.'
      //   this.$refs['invoice-number-required-modal'].show();
        // return
      // }
      this.$refs.partMenu.close()
      this.markParts()
    },
    markAsProcessing () {
      this.invoiceRequiredText = 'Marking this order as Complete.'
      this.$refs.partMenu.close()
      this.markParts('processing')
    },
    markOrderCompleted () {
      //removed by #512.1
      // if (_.isEmpty(this.order.invoiceNumber)) {
        // this.$refs['invoice-number-required-modal'].show();
        // return
      // }
      this.$refs.partMenu.close()
      if (this.isLoading) return
      Axios.post('/ir/supplier/order/' + this.order.id + '/completed')
          .then(response => {
            NProgress.done()
            if (response.data.status) {
              this.$toast.success('Order has been marked as completed')
            }
            this.loadOrder(this.order.id)
          }).catch(error => {
        console.log(error)
        this.$toast.error('An error occurred in submitting request')
        NProgress.done()
      }).finally(() => {
        this.isLoading = false
      })
    },
    changedTab: function (obj) {
      this.activeTab = obj.tab.name
    },
    loadOrder: function (id, callback = false) {
      Axios.get('/ir/supplier/order/' + id,)
          .then(response => {
            if (response.data.status) {
              if (callback) {
                callback(response.data)
                return
              }
              this.order = new Order(response.data.order)
              _.forEach(this.order.parts, (prt) => {
                let stock = this.getStockById(Number(prt.stock))
                prt.stockValue = {
                  'label': stock.label ? stock.label : '',
                  'textValue': stock.textValue ? stock.textValue : '',
                  'value': Number(prt.stock),
                  'comment': prt.orderStockComment  ? prt.orderStockComment  : '',
                }
                prt.isChecked = false
              })
              this.originalParts = response.data.order.parts.slice()
              this.isLoadedOrder = true
              if(response.data.chatMessages){
                this.chatMessages = response.data.chatMessages
                if(this.unviewedMessagesIds.length > 0){
                  this.markMessagesAsViewed(this.unviewedMessagesIds);
                }
              }

              setTimeout(() => {
                let el = document.getElementsByClassName("order-part-list")?.[0];

                if (el) {
                  el.addEventListener('scroll', this.onTableScroll)
                }
                this.setFakeScroll()
              }, 0)
              if(response.data.backOrders){
                this.backOrders = this.correctBackOrders(response.data.backOrders)
              }

              this.isEditOrder = this.order.isProcessing() || this.order.isShipped() || this.order.isDelivered()
            } else {
              this.$toast.error('Page not found')
              this.$router.push('/s')
            }
          })
          .catch(error => {
            console.log(error)
          })
    },
    openContext (item, index, event) {
      event.preventDefault()
      // if (!this.isEditOrder) {
      //   return
      // }
      if (!item.isChecked) {
        this.$set(this.order.parts[index], 'isChecked', true)
        this.callComputed++
        this.callComputed2++
      }

      this.$refs.partMenu.open(event, { item: item, index: index })
      // this.$refs.orderTable.clearSelected()
      // this.$refs.orderTable.selectRow(index)
    },
    disableDefaultRightClickOn (elSelector) {
      const noContext = document.querySelector(elSelector)
      if (noContext) {
        noContext.addEventListener('contextmenu', e => {
          e.preventDefault()
        })
      }
    },
    handleRowClicked() {
      setTimeout(() => {
        let checkedRows = _.filter(this.order.parts, (prt) => {
          return prt.isChecked
        })
        if (checkedRows.length == 1) {
          let ind = _.findIndex(this.order.parts, (prt) => {
            return Number(prt.id) == Number(checkedRows[0].id)
          })
          if (ind !== 1) {
            if (!this.$refs.orderTable.selectedRows[ind]) {
              this.isSelectedManualy = true
              this.$refs.orderTable.selectRow(ind)
              this.$set(this.order.parts[ind], 'isChecked', true)
            } else {
              this.$refs.orderTable.unselectRow(ind)
              this.$set(this.order.parts[ind], 'isChecked', false)
            }
          }
        }
      }, 100)
    },
    handleRowSelected (rows) {
      if (this.isSelectedManualy) {
        this.isSelectedManualy = false;
        return
      }
      this.callComputed++
      this.selectedRow = rows

      _.forEach(rows, row => {
        let index = _.findIndex(this.order.parts, (prt) => {
          return Number(prt.id) == Number(row.id)
        })
        if (index !== -1) {
          this.$nextTick(() => {
            if (this.order.parts[index].isChecked && (!this.isHoldShift && !this.isHoldCtrl)) {
              this.$set(this.order.parts[index], 'isChecked', false)
            } else {
              this.$set(this.order.parts[index], 'isChecked', true)
            }

            this.callComputed++
          })
        }
      })
      this.callComputed++
      this.callComputed2++
    },
    maxStringLengthAchieved (str) {
      return str.length > 58
    },
    handleIconClicked (row) {
      if (row.item.comment || (row?.item?.credit?.id && row?.item?.credit?.reason)) {
        this.toggleRowDetails(row)
      }
    },
    openRowDetails () {
      _.forEach(this.openedRows, (row) => {
        let partIndex = _.findIndex(this.order.parts, (p) => {
          let id = row.item && row.item.id ? row.item.id : row.id
          return Number(p.id) === Number(id)
        })
        if (partIndex !== -1) {
          if (this.order.parts[partIndex].comment) {
            this.$set(this.order.parts[partIndex], '_showDetails', true)
          }
        }
      })
    },
    toggleRowDetails (row) {
      let openedRow = _.find(this.openedRows, (r) => {
        return Number(r.item.id) === Number(row.item.id)
      })

      if (row.detailsShowing) {
        if (openedRow) {
          let ind = this.openedRows.indexOf(openedRow)
          this.openedRows.splice(ind, 1)
        }
      } else if (!openedRow) {
        this.openedRows.push(row)
      }

      row.toggleDetails()
    },

    handleCancelPartButton () {
      console.log('cancel part', this.selectedRow)
    },
    dragSelectResult (rows) {
      const orderTable = this.$refs.orderTable
      orderTable.clearSelected()
      rows.forEach((row) => {
        const rowIndex = parseInt(row.id.split('_')[3])
        orderTable.selectRow(rowIndex)
      })
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
    window.removeEventListener('keydown', this.checkCtrlAndShift)
    window.removeEventListener('keyup', this.removeCtrlAndShift)
    window.removeEventListener('scroll', this.onScroll)
    if(this._chat){
      console.log('UNSUBSCRIBING!',this._chat);
      this._chat.unsubscribe();
      console.log('UNSUBSCRIBED!');
    }
    clearInterval(this.interval);
  },
  created: function () {
    (async () => {
      this._chat = await subscribeToOrderChannel(this.orderId, {
        onPublication: this.onChatMessage,
        onSubscribed: () => {
          this.onlineStatusIntervalId = setInterval(() => {
            this._chat.publish({ type: 'supplierIsOnline' })
          }, 2000)
        },
        onUnsubscribed: () => {
          if(this.onlineStatusIntervalId !== null){
            clearInterval(this.onlineStatusIntervalId)
          }
        },
      })
    })();
  },
  mounted () {
    window.addEventListener("scroll", this.onScroll)
    this.loadOrder(this.orderId)
    this.$nextTick(() => {
      this.disableDefaultRightClickOn('.parts-order-view-page .table')
    })
    this.handleResize();
    window.addEventListener('resize', this.handleResize)
    window.addEventListener('keydown', this.checkCtrlAndShift)
    window.addEventListener('keyup', this.removeCtrlAndShift)

    this.debounceOnKeyPressUp = _.debounce(function (v) {
      this.onKeyPressUp(v);
    }, 10);

    this.debounceOnKeyPressDown = _.debounce(function (v) {
      this.onKeyPressDown(v);
    }, 10);

    this.debounceOnKeyPressLeft = _.debounce(function (v, e) {
      this.onKeyPressLeft(v, e);
    }, 10);

    this.debounceOnKeyPressRight = _.debounce(function (v, e) {
      this.onKeyPressRight(v, e);
    }, 10);

    this.debouncedDeliveryDateChanged = _.debounce(this.deliveryDateChanged, 10);
    this.repairerLeavedTheChat = _.debounce(function () {
      this.chatRepairerIsOnline = false;
    }, 5000)
  },
  filters: {
    maxFour (str) {
      switch (str) {
        case 'Open':
          return 'Open'
        case 'Processing':
          return 'Prcg'
        case 'Shipped':
          return 'Ship'
        case 'Delivered':
          return 'Deld'
        case 'Completed':
          return 'Comd'
        default:
          return str.substring(0, 4)
      }
    },
    creditStatus (status) {
      switch (status) {
        case 'Approved':
          return 'Approved'
        case 'UnderReview':
          return 'Under Review'
        case 'Denied':
          return 'Denied'
        default:
          return ''
      }
    }
  },
  components: {
    WindowNotification,
    VueContext,
    OrderProgress,
    StickyHeader,
    vinDate,
    datePicker,
    Chat,
    CustomerAccountDeliver,
    FileUpload,
    psLabelValue,
    partsOrdersImportPDF,
    NumberFormatter,
    StockSelect,
    HighlightableInput,
    DatePicker,
    VueEditor,
    VPerfectSignature,
    SearchFilter
  },
}

</script>

<style lang="scss" scoped>
.ps-label-value {
  min-width: 360px;
  font-size: 13px;
  span {
    display: inline-block;
    margin-bottom: 5px;
    vertical-align: top;
  }
  &__label {
    width: 170px;
    white-space: nowrap;
    color: #a0a1a7;
    font-weight: 700;
  }
  &__colon {
    width: auto;
    margin-left: 15px;
    margin-right: 15px;
  }
  &__value {
    font-weight: 700;
  }

}
</style>


<style scoped>
.context-menu-focus.active{
  color: #070707 !important;
  background-color: rgba(41, 187, 193, 0.1) !important;
}
.theme-supplier .parts-order-view-page .parts-order-view__context-menu >>> .stock-select__option:hover,
.theme-supplier .parts-order-view-page .parts-order-view__context-menu >>> .stock-select__option:focus {
  padding: 14px 15px 10px 15px !important;
}
.theme-supplier .parts-order-view-page .parts-order-view__context-menu >>> .stock-select__option {
  padding: 14px 15px 10px 15px !important;
}
.dropdown-actions >>> button{
  background-color: #7bbec0 !important;
  border-color: #7bbec0 !important;
  color: #fdfdfd !important;
}
.dropdown-actions >>> button:hover{
  background-color: #afd7d8 !important;
  color: #3f3f3f !important;
}
.dropdown-actions >>> button span{
  border-color: #7bbec0 !important;
}
.order-part-list {
  overflow: auto !important;
}
.no-customers-text {
  padding: 15px 15px 11px 15px !important;
  font-size: 12px !important;
  line-height: 16px !important;
  letter-spacing: normal !important;
  font-weight: 600 !important;
  text-align: justify !important;
}

.context-stock-select-height >>> .stock-select__option .bx-chevron-right.text-right{
  top: 14px !important;
}
.input-btn.decline {
  background-color: white !important;
  color: #1C1F39;
}
.color-orange {
  color: orange !important;
}
.date-picker-border-blinking{
  padding: 1px;
  border-radius: 3px;
  background-color: orange;
  animation: date-picker-border-blinking 1.5s linear infinite;
}
@keyframes date-picker-border-blinking {
  0% {
    background-color: orange;
  }
  50% {
    background-color: rgba(255, 165, 0, 0.07);
  }
  100% {
    background-color: orange;
  }
}
.date-picker-border-blinking >>> .form-control:focus{
  box-shadow: 0 0 0 0.2rem rgba(255, 165, 0, 0.25);
}
.position-sticky-header{
  /*position: absolute;*/
  /*left: 15px;*/
}
.header-scroll-position-fixed-parts-order-height >>> thead{
  position: sticky;
  top: 197px;
  z-index: 99;
}
.header-scroll-position-fixed-parts-order-height >>> th{
  position: relative !important;
}
.header-scroll-position-fixed-parts-order-height >>> th:nth-child(1){
  position: sticky !important;
  left: 240px !important;
  z-index: 100 !important;
}
.header-scroll-position-fixed-parts-order-height >>> th:nth-child(2){
  position: sticky !important;
  left: 321px !important;
  z-index: 100 !important;
}
.parts-order-height >>> tr td:nth-child(1){
  left: 240px !important;
}
.parts-order-height >>> tr td:nth-child(2){
  left: 321px !important;
}
.header-scroll-position-fixed{
  position: fixed;
  top: 135px;
  left: 240px;
  height: 75px;
  width: calc(100% - 240px);
  z-index: 999;
}
.header-scroll-position-fixed-vin-date{
  background-color: #fdfdfd;
  padding: 15px;
  margin: 0 !important;
}
.parts-order-margin-table{
  overflow: visible;
}
.parts-order-height >>> th:nth-child(6){
  min-width: 85px;
}
.parts-order-height >>> th:nth-child(7){
  min-width: 70px;
}
.parts-order-margin-table >>> th:nth-child(1) .form-check-inline{
  margin-bottom: 0;
}
.part-order-item-line{
  margin-top: 2px;
}
.order-sort-icon-flex-end{
  align-self: flex-end;
  margin-bottom: 4px;
}
.order-sort-icon{
  background-repeat: no-repeat;
}
.order-sort-icon-part-description{
  display: block;
  flex-basis: 30px;
}
.part-sub-context-menu {
  position: absolute;
  background-color: white;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
}

@keyframes blinking-orange {
  20% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
}

@keyframes blinking-red {
  20% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
}

.icon-red-blinking {
  color: red;
  animation: blinking-red 1s linear infinite;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: 250ms;
}

.icon-orange-blinking {
  animation: blinking-orange 1s linear infinite;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: 250ms;
}

.orange-icon-2 {
  color: orange;
}

.gray-icon-2 {
  color: rgb(121, 121, 121) !important;
}

.green-icon-2 {
  color: rgb(60, 199, 169) !important;
}

.blue-icon-2 {
  color: #2f6da5 !important;
}

.comment-textarea {
  padding: 0px;
  margin-top: 5px;
  border: none;
  color: #1c1f39;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  width: 100%;
  resize: none;
}

.part-number-stock-orange {
  color: #F59345 !important;
  font-weight: 600;
  width: auto;
  white-space: nowrap;
  text-align: start;
  padding-top: 5px;
  padding-left: 10px;
  animation: blinking-orange 1s linear infinite;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: 250ms;
}

.part-number-stock-red {
  color: red;
  font-weight: 600;
  width: auto;
  white-space: nowrap;
  text-align: start;
  padding-top: 5px;
  padding-left: 10px;
  animation: blinking-red 1s linear infinite;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: 250ms;
}

@keyframes blinking-orange {
  20% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
}

@keyframes blinking-red {
  20% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
}

.disabled-button {
  opacity: 0.7 !important;
}

.disabled-button:hover {
  background-color: #bdbdbd4d;
}

.gray-icon {
  color: rgb(121, 121, 121) !important
}

.orange-icon {
  color: #f0710f !important;
}

.red-icon {
  color: red !important;
}

.green-icon {
  color: green !important;
}

.processing-icon-orange {
  color: orange !important;
}

.part-context-arrow-icon {
  font-size: 20px;
  position: absolute;
  left: 117px;
  transform: translateY(-3px);
}

.limit-over {
  color: red !important;
}

.in-comment-block-context {
  top: 0px !important;
}

.low-comment-block-context {
  position: absolute;
  background: white;
  left: 147px;
  border: 1px solid #bdbdbd;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
  padding: 10px;
  top: 41px;
}

.low-comment-block-context-text-area {
  resize: none;
  width: 275px;
  height: 130px;
}

.low-comment-block-context-limit {
  color: #35495E;
  font-weight: bold;
  margin-bottom: 0px;
}

.bxs-truck {
  mask-size: contain;
  -webkit-mask-size: contain;
  height: 20px;
  width: 20px;
}
.bxs-truck.text-primary {
  color: #43B968 !important;
}

.no-stock-context-button {
  border: 1px solid rgba(28, 31, 57, 0.25);
}

.no-comment-block-context {
  position: absolute;
  background: white;
  left: 147px;
  border: 1px solid #bdbdbd;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
  padding: 10px;
  top: 83px;
}

.no-comment-block-context-text-area {
  resize: none;
  width: 172px;
  height: 130px;
}

.no-comment-block-context-limit {
  color: #35495E;
  font-weight: bold;
  margin-bottom: 0px;
}

.deny-order-modal-textarea {
  width: 92%;
  margin-top: 15px;
  margin-left: 15px;
  height: 80px;
}

.button-close-modal-header {
  background-color: var(--supplier-color);
  color: #000;
  opacity: .4;
  padding: 0;
  border: none;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  font-family: 'Inter', sans-serif;
}

.modal-undo-part-line-change-btn-yes:disabled {
  background-color: #bdbdbd !important;
}

.modal-undo-part-line-change-btn-yes:disabled:hover {
  color: #ccc !important;
  cursor: not-allowed;
}

.button-close-modal-header:hover {
  background-color: var(--supplier-color);
}

.reinstate-context {
  position: absolute;
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  border: 1px solid #bdbdbd;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
  left: 275px;
  top: -1px;
  padding: 10px;
  width: 210px;
  height: 180px;
  text-align: left;
}

.reinstate-text {
  color: #35495E;
  font-size: 12px !important;
  line-height: 16px !important;
  letter-spacing: normal;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  margin: 0;
}

.reinstate-textarea {
  border: none !important;
  width: 100%;
  height: 140px;
  resize: none;
  margin: 0;
  padding: 0;
  color: #35495E !important;
  font-size: 12px !important;
  line-height: 16px !important;
  letter-spacing: normal;
  font-family: "Inter", sans-serif;
  font-weight: 600;
}

.reinstate-textarea:focus {
  border: none !important;
  box-shadow: none !important;
}

.reinstate-textarea::placeholder {
  color: #35495E !important;
  font-size: 13px !important;
  line-height: 16px !important;
  letter-spacing: normal;
  font-family: "Inter", sans-serif;
  font-weight: 600;
}

.save-reinstate-icon {
  position: absolute;
  font-size: 25px;
  bottom: 6px;
  right: 10px;
  cursor: pointer;
  opacity: 0.7;
  color: #35495E;
}

.green-cell {
  color: #0cb458 !important;
}

.red-cell {
  color: red !important;
}

.import-pdf-additional-block .customer-account-deliver {
  margin: 25px 0 0 0;
}

@media screen and (max-width: 576px) {
  .vehicle-details-block {
    width: 100%;
    margin: 15px 0 0 0;
  }

  .V3 .block .block-header,
  .V3 .block .block-body {
    margin: 15px;
  }
}

.context-level-two {
  position: absolute;
  background-color: white;
  border: 1px solid #bdbdbd;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
  width: 148px;
  left: 275px;
  top: 37px;
}
.context-level-two-left275px{
  left: 275px;
}
.context2-level-two {
  position: absolute;
  background-color: white;
  border: 1px solid #bdbdbd;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
  width: 158px;
  left: 275px;
  top: 83px;
}

.context3-level-two {
  position: absolute;
  background-color: white;
  border: 1px solid #bdbdbd;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
  width: 158px;
  left: 273px;
  top: -210px;
}

.context4-level-two {
  position: absolute;
  background-color: white;
  border: 1px solid #bdbdbd;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
  width: 275px;
  left: 275px;
  top: 210px;
}

.context2-level-two-2 {
  top: -1px !important;
}

.context2-level-two-3 {
  top: 168px !important;
}

.context-level-three {
  position: absolute;
  background-color: white;
  border: 1px solid #bdbdbd;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
  width: 150px;
  left: 147px !important;
  top: 123px !important;
  padding: 10px;
}

.parts-order-view-body >>> .stock-select {
  min-width: 130px;
}

.order-parts-headerinformation {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}

@media screen and (max-width: 1240px) {
  .ps-label-value {
    display: flex;
    flex-direction: column;
    min-width: 200px !important;
  }
}

@media screen and (max-width: 1150px) {
  .order-parts-headerinformation {
    flex-wrap: wrap;
  }

  .customer-account-deliver {
    margin-left: 1.5rem !important;
  }

  .ps-label-value {
    flex-direction: unset;
    min-width: 360px !important;
  }
}

@media screen and (max-width: 400px) {
  .ps-label-value {
    flex-direction: unset;
    min-width: auto !important;
    flex-wrap: wrap !important;
  }
}

.parts-order-view__h45px{
  width: 45px;
}
.parts-order-view__sticky .icon{
  position: sticky;
}

</style>

<style>
#non-returnable-modal-supplier-order .close {
  color: white;
  opacity: 1;
}
.custom-special-order-modal-2 {
  margin: 15rem auto !important;
}
.custom-special-order-modal-2 .terms-checkbox>input[type="checkbox"]:checked+span.icon i {
  transform: translate(-2px, -2px);
  color: #29BBC1;
}

.theme-supplier .supplier-invoice-number-context {
  left: 275px !important;
  top: 125px !important;
}

.theme-supplier .supplier-invoice-number-context-2 {
  left: 275px !important;
  top: 83px !important;
}

.theme-supplier .supplier-invoice-date-context {
  left: 275px !important;
  top: 167px !important;
}

.theme-supplier .supplier-invoice-date-context-2 {
  left: 275px !important;
  top: 125px !important;
}


.theme-supplier .parts-order-view-page .checked-row td {
  background-color: #e5f6f5 !important;
}

.direct-order-blinker .send-direct-order-comment-block-blinking-text {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.editable-text-area .send-direct-order-comment-block {
  border: none !important;
  min-height: auto;
}

.editable-text-area .send-direct-order-comment-block .p-2 {
  padding: 5px 0px !important;
}

.editable-text-area p {
  margin-bottom: 0px !important;
}

.new-delivery-blinker {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.reinstate-cancel-tooltip .tooltip-inner {
  border-radius: 0px 10px 10px 10px !important;
}
.context-stock-select .stock-select__select.active {
  top: 5px !important;
  min-height: 279px !important;
  z-index: 9999999999999999;
}

.theme-supplier .parts-order-view-page .parts-order-view__context-menu .stock-select__option {
  color: #1C1F39;
  padding: 15px 15px 11px 15px !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  background-color: #fff !important;
}

.theme-supplier .parts-order-view-page .parts-order-view__context-menu .stock-select__option:hover,
.theme-supplier .parts-order-view-page .parts-order-view__context-menu .stock-select__option:focus {
  color: #070707 !important;
  background-color: rgba(41, 187, 193, 0.1) !important;
  padding: 15px 15px 11px 15px !important;
  font-size: 12px !important;
  font-weight: 700 !important;
}

.theme-supplier .parts-order-view-page .parts-order-view__context-menu .stock-select__option i {
  top: 5px !important;
  left: 5px;
}

.theme-supplier .parts-order-view-page .parts-order-view__context-menu .context-stock-select .stock-select__select.active {
  min-height: 296px !important;
}

.theme-supplier .parts-order-view-page .parts-order-view__context-menu .context-stock-select .stock-comment .stock-text {
  color: #35495E;
  font-size: 12px !important;
  line-height: 16px !important;
  letter-spacing: normal;
  font-family: "Inter", sans-serif;
  font-weight: 600;
}

.theme-supplier .parts-order-view-page .parts-order-view__context-menu .context-stock-select .stock-comment__info {
  color: #35495E;
  opacity: 0.5;
  position: absolute;
  bottom: 10px;
  left: 10px;
  font-size: 12px !important;
  line-height: 16px !important;
  letter-spacing: normal;
  font-weight: 600;
}

.theme-supplier .parts-order-view-page .parts-order-view__context-menu .context-stock-select .save-comment-icon {
  position: absolute;
  font-size: 25px;
  bottom: 6px;
  right: 10px;
  cursor: pointer;
  opacity: 0.7;
}

.theme-supplier .parts-order-view-page .parts-order-view__context-menu .context-stock-select .save-comment-icon:hover {
  opacity: 1;
}

.theme-supplier .parts-order-view-page .parts-order-view__context-menu .context-stock-select .stock-comment {
  font-size: 12px !important;
  line-height: 16px !important;
  letter-spacing: normal;
  font-weight: 600;
}

.theme-supplier .parts-order-view-page .parts-order-view__context-menu .context-stock-select .context-level-three {
  padding: 10px;
  width: 367px;
}

.theme-supplier .parts-order-view-page .parts-order-view__context-menu .context-stock-select .btn-secondary {
    color: #1C1F39;
    background-color: #FFFFFF;
    font-weight: bold;
    line-height: 18px;
    box-sizing: border-box;
    border: 1px solid rgba(28, 31, 57, 0.25);
    border-radius: 3px;
}

.theme-supplier .parts-order-view-page .parts-order-view__context-menu .btn-secondary:hover{
  color: #29BBC1 !important;
  background-color: #cff1f3 !important;
}

.theme-supplier .parts-order-view-page .context-menu-focus:focus {
  color: #070707 !important;
  background-color: rgba(41, 187, 193, 0.1) !important;
}
</style>
