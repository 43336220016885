<template>
    <div class="repairer-suppliers-page">
        <div class="page-header">
            <h4>View Suppliers</h4>
            <ol class="breadcrumbs">
                <li class="breadcrumb-item">
                    <router-link :to="{ name: 'RepairerDashboard'}">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item active">View Suppliers</li>
            </ol>
        </div>
        <div class="repairer-suppliers-body">
            <tabs @changed="tabChanged" ref="repairer-suppliers-table" cache-lifetime="0" :options="{useUrlFragment: false}">
                <tab name="All" id="/r/suppliers/all" :class-name="'tab-content-table'">
                    <div class="use-pagination-block-bottom">
                        <b-table
                                responsive
                                hover
                                :items="computedOtherSuppliers"
                                :fields="fields"
                                :filter="filter"
                                :current-page="gettersSuppliersAllCurrentPage"
                                :per-page="gettersPerPage"
                                @row-clicked="onSuppliersRowClick"
                                @filtered="value => suppliersAllFiltered = value.length"
                        >
                            <template v-slot:head()="data">
                                <div v-if="!data.field.sortable">{{ data.label }}</div>
                                <div v-if="data.field.sortable" class="table-sorting">{{ data.label }}<i></i></div>
                            </template>
                            <template v-slot:cell(types)="data">
                              {{getTypes(data.item)}}
                            </template>
                            <template v-slot:cell(action)="data">
                                <i class='bx bxs-add-to-queue action' @click="setAsPrefered(data.item)"
                                   style="margin-right:10px;"
                                   v-b-tooltip.hover="{customClass: 'ps-tooltip', title:'Add to Preferred Supplier List', placement:'leftbottom'}"></i>
                            </template>
                        </b-table>
                    </div>
                    <block-pagination
                            :role="'Suppliers'"
                            :count="gettersOtherSuppliers.length"
                            :countFiltered="filter ? suppliersAllFiltered : computedOtherSuppliers.length"
                            :currentPage="gettersSuppliersAllCurrentPage"
                            @changedCurrentPage="value => $store.commit('repairer/supplier/repairerSupplierAllCurrentPage', value)"
                            :perPage="gettersPerPage"
                            @changedPerPage="value => $store.commit('repairer/supplier/repairerSupplierPerPage', value)"
                    >
                    </block-pagination>
                </tab>
                <tab name="Prefered" id="/r/suppliers/prefered" :class-name="'tab-content-table'">
                    <div class="use-pagination-block-bottom">
                        <b-table
                                responsive
                                hover
                                :items="computedPreferedSuppliers"
                                :fields="fields"
                                :filter="filter"
                                :current-page="gettersSuppliersPreferedCurrentPage"
                                :per-page="gettersPerPage"
                                @row-clicked="onSuppliersRowClick"
                                @filtered="value => suppliersPreferedFiltered = value.length"
                        >
                            <template v-slot:head()="data">
                                <div v-if="!data.field.sortable">{{ data.label }}</div>
                                <div v-if="data.field.sortable" class="table-sorting">{{ data.label }}<i></i></div>
                            </template>
                            <template v-slot:cell(types)="data">
                              {{getTypes(data.item)}}
                            </template>
                            <template v-slot:cell(action)="data">
                                <div @click="setAsOther(data.item)" class="action">
                                    <i class='bx bx-x-circle action'
                                       v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title:'<strong>Remove</strong> from<br /> Preferred supplier list', placement:'leftbottom'}"></i>
                                </div>
                            </template>
                        </b-table>
                    </div>
                    <block-pagination
                            :role="'Suppliers'"
                            :count="gettersPreferedSuppliers.length"
                            :countFiltered="filter ? suppliersPreferedFiltered : computedPreferedSuppliers.length"
                            :currentPage="gettersSuppliersPreferedCurrentPage"
                            @changedCurrentPage="value => $store.commit('repairer/supplier/repairerSupplierPreferedCurrentPage', value)"
                            :perPage="gettersPerPage"
                            @changedPerPage="value => $store.commit('repairer/supplier/repairerSupplierPerPage', value)"
                    >
                    </block-pagination>
                </tab>
                <tab name="Tier 1 Suppliers" id="tier" :class-name="'tab-content-table'">
                  <div class="use-pagination-block-bottom">
                      <div class="tier-suppliers-table">
                        <div class="table-header">
                          <div v-for="field in fields2" :key="field.key" class="table-cell th-class" :class="['table-cell', 'th-class', field.thClass]">
                            {{ field.label }}
                          </div>
                        </div>

                        <div class="table-body">
                          <div v-for="item in computedTierByPerPage" :key="item.make" class="table-row flex-column">
                            <supplier-tier-line
                                :item="item"
                                @changedTierItem="changedTierItem"
                            ></supplier-tier-line>
                          </div>
                        </div>
                      </div>
                    </div>
                  <div class="" style="height: 75px; width: 10px"></div>
                  <block-pagination
                      :role="'Suppliers'"
                      :arrayOfSomeone="testTier"
                      :arrayOfSomeoneFiltered="filter ? computedTestTier : testTier"
                      :currentPage="getSuppliersTierCurrentPage"
                      @changedCurrentPage="value => $store.commit('repairer/supplier/repairerSupplierTierCurrentPage', value)"
                      :perPage="gettersPerPage2"
                      @changedPerPage="value => $store.commit('repairer/supplier/repairerSupplierPerPage2', value)"
                  >
                  </block-pagination>
                </tab>
                <template slot="nav-item-right">
                    <div v-if="currentTab !== 'Tier 1 Suppliers'" class="wizard-header-button-supplier-in"
                         style="margin-right: 45px; height: 39px; width: 151px ">
                        <div class="d-flex align-items-center">
                            <div class="filter-alt"><i class='bx bx-filter-alt'></i></div>

                            <b-dropdown id="dropdown-1" text="Type" class="dropdowns-header">
                                <template v-slot:button-content><span
                                        v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title:'Show Suppliers By Type', placement:'leftbottom'}">
                                      <span :class="{'active': filterType}">Type</span>
                                      <span class="dropdown-arrow">
                                      <i :class="{'active': filterType}" class='bx bx-down-arrow'></i>
                                       </span>
                                  </span>
                                </template>
                                <b-dropdown-item :class="{'active-item': filterType === null}"
                                                 @click="filterType = null">All Types
                                </b-dropdown-item>
                                <b-dropdown-item :class="{'active-item': (filterType === 'OEM-GEN' || filterType === 'OEM')}"
                                                 @click="filterType = 'OEM-GEN'">OEM-GEN
                                </b-dropdown-item>
                                <b-dropdown-item :class="{'active-item': filterType === 'OEM-PAR'}"
                                                 @click="filterType = 'OEM-PAR'">OEM-PAR
                                </b-dropdown-item>
                                <b-dropdown-item :class="{'active-item': filterType === 'OEM-ALT'}"
                                                 @click="filterType = 'OEM-ALT'">OEM-ALT
                                </b-dropdown-item>
                                <b-dropdown-item :class="{'active-item': filterType === 'CERT-AFTM'}"
                                                 @click="filterType = 'CERT-AFTM'">CERT-AFTM
                                </b-dropdown-item>
                              <b-dropdown-item :class="{'active-item': filterType === 'AFTM'}"
                                                 @click="filterType = 'AFTM'">AFTM
                                </b-dropdown-item>
                                <b-dropdown-item :class="{'active-item': filterType === 'USED'}"
                                                 @click="filterType = 'USED'">USED
                                </b-dropdown-item>
                                <b-dropdown-item :class="{'active-item': filterType === 'RECO'}"
                                                 @click="filterType = 'RECO'">RECO
                                </b-dropdown-item>
                            </b-dropdown>
                            |
                            <b-dropdown id="dropdown-2" text="State" class="dropdowns-header">
                                <template v-slot:button-content><span
                                        v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title:'Show Suppliers By State', placement:'leftbottom'}">
                                      <span :class="{'active': filterState}">State</span>
                                      <span class="dropdown-arrow">
                                          <i :class="{'active': filterState}" class='bx bx-down-arrow'></i>
                                      </span>
                                  </span>
                                </template>
                                <b-dropdown-item :class="{'active-item': filterState === null}" @click="filterState = null">All State</b-dropdown-item>
                                <b-dropdown-item :class="{'active-item': filterState === 'ACT'}" @click="filterState = 'ACT'">Australian Capital Territory</b-dropdown-item>
                                <b-dropdown-item :class="{'active-item': filterState === 'NSW'}" @click="filterState = 'NSW'">New South Wales</b-dropdown-item>
                                <b-dropdown-item :class="{'active-item': filterState === 'NT'}" @click="filterState = 'NT'">Northern Territory</b-dropdown-item>
                                <b-dropdown-item :class="{'active-item': filterState === 'QLD'}" @click="filterState = 'QLD'">Queensland</b-dropdown-item>
                                <b-dropdown-item :class="{'active-item': filterState === 'SA'}" @click="filterState = 'SA'">South Australia</b-dropdown-item>
                                <b-dropdown-item :class="{'active-item': filterState === 'TAS'}" @click="filterState = 'TAS'">Tasmania</b-dropdown-item>
                                <b-dropdown-item :class="{'active-item': filterState === 'VIC'}" @click="filterState = 'VIC'">Victoria</b-dropdown-item>
                                <b-dropdown-item :class="{'active-item': filterState === 'WA'}" @click="filterState = 'WA'">Western Australia</b-dropdown-item>
                            </b-dropdown>
                        </div>
                    </div>
                    <div class="tabs-nav-right-search pl-3">
                      <div class="d-flex">
                        <b-form-input v-model="filter" type="text" class="form-control search-input"
                                      placeholder="Search here"
                                      :style="{
                                        'marginTop': currentTab !== 'Tier 1 Suppliers' ? '0px' : '2px'
                                      }"
                                      debounce="500"></b-form-input>
                        <button :disabled="!isCanSaveTier" v-if="currentTab === 'Tier 1 Suppliers'" @click="saveTier1" style="margin-left: 10px;" class="btn btn-primary primary-save-btn">Save</button>
                      </div>
                    </div>
                </template>
            </tabs>
        </div>
    </div>
</template>
<script>
/*eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import BlockPagination from '../../../components/utility/block-pagination'
import SupplierTierLine from './supplier-tier-line'
import _ from "lodash";
import Axios from 'axios';

export default {
  name: 'suppliers',
  components: {
    BlockPagination,
    SupplierTierLine
  },
  data: function () {
    return {
      filter: '',
      perPage: 100,
      filterType: '',
      filterState: '',
      currentTab: '',
      currentPage: 1,
      suppliersAllFiltered: 0,
      suppliersPreferedFiltered: 0,
      fields: [
        { label: 'Supplier Name', key: 'name', sortable: true, tdClass: 'clickable supplier-name font-weight-bold', thClass: '' },
        { label: 'Type', key: 'types', sortable: true, tdClass: 'clickable font-weight-bold', thClass: '' },
        { label: 'Email', key: 'email', sortable: true, tdClass: 'clickable ', thClass: '' },
        { label: 'Address', key: 'address', sortable: true, tdClass: 'clickable ', thClass: '' },
        { label: 'Suburb', key: 'suburb', sortable: true, tdClass: 'clickable font-weight-bold', thClass: '' },
        { label: 'State', key: 'state', sortable: true, tdClass: 'clickable font-weight-bold', thClass: '' },
        { label: 'Phone', key: 'phone', sortable: true, tdClass: 'clickable font-weight-bold', thClass: '' },
        {
          label: 'Action',
          key: 'Action',
          sortable: false,
          tdClass: 'clickable text-center action',
          thClass: 'text-center'
        },
      ],

      testTier: [],
      fields2: [
        { label: 'VCH Make', key: 'make', sortable: true, tdClass: 'font-weight-bold tier-suppliers-table-make', thClass: 'tier-suppliers-table-make' },
        { label: 'OEM-GEN', key: 'oemGen', sortable: false, tdClass: 'clickable ', thClass: '' },
        { label: 'OEM-PAR', key: 'oemPar1', sortable: false, tdClass: 'clickable ', thClass: '' },
        { label: 'OEM-PAR', key: 'oemPar2', sortable: false, tdClass: 'clickable ', thClass: '' },
        { label: 'OEM-PAR', key: 'oemPar3', sortable: false, tdClass: 'clickable ', thClass: '' },
        { label: 'OEM-PAR', key: 'oemPar4', sortable: false, tdClass: 'clickable ', thClass: '' },
        { label: 'OEM-ALT', key: 'oemAlt1', sortable: false, tdClass: 'clickable ', thClass: '' },
        { label: 'OEM-ALT', key: 'oemAlt2', sortable: false, tdClass: 'clickable ', thClass: '' },
        { label: 'OEM-ALT', key: 'oemAlt3', sortable: false, tdClass: 'clickable ', thClass: '' },
        { label: 'OEM-ALT', key: 'oemAlt4', sortable: false, tdClass: 'clickable ', thClass: '' },
        { label: 'CERT-AFTM', key: 'certAftm1', sortable: false, tdClass: 'clickable ', thClass: '' },
        { label: 'CERT-AFTM', key: 'certAftm2', sortable: false, tdClass: 'clickable ', thClass: '' },
        { label: 'AFTM', key: 'aftm1', sortable: false, tdClass: 'clickable ', thClass: '' },
        { label: 'AFTM', key: 'aftm2', sortable: false, tdClass: 'clickable ', thClass: '' },
        { label: 'USED', key: 'used1', sortable: false, tdClass: 'clickable ', thClass: '' },
        { label: 'USED', key: 'used2', sortable: false, tdClass: 'clickable ', thClass: '' },
        { label: 'USED', key: 'used3', sortable: false, tdClass: 'clickable ', thClass: '' },
        { label: 'USED', key: 'used4', sortable: false, tdClass: 'clickable ', thClass: '' },
      ],
    }
  },
  created() {

    this.testTier = _.sortBy(this.testTier, [function (sp) {
      return _.trim(_.toLower(sp.make))
    }])

    let makes = _.sortBy(_.cloneDeep(this.makes), (item) => {
      return item;
    })
    _.forEach(makes, (m) => {
      this.testTier.push({
        make: _.toUpper(m),
        oemGen: null,
        oemPar1: null,
        oemPar2: null,
        oemPar3: null,
        oemPar4: null,
        oemAlt1: null,
        oemAlt2: null,
        oemAlt3: null,
        oemAlt4: null,
        aftm1: null,
        aftm2: null,
        used1: null,
        used2: null,
        used3: null,
        used4: null,
        certAftm1: null,
        certAftm2: null,
      })
    })

    this.setTier1Value(this.computedCompressFullSuppliers);
  },
  mounted () {
    if (!this.filter) {
      this.filter = this.gettersFilter
    }

     let interval = setInterval(() => {
      if (this.isLoadedCompanyInfo) {
        this.filterState = this.companyInfo.address.state
        clearInterval(interval)
      }
    }, 100)
  },
  watch: {
    filter (val) {
      this.$store.commit('repairer/supplier/repairerSupplierFilter', val)
    },
    gettersPreferedSuppliers: {
      handler() {
        this.setTier1Value(this.computedCompressFullSuppliers);
      },
      deep: true
    },
    getTier1: {
      handler() {
        this.setTier1Value(this.computedCompressFullSuppliers);
      },
      deep: true
    }
  },
  methods: {
    ...mapActions({
      changeSupplierGroup: 'repairer/supplier/changeSupplierGroup',
    }),
    getTypes(item) {
      if (item?.types?.length == 1) {
        return item.types[0]
      } else if (item?.types?.length > 1) {
        return 'Multiple'
      }
      return ''
    },
    changedTierItem(item) {
      let ind = _.findIndex(this.testTier, (t) => {
        return t.make === item.make
      })
      if (ind !== -1) {
        this.$set(this.testTier[ind], 'isChanged', true)
      }
    },
    saveTier1() {
      NProgress.start();
      let data = _.filter(this.testTier, (t) => {
        return t.isChanged
      })
      let r = [];
      _.forEach(data, (itm) => {
        r.push({
          make: itm.make,
          oemGen: itm?.oemGen?.id ? itm.oemGen.id : null,
          oemPar1: itm?.oemPar1?.id ? itm.oemPar1.id : null,
          oemPar2: itm?.oemPar2?.id ? itm.oemPar2.id : null,
          oemPar3: itm?.oemPar3?.id ? itm.oemPar3.id : null,
          oemPar4: itm?.oemPar4?.id ? itm.oemPar4.id : null,
          oemAlt1: itm?.oemAlt1?.id ? itm.oemAlt1.id : null,
          oemAlt2: itm?.oemAlt2?.id ? itm.oemAlt2.id : null,
          oemAlt3: itm?.oemAlt3?.id ? itm.oemAlt3.id : null,
          oemAlt4: itm?.oemAlt4?.id ? itm.oemAlt4.id : null,
          aftm1: itm?.aftm1?.id ? itm.aftm1.id : null,
          aftm2: itm?.aftm2?.id ? itm.aftm2.id : null,
          certAftm1: itm?.certAftm1?.id ? itm.certAftm1.id : null,
          certAftm2: itm?.certAftm2?.id ? itm.certAftm2.id : null,
          used1: itm?.used1?.id ? itm.used1.id : null,
          used2: itm?.used2?.id ? itm.used2.id : null,
          used3: itm?.used3?.id ? itm.used3.id : null,
          used4: itm?.used4?.id ? itm.used4.id : null,
        })
      })
      Axios.post('/ir/repairer/supplier/update-tier-1', r)
          .then(response => {
            if (response.data._status) {
              this.$toast.success('Tier Suppliers have been saved')
              _.forEach(this.testTier, (t, ind) => {
                this.$set(this.testTier[ind], 'isChanged', false)
              })
            }
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            NProgress.done()
          })
    },
    setTier1Value(val) {
      if (_.isEmpty(val)) {
        return
      }
      _.forEach(this.testTier, (itm, ind) => {
        let newItm = _.find(this.getTier1, (t) => {
          return t.make == itm.make && !itm.isChanged
        })
        if (newItm) {
          this.testTier.splice(ind, 1, newItm)
        }
      })
      let testTierCopy = _.cloneDeep(this.testTier);
      _.forEach(testTierCopy, (itm) => {
        if (_.isNumber(itm.oemGen) || _.isString(itm.oemGen)) {
          itm.oemGen = _.find(val, (v) => {
            return Number(v.id) === Number(itm.oemGen);
          })
        }
        if (_.isNumber(itm.oemPar1) || _.isString(itm.oemPar1)) {
          itm.oemPar1 = _.find(val, (v) => {
            return Number(v.id) === Number(itm.oemPar1);
          })
        }
        if (_.isNumber(itm.oemPar2) || _.isString(itm.oemPar2)) {
          itm.oemPar2 = _.find(val, (v) => {
            return Number(v.id) === Number(itm.oemPar2);
          })
        }
        if (_.isNumber(itm.oemPar3) || _.isString(itm.oemPar3)) {
          itm.oemPar3 = _.find(val, (v) => {
            return Number(v.id) === Number(itm.oemPar3);
          })
        }
        if (_.isNumber(itm.oemPar4) || _.isString(itm.oemPar4)) {
          itm.oemPar4 = _.find(val, (v) => {
            return Number(v.id) === Number(itm.oemPar4);
          })
        }
        if (_.isNumber(itm.certAftm1) || _.isString(itm.certAftm1)) {
          itm.certAftm1 = _.find(val, (v) => {
            return Number(v.id) === Number(itm.certAftm1);
          })
        }
        if (_.isNumber(itm.certAftm2) || _.isString(itm.certAftm2)) {
          itm.certAftm2 = _.find(val, (v) => {
            return Number(v.id) === Number(itm.certAftm2);
          })
        }
        if (_.isNumber(itm.aftm1) || _.isString(itm.aftm1)) {
          itm.aftm1 = _.find(val, (v) => {
            return Number(v.id) === Number(itm.aftm1);
          })
        }
        if (_.isNumber(itm.aftm2) || _.isString(itm.aftm2)) {
          itm.aftm2 = _.find(val, (v) => {
            return Number(v.id) === Number(itm.aftm2);
          })
        }
        if (_.isNumber(itm.oemAlt1) || _.isString(itm.oemAlt1)) {
          itm.oemAlt1 = _.find(val, (v) => {
            return Number(v.id) === Number(itm.oemAlt1);
          })
        }
        if (_.isNumber(itm.oemAlt2) || _.isString(itm.oemAlt2)) {
          itm.oemAlt2 = _.find(val, (v) => {
            return Number(v.id) === Number(itm.oemAlt2);
          })
        }
        if (_.isNumber(itm.oemAlt3) || _.isString(itm.oemAlt3)) {
          itm.oemAlt3 = _.find(val, (v) => {
            return Number(v.id) === Number(itm.oemAlt3);
          })
        }
        if (_.isNumber(itm.oemAlt4) || _.isString(itm.oemAlt4)) {
          itm.oemAlt4 = _.find(val, (v) => {
            return Number(v.id) === Number(itm.oemAlt4);
          })
        }
        if (_.isNumber(itm.used1) || _.isString(itm.used1)) {
          itm.used1 = _.find(val, (v) => {
            return Number(v.id) === Number(itm.used1);
          })
        }
        if (_.isNumber(itm.used2) || _.isString(itm.used2)) {
          itm.used2 = _.find(val, (v) => {
            return Number(v.id) === Number(itm.used2);
          })
        }
        if (_.isNumber(itm.used3) || _.isString(itm.used3)) {
          itm.used3 = _.find(val, (v) => {
            return Number(v.id) === Number(itm.used3);
          })
        }
        if (_.isNumber(itm.used4) || _.isString(itm.used4)) {
          itm.used4 = _.find(val, (v) => {
            return Number(v.id) === Number(itm.used4);
          })
        }
      })
      this.testTier.splice(0, this.testTier.length);
      _.forEach(testTierCopy, (itm) => {
        this.testTier.push(itm)
      })
    },
    tabChanged (selectedTab) {
      this.currentTab = selectedTab.tab.name;
    },
    setAsPrefered: function (item) {
      if (this.changeSupplierGroup({ type: 'Prefered', item: item })) {
        this.$toast.success(item.name + ' successfully added as a preferred Supplier')
      } else {
        this.$toast.error(item.name + ' did not add as a preferred Supplier. Please try again')
      }
    },
    setAsOther: function (item) {
      if (this.changeSupplierGroup({type: 'Other', item: item })) {
        this.$toast.success(item.name + ' successfully removed from preferred suppliers list')
      } else {
        this.$toast.error(item.name + ' did not remove from preferred suppliers list. Please try again')
      }
    },
    onSuppliersRowClick: function (item, index, event) {
      if (event && event.target && event.target.classList.contains('action')) {
        return
      }
      this.$router.push({ name: 'RepairerSupplierView', params: { supplier_id: item.id } })
      return
    },
  },
  computed: {
    ...mapGetters({
      gettersPreferedSuppliers: 'repairer/supplier/getPreferedSuppliers',
      gettersSpecialistSuppliers: 'repairer/supplier/getSpecialistSuppliers',
      gettersFullSuppliers: 'repairer/supplier/getFullSuppliers',
      gettersOtherSuppliers: 'repairer/supplier/getOtherSuppliers',
      gettersFilter: 'repairer/supplier/getFilter',
      gettersPerPage: 'repairer/supplier/getPerPage',
      gettersPerPage2: 'repairer/supplier/getPerPage2',
      gettersSuppliersAllCurrentPage: 'repairer/supplier/getSuppliersAllCurrentPage',
      gettersSuppliersPreferedCurrentPage: 'repairer/supplier/getSuppliersPreferedCurrentPage',
      companyInfo: 'currentCompany/getDetails',
      isLoadedCompanyInfo: 'currentCompany/isLoadedCompanyInfo',
      getPreferedSuppliersFilteredByCompanyState: 'repairer/supplier/getPreferedSuppliersFilteredByCompanyState',
      getOtherSuppliersFilteredByCompanyState: 'repairer/supplier/getOtherSuppliersFilteredByCompanyState',
      getSuppliersTierCurrentPage: 'repairer/supplier/getSuppliersTierCurrentPage',
      getTier1: 'repairer/supplier/getTier1',
      'makes': 'makes'
    }),
    computedCompressFullSuppliers() {
      return _.map(_.cloneDeep(this.gettersPreferedSuppliers), (s) => {
        return {
          name: s.name,
          id: s.id,
          types: s.types
        }
      })
    },
    isCanSaveTier() {
      return _.some(this.testTier, (t) => {
        return t.isChanged
      })
    },
    computedTestTier() {
      if (_.isEmpty(this.filter)) {
        return this.testTier
      }
      return _.filter(this.testTier, (itm) => {

        if (itm?.oemGen?.name) {
          if (_.includes(_.trim(_.toLower(itm.oemGen.name)), _.trim(_.toLower(this.filter)))) {
            return true
          }
        }
        if (itm?.oemPar1?.name) {
          if (_.includes(_.trim(_.toLower(itm.oemPar1.name)), _.trim(_.toLower(this.filter)))) {
            return true
          }
        }
        if (itm?.oemPar2?.name) {
          if (_.includes(_.trim(_.toLower(itm.oemPar2.name)), _.trim(_.toLower(this.filter)))) {
            return true
          }
        }
        if (itm?.oemPar3?.name) {
          if (_.includes(_.trim(_.toLower(itm.oemPar3.name)), _.trim(_.toLower(this.filter)))) {
            return true
          }
        }
        if (itm?.oemPar4?.name) {
          if (_.includes(_.trim(_.toLower(itm.oemPar4.name)), _.trim(_.toLower(this.filter)))) {
            return true
          }
        }
        if (itm?.certAftm1?.name) {
          if (_.includes(_.trim(_.toLower(itm.certAftm1.name)), _.trim(_.toLower(this.filter)))) {
            return true
          }
        }
        if (itm?.certAftm2?.name) {
          if (_.includes(_.trim(_.toLower(itm.certAftm2.name)), _.trim(_.toLower(this.filter)))) {
            return true
          }
        }
        if (itm?.aftm1?.name) {
          if (_.includes(_.trim(_.toLower(itm.aftm1.name)), _.trim(_.toLower(this.filter)))) {
            return true
          }
        }
        if (itm?.aftm2?.name) {
          if (_.includes(_.trim(_.toLower(itm.aftm2.name)), _.trim(_.toLower(this.filter)))) {
            return true
          }
        }
        if (itm?.oemAlt1?.name) {
          if (_.includes(_.trim(_.toLower(itm.oemAlt1.name)), _.trim(_.toLower(this.filter)))) {
            return true
          }
        }
        if (itm?.oemAlt2?.name) {
          if (_.includes(_.trim(_.toLower(itm.oemAlt2.name)), _.trim(_.toLower(this.filter)))) {
            return true
          }
        }
        if (itm?.oemAlt3?.name) {
          if (_.includes(_.trim(_.toLower(itm.oemAlt3.name)), _.trim(_.toLower(this.filter)))) {
            return true
          }
        }
        if (itm?.oemAlt4?.name) {
          if (_.includes(_.trim(_.toLower(itm.oemAlt4.name)), _.trim(_.toLower(this.filter)))) {
            return true
          }
        }
        if (itm?.used1?.name) {
          if (_.includes(_.trim(_.toLower(itm.used1.name)), _.trim(_.toLower(this.filter)))) {
            return true
          }
        }
        if (itm?.used2?.name) {
          if (_.includes(_.trim(_.toLower(itm.used2.name)), _.trim(_.toLower(this.filter)))) {
            return true
          }
        }
        if (itm?.used3?.name) {
          if (_.includes(_.trim(_.toLower(itm.used3.name)), _.trim(_.toLower(this.filter)))) {
            return true
          }
        }
        if (itm?.used4?.name) {
          if (_.includes(_.trim(_.toLower(itm.used4.name)), _.trim(_.toLower(this.filter)))) {
            return true
          }
        }

        if (itm?.make) {
          if (_.includes(_.trim(_.toLower(itm.make)), _.trim(_.toLower(this.filter)))) {
            return true
          }
        }

        return false
      })
    },
    computedTierByPerPage() {
      if (!_.isEmpty(this.computedTestTier)) {
        return this.computedTestTier.slice((this.getSuppliersTierCurrentPage - 1) * this.gettersPerPage2, this.getSuppliersTierCurrentPage * this.gettersPerPage2)
      }
      return []
    },
    computedOtherSuppliers () {
      let data = this.getOtherSuppliersFilteredByCompanyState.slice()

      if (this.filterState) {
        data = _.filter(this.gettersOtherSuppliers.slice(), (itm) => {
          return itm.state === this.filterState
        })
      } else if (this.filterState === null) {
        data = this.gettersOtherSuppliers.slice()
      }

      if (this.filterType) {
        data = _.filter(data, (itm) => {
          return itm.types.includes(this.filterType)
        })
      }

      return _.sortBy(data, [function (sp) {
        return _.trim(_.toLower(sp.name))
      }])
    },
    computedPreferedSuppliers () {
      let data = this.getPreferedSuppliersFilteredByCompanyState.slice()

      if (this.filterState) {
        data = _.filter(this.gettersPreferedSuppliers.slice(), (itm) => {
          return itm.state === this.filterState
        })
      } else if (this.filterState === null) {
        data = this.gettersPreferedSuppliers.slice()
      }

      if (this.filterType) {
        data = _.filter(data, (itm) => {
          return itm.types.includes(this.filterType)
        })
      }

      return _.sortBy(data, [function (sp) {
        return _.trim(_.toLower(sp.name))
      }])
    },
  }
}
</script>

<style scoped>
.tier-suppliers-table {
  display: flex;
  flex-direction: column;
  width: calc(100vw - 247px);
  height: calc(100vh - 271px);
  overflow: scroll;
}

.table-header {
  display: flex;
  position: sticky;
  top: 0;
  z-index: 99;
  width: calc(100vw + 700px);
}

.table-row {
  display: flex;
  width: calc(100vw + 700px);
}

.table-body {
  height: calc(100vh - 250px);
  position: relative;
  z-index: 50;
}

.tier-suppliers-table-make {
  display: flex;
  align-items: center;
  position: sticky;
  left: 0;
  background-color: #fff;
  z-index: 99;
  min-width: 120px !important;
  max-width: 120px;
  width: 120px;
}

.table-cell {
  flex: 1;
  padding: 8px;
  border-bottom: 1px solid rgba(27, 30, 56, 0.25);
}

.th-class {
  min-width: 190px;
  height: 48px;
  background-color: #e3e4e7;
  display: flex;
  align-items: center;
}

.tr-line {
  border-bottom: 1px solid rgba(27, 30, 56, 0.25);
}

.td-class {
  min-width: 190px;
  height: 48px;
}

@media screen and (max-width: 767px) {
  .tier-suppliers-table {
    width: auto;
  }
  .table-body {
    height: calc(100vh - 320px);
  }
  .table-row {
    width: calc(100vw + 2200px);
  }
  .table-header {
    width: calc(100vw + 2200px);
  }
}
</style>

<style>
   .V3 .repairer-suppliers-page .repairer-suppliers-body .b-table td {
        color: #1C1F39;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 16px;
    }
   .V3 .repairer-suppliers-page .repairer-suppliers-body .b-table td.supplier-name {
       font-weight: bold;
   }

</style>
