<template>
  <div class="supplier-rfqs-page">
    <div class="page-header">
      <div class="d-flex justify-content-between">
        <div class="">
          <h4>Request for Quotes</h4>
          <ol class="breadcrumbs">
            <li class="breadcrumb-item">
              <router-link :to="{ name: 'SupplierDashboard'}">Dashboard</router-link>
            </li>
            <li class="breadcrumb-item active">View Request for Quotes</li>
          </ol>
        </div>
        <div class="search-filter-in-header d-flex">
          <window-notification></window-notification>
          <search-filter
              type="estimates"
              :placeholder="'Search'"
              :isSearch="true"
              v-model="searchFilter2"
              :filterValue="searchFilter2"
              :additional-menu="additionalMenu"
              @selectAdditional="redirectToRfqView"
              @onEnter="onEnter"
              height="41px"
              aria-expanded="false"
              data-toggle="dropdown"
              :isMyltipleOptions="true"
              :isMyltiple="true"
              :optionsLength="8"
          />
        </div>
      </div>
    </div>
    <div class="supplier-rfqs-body">
      <tabs ref="supplier-rfqs-table" cache-lifetime="0" :options="{useUrlFragment:true}" @changed="changedTab"
            :custom-counts="computedTotalCounts">
        <tab name="All" id="all" :class-name="'tab-content-table'">
          <div class="use-pagination-block-bottom">
            <b-table
                ref="allTable"
                responsive
                hover
                :items="computedAllRFQs"
                :fields="allFields"
                :filter="filter"
                :current-page="gettersAllRFQsCurrentPage"
                :per-page="gettersPerPage"
                :filter-function="filterTable"
                @filtered="value => allRFQsFiltered = value.length"
                @row-clicked="clickRFQRow"
                @row-contextmenu="openMenuForRFQs"
                :tbody-tr-class="rowClass"
            >
              <template v-slot:head()="data">
                <div v-if="!data.field.sortable">{{ data.label }}</div>
                <div v-if="data.field.sortable" class="table-sorting">{{ data.label }}<i></i></div>
              </template>
              <template #cell(dateReceived)="data">
                {{ data.value | formatDate }}
              </template>
              <template #cell(dateDue)="data">
                <span v-if="data && data.item && data.item.isSentAsDirectOrder">DIRECT ORDER<br
                    v-if="data.value"/></span>
                {{ data.value | formatDate('DD/MM/YYYY || hh:mmA') }}
              </template>
              <template v-slot:cell(assignedTo)="data">
                <span v-if="data.value && data.value.id > 0">{{ data.value.name }}</span>
              </template>
              <template v-slot:cell(notifications)="data">
                <notificationOnTable
                    :dueDate10Min="isBlinkingRed(data.item)"
                    :dueDate20Min="isBlinkingOrange(data.item)"
                    :isBiddersBlinking="true"
                    @updateTable="updateNewTable(data.item.id)"
                    :items="data"/>
              </template>
              <template v-slot:cell(priority)="data">
                <span v-if="data.item.priority && data.item.priority !== 'None'" class="status-text"
                      :class="getPriorityClass(data.item.priority)">
                  {{ data.item.priority }}
                </span>
              </template>
              <template v-slot:cell(viewerUserList)="data">
                  <span v-for="(user, index) in data.item.viewingUsers" :key="user" style="color: #29BBC1">
                    {{ getUserNameById(user) }}<span v-if="index + 1 !== data.item.viewingUsers.length">, </span>
                  </span>
              </template>
            </b-table>
          </div>
          <block-pagination
              :role="'RFQs'"
              :count="gettersAllRFQs.length"
              :countFiltered="filter ? allRFQsFiltered : computedAllRFQs.length"
              :currentPage="gettersAllRFQsCurrentPage"
              @changedCurrentPage="value => $store.commit('supplier/rfq/supplierRFQAllCurrentPage', value)"
              :perPage="gettersPerPage"
              @changedPerPage="value => $store.commit('supplier/rfq/supplierPerPage', value)"
          >
          </block-pagination>

        </tab>
        <tab name="New" id="new" :class-name="'tab-content-table'">
          <div class="use-pagination-block-bottom">
            <b-table
                ref="newTable"
                responsive
                hover
                :items="computedNewRFQs"
                :fields="computedNewFields"
                :filter="filter"
                :current-page="gettersNewRFQsCurrentPage"
                :per-page="gettersPerPage"
                :filter-function="filterTable"
                @filtered="value => newRFQsFiltered = value.length"
                @row-clicked="clickRFQRow"
                @row-contextmenu="openMenuForRFQs"
                :tbody-tr-class="rowClass"
            >
              <template v-slot:cell(priority)="data">
                <span v-if="data.item.priority && data.item.priority !== 'None'" class="status-text"
                      :class="getPriorityClass(data.item.priority)">
                  {{ data.item.priority }}
                </span>
              </template>
              <template v-slot:head()="data">
                <div v-if="!data.field.sortable">{{ data.label }}</div>
                <div v-if="data.field.sortable" class="table-sorting">{{ data.label }}<i></i></div>
              </template>
              <template #cell(bidders)="data">
                <div class="">
                   <span  v-for="(bidder, ind) in data.value" :key="ind">
                     <i v-if="!(bidder.status === 'Declined' && !isShowBidders)"
                        style="font-size: 23px"
                        :class="{
                           'bx bx-minus-circle bidder-color-gray': bidder.status === 'Nil Stock',
                           'bx bxs-badge-dollar': bidder.status !== 'Nil Stock',
                           'bidder-color-gray': bidder.status === 'Not Priced' || (bidder.status === 'Priced' && data.item.rfqUpdateRequested === 'UpdateRequested'),
                           'bidder-color-red red-icon-blinker': bidder.status === 'Declined',
                           'bidder-color-green': bidder.status === 'Priced' && data.item.rfqUpdateRequested !== 'UpdateRequested',
                         }"
                        v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title: getBidderTooltip(bidder), placement:'leftbottom'}"
                     ></i>
                     <i
                         v-else
                         class='bx bxs-badge-dollar'
                         style="font-size: 23px; color: white"
                     ></i>
                   </span>
                </div>
              </template>
              <template #cell(dateDue)="data">
                <span v-if="(data && data.item && data.item.isSentAsDirectOrder)">
                  DIRECT ORDER
                  <br v-if="data.value || data.item.rfqUpdateRequested === 'UpdateRequested'"/>
                </span>
                <template v-if="data.item.rfqUpdateRequested === 'UpdateRequested'">
                  <span class="orange-icon-blinker orange-icon">{{ data.item.rfqUpdateRequestedDue | formatDate('DD/MM/YYYY || hh:mmA') }}</span>
                  <br v-if="data.value"/>
                </template>
                <span v-if="data.value && data.item.rfqUpdateRequested === 'UpdateRequested'" class="orange-icon-blinker orange-icon">PRC/STK UPDATE</span> {{ data.value | formatDate('DD/MM/YYYY || hh:mmA') }}
              </template>

              <template v-slot:cell(assignedTo)="data">
                <span v-if="data.value && data.value.id > 0">{{ data.value.name }}</span>
              </template>
              <template v-slot:cell(notifications)="data">
                <notificationOnTable
                    :dueDate10Min="isBlinkingRed(data.item)"
                    :dueDate20Min="isBlinkingOrange(data.item)"
                    :isBiddersBlinking="isShowBidders"
                    @updateTable="updateNewTable(data.item.id)"
                    :items="data"/>
              </template>
              <template v-slot:cell(viewerUserList)="data">
                  <span v-for="(user, index) in data.item.viewingUsers" :key="user" style="color: #29BBC1">
                    {{ getUserNameById(user) }}<span v-if="index + 1 !== data.item.viewingUsers.length">, </span>
                  </span>
              </template>
            </b-table>
          </div>
          <block-pagination
              :role="'RFQs'"
              :count="gettersNewRFQs.length"
              :countFiltered="filter ? newRFQsFiltered : computedNewRFQs.length"
              :currentPage="gettersNewRFQsCurrentPage"
              @changedCurrentPage="changedCurrentPageNew"
              :perPage="gettersPerPage"
              @changedPerPage="value => $store.commit('supplier/rfq/supplierPerPage', value)"
          >
          </block-pagination>

        </tab>
        <tab name="Priced" id="priced" :class-name="'tab-content-table'">
          <div class="use-pagination-block-bottom">
            <b-table
                ref="pricedTable"
                responsive
                hover
                :items="computedPricedRFQs"
                :fields="computedPricedFields"
                :filter="filter"
                :sort-by.sync="newTableSortBy"
                :sort-desc.sync="newTableSortDesc"
                :current-page="gettersPricedRFQsCurrentPage"
                :per-page="gettersPerPage"
                :filter-function="filterTable"
                @filtered="value => pricedRFQsFiltered = value.length"
                @row-clicked="clickRFQRow"
                @row-contextmenu="openMenuForRFQs"
            >
              <template #cell(bidders)="data">
                <div class="">
                   <span  v-for="(bidder, ind) in data.value" :key="ind">
                     <i v-if="!(bidder.status === 'Declined' && !isShowBidders)"
                        style="font-size: 23px"
                        :class="{
                         'bx bx-minus-circle bidder-color-gray': bidder.status === 'Nil Stock',
                         'bx bxs-badge-dollar': bidder.status !== 'Nil Stock',
                         'bidder-color-gray': bidder.status === 'Not Priced' || (bidder.status === 'Priced' && data.item.rfqUpdateRequested === 'UpdateRequested'),
                         'bidder-color-red red-icon-blinker': bidder.status === 'Declined',
                         'bidder-color-green': bidder.status === 'Priced' && data.item.rfqUpdateRequested !== 'UpdateRequested',
                       }"
                        v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title: getBidderTooltip(bidder), placement:'leftbottom'}"
                     ></i>
                     <i
                         v-else
                         class='bx bxs-badge-dollar'
                         style="font-size: 23px; color: white"
                     ></i>
                   </span>
                </div>
              </template>
              <template v-slot:cell(priority)="data">
                <span v-if="data.item.priority && data.item.priority !== 'None'" class="status-text"
                      :class="getPriorityClass(data.item.priority)">
                  {{ data.item.priority }}
                </span>
              </template>
              <template v-slot:head()="data">
                <div v-if="!data.field.sortable">{{ data.label }}</div>
                <div v-if="data.field.sortable" class="table-sorting">{{ data.label }}<i></i></div>
              </template>
              <template v-slot:cell(assignedTo)="data">
                <span v-if="data.value && data.value.id > 0">{{ data.value.name }}</span>
              </template>
              <template #cell(dateDue)="data">
                <span v-if="data && data.item && data.item.isSentAsDirectOrder">DIRECT ORDER<br
                    v-if="data.value"/></span>
                    <span v-if="data.value && data.item.rfqUpdateRequested === 'UpdateRequested'" class="orange-icon-blinker orange-icon">PRC/STK UPDATE</span> {{ data.value | formatDate('DD/MM/YYYY || hh:mmA') }}
              </template>
              <template v-slot:cell(notifications)="data">
                <notificationOnTable
                    :isBiddersBlinking="isShowBidders"
                    :items="data">
                </notificationOnTable>
              </template>
              <template #cell(totalValueQuoted)="data">
                <div class="d-flex justify-content-end pr-4"> {{ data.value | formatMoney }}</div>
              </template>
              <template v-slot:cell(viewerUserList)="data">
                  <span v-for="(user, index) in data.item.viewingUsers" :key="user" style="color: #29BBC1">
                    {{ getUserNameById(user) }}<span v-if="index + 1 !== data.item.viewingUsers.length">, </span>
                  </span>
              </template>
            </b-table>
          </div>
          <block-pagination
              :role="'RFQs'"
              :count="gettersPricedRFQs.length"
              :countFiltered="filter ? pricedRFQsFiltered : computedPricedRFQs.length"
              :currentPage="gettersPricedRFQsCurrentPage"
              @changedCurrentPage="changedCurrentPagePriced"
              :perPage="gettersPerPage"
              @changedPerPage="value => $store.commit('supplier/rfq/supplierPerPage', value)"
          >
          </block-pagination>
        </tab>
        <tab name="Lost" id="lost" :class-name="'tab-content-table'">
          <div class="use-pagination-block-bottom">
            <b-table
                ref="lostTable"
                responsive
                hover
                :items="computedLostRFQs"
                :fields="lostFields"
                :sort-by.sync="newTableSortBy"
                :sort-desc.sync="newTableSortDesc"
                :filter="filter"
                :current-page="gettersLostRFQsCurrentPage"
                :per-page="gettersPerPage"
                :filter-function="filterTable"
                @filtered="value => lostRFQsFiltered = value.length"
                @row-clicked="clickRFQRow"
                @row-contextmenu="openMenuForRFQs"
            >
              <template v-slot:cell(priority)="data">
                <span v-if="data.item.priority && data.item.priority !== 'None'" class="status-text"
                      :class="getPriorityClass(data.item.priority)">
                  {{ data.item.priority }}
                </span>
              </template>
              <template v-slot:head()="data">
                <div v-if="!data.field.sortable">{{ data.label }}</div>
                <div v-if="data.field.sortable" class="table-sorting">{{ data.label }}<i></i></div>
              </template>
              <template v-slot:cell(assignedTo)="data">
                <span v-if="data.value && data.value.id > 0">{{ data.value.name }}</span>
              </template>
              <template #cell(dateDue)="data">
                <span v-if="data && data.item && data.item.isSentAsDirectOrder">DIRECT ORDER<br
                    v-if="data.value"/></span>
                {{ data.value | formatDate('DD/MM/YYYY || hh:mmA') }}
              </template>
              <template v-slot:cell(notifications)="data">
                <notificationOnTable
                    :isBiddersBlinking="true"
                    :items="data">
                </notificationOnTable>
              </template>
              <template #cell(totalValueQuoted)="data">
                <div class="d-flex justify-content-end pr-4"> {{ data.value | formatMoney }}</div>
              </template>
              <template v-slot:cell(viewerUserList)="data">
                  <span v-for="(user, index) in data.item.viewingUsers" :key="user" style="color: #29BBC1">
                    {{ getUserNameById(user) }}<span v-if="index + 1 !== data.item.viewingUsers.length">, </span>
                  </span>
              </template>
            </b-table>
          </div>
          <block-pagination
              :role="'RFQs'"
              :count="gettersLostRFQs.length"
              :countFiltered="filter ? lostRFQsFiltered : computedLostRFQs.length"
              :currentPage="gettersLostRFQsCurrentPage"
              @changedCurrentPage="value => $store.commit('supplier/rfq/supplierRFQLostCurrentPage', value)"
              :perPage="gettersPerPage"
              @changedPerPage="value => $store.commit('supplier/rfq/supplierPerPage', value)"
          >
          </block-pagination>
        </tab>
        <tab name="Won" id="won" :class-name="'tab-content-table'">
          <div class="use-pagination-block-bottom">
            <b-table
                ref="wonTable"
                responsive
                hover
                :items="computedWonRFQs"
                :fields="wonFields"
                :sort-by.sync="newTableSortBy"
                :sort-desc.sync="newTableSortDesc"
                :filter="filter"
                :current-page="gettersWonRFQsCurrentPage"
                :per-page="gettersPerPage"
                :filter-function="filterTable"
                @filtered="value => wonRFQsFiltered = value.length"
                @row-clicked="clickRFQRow"
                @row-contextmenu="openMenuForRFQs"
            >
              <template v-slot:cell(priority)="data">
                <span v-if="data.item.priority && data.item.priority !== 'None'" class="status-text"
                      :class="getPriorityClass(data.item.priority)">
                  {{ data.item.priority }}
                </span>
              </template>
              <template v-slot:head()="data">
                <div v-if="!data.field.sortable">{{ data.label }}</div>
                <div v-if="data.field.sortable" class="table-sorting">{{ data.label }}<i></i></div>
              </template>
              <template v-slot:cell(assignedTo)="data">
                <span v-if="data.value && data.value.id > 0">{{ data.value.name }}</span>
              </template>
              <template #cell(dateDue)="data">
                <span v-if="data && data.item && data.item.isSentAsDirectOrder">DIRECT ORDER<br
                    v-if="data.value"/></span>
                {{ data.value | formatDate('DD/MM/YYYY || hh:mmA') }}
              </template>
              <template v-slot:cell(notifications)="data">
                <notificationOnTable
                    :isBiddersBlinking="true"
                    :items="data">
                </notificationOnTable>
              </template>
              <template #cell(totalValueQuoted)="data">
                <div class="d-flex justify-content-end pr-4"> {{ data.value | formatMoney }}</div>
              </template>
              <template #cell(totalValueWon)="data">
                <div class="d-flex justify-content-end pr-4"> {{ data.value | formatMoney }}</div>
              </template>
              <template v-slot:cell(viewerUserList)="data">
                  <span v-for="(user, index) in data.item.viewingUsers" :key="user" style="color: #29BBC1">
                    {{ getUserNameById(user) }}<span v-if="index + 1 !== data.item.viewingUsers.length">, </span>
                  </span>
              </template>
            </b-table>
          </div>
          <block-pagination
              :role="'RFQs'"
              :count="gettersWonRFQs.length"
              :countFiltered="filter ? wonRFQsFiltered : computedWonRFQs.length"
              :currentPage="gettersWonRFQsCurrentPage"
              @changedCurrentPage="value => $store.commit('supplier/rfq/supplierRFQWonCurrentPage', value)"
              :perPage="gettersPerPage"
              @changedPerPage="value => $store.commit('supplier/rfq/supplierPerPage', value)"
          >
          </block-pagination>
        </tab>
        <tab name="Order Processed" id="ordered" :class-name="'tab-content-table'">
          <div class="use-pagination-block-bottom">
            <b-table
                ref="orderedTable"
                responsive
                hover
                :items="computedOrderedRFQs"
                :fields="orderedFields"
                :filter="filter"
                :current-page="gettersOrderedRFQsCurrentPage"
                :per-page="gettersPerPage"
                :filter-function="filterTable"
                @filtered="value => orderRFQsFiltered = value.length"
                @row-clicked="clickRFQRow"
                @row-contextmenu="openMenuForRFQs"
            >
              <template v-slot:cell(priority)="data">
                <span v-if="data.item.priority && data.item.priority !== 'None'" class="status-text"
                      :class="getPriorityClass(data.item.priority)">
                  {{ data.item.priority }}
                </span>
              </template>
              <template v-slot:head()="data">
                <div v-if="!data.field.sortable">{{ data.label }}</div>
                <div v-if="data.field.sortable" class="table-sorting">{{ data.label }}<i></i></div>
              </template>
              <template #cell(dateReceived)="data">
                {{ data.value | formatDate }}
              </template>
              <template #cell(dateDue)="data">
                <span v-if="data && data.item && data.item.isSentAsDirectOrder">DIRECT ORDER<br
                    v-if="data.value"/></span>
                {{ data.value | formatDate('DD/MM/YYYY || hh:mmA') }}
              </template>
              <template v-slot:cell(assignedTo)="data">
                <span v-if="data.value && data.value.id > 0">{{ data.value.name }}</span>
              </template>
              <template #cell(totalValueQuoted)="data">
                <div class="d-flex justify-content-end pr-4"> {{ data.value | formatMoney }}</div>
              </template>
              <template #cell(totalValueWon)="data">
                <div class="d-flex justify-content-end pr-4"> {{ data.value | formatMoney }}</div>
              </template>
              <template v-slot:cell(viewerUserList)="data">
                  <span v-for="(user, index) in data.item.viewingUsers" :key="user" style="color: #29BBC1">
                    {{ getUserNameById(user) }}<span v-if="index + 1 !== data.item.viewingUsers.length">, </span>
                  </span>
              </template>
              <template v-slot:cell(notifications)="data">
                <notificationOnTable
                    :isBiddersBlinking="true"
                    :items="data">
                </notificationOnTable>
              </template>
            </b-table>
          </div>
          <block-pagination
              :role="'RFQs'"
              :count="gettersOrderedRFQs.length"
              :countFiltered="filter ? orderRFQsFiltered : computedOrderedRFQs.length"
              :currentPage="gettersOrderedRFQsCurrentPage"
              @changedCurrentPage="value => $store.commit('supplier/rfq/supplierRFQOrderedCurrentPage', value)"
              :perPage="gettersPerPage"
              @changedPerPage="value => $store.commit('supplier/rfq/supplierPerPage', value)"
          >
          </block-pagination>
        </tab>
        <tab name="Closed" id="closed" :class-name="'tab-content-table'">
          <div class="use-pagination-block-bottom">
            <b-table
                ref="closedTable"
                responsive
                hover
                :items="computedClosedRFQs"
                :fields="closedFields"
                :filter="filter"
                :current-page="gettersClosedRFQsCurrentPage"
                :per-page="gettersPerPage"
                :sort-by.sync="newTableSortBy"
                :sort-desc.sync="newTableSortDesc"
                :filter-function="filterTable"
                @filtered="value => closedRFQsFiltered = value.length"
                @row-clicked="clickRFQRow"
                @row-contextmenu="openMenuForRFQs"
            >
              <template v-slot:cell(priority)="data">
                <span v-if="data.item.priority && data.item.priority !== 'None'" class="status-text"
                      :class="getPriorityClass(data.item.priority)">
                  {{ data.item.priority }}
                </span>
              </template>
              <template v-slot:head()="data">
                <div v-if="!data.field.sortable">{{ data.label }}</div>
                <div v-if="data.field.sortable" class="table-sorting">{{ data.label }}<i></i></div>
              </template>
              <template #cell(dateReceived)="data">
                {{ data.value | formatDate }}
              </template>
              <template #cell(dateDue)="data">
                <span v-if="data && data.item && data.item.isSentAsDirectOrder">DIRECT ORDER<br
                    v-if="data.value"/></span>
                {{ data.value | formatDate('DD/MM/YYYY || hh:mmA') }}
              </template>
              <template v-slot:cell(assignedTo)="data">
                <span v-if="data.value && data.value.id > 0">{{ data.value.name }}</span>
              </template>
              <template v-slot:cell(notifications)="data">
                <notificationOnTable
                    :isBiddersBlinking="true"
                    :items="data">
                </notificationOnTable>
              </template>
              <template #cell(totalValueQuoted)="data">
                <div class="d-flex justify-content-end pr-4"> {{ data.value | formatMoney }}</div>
              </template>
              <template v-slot:cell(viewerUserList)="data">
                  <span v-for="(user, index) in data.item.viewingUsers" :key="user" style="color: #29BBC1">
                    {{ getUserNameById(user) }}<span v-if="index + 1 !== data.item.viewingUsers.length">, </span>
                  </span>
              </template>
            </b-table>
          </div>
          <block-pagination
              :role="'RFQs'"
              :count="gettersClosedRFQs.length"
              :countFiltered="filter ? closedRFQsFiltered : computedClosedRFQs.length"
              :currentPage="gettersClosedRFQsCurrentPage"
              @changedCurrentPage="value => $store.commit('supplier/rfq/supplierRFQClosedCurrentPage', value)"
              :perPage="gettersPerPage"
              @changedPerPage="value => $store.commit('supplier/rfq/supplierPerPage', value)"
          >
          </block-pagination>
        </tab>
        <template slot="nav-item-right">
          <div class="tabs-nav-right-search">
            <search-filter
                type="estimates"
                v-model="searchFilter"
                :filters="computedSearchFilters"
                :style="'min-width:' +  this.widthInputSearch + 'px'"
                :filterValue="filter"
                :limit-length="50"
                :placeholder="'Filter By'"
                :isShowSearIcon="false"
                @change="onSearchFilterChange"
                @updateToken="value => $store.commit('supplier/rfq/setToken', value)"
                @updateTokens="value => $store.commit('supplier/rfq/setTokens', value)"
                :tokenCopy="computedToken"
                :tokensCopy="getterTokens"
                height="41px"
                aria-expanded="false"
                data-toggle="dropdown"
                :isMyltipleOptions="true"
                :isMyltiple="true"
                :optionsLength="8"
            />
            <!--                        <b-form-input v-model="filter" type="text" class="search-input" placeholder="Search or Filter results"-->
            <!--                                      debounce="500"></b-form-input>-->
          </div>
        </template>
      </tabs>
    </div>
    <vue-context ref="menuRFQs"
                 style="width: 194px"
                 :lazy="true"
                 class="supp-rfqs-context"
                 @close="contextClose"
                 @open="contextOpen"
                 :closeOnClick="false"
                 :closeOnScroll="true">
      <template slot-scope="item">
        <b-button-toolbar key-nav>
          <b-button-group v-if="item && item.data && item.data.row" vertical style="width: 140px"
                          class="parts-order-view__context-menu">
            <b-button
                @click="viewRfq(item.data.row)"
                class="text-left font-12 font-normal" variant="outline-primary" block
            >View RFQ
            </b-button>
            <b-button
                v-if="(activeTab === 'New' || (activeTab === 'All' && item.data.row.status === 'New')) && !item.data.row.wasRead"
                @click="markAsRead(item.data.row.id)"
                class="text-left font-12 font-normal" variant="outline-primary" block
            > Mark as Read
            </b-button>
            <b-button
                @click="openNewTab(item.data.row)"
                class="text-left font-12 font-normal" variant="outline-primary" block
            >Open in New Tab
            </b-button>
            <div
                @mouseover="isOpenSecondContextLevel = true; isOpenDatePicker = false"
                @mouseleave="isOpenSecondContextLevel = false"
            >
              <b-button
                  class="text-left font-12 font-normal d-flex align-items-center justify-content-between pr-0"
                  variant="outline-primary" block
              >
                Assign To
                <i class="bx bx-chevron-right text-right"></i>
              </b-button>
              <AssignTo
                  v-if="isOpenSecondContextLevel"
                  :is-reverse="assignedToColumnReverse"
                  :users="computedUsersForAssignedFiltered(item.data.row)"
                  :style="{
                      top: getTopForContext(item),
                      bottom: getBottomForContext + 'px',
                    }"
                  :className="'users-context-level'"
                  @click="user => clickOnAssignedTo(item.data.row, user)"
                  class="assing-to-position-top"
              ></AssignTo>
            </div>

            <div v-if="isHasOEMGEN && item.data.row.status == 'New' && !item.data.row.scheduleTime"
                 @mouseover="openScheduleContext(item.data.row)"
                 @mouseleave="closeScheduleContext"
            >
              <b-button
                  class="text-left font-12 font-normal d-flex align-items-center justify-content-between pr-0"
                  variant="outline-primary" block
              >
                Schedule Submit Quote
                <i class="bx bx-chevron-right text-right"></i>
              </b-button>
              <div v-if="isOpenSecondScheduleTimeContextLevel || isOpenDatePicker"
                   class="schedule-context-level schedule-context-level-fixed">
                <p class="schedule-text f-12 font-normal">Time & Date</p>
                <div @mouseover="onMouseOver" class="">
                  <date-picker v-model="scheduleDateTime" :placeHolderText="'hh:mmA || dd/mm/yyyy'"
                               :isDateTime="true"></date-picker>
                </div>
                <p class="schedule-text f-12 font-normal">Scheduled Submission</p>
                <p v-if="scheduleDateTime" class="schedule-text f-12 font-normal">{{ scheduleDateTime }} <i
                    @click="saveSchedule(item.data.row)" class="bx bx-save save-comment-icon"></i></p>
                <p v-else class="schedule-text f-12 font-normal">No Time Scheduled</p>
              </div>
            </div>

            <b-button
                v-else-if="isHasOEMGEN && item.data.row.status == 'New'"
                style="height: 46px"
                @click="saveSchedule(item.data.row, true)"
                class="text-left font-12 font-normal" variant="outline-primary" block
            >Remove Schd Submit Quote
            </b-button>

            <div
                @mouseover="isOpenSecondPriorityContextLevel = true; isOpenDatePicker = false"
                @mouseleave="isOpenSecondPriorityContextLevel = false"
            >
              <b-button
                  class="text-left font-12 font-normal d-flex align-items-center justify-content-between pr-0"
                  variant="outline-primary" block
              >
                Set Priority
                <i class="bx bx-chevron-right text-right"></i>
              </b-button>
              <div v-if="isOpenSecondPriorityContextLevel"
                   :class="{
                    'priority-context-level-not-oem': !isHasOEMGEN,
                    'priority-context-level-fixed' : isHasOEMGEN && !((activeTab === 'New' || (activeTab === 'All' && item.data.row.status === 'New')) && !item.data.row.wasRead),
                    'priority-context-level-not-oem-fixed' : !isHasOEMGEN && !((activeTab === 'New' || (activeTab === 'All' && item.data.row.status === 'New')) && !item.data.row.wasRead),
                    'priority-context-level-order-processed': (activeTab === 'Order Processed' && !item.data.row.wasRead)
                    }"
                   class="priority-context-level">
                <b-button @click="setPriority(item.data.row.id, 'High')"
                          class="text-left font-12 font-normal d-flex" variant="outline-primary" block>
                  <span class="status-color high-color"></span>
                  <div class="">High</div>
                </b-button>
                <b-button @click="setPriority(item.data.row.id, 'Medium')"
                          class="text-left font-12 font-normal d-flex" variant="outline-primary" block>
                  <span class="status-color medium-color"></span>
                  <div class="">Medium</div>
                </b-button>
                <b-button @click="setPriority(item.data.row.id, 'Low')"
                          class="text-left font-12 font-normal d-flex" variant="outline-primary" block>
                  <span class="status-color low-color"></span>
                  <div class="">Low</div>
                </b-button>
                <b-button @click="setPriority(item.data.row.id, 'On Hold')"
                          class="text-left font-12 font-normal d-flex" variant="outline-primary" block>
                  <span class="status-color on-hold-color"></span>
                  <div class="">On Hold</div>
                </b-button>
                <b-button v-if="!item.data.row.priority || item.data.row.priority !== 'None'"
                          @click="setPriority(item.data.row.id, 'None')"
                          class="text-left font-12 font-normal d-flex" variant="outline-primary" block>
                  <span class="status-color undo-priority-color"></span>
                  <div class="">Undo Priority</div>
                </b-button>
              </div>
            </div>

           <b-button v-if="
              activeTab !== 'Closed' &&
              activeTab !== 'Won' &&
              activeTab !== 'Order Processed' &&
              !(activeTab === 'All' && item && item.data && item.data.row && (item.data.row.status === 'Closed' || item.data.row.status === 'Won'  || item.data.row.status === 'Ordered'))"
                      class="text-left font-12 font-normal" @click="closeRFQ(item.data.row)"
                      variant="outline-primary" block>Close RFQ
            </b-button>
            <b-button v-if="activeTab === 'Won' || (activeTab === 'All' && item && item.data && item.data.row && item.data.row.status === 'Won')"
                      class="text-left font-12 font-normal"
                      @click="processOrder(item.data.row)" variant="outline-primary" block>Process Order
            </b-button>
            <b-button v-if="activeTab === 'Closed' || (activeTab === 'All' && item && item.data && item.data.row && item.data.row.status === 'Closed')" class="text-left font-12 font-normal"
                      @click="deleteQuote(item.data.row)" variant="outline-primary" block>Delete RFQ
            </b-button>
          </b-button-group>
        </b-button-toolbar>
      </template>
    </vue-context>
  </div>
</template>
<script>
/*eslint-disable */
import {mapGetters, mapActions} from 'vuex'
import notificationOnTable from '@/components/notification-on-table.vue'
import BlockPagination from '../../../components/utility/block-pagination'
import SearchFilter from '../../../components/utility/search-filter/search-filter'
import moment from 'moment'
import {VueContext} from 'vue-context'
import Axios from 'axios'
import * as dayjs from 'dayjs'
import NProgress from 'nprogress'
import DatePicker from '../../../components/datePicker'
import _ from 'lodash'
import AssignTo from '../../../components/assign-to'
import WindowNotification from "@/components/utility/window-notification.vue";

export default {
  name: 'rfqs',
  data: function () {
    return {
      widthInputSearch: 350,
      // filter: '',
      activeTab: '',
      usersFilter: '',
      searchFilter2: '',
      isShowBidders: false,
      isOpenDatePicker: false,
      newTableSortBy: 'dateDue',
      newTableSortDesc: true,
      searchFilter: '',
      scheduleDateTime: null,
      isMounted: false,
      isOpenSecondContextLevel: false,
      isOpenSecondScheduleTimeContextLevel: false,
      isOpenSecondPriorityContextLevel: false,
      assignedToColumnReverse: false,
      getBottomForContext: null,
      mouseY: 0,
      contextOpenEvent: null,
      allRFQsFiltered: 0,
      newRFQsFiltered: 0,
      pricedRFQsFiltered: 0,
      wonRFQsFiltered: 0,
      orderRFQsFiltered: 0,
      closedRFQsFiltered: 0,
      lostRFQsFiltered: 0,
      allFields: [
        {label: 'Request Nbr', key: 'number', sortable: true, tdClass: 'clickable', thClass: ''},
        {label: 'Quote Number', key: 'supplierQuoteNumber', sortable: true, tdClass: 'clickable number', thClass: ''},
        {label: 'Repairer Quote Nbr', key: 'repairerQuoteNumber', sortable: true, tdClass: 'clickable number', thClass: ''},
        {label: 'Type', key: 'rfqToSupplierType', sortable: true, tdClass: 'clickable', thClass: ''},
        {
          label: 'Customer Name',
          key: 'customerName',
          sortable: true,
          tdClass: 'clickable customer-name number',
          thClass: ''
        },
        {label: 'MAKE', key: 'make', sortable: true, tdClass: 'clickable number', thClass: ''},
        {label: 'MODEL', key: 'model', sortable: true, tdClass: 'clickable number', thClass: ''},
        {label: 'Notifications', key: 'notifications', sortable: false, tdClass: 'clickable notification-column', thClass: ''},
        {label: 'Date Received', key: 'dateReceived', sortable: true, tdClass: 'clickable ', thClass: ''},
        {label: 'Due Date : Time', key: 'dateDue', sortable: true, tdClass: 'clickable number', thClass: ''},
        {label: 'Priority', key: 'priority', sortable: true, tdClass: 'clickable number', thClass: ''},
        {label: 'Assigned To', key: 'assignedTo', sortable: true, tdClass: 'clickable number', thClass: ''},
        {label: 'Currently Viewing', key: 'viewerUserList', sortable: true, tdClass: 'clickable number', thClass: ''},
        {label: 'Nbr Parts in RFQ', key: 'qtyPartsInRfq', sortable: true, tdClass: 'clickable number', thClass: ''},
      ],
      newFields: [
        {label: 'Request Nbr', key: 'number', sortable: true, tdClass: 'clickable', thClass: ''},
        {label: 'Quote Number', key: 'supplierQuoteNumber', sortable: true, tdClass: 'clickable number', thClass: ''},
        {label: 'Repairer Quote Nbr', key: 'repairerQuoteNumber', sortable: true, tdClass: 'clickable number', thClass: ''},
        {label: 'Type', key: 'rfqToSupplierType', sortable: true, tdClass: 'clickable', thClass: ''},
        {
          label: 'Customer Name',
          key: 'customerName',
          sortable: true,
          tdClass: 'clickable customer-name number',
          thClass: ''
        },
        {label: 'MAKE', key: 'make', sortable: true, tdClass: 'clickable number', thClass: ''},
        {label: 'MODEL', key: 'model', sortable: true, tdClass: 'clickable number', thClass: ''},
        {label: 'Notifications', key: 'notifications', sortable: false, tdClass: 'clickable notification-column', thClass: ''},
        {label: 'Due Date : Time', key: 'dateDue', sortable: true, tdClass: 'clickable number', thClass: ''},
        {label: 'Bidders', key: 'bidders', sortable: true, tdClass: 'clickable number bidders-item', thClass: 'bidders-item'},
        {label: 'Priority', key: 'priority', sortable: true, tdClass: 'clickable number', thClass: ''},
        {label: 'Assigned To', key: 'assignedTo', sortable: true, tdClass: 'clickable number', thClass: ''},
        {label: 'Currently Viewing', key: 'viewerUserList', sortable: true, tdClass: 'clickable number', thClass: ''},
        {label: 'Nbr Parts in RFQ', key: 'qtyPartsInRfq', sortable: true, tdClass: 'clickable number', thClass: ''},
      ],
      pricedFields: [
        {label: 'Request Nbr', key: 'number', sortable: true, tdClass: 'clickable', thClass: ''},
        {label: 'Quote Number', key: 'supplierQuoteNumber', sortable: true, tdClass: 'clickable number', thClass: ''},
        {label: 'Repairer Quote Nbr', key: 'repairerQuoteNumber', sortable: true, tdClass: 'clickable number', thClass: ''},
        {label: 'Type', key: 'rfqToSupplierType', sortable: true, tdClass: 'clickable', thClass: ''},
        {
          label: 'Customer Name',
          key: 'customerName',
          sortable: true,
          tdClass: 'clickable customer-name number',
          thClass: ''
        },
        {label: 'MAKE', key: 'make', sortable: true, tdClass: 'clickable number', thClass: ''},
        {label: 'MODEL', key: 'model', sortable: true, tdClass: 'clickable number', thClass: ''},
        {label: 'Notifications', key: 'notifications', sortable: false, tdClass: 'clickable notification-column', thClass: ''},
        {label: 'Due Date : Time',
          key: 'dateDue',
          sortable: true,
          sortByFormatted: (value, key, item) => {
            return item.rfqUpdateRequested === 'UpdateRequested' ? '99999999999' : item.dateDue;
          },
          tdClass: 'clickable number',
          thClass: ''},
        {label: 'Bidders', key: 'bidders', sortable: true, tdClass: 'clickable number bidders-item', thClass: 'bidders-item'},
        {label: 'Priority', key: 'priority', sortable: true, tdClass: 'clickable number', thClass: ''},
        {label: 'Assigned To', key: 'assignedTo', sortable: true, tdClass: 'clickable number', thClass: ''},
        {label: 'Currently Viewing', key: 'viewerUserList', sortable: true, tdClass: 'clickable number', thClass: ''},
        {label: 'Nbr Parts in RFQ', key: 'qtyPartsInRfq', sortable: true, tdClass: 'clickable number', thClass: ''},
        {label: 'Total Value Quoted', key: 'totalValueQuoted', sortable: true, tdClass: 'clickable number', thClass: ''},

      ],
      lostFields: [
        {label: 'Request Nbr', key: 'number', sortable: true, tdClass: 'clickable', thClass: ''},
        {label: 'Quote Number', key: 'supplierQuoteNumber', sortable: true, tdClass: 'clickable number', thClass: ''},
        {label: 'Repairer Quote Nbr', key: 'repairerQuoteNumber', sortable: true, tdClass: 'clickable number', thClass: ''},
        {label: 'Type', key: 'rfqToSupplierType', sortable: true, tdClass: 'clickable', thClass: ''},
        {
          label: 'Customer Name',
          key: 'customerName',
          sortable: true,
          tdClass: 'clickable customer-name number',
          thClass: ''
        },
        {label: 'MAKE', key: 'make', sortable: true, tdClass: 'clickable number', thClass: ''},
        {label: 'MODEL', key: 'model', sortable: true, tdClass: 'clickable number', thClass: ''},
        {label: 'Notifications', key: 'notifications', sortable: false, tdClass: 'clickable notification-column', thClass: ''},
        {label: 'Due Date : Time', key: 'dateDue', sortable: true, tdClass: 'clickable number', thClass: ''},
        {label: 'Priority', key: 'priority', sortable: true, tdClass: 'clickable number', thClass: ''},
        {label: 'Assigned To', key: 'assignedTo', sortable: true, tdClass: 'clickable ', thClass: ''},
        {label: 'Currently Viewing', key: 'viewerUserList', sortable: true, tdClass: 'clickable number', thClass: ''},
        {label: 'Nbr Parts in RFQ', key: 'qtyPartsInRfq', sortable: true, tdClass: 'clickable number', thClass: ''},
        {label: 'Total Value Quoted', key: 'totalValueQuoted', sortable: true, tdClass: 'clickable number', thClass: ''},
      ],
      wonFields: [
        {label: 'Request Nbr', key: 'number', sortable: true, tdClass: 'clickable', thClass: ''},
        {label: 'Quote Number', key: 'supplierQuoteNumber', sortable: true, tdClass: 'clickable number', thClass: ''},
        {label: 'Repairer Quote Nbr', key: 'repairerQuoteNumber', sortable: true, tdClass: 'clickable number', thClass: ''},
        {label: 'Type', key: 'rfqToSupplierType', sortable: true, tdClass: 'clickable', thClass: ''},
        {
          label: 'Customer Name',
          key: 'customerName',
          sortable: true,
          tdClass: 'clickable customer-name number',
          thClass: ''
        },
        {label: 'MAKE', key: 'make', sortable: true, tdClass: 'clickable number', thClass: ''},
        {label: 'MODEL', key: 'model', sortable: true, tdClass: 'clickable number', thClass: ''},
        {label: 'Notifications', key: 'notifications', sortable: false, tdClass: 'clickable notification-column', thClass: ''},
        {label: 'Due Date : Time', key: 'dateDue', sortable: true, tdClass: 'clickable number', thClass: ''},
        {label: 'Priority', key: 'priority', sortable: true, tdClass: 'clickable number', thClass: ''},
        {label: 'Assigned To', key: 'assignedTo', sortable: true, tdClass: 'clickable number', thClass: ''},
        {label: 'Currently Viewing', key: 'viewerUserList', sortable: true, tdClass: 'clickable number', thClass: ''},
        {label: 'Nbr Parts in RFQ', key: 'qtyPartsInRfq', sortable: true, tdClass: 'clickable number', thClass: ''},
        {label: 'Total Value Quoted', key: 'totalValueQuoted', sortable: true, tdClass: 'clickable number', thClass: ''},
        {label: 'Total Value Won', key: 'totalValueWon', sortable: true, tdClass: 'clickable number', thClass: ''},
        {label: 'Lost Diff %', key: 'lostDiff', sortable: true, tdClass: 'clickable number', thClass: ''},
      ],
      orderedFields: [
        {label: 'Request Nbr', key: 'number', sortable: true, tdClass: 'clickable', thClass: ''},
        {label: 'Quote Number', key: 'supplierQuoteNumber', sortable: true, tdClass: 'clickable number', thClass: ''},
        {label: 'Repairer Quote Nbr', key: 'repairerQuoteNumber', sortable: true, tdClass: 'clickable number', thClass: ''},
        {label: 'Type', key: 'rfqToSupplierType', sortable: true, tdClass: 'clickable', thClass: ''},
        {
          label: 'Customer Name',
          key: 'customerName',
          sortable: true,
          tdClass: 'clickable customer-name number',
          thClass: ''
        },
        {label: 'MAKE', key: 'make', sortable: true, tdClass: 'clickable number', thClass: ''},
        {label: 'MODEL', key: 'model', sortable: true, tdClass: 'clickable number', thClass: ''},
        {label: 'Notifications', key: 'notifications', sortable: false, tdClass: 'clickable notification-column', thClass: ''},
        {label: 'Date Received', key: 'dateReceived', sortable: true, tdClass: 'clickable ', thClass: ''},
        {label: 'Due Date : Time', key: 'dateDue', sortable: true, tdClass: 'clickable number', thClass: ''},
        {label: 'Priority', key: 'priority', sortable: true, tdClass: 'clickable number', thClass: ''},
        {label: 'Assigned To', key: 'assignedTo', sortable: true, tdClass: 'clickable number', thClass: ''},
        {label: 'Currently Viewing', key: 'viewerUserList', sortable: true, tdClass: 'clickable number', thClass: ''},
        {label: 'Nbr Parts in RFQ', key: 'qtyPartsInRfq', sortable: true, tdClass: 'clickable number', thClass: ''},
        {label: 'Total Value Quoted', key: 'totalValueQuoted', sortable: true, tdClass: 'clickable number', thClass: ''},
        {label: 'Total Value Won', key: 'totalValueWon', sortable: true, tdClass: 'clickable number', thClass: ''},
        {label: 'Lost Diff %', key: 'lostDiff', sortable: true, tdClass: 'clickable number', thClass: ''},
      ],
      closedFields: [
        {label: 'Request Nbr', key: 'number', sortable: true, tdClass: 'clickable', thClass: ''},
        {label: 'Quote Number', key: 'supplierQuoteNumber', sortable: true, tdClass: 'clickable number', thClass: ''},
        {label: 'Repairer Quote Nbr', key: 'repairerQuoteNumber', sortable: true, tdClass: 'clickable number', thClass: ''},
        {label: 'Type', key: 'rfqToSupplierType', sortable: true, tdClass: 'clickable', thClass: ''},
        {
          label: 'Customer Name',
          key: 'customerName',
          sortable: true,
          tdClass: 'clickable customer-name number',
          thClass: ''
        },
        {label: 'MAKE', key: 'make', sortable: true, tdClass: 'clickable number', thClass: ''},
        {label: 'MODEL', key: 'model', sortable: true, tdClass: 'clickable number', thClass: ''},
        {label: 'Notifications', key: 'notifications', sortable: false, tdClass: 'clickable notification-column', thClass: ''},
        {label: 'Date Received', key: 'dateReceived', sortable: true, tdClass: 'clickable ', thClass: ''},
        {label: 'Due Date : Time', key: 'dateDue', sortable: true, tdClass: 'clickable number', thClass: ''},
        {label: 'Priority', key: 'priority', sortable: true, tdClass: 'clickable number', thClass: ''},
        {label: 'Assigned To', key: 'assignedTo', sortable: true, tdClass: 'clickable number', thClass: ''},
        {label: 'Currently Viewing', key: 'viewerUserList', sortable: true, tdClass: 'clickable number', thClass: ''},
        {label: 'Nbr Parts in RFQ', key: 'qtyPartsInRfq', sortable: true, tdClass: 'clickable number', thClass: ''},
        {label: 'Total Value Quoted', key: 'totalValueQuoted', sortable: true, tdClass: 'clickable number', thClass: ''},
      ],
    }
  },
  methods: {
    ...mapActions({
      setFilter: 'supplier/rfq/setFilter',
    }),
    redirectToRfqView(itm) {
      this.setFilter('')
      this.$router.push({ name: 'RefView', params: { rfqId: itm.value, number: itm.number } })
    },
    onEnter() {
      setTimeout(() => {
        let item =  this.additionalMenu[0]

        if (item && !_.isEmpty(this.searchFilter2)) {
          this.setFilter('')
          this.$router.push({ name: 'RefView', params: { rfqId: item.value, number: item.number } })
        }
      }, 500)
    },
    changedCurrentPagePriced(value) {
      this.$store.commit('supplier/rfq/supplierRFQPricedCurrentPage', value);
      this.isShowBidders = false
      setTimeout(() => {
        this.isShowBidders = true
      }, 200)
    },
    changedCurrentPageNew(value) {
      this.$store.commit('supplier/rfq/supplierRFQNewCurrentPage', value);
      this.isShowBidders = false
      setTimeout(() => {
        this.isShowBidders = true
      }, 200)
    },
    addTwoHours(v) {
      v = new Date(v).getTime();
      return new Date(v + 2 * 60 * 60 * 1000)
    },
    getBidderTooltip(bidder) {
      let r = bidder.supplier + ' ' + '<br /><b>' + bidder.status + '</b>'
      if (bidder.declineReason) {
        r += `<br/> ${bidder.declineReason}`
      }
      return r
    },
    getTopForContext(item) {
      if (this.activeTab === 'New' || (this.activeTab === 'All' && item.data.row.status === 'New')) {
        if (this.isWasRead(item) && this.mouseY + 500 > document.body.offsetHeight) {
          this.assignedToColumnReverse = true
          this.getBottomForContext = 133
          return 'auto'
        } else if (this.mouseY + 500 > document.body.offsetHeight) {
          this.assignedToColumnReverse = true
          this.getBottomForContext = 134
          return 'auto'
        }
        this.getBottomForContext = 'auto'
        this.assignedToColumnReverse = false
        return '122px'
      } else if (!(this.activeTab === 'Order Processed' || (this.activeTab === 'All' && item.data.row.status === 'Ordered'))) {
        if (this.mouseY + 500 > document.body.offsetHeight) {
          console.log(this.assignedToColumnReverse)
          this.assignedToColumnReverse = true
          this.getBottomForContext = 87
          return 'auto'
        }
        this.assignedToColumnReverse = false
        this.getBottomForContext = 'auto'
        return '82px'
      } else if (this.activeTab === 'Order Processed') {
        if (this.mouseY + 500 > document.body.offsetHeight) {
          console.log(this.assignedToColumnReverse)
          this.assignedToColumnReverse = true
          this.getBottomForContext = 47
          return 'auto'
        }
        this.assignedToColumnReverse = false
        this.getBottomForContext = 'auto'
        return '82px'
      }

      return null
    },
    isWasRead (item) {
      return !((this.activeTab === 'New' || (this.activeTab === 'All' && item.data.row.status === 'New')) && !item.data.row.wasRead)
    },
    onMouseOver() {
      setTimeout(() => {
        this.isOpenDatePicker = true
      }, 10)
    },
    openScheduleContext() {
      if (!this.isOpenSecondScheduleTimeContextLevel && !this.isOpenDatePicker) {
        this.scheduleDateTime = null;
      }
      this.isOpenSecondScheduleTimeContextLevel = true
      this.isOpenDatePicker = false
    },
    closeScheduleContext() {
      this.isOpenSecondScheduleTimeContextLevel = false
    },
    saveSchedule(row, isRemove = false) {
      if (!isRemove && _.isEmpty(this.scheduleDateTime)) {
        this.$toast.error('Schedule Date and Time must not be empty')
        return
      }
      let scheduleTime = isRemove ? null : dayjs(this.scheduleDateTime, 'hh:mmA || DD/MM/YYYY').format('DD/MM/YYYY hh:mmA')
      let scheduleTime2 = isRemove ? null : dayjs(this.scheduleDateTime, 'hh:mmA || DD/MM/YYYY').format('YYYY-MM-DDTHH:mm:ssZ')
      Axios.post(`/ir/supplier/rfq/${row.id}/schedule-submit-quote`, {
        scheduleTime: scheduleTime,
        scheduleTime2: scheduleTime2,
      })
          .then((r) => {
            if (r?.data?._status) {
              this.$store.commit('supplier/rfq/setScheduleTime', {
                id: row.id,
                scheduleTime: !isRemove ? dayjs(scheduleTime, 'DD/MM/YYYY hh:mmA').format() : null
              })
            }
          })
      this.$refs.menuRFQs.close()
    },
    getPriorityClass(priority) {
      if (priority === 'High') {
        return 'high-color'
      } else if (priority === 'Medium') {
        return 'medium-color'
      } else if (priority === 'Low') {
        return 'low-color'
      } else if (priority === 'On Hold') {
        return 'on-hold-color'
      } else {
        return ''
      }
    },
    setPriority(id, priority) {
      Axios.post(`/ir/supplier/rfq/${id}/set-priority`, {
        priority: priority,
      })
          .then((r) => {
            if (r?.data?._status) {
              this.$store.commit('supplier/rfq/setPriority', {id: id, priority: priority})
            }
          })
      this.$refs.menuRFQs.close()
    },
    markAsRead(id) {
      Axios.post(`/ir/supplier/rfq/${id}/set-mark-as-read`, {
        wasRead: true,
      })
          .then((r) => {
            if (r?.data?._status) {
              this.$store.commit('supplier/rfq/markAsRead', {id: id, wasRead: true})
            }
          })
      this.$refs.menuRFQs.close()
    },
    updateNewTable(id) {
      if (this.isMounted) {
        this.$store.dispatch('supplier/rfq/init', {}, {root: true})
        this.$refs.newTable.refresh()
      }
    },
    computedUsersForAssignedFiltered(row) {
      let users = []
      if (!row.assignedTo) {
        users = this.computedUsers.slice()
      } else {
        users = _.filter(this.computedUsersForAssigned, (user) => {
          return Number(user.id) !== Number(row.assignedTo.id)
        })
      }

      if (this.isMultipleLocations) {
        users = _.filter(_.cloneDeep(users), (u) => {
          return  u?.businessLocation?.includes(row.supplierId) || u?.name === 'None'
        });
      }

      return _.filter(users, (u) => {
        return _.includes(_.trim(_.toLower(u.name)), _.trim(_.toLower(this.usersFilter)))
      })
    },
    changedTab: function (obj) {
      this.activeTab = obj.tab.name
    },
    deleteQuote(row) {
      if (!confirm('Are you sure?')) {
        return
      }
      Axios.post('/ir/supplier/rfq/' + row.id + '/delete', {
        rfqId: row.id
      }).then(response => {
        this.$toast.success('Quote has been delete')
        this.$store.commit('supplier/rfq/supplierRFQDelete', {id: row.id})
        this.$store.dispatch('supplier/rfq/init', {}, {root: true})
      }).catch(error => {
        this.$toast.error('Quote haven\'t been delete')
        console.log(error)
      })
      this.$refs.menuRFQs.close()
    },
    processOrder(row) {
      let id = row.id
      Axios.post('/ir/supplier/rfq/' + id + '/process-order', {rfqId: id})
          .then(response => {
            if (response.data && response.data.status && response.data.status == true) {
              this.$toast.success('Order has been processed')
              this.$store.dispatch('supplier/rfq/init', {}, {root: true})
              return
            }
            this.$toast.error('Order hasn`t been processed')
          })
          .catch(error => {
            console.log(error)
            this.$toast.error('Order hasn`t been processed')
          })
      this.$refs.menuRFQs.close()
    },
    closeRFQ(row) {
      Axios.post('/ir/supplier/rfq/' + row.id + '/close', {
        rfqId: row.id
      }).then(response => {
        this.$toast.success('Quote has been closed')
        this.$store.dispatch('supplier/rfq/init', {}, {root: true})
      }).catch(error => {
        this.$toast.error('Quote haven\'t been closed')
        console.log(error)
      })
      this.$refs.menuRFQs.close()
    },
    isBlinkingRed(item) {
      if (item.status !== 'New') {
        return false;
      }
      if (!this.getSettings?.rfq?.responseTimeNotification?.active || item.status !== 'New') {
        return false;
      }

      let settingsValue = this?.companyInfo?.settings?.rfq?.timeNotifications?.closingSoon

      let time = Number(settingsValue.timeLeft) ? Number(settingsValue.timeLeft) : 10
      // console.log(settingsValue, time, 'time')
      // let due = moment(item.dateDue).format('YYYY-MM-DD HH:mm:ss')
      let diff = dayjs(item.dateDue).diff(dayjs())
      if (diff <= time * 60 * 1000 && diff >= 0) {
        return true
      }
      return false
    },
    isBlinkingOrange(item) {
      //let due = moment(item.dateDue).format('YYYY-MM-DD HH:mm:ss')
      // console.log(item, item.number, due, 'due')
      if (!this.getSettings?.rfq?.responseTimeNotification?.active || item.status !== 'New') {
        return false;
      }

      let settingsValue1 = this?.companyInfo?.settings?.rfq?.timeNotifications?.closingShortly;
      let settingsValue2 = this?.companyInfo?.settings?.rfq?.timeNotifications?.closingSoon;

      let time = Number(settingsValue1.timeLeft) ? Number(settingsValue1.timeLeft) : 20
      let time2 = Number(settingsValue2.timeLeft) ? Number(settingsValue2.timeLeft) : 10

      let diff = dayjs(item.dateDue).diff(dayjs())
      if (diff <= time * 60 * 1000 && diff > time2 * 60 * 1000) {
        return true
      }
      return false
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (this.isBlinkingRed(item)) {
        if (this?.companyInfo?.settings?.rfq?.timeNotifications?.closingSoon?.displayAs === 'Background') {
          return 'background-red-straboscope'
        }
        return 'text-red-straboscope'
      } else if (this.isBlinkingOrange(item)) {
        if (this?.companyInfo?.settings?.rfq?.timeNotifications?.closingShortly?.displayAs === 'Background') {
          return 'background-orange-straboscope'
        }
        return 'text-orange-straboscope'
      }
    },
    viewRfq(row) {
      this.$router.push({name: 'SupplierRFQView', params: {rfq_id: row.id, tab: this.activeTab, isNeedOpenChat: row.hasUnreadMessages}})
      this.$refs.menuRFQs.close()
    },
    openNewTab(row) {
      console.log(window.location, 'row', row)
      window.open(window.location.origin + '/s/rfq/' + row.id, '_blank')
      this.$refs.menuRFQs.close()
    },
    getUserNameById(userId) {
      let user = _.find(this.computedAvailableUsers, (u) => {
        return Number(u.id) === Number(userId)
      })
      if (user) {
        return user.name
      }
    },
    clickOnAssignedTo(row, u) {
      let user = _.cloneDeep(u)
      if (Number(user.id) === Number(this.currentUser.id)) {
        user.name = this.currentUser.fullName
      }
      Axios.post('ir/supplier/rfq/' + row.id + '/assign-user', {userId: user.id})
          .then(response => {
            if (response.data._status) {
              this.$store.commit('supplier/rfq/setAssignedUser', {id: row.id, user: user})
            }
          })
      this.$refs.menuRFQs.close()
      console.log('clickOnAssignedTo', row, user)
    },
    contextOpen: function (event) {
      this.contextOpenEvent = event
      this.mouseY = event.clientY;
    },
    contextClose: function () {
      this.isOpenSecondContextLevel = false
      this.isOpenSecondPriorityContextLevel = false
      this.isOpenDatePicker = false
      this.isOpenSecondScheduleTimeContextLevel = false
      this.usersFilter = '';
      let event = this.contextOpenEvent
      if (event) {
        let row = event.target.parentElement
        let rows = row.parentElement.rows
        while (!rows) {
          row = row.parentElement
          rows = row.parentElement.rows
        }
        if (rows) {
          for (let i = 0; i < rows.length; i++) {
            let elem = rows[i]
            elem.style.backgroundColor = ''
          }
        }
      }
    },
    openMenuForRFQs(row, index, event) {
      event.preventDefault()
      let tableRow = event.target.parentElement
      let rows = tableRow.parentElement.rows
      while (!rows) {
        tableRow = tableRow.parentElement
        rows = tableRow.parentElement.rows
      }
      if (rows) {
        for (let i = 0; i < rows.length; i++) {
          let elem = rows[i]
          elem.style.backgroundColor = ''
        }
      }
      tableRow.style.backgroundColor = 'rgb(233,248,249)'
      this.$refs.menuRFQs.open(event, {row: row, index: index})
    },
    filterTable(row, filter) {
      let formattedFilter = filter.toLowerCase()
      let dateDue = row.dateDue ? moment(row.dateDue).format('DD/MM/YYY || hh:mmA') : ''
      let dateReceived = row.dateReceived ? moment(row.dateReceived).format('DD/MM/YYYY') : ''

      if (dateDue && _.includes(_.toLower(dateDue), formattedFilter)) {
        return true
      } else if (dateReceived && _.includes(_.toLower(dateReceived), formattedFilter)) {
        return true
      } else if (row.partsNumbers && _.includes(_.toLower(row.partsNumbers), formattedFilter)) {
        return true
      } else if (row.customerName && _.includes(_.toLower(row.customerName), formattedFilter)) {
        return true
      } else if (row.estimator && _.includes(_.toLower(row.estimator), formattedFilter)) {
        return true
      } else if (row.number && _.includes(_.toLower(row.number), formattedFilter)) {
        return true
      } else if (row.orderNumber && _.includes(_.toLower(row.orderNumber), formattedFilter)) {
        return true
      } else if (row.qty && _.includes(_.toLower(_.toString(row.qty)), formattedFilter)) {
        return true
      } else if (row.vin && _.includes(_.toLower(row.vin), formattedFilter)) {
        return true
      } else if (row.supplierQuoteNumber && _.includes(_.toLower(row.supplierQuoteNumber), formattedFilter)) {
        return true
      } else if (row.repairerQuoteNumber && _.includes(_.toLower(row.repairerQuoteNumber), formattedFilter)) {
        return true
      }

    },
    onSearchFilterChange(filters) {
      console.log(filters, 'filters')
      this.$store.commit('supplier/rfq/setSearchFilterObj', filters)
      if (filters && filters[0] && filters[0].search) {
        this.setFilter(filters[0].search)
        // this.filter = filters.search;
      } else {
        this.setFilter('')
        // this.filter = '';
      }

      this.isShowBidders = false
      setTimeout(() => {
        this.isShowBidders = true
      }, 200)
      // console.log('onSearchFilterChange', filters);
    },
    clickRFQRow: function (item, index, event) {
      this.$router.push({name: 'SupplierRFQView', params: {rfq_id: item.id, tab: this.activeTab, isNeedOpenChat: item.hasUnreadMessages}})
      return
    },
    getFilteredRFQs(rfqs) {
      if (this.isPartNumberFilterActive) {
        let formattedFilter = this.computedPartNumberFilter
        rfqs = _.filter(rfqs, rfq => {
          let bool = false
          _.forEach(formattedFilter, (nbr) => {
            if (rfq.partsNumbers && _.includes(_.toLower(rfq.partsNumbers), _.trim(_.toLower(nbr)))) {
              bool = true
            }
          })
          return bool
        })
      }
      if (this.isDateRangeFilterActive) {
        rfqs = _.filter(rfqs, rfq => {
          let time = dayjs(rfq.dateReceived).unix()
          if (rfq.dateReceived && time > 0 && time >= this.computedDateRangeFilter.start && time <= this.computedDateRangeFilter.end) {
            return true
          }
          return false
        })
      }
      if (this.isCustomerNameFilterActive) {
        rfqs = _.filter(rfqs, rfq => {
          return rfq.customerName && _.some(this.computedCustomerNameFilter, (itm) => itm == rfq.customerName)
        })
      }
      if (this.isMakeFilterActive) {
        rfqs = _.filter(rfqs, rfq => {
          return rfq.make && _.some(this.computedMakeFilter, (itm) => itm == rfq.make)
        })
      }
      if (this.isQuoteNumberFilterActive) {
        rfqs = _.filter(rfqs, rfq => {
          return rfq.supplierQuoteNumber && _.some(this.computedQuoteNumberFilter, (itm) => _.trim(itm) == _.trim(rfq.supplierQuoteNumber))
        })
      }
      if (this.isRegoFilterActive) {
        rfqs = _.filter(rfqs, rfq => {
          return rfq.rego && _.some(this.computedRegoFilter, (itm) => _.trim(itm) == _.trim(rfq.rego))
        })
      }
      if (this.isVinFilterActive) {
        rfqs = _.filter(rfqs, rfq => {
          return rfq.vin && _.some(this.computedVinFilter, (itm) => _.trim(itm) == _.trim(rfq.vin))
        })
      }
      if (this.isRepairerQuoteNumberFilterActive) {
        rfqs = _.filter(rfqs, rfq => {
          return rfq.repairerQuoteNumber && _.some(this.computedRepairerQuoteNumberFilter, (itm) => itm == rfq.repairerQuoteNumber)
        })
      }
      return rfqs
    },
  },
  computed: {
    ...mapGetters({
      gettersNewRFQs: 'supplier/rfq/getNewRFQs',
      gettersPricedRFQs: 'supplier/rfq/getPricedRFQs',
      gettersLostRFQs: 'supplier/rfq/getLostRFQs',
      gettersWonRFQs: 'supplier/rfq/getWonRFQs',
      gettersOrderedRFQs: 'supplier/rfq/getOrderedRFQs',
      gettersClosedRFQs: 'supplier/rfq/getClosedRFQs',
      gettersAllRFQs: 'supplier/rfq/getAllRfqs',
      gettersFilter: 'supplier/rfq/getFilter',
      gettersAllRFQsCurrentPage: 'supplier/rfq/getAllRFQsCurrentPage',
      gettersNewRFQsCurrentPage: 'supplier/rfq/getNewRFQsCurrentPage',
      gettersPricedRFQsCurrentPage: 'supplier/rfq/getPricedRFQsCurrentPage',
      gettersClosedRFQsCurrentPage: 'supplier/rfq/getClosedRFQsCurrentPage',
      gettersLostRFQsCurrentPage: 'supplier/rfq/getLostRFQsCurrentPage',
      gettersWonRFQsCurrentPage: 'supplier/rfq/getWonRFQsCurrentPage',
      gettersOrderedRFQsCurrentPage: 'supplier/rfq/getOrderedRFQsCurrentPage',
      gettersPerPage: 'supplier/rfq/getPerPage',
      getterSearchFilterObj: 'supplier/rfq/getSearchFilterObj',
      getterIsNeedRefreshTable: 'supplier/rfq/getIsNeedRefreshTable',
      getterToken: 'supplier/rfq/getToken',
      getterTokens: 'supplier/rfq/getTokens',
      getterAllUsers: 'users/getAllUsersForAssignedTo',
      companyInfo: 'currentCompany/getDetails',
      isHasOEMGEN: 'currentCompany/isHasOEMGEN',
      currentUser: 'currentUser/getCurrentUserInfo',
      getSettings: 'settings/getSettings',
      isMultipleLocations: 'settings/isMultipleLocations',
    }),
    isWidthInputSearch(){
      if(this.searchFilter.length * 8 + 75 > 350 && this.searchFilter.length * 8 + 75 < 600){
        this.widthInputSearch = this.searchFilter.length * 8 + 75
      }
      if(this.searchFilter.length * 8 + 75 > 600){
        this.widthInputSearch = 600
      }
      if(this.searchFilter.length * 8 + 75 < 350){
        this.widthInputSearch = 350
      }
    },
    additionalMenu() {
      if (_.isEmpty(this.searchFilter2)) {
        return []
      }
      let data = _.filter(this.gettersAllRFQs, (r) => {
        if (_.includes(this.searchFilter2, ' -')) {
          return _.startsWith(_.trim(_.toLower(r.repairerQuoteNumber)), _.trim(_.toLower(this.searchFilter2.split(' -')?.[0]))) &&  _.startsWith(_.trim(_.toLower(r.customerName)), _.trim(_.toLower(this.searchFilter2.split(' -')?.[1])))
        }
        return _.startsWith(_.trim(_.toLower(r.repairerQuoteNumber)), _.trim(_.toLower(this.searchFilter2)))
      })

      data = _.orderBy(data, [(itm) => Number(itm.id)], ['asc'])
      data = _.uniqBy(data, itm => {return Number(itm.repairerMainRfqId)})

      let result = [];
      _.forEach(data, (itm) => {
        let label = itm.repairerQuoteNumber
        if (itm.customerName) {
          label +=  ' - ' + itm.customerName
        }

        result.push({
          value: itm.repairerMainRfqId,
          number: itm.repairerQuoteNumber,
          label: label,
        })
      })
      result = _.orderBy(result, ['label'], ['asc']).slice(0, 5)
      return result
    },
    computedNewFields() {
      if (this.isHasOEMGEN) {
        return this.newFields
      } else {
        let result = _.cloneDeep(this.newFields)
        let index = _.findIndex(result, (f) => {
          return f.key === 'bidders'
        })
        if (index !== -1) {
          result.splice(index, 1)
        }
        return result
      }
    },
    computedPricedFields() {
      if (this.isHasOEMGEN) {
        return this.pricedFields
      } else {
        let result = _.cloneDeep(this.pricedFields)
        let index = _.findIndex(result, (f) => {
          return f.key === 'bidders'
        })
        if (index !== -1) {
          result.splice(index, 1)
        }
        return result
      }
    },
    computedAvailableUsers() {
      let users = _.filter(_.cloneDeep(this.getterAllUsers), (u) => {
        return u?.isActive;
      });
      return users
    },
    computedUsers() {
      let data = this.computedAvailableUsers.slice()
      let currUser = _.find(data, (user) => {
        return Number(user.id) === Number(this.currentUser.id)
      })
      if (currUser) {
        currUser.name = 'Me'
        data = _.filter(data, (itm) => {
          return Number(itm.id) !== Number(currUser.id)
        })
        data.unshift({name: 'Me', id: this.computedCurrentUser.id, isActive: this.computedCurrentUser.isActive, businessLocation: this.computedCurrentUser.businessLocation})
      }
      return data
    },
    computedCurrentUser() {
      return _.find(this.getterAllUsers, (u) => {
        return Number(u.id) === Number(this.currentUser.id)
      })
    },
    computedUsersForAssigned() {
      let data = this.computedAvailableUsers.slice()
      let currUser = _.find(data, (user) => {
        return Number(user.id) === Number(this.currentUser.id)
      })
      if (currUser) {
        currUser.name = 'Me'
        data = _.filter(data, (itm) => {
          return Number(itm.id) !== Number(currUser.id)
        })
        data.unshift({name: 'Me', id: this.computedCurrentUser.id, isActive: this.computedCurrentUser.isActive, businessLocation: this.computedCurrentUser.businessLocation})
      }
      data.unshift({name: 'None', id: null})
      // if (data) {
      // }
      return data
    },
    computedAllRFQs() {
      return this.getFilteredRFQs(this.gettersAllRFQs.slice())
    },
    computedNewRFQs() {
      let rfqs = this.gettersNewRFQs.slice()
      let directRfqs = _.filter(this.gettersNewRFQs, (itm) => {
        return itm.isSentAsDirectOrder
      });
      let pricedRfqs = _.filter(this.gettersNewRFQs, (itm) => {
        return itm.status === 'Priced'
      });
      directRfqs = _.orderBy(directRfqs, [(itm) => {
        return Number(itm.id)
      }], ['asc']);
      pricedRfqs = _.orderBy(pricedRfqs, [(itm) => {
        return Number(itm.id)
      }], ['asc']);
      rfqs = _.filter(rfqs, (itm) => {
        return !itm.isSentAsDirectOrder && itm.status !== 'Priced'
      });

      rfqs = _.orderBy(rfqs, [(item) => {
        if (!_.isEmpty(item.dateDue)) {
          return item.dateDue
        } else {
          return ''
        }
      }], ['desc'])
      rfqs = _.orderBy(rfqs, [(item) => {
        let due = moment(item.dateDue).format('YYYY-MM-DD HH:mm:ss')
        let diff = dayjs(due).diff(dayjs())
        if (diff >= 0 && diff <= 20 * 60 * 1000) {
          return diff
        }
      }], ['asc'])



      _.forEach(directRfqs, (itm) => {
        rfqs.unshift(itm);
      })

      _.forEach(pricedRfqs, (itm) => {
        rfqs.unshift(itm);
      })

      return this.getFilteredRFQs(rfqs)
    },
    computedPricedRFQs() {
      return this.getFilteredRFQs(this.gettersPricedRFQs.slice())
    },
    computedLostRFQs() {
      return this.getFilteredRFQs(this.gettersLostRFQs.slice())
    },
    computedWonRFQs() {
      return this.getFilteredRFQs(this.gettersWonRFQs.slice())
    },
    computedOrderedRFQs() {
      return this.getFilteredRFQs(this.gettersOrderedRFQs.slice())
    },
    computedClosedRFQs() {
      return this.getFilteredRFQs(this.gettersClosedRFQs.slice())
    },
    filter: {
      get() {
        return this.gettersFilter
      },
      set(value) {
        this.setFilter(value)
      }
    },
    computedToken() {
      return this.getterToken
    },
    computedMakeFilter() {
      let filter = {
        make: '',
      }
      let obj = Object.assign({}, this.searchFilterObj)
      for (let key in obj) {
        if (obj[key].make) {
          filter = obj[key]
          break
        }
      }
      filter = _.split(filter.make, ';')
      return filter
    },
    computedCustomerNameFilter() {
      let filter = {
        customer: '',
      }
      let obj = Object.assign({}, this.searchFilterObj)
      for (let key in obj) {
        if (obj[key].customer) {
          filter = obj[key]
          break
        }
      }
      filter = _.split(filter.customer, ';')
      return filter
    },
    computedDateRangeFilter() {
      let filter = {
        start: '',
        end: '',
      }
      let obj = Object.assign({}, this.searchFilterObj)
      for (let key in obj) {
        if (obj[key].dateRange && obj[key].dateRange.start && obj[key].dateRange.end) {
          filter.start = dayjs(obj[key].dateRange.start, 'DD/MM/YYYY').unix()
          filter.end = dayjs(obj[key].dateRange.end, 'DD/MM/YYYY').unix()
          break
        }
      }
      return filter
    },
    computedPartNumberFilter() {
      let filter = {
        partNumber: '',
      }
      let obj = Object.assign({}, this.searchFilterObj)
      for (let key in obj) {
        if (obj[key].partNumber) {
          filter = obj[key]
          break
        }
      }
      let formattedFilter = filter.partNumber.toLowerCase().split(';').filter(Boolean)
      return formattedFilter
    },
    computedQuoteNumberFilter() {
      let filter = {
        quoteNumber: '',
      }
      let obj = Object.assign({}, this.searchFilterObj)
      for (let key in obj) {
        if (obj[key].quoteNumber) {
          filter = obj[key]
          break
        }
      }
      filter = _.split(filter.quoteNumber, ';')
      return filter
    },
    computedVinFilter() {
      let filter = {
        vin: '',
      }
      let obj = Object.assign({}, this.searchFilterObj)
      for (let key in obj) {
        if (obj[key].vin) {
          filter = obj[key]
          break
        }
      }
      filter = _.split(filter.vin, ';')
      return filter
    },
    computedRegoFilter() {
      let filter = {
        rego: '',
      }
      let obj = Object.assign({}, this.searchFilterObj)
      for (let key in obj) {
        if (obj[key].rego) {
          filter = obj[key]
          break
        }
      }
      filter = _.split(filter.rego, ';')
      return filter
    },
    computedRepairerQuoteNumberFilter() {
      let filter = {
        repairerQuoteNbr: '',
      }
      let obj = Object.assign({}, this.searchFilterObj)
      for (let key in obj) {
        if (obj[key].repairerQuoteNbr) {
          filter = obj[key]
          break
        }
      }
      filter = _.split(filter.repairerQuoteNbr, ';')
      return filter
    },
    isDateRangeFilterActive() {
      for (let key in this.searchFilterObj) {
        if (this.searchFilterObj[key].dateRange) {
          return true
        }
      }
      return false
    },
    isMakeFilterActive() {
      for (let key in this.searchFilterObj) {
        if (this.searchFilterObj[key].make) {
          return true
        }
      }
      return false
    },
    isPartNumberFilterActive() {
      for (let key in this.searchFilterObj) {
        if (this.searchFilterObj[key].partNumber) {
          return true
        }
      }
      return false
    },
    isCustomerNameFilterActive() {
      for (let key in this.searchFilterObj) {
        if (this.searchFilterObj[key].customer) {
          return true
        }
      }
      return false
    },
    isQuoteNumberFilterActive() {
      for (let key in this.searchFilterObj) {
        if (this.searchFilterObj[key].quoteNumber) {
          return true
        }
      }
      return false
    },
    isRegoFilterActive() {
      for (let key in this.searchFilterObj) {
        if (this.searchFilterObj[key].rego) {
          return true
        }
      }
      return false
    },
    isVinFilterActive() {
      for (let key in this.searchFilterObj) {
        if (this.searchFilterObj[key].vin) {
          return true
        }
      }
      return false
    },
    isRepairerQuoteNumberFilterActive() {
      for (let key in this.searchFilterObj) {
        if (this.searchFilterObj[key].repairerQuoteNbr) {
          return true
        }
      }
      return false
    },
    computedSearchFilters() {
      let regos = {};
      let vins = {}
      let partNumbers = {}
      let makes = {}
      let customers = {}
      let supplierQuoteNumbers = {}
      let repairerQuoteNumbers = {}

      this.gettersAllRFQs.forEach(rfq => {
        if (rfq.rego) {
          regos[rfq.rego.trim().toLowerCase()] = {label: rfq.rego.trim()}
        }
      })

      this.gettersAllRFQs.forEach(rfq => {
        if (rfq.vin) {
          vins[rfq.vin.trim().toLowerCase()] = {label: rfq.vin.trim()}
        }
      })
      this.gettersAllRFQs.forEach(rfq => {
        if (rfq.partsNumbers) {
          let numbers = rfq.partsNumbers.split(';')
          _.forEach(numbers, n => {
            partNumbers[n.toLowerCase().trim()] = {label: n.toLowerCase().trim()}
          })
        }
      })
      this.gettersAllRFQs.forEach(rfq => {
        if (rfq.make) {
          makes[rfq.make.trim().toLowerCase()] = {label: rfq.make.trim()}
        }
      })
      this.gettersAllRFQs.forEach(rfq => {
        if (rfq.customerName) {
          customers[rfq.customerName.trim().toLowerCase()] = {label: rfq.customerName.trim()}
        }
      })

      this.gettersAllRFQs.forEach(rfq => {
        if (rfq.supplierQuoteNumber) {
          supplierQuoteNumbers[rfq.supplierQuoteNumber.trim().toLowerCase()] = {label: rfq.supplierQuoteNumber.trim()}
        }
      })

      this.gettersAllRFQs.forEach(rfq => {
        if (rfq.repairerQuoteNumber) {
          repairerQuoteNumbers[rfq.repairerQuoteNumber.trim().toLowerCase()] = {label: rfq.repairerQuoteNumber.trim()}
        }
      })

      partNumbers = _.orderBy(Object.values(partNumbers), [(itm) => itm.label.toLowerCase().trim()], ['asc'])
      customers = _.orderBy(Object.values(customers), [(itm) => itm.label.toLowerCase().trim()], ['asc'])
      makes = _.orderBy(Object.values(makes), [(itm) => itm.label.toLowerCase().trim()], ['asc'])
      vins = _.orderBy(Object.values(vins), [(itm) => itm.label.toLowerCase().trim()], ['asc'])
      regos = _.orderBy(Object.values(regos), [(itm) => itm.label.toLowerCase().trim()], ['asc'])
      supplierQuoteNumbers = _.orderBy(Object.values(supplierQuoteNumbers), [(itm) => itm.label.toLowerCase().trim()], ['asc'])
      repairerQuoteNumbers = _.orderBy(Object.values(repairerQuoteNumbers), [(itm) => itm.label.toLowerCase().trim()], ['asc'])

      // Create date template
      // const date = [
      //   {
      //     id: 'date',
      //     label: "Date",
      //     resultObjectOnly: true,
      //     type: "Date",
      //     iconClass: "bx-calendar"
      //   }
      // ];

      // Create searchFilter filters
      return [
        {
          id: 'rego',
          label: 'Rego',
          iconClass: 'bx-user',
          options: [
            regos,
          ]
        },
        {
          id: 'vin',
          label: 'VIN',
          iconClass: 'bx-user',
          options: [
            vins,
          ]
        },
        {
          id: 'customer',
          label: 'Customers',
          iconClass: 'bx-user',
          options: [
            customers,
          ]
        },
        {
          id: 'make',
          label: 'Make',
          iconClass: 'bx-user',
          options: [
            makes,
          ]
        },
        {
          id: 'partNumber',
          label: 'Part Number',
          iconClass: 'bx-user',
          options: [
            partNumbers,
          ]
        },
        {
          id: 'quoteNumber',
          label: 'Quote Number',
          iconClass: 'bx-store',
          options: [
            supplierQuoteNumbers
          ]
        },
        {
          id: 'repairerQuoteNbr',
          label: 'Repairer Quote Nbr',
          iconClass: 'bx-store',
          options: [
            repairerQuoteNumbers
          ]
        },
        {
          id: 'dateRange',
          label: 'Date Range',
          iconClass: 'bx-store',
          options: [
            []
          ]
        }
      ]
    },
    searchFilterObj() {
      return this.getterSearchFilterObj
    },
    computedTotalCounts() {
      let counts = {
        all: 0,
        new: 0,
        priced: 0,
        lost: 0,
        won: 0,
        closed: 0,
        ordered: 0,
      }
      counts.new = this.gettersNewRFQs.length
      counts.priced = this.gettersPricedRFQs.length
      counts.lost = this.gettersLostRFQs.length
      counts.won = this.gettersWonRFQs.length
      counts.closed = this.gettersClosedRFQs.length
      counts.ordered = this.gettersOrderedRFQs.length
      counts.all = this.gettersAllRFQs.length
      return counts
    },
  },
  components: {
    WindowNotification,
    notificationOnTable,
    BlockPagination,
    SearchFilter,
    VueContext,
    DatePicker,
    AssignTo
  },
  mounted() {
    this.isMounted = true
  },
  watch: {
    getterIsNeedRefreshTable: {
      handler(val) {
        if (val && this.isMounted) {
          this.$refs.newTable.refresh()
          this.$refs.pricedTable.refresh()
          this.$refs.lostTable.refresh()
          this.$refs.wonTable.refresh()
          this.$refs.orderedTable.refresh()
          this.$refs.closedTable.refresh()
        }
      },
      immediate: true,
    },
    gettersNewRFQs: {
      handler() {
        this.isShowBidders = false
        setTimeout(() => {
          this.isShowBidders = true
        }, 200)
      },
      immediate: true
    },
    gettersPricedRFQs: {
      handler() {
        this.isShowBidders = false
        setTimeout(() => {
          this.isShowBidders = true
        }, 200)
      },
      immediate: true
    },
  }
}
</script>
<style>
.search-filter-in-header .custom-dropdown {
  left: -42px !important;
  min-width: 15rem !important;
}

.search-filter-in-header .custom-dropdown .dropdown-item {
  padding: 7px 15px !important;
}


.theme-supplier .supplier-rfqs-page .table .notification-column {
  min-width: 130px !important;
}

.theme-supplier .supplier-rfqs-page .table tr.text-red-straboscope,
.theme-supplier .supplier-rfqs-page .table tr.text-orange-straboscope {
  width: 100%;
  height: 100%;
  align-items: center;
  animation-name: supplier-rfqs-text-straboscope;
  animation-timing-function: ease-in-out;
  animation-duration: 5s;
  animation-delay: 250ms;
  animation-iteration-count: infinite;
}

.theme-supplier .supplier-rfqs-page .table tr.text-red-straboscope {
  color: #E34F5B;
}

.theme-supplier .supplier-rfqs-page .table tr.text-orange-straboscope {
  color: #EE8F2F;
}

.theme-supplier .supplier-rfqs-page .table tr.background-red-straboscope {
  background-color: #E34F5B;
  color: white;
  width: 100%;
  height: 100%;
  align-items: center;
  animation-name: supplier-rfqs-background-red-straboscope;
  animation-timing-function: cubic-bezier(0.03, 0.25, 0.32, 1.28);
  animation-duration: 5s;
  animation-delay: 250ms;
  animation-iteration-count: infinite;
}

.theme-supplier .supplier-rfqs-page .table tr.background-orange-straboscope {
  background-color: #EE8F2F;
  color: white;
  width: 100%;
  height: 100%;
  //display: flex;
  align-items: center;
  animation-name: supplier-rfqs-background-orange-straboscope;
  animation-timing-function: cubic-bezier(0.03, 0.25, 0.32, 1.28);
  animation-duration: 6s;
  animation-delay: 250ms;
  animation-iteration-count: infinite;
}

@keyframes supplier-rfqs-text-straboscope {
  50% {
    color: black;
  }
}

@keyframes supplier-rfqs-background-red-straboscope {
  50% {
    background-color: white;
    color: #E34F5B;
  }
}

@keyframes supplier-rfqs-background-orange-straboscope {
  50% {
    background-color: white;
    color: #EE8F2F;
  }
}


.bidders-item {
  width: 168px !important;
  min-width: 168px !important;
}
.bidder-color-gray {
  color: rgb(166,165,165)
}
.bidder-color-blue {
  color: rgb(46,108,164)
}
.bidder-color-red {
  color: red;
}
.bidder-color-green {
  color: rgb(74,133,34)
}
.supplier-rfqs-page .search-users-input {
  padding: 5px;
  border: 1px solid #bdbdbd;
  border-radius: 5px;
  margin: 10px 10px;
  top: 10px;
  background-color: #fff;
  z-index: 100;
}
.supplier-rfqs-page .search-users-input-reverse {
  padding: 5px;
  border: 1px solid #bdbdbd;
  border-radius: 5px;
  margin: 10px 10px;
  top: 0px;
  bottom: 5px;
  z-index: 100;
}
.supplier-rfqs-page .supp-rfqs-context .parts-order-view__context-menu > * {
  width: 192px;
}

.supplier-rfqs-page .supp-rfqs-context .parts-order-view__context-menu .bx-chevron-right {
  padding-right: 8px !important
}

.status-color {
  display: block;
  height: 11px;
  width: 11px;
  background: #FF5E5E;
  border-radius: 3px;
  margin-right: 5px;
  margin-top: 1px
}

.status-color.medium-color {
  background: #FFDB00;
}

.status-color.low-color {
  background: #18D06B;
}

.status-color.on-hold-color {
  background: #419abe;
}

.status-color.undo-priority-color {
  background: black;
}

.status-text.high-color {
  color: #ff5e5e;
}

.status-text.medium-color {
  color: #FFDB00;
}

.status-text.low-color {
  color: #18D06B;
}

.status-text.on-hold-color {
  color: #419abe;
}

.status-text.undo-priority-color {
  color: black;
}

.supplier-rfqs-page .custom-dropdown-2 {
  position: static !important;
}
</style>
<style scoped>
.assing-to-position-top.users-context-level{
  top: 80px;
}
.save-comment-icon {
  font-size: 25px !important;
  cursor: pointer;
  opacity: 0.7;
  position: absolute;
  right: 14px;
  bottom: 8px;
}

.schedule-context-level {
  position: absolute;
  border: 1px solid #bdbdbd;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
  background: white;
  left: 192px;
  top: 170px;
  padding: 15px;
  width: 224px;
}

.schedule-context-level-fixed {
  top: 129px !important;
}

.schedule-context-level .schedule-text {
  margin: 5px 0px 5px 0px;
}

.schedule-context-level .schedule-text:first-child {
  margin-top: 0px;
}

.users-context-level {
  position: absolute;
  border: 1px solid #bdbdbd;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
  background: white;
  left: 192px;
  top: 124px;
  width: 170px;
  max-height: 400px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.assigned-to-column-reverse {
  flex-direction: column-reverse !important;
}

.priority-context-level {
  position: absolute;
  border: 1px solid #bdbdbd;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
  background: white;
  left: 192px;
  top: 216px;
  width: 128px;
}

.priority-context-level-not-oem {
  top: 170px !important;
}

.priority-context-level-fixed {
  top: 27px !important;
}

.priority-context-level-not-oem-fixed {
  top: 129px !important;
}

.times-context-level {
  position: absolute;
  border: 1px solid #bdbdbd;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
  background: white;
  left: 192px;
  top: 262px;
  width: 114px;
}

.times-context-level-fixed {
  top: 119px !important;
}

.supplier-rfqs-page >>> tr.blinking-rfq-due-date-10-min td,
.supplier-rfqs-page >>> tr.blinking-rfq-due-date-10-min .notifications-table .bx-timer {
  animation-name: blinking-red;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-delay: 250ms;
}

.supplier-rfqs-page >>> tr.blinking-rfq-due-date-20-min td,
.supplier-rfqs-page >>> tr.blinking-rfq-due-date-20-min .notifications-table .bx-timer {
  animation-name: blinking-orange;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-delay: 250ms;
}

@keyframes blinking-red {
  20% {
    color: #F44236;
    opacity: 1;
  }
  80% {
    color: #F44236;
    opacity: 1;
  }
}

@keyframes blinking-orange {
  20% {
    color: #F59345;
    opacity: 1;
  }
  80% {
    color: #F59345;
    opacity: 1;
  }
}

.parts-order-view__context-menu i {
  font-size: 20px;
}

.priority-context-level-order-processed {
  top: -14px !important;
}

.theme-supplier .btn-outline-primary.font-normal {
  font-weight: 400 !important;
}

.theme-supplier .btn-outline-primary.font-normal:hover {
  font-weight: 600 !important;
}
</style>
