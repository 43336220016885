<template>
  <div>
    <div class="use-pagination-block-bottom">
      <b-table
          responsive
          hover
          :items="computedItems"
          :fields="fields"
          :filter="filter"
          :current-page="currentPage"
          :per-page="perPage"
          :tbody-tr-class="rowClass"
          @row-clicked="onCreditRowClick"
          @filtered="value => filteredItems = value.length"
          @row-contextmenu="onCreditRightClick"
      >
        <template v-slot:head()="data">
          <div v-if="!data.field.sortable">{{ data.label }}</div>
          <div v-if="data.field.sortable" class="table-sorting">{{ data.label }}<i></i></div>
        </template>
        <template v-slot:cell(status)="data">
                    <span
                        v-bind:class="{'color_denied': data.value === 'Denied','color_approved': (data.value === 'Approved' || data.value === 'PartialApproved'), 'color_review': data.value === 'UnderReview', 'style_cancelled': data.value === 'Cancelled'}">
                        <template v-if="data.item.status === 'Denied'">Credit Denied</template>
                        <template v-else-if="data.item.status === 'Approved'">Credit Approved</template>
                      <template v-else-if="data.item.status === 'PartialApproved'">Credit Partial Approved</template>
                        <template v-else-if="data.item.status === 'UnderReview'">Credit Under Review</template>
                      <template v-else-if="data.item.status === 'Cancelled'">Credit Cancelled</template>
                    </span>
        </template>
        <template #cell(dateCreated)="data">
          {{ data.value | formatDate }}
        </template>
        <template v-slot:cell(amount)="data">
          <span v-if="Number(data.value)">-</span>
          {{ data.value | formatMoney }}
        </template>
        <template v-slot:cell(amountApproved)="data">
          <span v-if="Number(data.value)">-</span>
          {{ data.value | formatMoney }}
        </template>
        <template v-slot:cell(partNumbers)="data">
          <div v-html="data.value.split(';').join('<br>')"></div>
        </template>
        <template v-slot:cell(repairerName)="data">
          <template v-if="data.item.id === selectedItem">
            <span>{{ data.value }}</span>

            <!-- repairer reason -->
            <template v-if="data.item.reasonCredit">
              <div class="reason">
                <div class="reason__label mt-3 ml-2">
                  <div class="font-weight-bold">Repairer Reason For Return and Credit Request:</div>
                </div>
                <div class="reason__value font-inter--normal ml-2">
                  {{ data.item.reasonCredit }}
                </div>
              </div>
            </template>

            <!-- supplier reason -->
            <div class="reason">
              <div class="mt-3 ml-2">
                <div class="reason__label" v-if="data.item.status === 'Denied'">Supplier - Reason for Credit Denial:
                </div>
                <template v-else-if="data.item.subStatus">
                  <span class="reason__label" v-if="data.item.subStatus === 'Cancellation'">Repairer - Reason for cancellation: </span>
                  <span class="reason__label"
                        v-else-if="data.item.subStatus === 'Return'">Repairer - Reason for return: </span>
                </template>
                <div v-if="showDenyReasonBox && !data.item.reasonDenied" class="reason__label font-weight-bold mb-2">
                  Supplier reason for denial of credit:
                </div>
              </div>
              <div class="ml-2">
                                <textarea class="deny-reason-input reason__textarea"
                                          v-if="showDenyReasonBox && !data.item.reasonDenied"
                                          v-model="denyReason"
                                          @change="denyCredit"
                                          placeholder="Write Your reason for repairer here…">
                                </textarea>
                <template v-else-if="data.item.status === 'Denied'">
                  <div class="reason__value font-inter--normal">{{ data.item.reasonDenied }}</div>
                </template>
                <template v-else-if="data.item.subStatus">
                  <div class="reason__value font-inter--normal">{{ data.item.subStatusText }}</div>
                </template>
              </div>
            </div>
          </template>
          <span v-else>{{ data.value }}</span>
        </template>
      </b-table>
    </div>

    <block-pagination
        :role="'Credits'"
        :count="items.length"
        :countFiltered="filter ? filteredItems : computedItems.length"
        :currentPage="currentPage"
        @changedCurrentPage="changedCurrentPage"
        :perPage="perPage"
        @changedPerPage="changedPerPage"
    >
    </block-pagination>

    <vue-context
        ref="menu"
        @close="contextClose"
        @open="contextOpen"
        class="context-menu-right-click"
        style="width: 180px">
      <template slot-scope="child">
        <ul v-if="child && child.data" class="credit-context-menu">
          <li class="credit-context-menu__item">
            <b-button class="font-12" @click.prevent="onCreditRowClick(child.data)">
              View Credit
            </b-button>
          </li>
          <li v-if="child.data.status == 'UnderReview'" class="credit-context-menu__item">
            <b-button id="approveBtn" class="font-12" @click.prevent="approveCreditClick(child.data)">
              Approve Credit
            </b-button>
          </li>
          <li v-if="child.data.status == 'UnderReview'" class="credit-context-menu__item">
            <b-button id="denyBtn" class="font-12" @click.prevent="denyCreditClick(child.data)">
              Deny Credit
            </b-button>
          </li>
          <li v-if="child.data.status == 'UnderReview'" class="credit-context-menu__item">
            <b-button class="font-12" @click.prevent="cancelCreditClick(child.data)">
              Cancel Credit
            </b-button>
          </li>
<!--          <li v-if="child.data.status == 'UnderReview'" class="credit-context-menu__item">-->
<!--            <b-button class="font-12">-->
<!--              Split Credit Request-->
<!--            </b-button>-->
<!--          </li>-->
          <li v-if="child.data.status == 'Approved' || child.data.status == 'Denied'  || child.data.status == 'Cancelled' " class="credit-context-menu__item">
            <b-button id="approveBtn" class="font-12" @click.prevent="reinstateCreditClick(child.data)">
              Reinstate Credit Review
            </b-button>
          </li>
          <li class="credit-context-menu__item">
            <b-button class="font-12" @click.prevent="openNewTab(child.data)">
              Open in New Tab
            </b-button>
          </li>
        </ul>
      </template>
    </vue-context>
  </div>
</template>

<script>
/* eslint-disable */
import NProgress from 'nprogress'
import Axios from 'axios'
import { VueContext } from 'vue-context'
import BlockPagination from '../../../../components/utility/block-pagination'
import _ from 'lodash'
import * as dayjs from 'dayjs'

export default {
  name: 'CreditTabTableView',
  props: {
    items: {
      type: Array,
      required: true
    },
    filter: {
      type: String,
      default: ''
    },
    searchFilterObj: {
      type: Object,
      default () {
        return {}
      }
    },
    propsPerPage: {
      type: Number,
      default: 20,
    },
    propsCurrentPage: {
      type: Number,
      default: 1,
    }
  },
  data: function () {
    return {
      contextOpenEvent: null,
      selectedItem: null,
      perPage: this.propsPerPage,
      filteredItems: 0,
      currentPage: this.propsCurrentPage,
      showDenyReasonBox: false,
      denyReason: '',
      fields: [
        { label: 'Repairer', key: 'repairerName', sortable: true, tdClass: 'clickable repairer-name', thClass: '' },
        { label: 'Credit Request Nbr', key: 'number', sortable: true, tdClass: 'clickable credit-nbr', thClass: '' },
        { label: 'Credit Nbr', key: 'manualNumber', sortable: true, tdClass: 'clickable credit-nbr', thClass: '' },
        { label: 'Order Nbr', key: 'orderNumber', sortable: true, tdClass: 'clickable order-nbr', thClass: '' },
        { label: 'Parts Type', key: 'rfqToSupplierType', sortable: true, tdClass: 'clickable', thClass: '' },
        { label: 'Part Nbr/s', key: 'partNumbers', sortable: true, tdClass: 'clickable part-number', },
        { label: 'Credit Note Amt', key: 'amount', sortable: true, tdClass: 'clickable text-right pr-5 credit-nbr', },
        { label: 'Make', key: 'make', sortable: true, tdClass: 'clickable car-rego', },
        { label: 'Car Rego', key: 'rego', sortable: true, tdClass: 'clickable car-rego', },
        { label: 'Date Created', key: 'dateCreated', sortable: true, tdClass: 'clickable date-created', },
        {
          label: 'Amount Approved',
          key: 'amountApproved',
          sortable: true,
          tdClass: 'clickable amount text-right pr-5',
        },
        { label: 'Credit Status', key: 'status', sortable: true, tdClass: 'clickable status', },
      ]
    }
  },
  computed: {
    computedItems () {
      let items = this.items.slice()

      if (this.isPartNumberFilterActive) {
        items = _.filter(items, item => {
          let bool = false
          _.forEach(this.computedPartNumberFilter, (nbr) => {
            if (item.partNumbers && _.includes(_.toLower(item.partNumbers), _.trim(_.toLower(nbr)))) {
              bool = true
            }
          })
          return bool
        })
      }
      if (this.isOrderNumberFilterActive) {
        items = _.filter(items, item => {
          // console.log('item',item)
          // console.log('item2',_.some(this.computedOrderNumberFilter, (itm) => itm == item.orderNumber))
          return item.orderNumber && _.some(this.computedOrderNumberFilter, (itm) => itm == item.orderNumber)
        })
      }
      if (this.isCreditStatusFilterActive) {
        items = _.filter(items, item => {
          return item.status && _.some(this.computedCreditStatusFilter, (itm) => {
            if (itm == item.status) {return true}
            if (itm == 'Partially Approved' && item.status == 'PartialApproved') {return true}
            if (itm == 'Under Review' && item.status == 'UnderReview') {return true}
            return false
          })
        })
      }
      if (this.isCustomerNameFilterActive) {
        items = _.filter(items, item => {
          return item.repairerName && _.some(this.computedCustomerNameFilter, (itm) => itm == item.repairerName)
        })
      }
      return items
    },
    isPartNumberFilterActive () {
      for (let key in this.searchFilterObj) {
        if (this.searchFilterObj[key].partNumber) {
          return true
        }
      }
      return false
    },
    isOrderNumberFilterActive () {
      for (let key in this.searchFilterObj) {
        if (this.searchFilterObj[key].orderNumber) {
          return true
        }
      }
      return false
    },
    isCreditStatusFilterActive () {
      for (let key in this.searchFilterObj) {
        if (this.searchFilterObj[key].creditStatus) {
          return true
        }
      }
      return false
    },
    isCustomerNameFilterActive () {
      for (let key in this.searchFilterObj) {
        if (this.searchFilterObj[key].customer) {
          return true
        }
      }
      return false
    },
    computedOrderNumberFilter () {
      let filter = {
        orderNumber: '',
      }
      let obj = Object.assign({}, this.searchFilterObj)
      for (let key in obj) {
        if (obj[key].orderNumber) {
          filter = obj[key]
          break
        }
      }
      filter = _.split(filter.orderNumber, ';')
      return filter
    },
    computedCustomerNameFilter () {
      let filter = {
        customer: '',
      }
      let obj = Object.assign({}, this.searchFilterObj)
      for (let key in obj) {
        if (obj[key].customer) {
          filter = obj[key]
          break
        }
      }
      filter = _.split(filter.customer, ';')
      return filter
    },
    computedCreditStatusFilter () {
      let filter = {
        creditStatus: '',
      }
      let obj = Object.assign({}, this.searchFilterObj)
      for (let key in obj) {
        if (obj[key].creditStatus) {
          filter = obj[key]
          break
        }
      }
      filter = _.split(filter.creditStatus, ';')
      return filter
    },
    computedPartNumberFilter () {
      let filter = {
        partNumber: '',
      }
      let obj = Object.assign({}, this.searchFilterObj)
      for (let key in obj) {
        if (obj[key].partNumber) {
          filter = obj[key]
          break
        }
      }
      let formattedFilter = filter.partNumber.toLowerCase().split(';').filter(Boolean)
      return formattedFilter
    },
  },
  methods: {
    filterOrdersForComputed (orders) {

      if (this.isDateRangeFilterActive) {
        orders = _.filter(orders, order => {
          let time = dayjs(order.dateOrdered).unix()
          if (order.dateOrdered && time > 0 && time >= this.computedDateRangeFilter.start && time <= this.computedDateRangeFilter.end) {
            return true
          }
          return false
        })
      }
      if (this.isCustomerNameFilterActive) {
        orders = _.filter(orders, order => {
          return order.customerName && _.some(this.computedCustomerNameFilter, (itm) => itm == order.customerName)
        })
      }

      if (this.isPartReceivedStatusFilterActive) {
        let filter = []
        let isReceivedFilterActive = false
        let isNotReceivedFilterActive = false
        let obj = Object.assign({}, this.searchFilterObj)
        for (let key in obj) {
          if (obj[key].partRcvdStatus) {
            filter = obj[key]
            let statuses = obj[key].partRcvdStatus.split(';').filter(Boolean)
            _.forEach(statuses, (st) => {
              if (st === 'Received') {
                isReceivedFilterActive = true
              }
              if (st === 'Not Received') {
                isNotReceivedFilterActive = true
              }
            })
            break
          }
        }
        orders = _.filter(orders, order => {
          let bool = false
          if (isReceivedFilterActive) {
            if (order.partsStatuses && (order.partsStatuses.somePartsReceived || order.partsStatuses.allPartsReceived)) {
              bool = true
            }
          }
          if (filter.partRcvdStatus.includes('Incorrect Part')) {
            if (order.partsStatuses && order.partsStatuses.somePartsIncorrect) {
              bool = true
            }
          }
          if (filter.partRcvdStatus.includes('Return Damaged')) {
            if (order.partsStatuses && order.partsStatuses.somePartsDamaged) {
              bool = true
            }
          }
          if (filter.partRcvdStatus.includes('Cancelled Part')) {
            if (order.partsStatuses && order.partsStatuses.somePartsCancelled) {
              bool = true
            }
          }
          if (isNotReceivedFilterActive) {
            if (order.partsStatuses && order.partsStatuses.somePartsReceived === false) {
              bool = true
            }
          }
          return bool
        })
      }

      if (this.isPartStockStatusFilterActive) {
        let filter = ''
        let obj = Object.assign({}, this.searchFilterObj)
        for (let key in obj) {
          if (obj[key].partStockStatus) {
            filter = obj[key].partStockStatus
            break
          }
        }
        orders = _.filter(orders, order => {
          let bool = false
          if (filter.includes('None')) {
            if (order.partsStocks && order.partsStocks.someNone) {
              bool = true
            }
          }
          if (filter.includes('In Stock')) {
            if (order.partsStocks && order.partsStocks.someInStock) {
              bool = true
            }
          }
          if (filter.includes('Low Stock')) {
            if (order.partsStocks && order.partsStocks.someLowStock) {
              bool = true
            }
          }
          if (filter.includes('No Stock')) {
            if (order.partsStocks && order.partsStocks.someNoStock) {
              bool = true
            }
          }
          if (filter.includes('On Back Order')) {
            if (order.partsStocks && order.partsStocks.someOnBackOrder) {
              bool = true
            }
          }
          if (filter.includes('No Longer Avail')) {
            if (order.partsStocks && order.partsStocks.someNoLongerAvailable) {
              bool = true
            }
          }
          return bool
        })
      }
      return orders
    },
    changedCurrentPage (value) {
      this.$emit('changedCurrentPage', value)
      this.currentPage = value
    },
    changedPerPage (value) {
      this.$emit('changedPerPage', value)
      this.perPage = value
    },
    onCreditRowClick (item) {
      this.$router.push({ name: 'SuppliersCreditView', params: { creditId: item.id } })
    },
    onCreditRightClick: function (row, index, event) {
      event.preventDefault()
      let tableRow = event.target.parentElement
      let rows = tableRow.parentElement.rows
      while (!rows) {
        tableRow = tableRow.parentElement
        rows = tableRow.parentElement.rows
      }
      if (rows) {
        for (let i = 0; i < rows.length; i++) {
          let elem = rows[i]
          elem.style.backgroundColor = ''
        }
      }
      tableRow.style.backgroundColor = 'rgb(233,248,249)'
      console.log('onCreditRightClick', row)
      this.$refs.menu.open(event, row, 0, 0)
      this.$emit('right-click', row, index, event)
    },
    contextOpen: function (event) {
      this.contextOpenEvent = event
    },
    contextClose: function () {
      let event = this.contextOpenEvent
      if (event) {
        let row = event.target.parentElement
        let rows = row.parentElement.rows
        while (!rows) {
          row = row.parentElement
          rows = row.parentElement.rows
        }
        if (rows) {
          for (let i = 0; i < rows.length; i++) {
            let elem = rows[i]
            elem.style.backgroundColor = ''
          }
        }
      }
    },
    rowClass (item, type) {
      let rowClass = ''
      if (!item || type !== 'row') return
      if (item.id === this.selectedItem
          && !item.subStatus
          && !this.showDenyReasonBox
          && !item.reasonDenied) rowClass = 'table-tr-selected'
      return rowClass
    },
    openNewTab (row) {
      window.open(window.location.origin + '/s/credit/' + row.id, '_blank')
      this.contextClose();
    },
    reinstateCreditClick: function (credit) {
      NProgress.start()
      Axios.post('/ir/supplier/credit/' + credit.id + '/reinstate', {
        creditId: credit.id
      }).then((response) => {
        if (response.data._status) {
          this.$toast.success('The Credit has been reinstated for review')
          this.$emit('updated')
        } else {
          this.$toast.error('The Credit Review didn`t reinstate')
        }
      }).catch(error => {
        console.log(error)
      }).finally(() => {
        NProgress.done()
      })
      this.contextClose();
    },
    approveCreditClick: function (credit) {
      NProgress.start()
      Axios.post('/ir/supplier/credit/' + credit.id + '/approve', {
        creditId: credit.id
      }).then((response) => {
        if (response.data.status === true) {
          this.$toast.success('Credit Approved')
          this.$emit('updated')
        } else {
          this.$toast.error('Credit do not approve')
        }
      }).catch(error => {
        console.log(error)
      }).finally(() => {
        NProgress.done()
      })
      this.contextClose();
    },
    cancelCreditClick (credit) {
      this.cancelCredit(credit.id)
    },
    cancelCredit: function (id) {
      Axios.post('/ir/supplier/credit/' + id + '/cancel',
          {
            creditId: id,
          }).then(response => {
        if (response.data._status) {
          this.$toast.success('The Credit has been cancelled')
          this.$emit('updated')
        } else {
          this.$toast.error('The Credit hasn`t been cancelled')
        }
      }).catch(error => {
        console.log(error)
      }).finally(() => {
        NProgress.done()
      })
      this.showDenyReasonBox = false
      this.contextClose();
    },
    denyCreditClick: function (credit) {
      if (!credit.reasonDenied) {
        this.selectedItem = credit.id
        this.showDenyReasonBox = true
      }
      this.contextClose();
    },
    denyCredit: function () {
      Axios.post('/ir/supplier/credit/' + this.selectedItem + '/deny',
          {
            creditId: this.selectedItem,
            reasonDenied: this.denyReason
          }).then(response => {
        if (response.data._status === true) {
          this.$toast.success('The Credit has been denied')
          this.denyReason = ''
          this.$emit('updated')
        } else {
          this.$toast.error('The Credit hasn`t been denied')
        }
      }).catch(error => {
        console.log(error)
      }).finally(() => {
        NProgress.done()
      })
      this.showDenyReasonBox = false
      this.contextClose();
    }
  },

  watch: {
    propsPerPage: {
      handler (val) {
        this.perPage = val
      },
      immediate: true,
    },
    propsCurrentPage: {
      handler (val) {
        this.currentPage = val
      },
      immediate: true,
    },
  },
  components: {
    VueContext,
    BlockPagination,
  },
}
</script>
