<script>
import SearchFilter from "@/components/utility/search-filter/search-filter.vue";
import notificationOnTable from "@/components/notification-on-table.vue";
import _ from "lodash";
import moment from "moment";
import Axios from "axios";
import {mapGetters} from "vuex";
import * as dayjs from "dayjs";
import PdfViewer from '../../../components/utility/pdf-viewer'
import Notifications from '../../../components/utility/notifications/notifications'

export default {
  name: "job-view",
  components: {notificationOnTable, SearchFilter, PdfViewer, Notifications},
  data() {
    return{
      jobData: {
        "jobId": null,
        "rfqIds": [],
        "activityList": [],
        "orderIds": [],
        "creditIds": [],
        "notices": [],
        "rfqFiles": [],
        "orderFiles": []
      },
      searchFilter1: '',
      searchFilter2: '',
      searchFilter3: '',
      filter1: '',
      filter2: '',
      filter3: '',
      rfqFields: [
        {label: "Quoting Package Nbr", key: "QPNumber", sortable: true, tdClass: 'clickable number', thClass: ''},
        {label: "Bidders", key: "bidders", sortable: true, tdClass: 'clickable', thClass: ''},
        {label: "Car Rego", key: "rego", sortable: true, tdClass: 'clickable ', thClass: ''},
        {label: "Make", key: "make", sortable: true, tdClass: 'clickable number', thClass: ''},
        {label: "Model", key: "model", sortable: true, tdClass: 'clickable', thClass: ''},
        {label: "Date Submitted", key: "dateSubmitted", sortable: true, tdClass: 'clickable', thClass: ''},
        // {label: "Notifications", key: "notifications", sortable: false, tdClass: 'clickable ', thClass: 'notification-column'},
        {label: "Nbr Parts", key: "nbrParts", sortable: true, tdClass: 'clickable', thClass: ''},
        {label: "Status", key: "status", sortable: true, tdClass: 'clickable number', thClass: ''},
      ],
      orderFields: [
        {label: "Order Nbr", key: "number", sortable: true, tdClass: 'clickable number', thClass: ''},
        {label: "Invoice Nbr", key: "invoiceNumber", sortable: true, tdClass: 'clickable', thClass: ''},
        {label: "Supplier", key: "supplierName", sortable: true, tdClass: 'clickable number', thClass: ''},
        {label: "Delivery Date", key: "dateDue", sortable: true, tdClass: 'clickable number', thClass: ''},
        {label: "Delivery Type", key: "deliveryType", sortable: true, tdClass: 'clickable number', thClass: ''},
        {label: "Status", key: "status", sortable: true, tdClass: 'clickable number', thClass: ''},
      ],
      creditsFields: [
        {label: "Credit Request Nbr", key: "number", sortable: true, tdClass: 'clickable number', thClass: ''},
        {label: "Supplier Credit Nbr", key: "manualNumber", sortable: true, tdClass: 'clickable', thClass: ''},
        {label: "Supplier", key: "supplierName", sortable: true, tdClass: 'clickable number', thClass: ''},
        {label: "Order Nbr", key: "orderNumber", sortable: true, tdClass: 'clickable', thClass: ''},
        {label: "Date Created", key: "dateCreated", sortable: true, tdClass: 'clickable', thClass: ''},
        {label: "Status", key: "status", sortable: true, tdClass: 'clickable number', thClass: ''},
      ],
      searchFilterObj1: {},
      searchFilterObj2: {},
      isShowSearch1: false,
      isShowPdf: false,
      documentHeight: 0,
      selectedUrl: '',
      documentWidth: 0,
      isShowSearch2: false,
    }
  },
  watch: {
    filesUpdater: {
      handler(v) {
        v = _.cloneDeep(v)
        if (v.type === 'fileRemovedFromRfq') {
          let index = _.findIndex(this.jobData.rfqFiles, (f) => {
            return Number(f.id) === Number(v.fileId)
          })
          if (index !== -1) {
            this.jobData.rfqFiles.splice(index, 1)
          }
        } else if (v.type === 'fileRemovedFromOrder') {
          let index = _.findIndex(this.jobData.orderFiles, (f) => {
            return Number(f.id) === Number(v.fileId)
          })
          if (index !== -1) {
            this.jobData.orderFiles.splice(index, 1)
          }
        } else if (v.type === 'fileAddedToRfq') {
          let index = _.findIndex(this.jobData.rfqFiles, (f) => {
            return Number(f.id) === Number(v.file.id)
          })
          if (index === -1) {
            v.file.url += '?at=' + localStorage.getItem('token')
            this.jobData.rfqFiles.push(v.file)
          }
        } else if (v.type === 'fileAddedToOrder') {
          let index = _.findIndex(this.jobData.orderFiles, (f) => {
            return Number(f.id) === Number(v.file.id)
          })
          if (index === -1) {
            v.file.url += '?at=' + localStorage.getItem('token')
            this.jobData.orderFiles.push(v.file)
          }
        }
      },
      immediate: true
    },
  },
  methods: {
    getBidderTooltip(bidder, data) {
      let r
      if (bidder.status === 'Priced' && bidder.isSentUpdateRequested) {
        r = bidder.supplier + ' ' + '<br /><b>' + 'to be updated' + '</b>'
      } else if (bidder.status == 'Declined' && data.item.isSentAsDirectOrder) {
        r = bidder.supplier + ' ' + '<br /><b>' + 'Declined Direct Order' + '</b>'
      } else {
        r = bidder.supplier + ' ' + '<br /><b>' + bidder.status + '' + '</b>'
      }

      if (bidder.declineReason) {
        r += `<br/> ${bidder.declineReason}`
      }
      return r
    },
    _isEmpty(v) {
      return _.isEmpty(v)
    },
    handleResize() {
      this.documentHeight =  window.innerHeight - document.getElementsByClassName('site-header')?.[0]?.clientHeight -
          document.getElementsByClassName('page-header')?.[0]?.clientHeight - 4

      this.documentWidth =  window.innerWidth - document.getElementsByClassName('site-sidebar')?.[0]?.clientWidth
    },
    redirectToRfq(itm) {
      if (itm.creditId) {
        this.$router.push({name: 'CreditManagementView', params: {creditId: itm.creditId, isNeedOpenChat: true}})
      } else if (itm.orderId) {
        this.$router.push({name: 'RepairerPartsOrderView', params: {orderId: itm.orderId, isNeedOpenChat: true}})
      } else if (itm.rfqId) {
        this.$router.push({name: 'RepairerRFQView', params: {rfq_id: itm.rfqId, isNeedOpenChat: true}})
      }
    },
    formatDateTime2 (val) {
      let format = 'DD/MM'
      if (dayjs(val).isToday()) {
        format = 'hh:mmA'
      }
      return (val) ? dayjs(val).format(format) : ''
    },
    getOrderNumber(id) {
      let order = _.find(this.gettersAllOrders, (o) => {
        return Number(o.id) === Number(id)
      })
      if (order) {
        return ' - ' + order.number
      }
      return ''
    },
    downloadFile(itm) {
      if (itm.type === 'scheme') {
        this.$router.push({ name: 'RepairerRFQView', params: { rfq_id: itm.rfqId, shemeId: itm.id } })
      } else if (itm.fileType === 'application/pdf') {
        this.selectedUrl = itm.url;
        this.isShowPdf = true;
        this.handleResize()
      } else {
        window.open(itm.url, '_blank')
      }
    },
    formatDeliveryType(v) {
      if (_.isEmpty(v)) {
        return ''
      }
      return v.charAt(0).toUpperCase() + v.slice(1)
    },
    isToday(v) {
      return dayjs(v).isToday()
    },
    isTomorrow(v) {
      return dayjs(v).isTomorrow()
    },
    loadData() {
      Axios.get('/ir/repairer/job/' + this.$route.params.job_id)
      .then((response) => {
        if (response.data._status) {
          this.jobData = response.data
        }
      })
      .catch(() => {

      })
    },
    filterData(data) {
      if (this.isPartNumberFilterActive2) {
        let filter = []
        let obj = Object.assign({}, this.searchFilterObj2)
        for (let key in obj) {
          if (obj[key].partNumber) {
            filter = obj[key]
            break
          }
        }
        let formattedFilter = filter.partNumber.toLowerCase().split(';').filter(Boolean)

        data = _.filter(data, order => {
          let bool = false
          _.forEach(formattedFilter, (nbr) => {
            if (order.partsNumbers &&  _.includes(_.toLower(order.partsNumbers), _.trim(_.toLower(nbr)))) {
              bool = true
            }
          })
          return bool
        })
      }
      if (this.isSupplierFilterActive2) {
        let filter = []
        let obj = Object.assign({}, this.searchFilterObj2)
        for (let key in obj) {
          if (obj[key].supplier) {
            filter = obj[key]
            break
          }
        }
        let formattedFilter = filter.supplier.toLowerCase().split(';').filter(Boolean)
        data = _.filter(data, order => {
          let bool = false
          _.forEach(formattedFilter, (suppName) => {
            if (order.supplierName && order.supplierName.toLowerCase().trim().includes(suppName.toLowerCase().trim())) {
              bool = true
            }
          })
          return bool
        })
      }
      if (this.isMakeFilterActive2) {
        let filter = []
        let obj = Object.assign({}, this.searchFilterObj2)
        for (let key in obj) {
          if (obj[key].make) {
            filter = obj[key]
            break
          }
        }
        let formattedFilter = filter.make.toLowerCase().split(';').filter(Boolean)
        data = _.filter(data, order => {
          let bool = false
          _.forEach(formattedFilter, (make) => {
            if (order.make && order.make.toLowerCase().trim().includes(make.toLowerCase().trim())) {
              bool = true
            }
          })
          return bool
        })
      }
      if (this.isInvoiceNumberFilterActive2) {
        let filter = []
        let obj = Object.assign({}, this.searchFilterObj2)
        for (let key in obj) {
          if (obj[key].invoiceNumber) {
            filter = obj[key]
            break
          }
        }
        let formattedFilter = filter.invoiceNumber.toLowerCase().split(';').filter(Boolean)
        data = _.filter(data, order => {
          let bool = false
          _.forEach(formattedFilter, (invoiceNumber) => {
            if (order.invoiceNumber && order.invoiceNumber.toLowerCase().trim().includes(invoiceNumber.toLowerCase().trim())) {
              bool = true
            }
          })
          return bool
        })
      }
      if (this.isStatusFilterActive2) {
        let filter = [];
        let isReceivedFilterActive = false;
        let isNotReceivedFilterActive = false;
        let obj = Object.assign({}, this.searchFilterObj2)
        for (let key in obj) {
          if (obj[key].status) {
            filter = obj[key];
            let statuses = obj[key].status.split(';').filter(Boolean);
            _.forEach(statuses, (st) => {
              if (st === 'Received') {
                isReceivedFilterActive = true;
              }
              if (st === 'Not Received') {
                isNotReceivedFilterActive = true;
              }
            })
            break
          }
        }
        data = _.filter(data, order => {
          let bool = false
          if (isReceivedFilterActive) {
            if (order.partsStatuses && (order.partsStatuses.somePartsReceived || order.partsStatuses.allPartsReceived)) {
              bool = true
            }
          }
          if (filter.status.includes('Order Completed')) {
            if (order.state == 2) {
              bool = true
            }
          }
          if (filter.status.includes('Incorrect Part Delivered')) {
            if (order.partsStatuses && order.partsStatuses.somePartsIncorrect) {
              bool = true
            }
          }
          if (filter.status.includes('Incorrect Part Ordered')) {
            if (order.partsStatuses && order.partsStatuses.somePartsIncorrectOrdered) {
              bool = true
            }
          }
          if (filter.status.includes('No Longer Needed')) {
            if (order.partsStatuses && order.partsStatuses.somePartsNoLongerNeeded) {
              bool = true
            }
          }
          if (filter.status.includes('Change in Repair Method')) {
            if (order.partsStatuses && order.partsStatuses.somePartsChangeInRepairMethod) {
              bool = true
            }
          }
          if (filter.status.includes('Damaged Part in Transport')) {
            if (order.partsStatuses && order.partsStatuses.somePartsDamaged) {
              bool = true
            }
          }
          if (filter.status.includes('Damaged Part EX Warehouse')) {
            if (order.partsStatuses && order.partsStatuses.somePartsDamagedExWarehouse) {
              bool = true
            }
          }
          if (filter.status.includes('Partial Delivery')) {
            if (order.partsStatuses && order.partsStatuses.somePartsPartialDelivery) {
              bool = true
            }
          }
          if (filter.status.includes('Cancelled Part')) {
            if (order.partsStatuses && order.partsStatuses.somePartsCancelled) {
              bool = true
            }
          }
          if (isNotReceivedFilterActive) {
            if (order.partsStatuses && order.partsStatuses.somePartsReceived === false) {
              bool = true
            }
          }
          return bool
        })
      }
      return data
    },
    onSearchFilterChange1(filters) {
      this.searchFilterObj1 = filters
      if (filters && filters.search) {
        this.filter1 = filters.search;
      } else {
        this.filter1 = '';
      }
    },
    isStatusApproved: function (item) {
      return item.status === 'Approved'
    },
    isStatusPartialApproved: function (item) {
      return item.status === 'PartialApproved'
    },
    isStatusUnderReview: function (item) {
      return item.status === 'UnderReview'
    },
    isStatusDenied: function (item) {
      return item.status === 'Denied'
    },
    isStatusCancelled: function (item) {
      return item.status === 'Cancelled'
    },
    onSearchFilterChange2(filters) {
      this.searchFilterObj2 = filters
      if (filters[0] && filters[0].search) {
        this.filter2 = filters[0].search;
      } else {
        this.filter2 = '';
      }
    },
    filterTable1(row, filter) {
      let formattedFilter = filter.toLowerCase();
      let percent = parseInt(row.quoteCompletion);
      if (this.currentTab === 'Submitted') {
        if (filter === 'upto 25%') return this.filterByPercentRange(percent, 0, 25);
        else if (filter === 'upto 50%') return this.filterByPercentRange(percent, 26, 50);
        else if (filter === 'upto 75%') return this.filterByPercentRange(percent, 51, 75);
      }
      if (this.currentTab === 'Priced') {
        if (filter === 'Recently Priced') return this.filterByDatePriced(row.datePriced, 0, 8);
        else if (filter === '1 day old') return this.filterByDatePriced(row.datePriced, 24, 48);
        else if (filter === 'Over 2 days old') return this.filterByDatePriced(row.datePriced, 49);
      }

      if (this.isHasFilters) {
        if (this.isRegoFilterActive && _.includes(_.toLower(row.rego), formattedFilter)) {
          return true;
        } else if (this.isMakeFilterActive && _.includes(_.toLower(row.make), formattedFilter)) {
          return true;
        } else if (this.isOrderNumberFilterActive && row.orderNumbers && row.orderNumbers.length && _.includes(_.toLower(_.join(row.orderNumbers, ' ')), formattedFilter)) {
          return true;
        } else if (this.isQuotingPackageNbrFilterActive && _.includes(_.toLower(row.QPNumber), formattedFilter)) {
          return true;
        } else if (this.isVINFilterActive && _.includes(_.toLower(row.vin), formattedFilter)) {
          return true;
        }

      } else {
        let dateDue = row.dateDue ? moment(row.dateDue).format('DD/MM/YYYY || hh:mmA') : '';
        let dateReceived = row.dateReceived ? moment(row.dateReceived).format('DD/MM/YYYY') : '';
        let datePriced = row.datePriced ? moment(row.datePriced).format('DD/MM/YYYY') : '';
        let dateSubmitted = row.dateSubmitted ? moment(row.dateSubmitted).format('DD/MM/YYYY') : '';

        if (row.QPNumber && _.includes(_.toLower(row.QPNumber), formattedFilter)) {
          return true
        } else if (row.costStr && _.includes(_.toLower(row.costStr), formattedFilter)) {
          return true
        } else if (dateDue && _.includes(_.toLower(dateDue), formattedFilter)) {
          return true
        } else if (dateReceived && _.includes(_.toLower(dateReceived), formattedFilter)) {
          return true
        } else if (datePriced && _.includes(_.toLower(datePriced), formattedFilter)) {
          return true
        } else if (dateSubmitted && _.includes(_.toLower(dateSubmitted), formattedFilter)) {
          return true
        } else if (row.dom && _.includes(_.toLower(row.dom), formattedFilter)) {
          return true
        } else if (row.make && _.includes(_.toLower(row.make), formattedFilter)) {
          return true
        } else if (row.model && _.includes(_.toLower(row.model), formattedFilter)) {
          return true
        } else if (row.number && _.includes(_.toLower(row.number), formattedFilter)) {
          return true
        }  else if (row.orderNumbers && row.orderNumbers.length && _.includes(_.toLower(_.join(row.orderNumbers, ' ')), formattedFilter)) {
          return true
        }  else if (row.quoteCompletion && _.includes(_.toLower(row.quoteCompletion), formattedFilter)) {
          return true
        }  else if (row.rego && _.includes(_.toLower(row.rego), formattedFilter)) {
          return true
        }  else if (row.vin && _.includes(_.toLower(row.vin), formattedFilter)) {
          return true
        } else if (row.partsNumbers && _.includes(_.toLower(row.partsNumbers), formattedFilter)) {
          return true;
        } else if (row.suppliers && row.suppliers.length && _.includes(_.toLower(_.join(row.suppliers, ' ')), formattedFilter)) {
          return true;
        }

      }

      // return _.some(row, v => _.includes(v, filter));
    },
    clickRFQRow:function (item,index,event) {
      this.$router.push({name: 'RepairerRFQView', params: {rfq_id: item.id, isNeedOpenChat: item.hasUnreadMessages}});
      return;
    },
    clickOrderRow: function (order, index, event) {
      this.$router.push({ path: '/r/order/' + order.id })
      return
    },
    onCreditRowClick: function (item, index, event) {
      this.$router.push('/r/credit/view/' + item.id)
      return
    },
    filterTable2 (row, filter) {

      let formattedFilter = filter.toLowerCase()
      let dateDue = row.dateDue ? moment(row.dateDue).format('DD/MM/YYYY || hh:mmA') : ''
      let dateCreated = row.dateCreated ? moment(row.dateCreated).format('DD/MM/YYYY') : ''

      if (filter === 'Ordered') return this.filterByStatus(row, 'Open')
      else if (filter === 'On back-order') return row.onBackOrder
      else if (filter === 'Shipped') return row.shipped
      else if (filter === 'Delivered') return this.filterByStatus(row, 'Delivered')

      if (dateDue && _.includes(_.toLower(dateDue), formattedFilter)) {
        return true
      } else if (dateCreated && _.includes(_.toLower(dateCreated), formattedFilter)) {
        return true
      } else if (row.partsNumbers && _.includes(_.toLower(row.partsNumbers), formattedFilter)) {
        return true
      } else if (row.QPNumber && _.includes(_.toLower(row.QPNumber), formattedFilter)) {
        return true
      } else if (row.costStr && _.includes(_.toLower(row.costStr), formattedFilter)) {
        return true
      } else if (row.delivered && _.includes(_.toLower(_.toString(row.delivered)), formattedFilter)) {
        return true
      } else if (row.make && _.includes(_.toLower(row.make), formattedFilter)) {
        return true
      } else if (row.makeModel && _.includes(_.toLower(row.makeModel), formattedFilter)) {
        return true
      } else if (row.model && _.includes(_.toLower(row.model), formattedFilter)) {
        return true
      } else if (row.number && _.includes(_.toLower(row.number), formattedFilter)) {
        return true
      } else if (row.rego && _.includes(_.toLower(row.rego), formattedFilter)) {
        return true
      } else if (row.rfqNumber && _.includes(_.toLower(row.rfqNumber), formattedFilter)) {
        return true
      } else if (row.supplierName && _.includes(_.toLower(row.supplierName), formattedFilter)) {
        return true
      } else if (row.vin && _.includes(_.toLower(row.vin), formattedFilter)) {
        return true
      }

    },
  },
  computed: {
    ...mapGetters({
      gettersAllRFQs: 'repairer/rfq/getAllRFQs',
      gettersAllCredits: 'repairer/credit/getAllCredits',
      gettersAllOrders: 'repairer/order/getAllOrders',
      messages: 'repairer/dashboard/getMessages',
      filesUpdater: 'repairer/dashboard/getFiles',
      getActivityList: 'repairer/dashboard/getActivityList',
    }),
    computedRfqFields() {
      let isShowBidders = _.some(this.rfq, (r) => {
        return r.status === 'Priced' || r.status == 'Submitted'
      })
      let isDateSubmitted = _.some(this.rfq, (r) => {
        return r.status === 'Priced' || r.status == 'Submitted' || r.status == 'Ordered'
      })
      let data = _.cloneDeep(this.rfqFields)
      if (!isShowBidders) {
        let index = _.findIndex(data, (f) => {
          return f.key === 'bidders'
        })
        data.splice(index, 1)
      }
      if (!isDateSubmitted) {
        let index = _.findIndex(data, (f) => {
          return f.key === 'dateSubmitted'
        })
        data.splice(index, 1)
      }
      return data
    },
    ordersActivity() {
      return _.orderBy(_.filter(this.getActivityList, (a) => {
        let id = a?.data?.orderId;
        if (a?.entity?.type == 'order' && a?.entity?.id) {
          id = a.entity.id
        }
        return  (a?.entity?.type == 'order' || a?.entity?.type == 'back_order') && _.includes(this.jobData.orderIds, id)
      }), (itm) => {return new Date(itm.createdOn).getTime()}, ['desc'])
    },
    computedCustomCountsCredit() {
      return {
        'credit': this.jobData.creditIds.length
      }
    },
    computedCustomCountsRfq() {
      return {
        'rfq': this.jobData.rfqIds.length
      }
    },
    computedCustomCountsOrder() {
      return {
        'order': this.jobData.orderIds.length
      }
    },
    currentRfq() {
      return _.find(this.gettersAllRFQs, (r) => {
        return Number(r.id) === Number(this.$route.params.job_id)
      })
    },
    rfq() {
      return _.filter(this.gettersAllRFQs, (r) => {
        return this.jobData.rfqIds.includes(r.id)
      })
    },
    orders() {
      return _.filter(this.gettersAllOrders, (r) => {
        return this.jobData.orderIds.includes(r.id)
      })
    },
    credits() {
      return _.filter(this.gettersAllCredits, (r) => {
        return this.jobData.creditIds.includes(r.id)
      })
    },
    messagesCredit() {
      let ids = []
      _.forEach(this.credits, (r) => {
        ids.push(r.id)
      })
      return _.filter(this.messages, (m) => {
        return _.includes(ids, m.creditId)
      })
    },
    messagesRfqs() {
      let ids = [this.$route.params.job_id]
      _.forEach(this.rfq, (r) => {
        ids.push(r.id)
      })
      return _.filter(this.messages, (m) => {
        return _.includes(ids, m.rfqId)
      })
    },
    messagesOrders() {
      let ids = []
      _.forEach(this.orders, (r) => {
        ids.push(r.id)
      })
      return _.filter(this.messages, (m) => {
        return _.includes(ids, m.orderId)
      })
    },
    computedSearchFilters1() {
      let partNumbers = {}
      let suppliers = {}

      this.rfq.forEach(rfq => {
        if (rfq.partsNumbers) {
          let numbers = rfq.partsNumbers.split(';');
          _.forEach(numbers, n => {
            partNumbers[n.toLowerCase().trim()] = { label: n.toLowerCase().trim() }
          })
        }
        if (rfq.suppliers) {
          _.forEach(rfq.suppliers, s => {
            suppliers[s.toLowerCase().trim()] = { label: s.trim() }
          })
        }

      });

      partNumbers = _.orderBy(Object.values(partNumbers), [(itm) => itm.label.toLowerCase().trim()], ["asc"]);
      suppliers = _.orderBy(Object.values(suppliers), [(itm) => itm.label.toLowerCase().trim()], ["asc"]);

      // Create date template
      const date = [
        {
          id: 'date',
          label: "Date",
          resultObjectOnly: true,
          type: "Date",
          iconClass: "bx-calendar"
        }
      ];

      // Create searchFilter filters
      let r = [
        {
          id: 'partNumber',
          label: 'Part Number',
          iconClass: 'bx-user',
          options: [
            partNumbers,
          ]
        },
        {
          id: 'make',
          label: 'Make',
          iconClass: 'bx-user',
          options: []
        },
        {
          id: 'rego',
          label: 'Rego',
          iconClass: 'bx-user',
          options: []
        },
        {
          id: 'vin',
          label: 'VIN',
          iconClass: 'bx-user',
          options: []
        },
        {
          id: 'orderNumber',
          label: 'Order Number',
          iconClass: 'bxs-user-badge',
          options: []
        },
        {
          id: 'quotingPackageNbr',
          label: 'Quoting Package Nbr',
          iconClass: 'bx-car',
          options: []
        },
        {
          id: 'supplier',
          label: 'Suppliers',
          iconClass: 'bx-car',
          options: [
            suppliers,
          ]
        }
      ];
      return r
    },
    computedSearchFilters2 () {
      let makes = {}
      let partNumbers = {}
      let suppliers = {}
      let invoiceNbrs = {}
      let data = this.orders.slice()

      data.forEach(rfq => {
        if (rfq.partsNumbers) {
          let numbers = rfq.partsNumbers.split(';')
          _.forEach(numbers, n => {
            partNumbers[n.toLowerCase().trim()] = { label: n.toLowerCase().trim() }
          })
        }

      })

      data.forEach(rfq => {
        if (rfq.supplierName) {
          suppliers[rfq.supplierName.trim().toLowerCase()] = { label: rfq.supplierName.trim() }
        }
      })

      data.forEach(rfq => {
        if (rfq.make) {
          makes[rfq.make.trim().toLowerCase()] = { label: rfq.make.trim() }
        }
      })

      data.forEach(rfq => {
        if (rfq.invoiceNumber) {
          invoiceNbrs[rfq.invoiceNumber.trim().toLowerCase()] = { label: rfq.invoiceNumber.trim() }
        }
      })

      suppliers = _.orderBy(Object.values(suppliers), ['label'], ['asc'])
      invoiceNbrs = _.orderBy(Object.values(invoiceNbrs), ['label'], ['asc'])
      partNumbers = _.orderBy(Object.values(partNumbers), [(itm) => itm.label.toLowerCase().trim()], ['asc'])
      makes = _.orderBy(Object.values(makes), [(itm) => itm.label.toLowerCase().trim()], ['asc'])
      let statusOptions = [
        { label: 'Received' },
        { label: 'Incorrect Part Delivered' },
        { label: 'Incorrect Part Ordered' },
        { label: 'Damaged Part in Transport' },
        { label: 'Damaged Part EX Warehouse' },
        { label: 'No Longer Needed' },
        { label: 'Change in Repair Method' },
        { label: 'Cancelled Part' },
        { label: 'Not Received' },
        { label: 'Partial Delivery' },
        { label: 'Order Completed' },
      ]

      // Create searchFilter filters
      return [
        {
          id: 'partNumber',
          label: 'Part Number',
          iconClass: 'bx-user',
          options: [
            partNumbers,
          ]
        },
        {
          id: 'make',
          label: 'Make',
          iconClass: 'bx-user',
          options: [
            makes,
          ]
        },
        {
          id: 'invoiceNumber',
          label: 'Invoice Nbr',
          iconClass: 'bx-user',
          options: [
            invoiceNbrs,
          ]
        },
        {
          id: 'supplier',
          label: 'Supplier',
          iconClass: 'bx-user',
          options: [
            suppliers
          ]
        },
        {
          id: 'status',
          label: 'Status',
          iconClass: 'bx-user',
          options: [
            statusOptions
          ]
        },
      ]
    },
    isRegoFilterActive() {
      return !!(this.searchFilterObj1 && this.searchFilterObj1.hasOwnProperty('rego'));
    },
    isMakeFilterActive() {
      return !!(this.searchFilterObj1 && this.searchFilterObj1.hasOwnProperty('make'));
    },
    isVINFilterActive() {
      return !!(this.searchFilterObj1 && this.searchFilterObj1.hasOwnProperty('vin'));
    },
    isOrderNumberFilterActive() {
      return !!(this.searchFilterObj1 && this.searchFilterObj1.hasOwnProperty('orderNumber'));
    },
    isPartNumberFilterActive() {
      return !!(this.searchFilterObj1 && this.searchFilterObj1.hasOwnProperty('partNumber'));
    },
    isSupplierFilterActive() {
      return !!(this.searchFilterObj1 && this.searchFilterObj1.hasOwnProperty('supplier'));
    },
    isQuotingPackageNbrFilterActive() {
      return !!(this.searchFilterObj1 && this.searchFilterObj1.hasOwnProperty('quotingPackageNbr'));
    },
    isHasFilters() {
      return this.isRegoFilterActive || this.isMakeFilterActive || this.isVINFilterActive || this.isOrderNumberFilterActive || this.isQuotingPackageNbrFilterActive;
    },

    isPartNumberFilterActive2 () {
      for (let key in this.searchFilterObj2) {
        if (this.searchFilterObj2[key].partNumber) {
          return true
        }
      }
      return false
    },
    isMakeFilterActive2 () {
      for (let key in this.searchFilterObj2) {
        if (this.searchFilterObj2[key].make) {
          return true
        }
      }
      return false
    },
    isStatusFilterActive2 () {
      for (let key in this.searchFilterObj2) {
        if (this.searchFilterObj2[key].status) {
          return true
        }
      }
      return false
    },
    isSupplierFilterActive2 () {
      for (let key in this.searchFilterObj2) {
        if (this.searchFilterObj2[key].supplier) {
          return true
        }
      }
      return false
    },
    isInvoiceNumberFilterActive2 () {
      for (let key in this.searchFilterObj2) {
        if (this.searchFilterObj2[key].invoiceNumber) {
          return true
        }
      }
      return false
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  mounted() {
    this.loadData()
    this.isShowSearch1 = true;
    setTimeout(() => {
      this.isShowSearch2 = true
    }, 10)
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
}
</script>

<template>
  <div class="job-view">
    <div class="page-header">
      <h4>Job View<span v-if="currentRfq && currentRfq.QPNumber"> | {{currentRfq.QPNumber}}</span><span v-if="currentRfq && (currentRfq.make || currentRfq.model)"> | {{currentRfq.make}} {{currentRfq.model}}</span></h4>
      <ol class="breadcrumbs">
        <li class="breadcrumb-item home">
          <router-link to="/r">Dashboard</router-link>
        </li>
        <li class="breadcrumb-item">
          <span style="cursor: pointer;">View Job Quotes & Orders</span>
        </li>
      </ol>
<!--      <div class="">-->
<!--        <sticky-header-->
<!--            v-if="isShowStickyHeader"-->
<!--            @onFocusPaintCode="onFocusPaintCode"-->
<!--            @saveClaimNbr="saveClaimNbr"-->
<!--            @onBlurPaintCode="onBlurPaintCode"-->
<!--            @onQuoteNbrBlur="onQuoteNbrBlur"-->
<!--            @selectedAllStock="selectedAllStock"-->
<!--            @updatePaintCodeForRepair="updatePaintCodeForRepair"-->
<!--            @viewOrder="viewOrder"-->
<!--            @updateSupplierQuoteNumber="updateSupplierQuoteNumber"-->
<!--            @selectQuoteNbr="selectQuoteNbr"-->
<!--            @removeQuoteNbr="removeQuoteNbr"-->
<!--            @onFocusOrBlurClaimNbr="v => isClaimNumberFieldFocused = v"-->
<!--            :isSelectedImportCSV="isSelectedImportCSV"-->
<!--            :priceResubmitFlag="priceResubmitActiveFlag"-->
<!--            :isQuoteNbrFocus="isQuoteNbrFocus"-->
<!--            :isSelectedImportDMS="isSelectedImportDMS"-->
<!--            :showPlaceholderPaintCode="showPlaceholderPaintCode"-->
<!--            :isRfqToUsed="isRfqToUsed"-->
<!--            :isFlashingPaintCode="isFlashingPaintCode"-->
<!--            :isRFQNew="isRFQNew"-->
<!--            :isAdditionalRfq="isAdditionalRfq"-->
<!--            :isRFQWon="isRFQWon"-->
<!--            :isNewStatus="rfq.status === 'New'"-->
<!--            :isRFQPriced="isRFQPriced"-->
<!--            :priceResubmitActiveFlag="priceResubmitActiveFlag"-->
<!--            :isLockedRfq="isLockedRfq"-->
<!--            :computedUpdatedQuoteNbr="computedUpdatedQuoteNbr"-->
<!--            :quoteNumbersFiltered="quoteNumbersFiltered"-->
<!--            :activeSupplierId="activeSupplierId"-->
<!--            :clickedSupplierId="clickedSupplierId"-->
<!--            :activeColumnIndex="activeColumnIndex"-->
<!--            @changedClickedSupplierId="changedClickedSupplierId"-->
<!--            @changedActiveColumnIndex="changedActiveColumnIndex"-->
<!--            @changedActiveSupplierId="changedActiveSupplierId"-->
<!--            :rfq="rfq"-->
<!--        ></sticky-header>-->
<!--      </div>-->
    </div>
      <div v-if="!isShowPdf" class="job-view-body">
        <tabs
            :custom-counts="computedCustomCountsRfq"
        >
          <div class="job-view-body-bottom-border"></div>
          <tab name="Request For Quotes" id="rfq">
            <div class="d-flex table-and-notification-wrapper">
              <div class="col-md-6 rfq-table table">
                <b-table
                    :items="rfq"
                    :fields="computedRfqFields"
                    :filter="filter1"
                    :filter-function="filterTable1"
                    @row-clicked="clickRFQRow"
                >
                  <template #cell(bidders)="data">
                    <div v-if="data && data.item && (data.item.status == 'Priced' || data.item.status == 'Submitted')" class="">
                      <span  v-for="(bidder, ind) in data.value" :key="ind">
                        <i v-if="!(((bidder.status === 'Declined') || (bidder.status === 'Priced' && bidder.isUpdatedPrices)) && !true)"
                           style="font-size: 23px"
                           :class="{
                             'bx bx-minus-circle bidder-color-gray': bidder.status === 'Nil Stock',
                             'bx bxs-badge-dollar': bidder.status !== 'Nil Stock',
                             'bidder-color-gray': bidder.status === 'Not Priced',
                             'bidder-color-red red-icon-blinker': bidder.status === 'Declined',
                             'bidder-color-green': bidder.status === 'Priced',
                             'orange-icon orange-icon-blinker': bidder.status === 'Priced' && bidder.isSentUpdateRequested,
                             'green-icon-blinker': bidder.status === 'Priced' && bidder.isUpdatedPrices,
                           }"
                           v-b-tooltip.hover="{customClass: 'ps-tooltip job-view-tooltip', html: true, title: getBidderTooltip(bidder, data), placement:'rightbottom'}"
                        ></i>
                      </span>
                    </div>
                  </template>

                  <template #cell(dateSubmitted)="data">
                    <span v-if="data && data.item && (data.item.status == 'Priced' || data.item.status == 'Submitted' || data.item.status === 'Ordered')" >
                        {{ data.value | formatDate }}
                    </span>
                  </template>
                </b-table>
              </div>
              <div class="job-view-right">
                <div class="d-flex">
                  <div class="job-view-right-block">
                    <div class="job-view-title">Files</div>
                    <div class="job-view-notifications">
                      <template v-if="jobData.rfqFiles && jobData.rfqFiles.length">
                        <div class="job-view-notification_item clickable" @click="downloadFile(itm)" v-for="(itm) in jobData.rfqFiles">
                          <div class="job-view-notification_item-title">
                            <span v-if="itm.type === 'scheme'">Parts Diagram</span>
                            <span v-else-if="itm && itm.fileType && itm.fileType === 'application/pdf'">Quote PDF</span>
                            <span v-else-if="itm && itm.fileType && itm.fileType === 'text/html'">Quote HTML</span>
                            <span v-else-if="itm && itm.fileType && itm.fileType === 'text/csv'">Quote CSV</span>
                            <span v-else-if="itm && itm.fileType && itm.fileType === 'image/jpeg'">Image</span>
                          </div>
                          <div class="job-view-notification_item-info">{{itm.name}}<span class="job-view-notification_item-info-icon-file">
                            <i v-if="itm && itm.fileType && itm.fileType === 'application/pdf'" class='bx bxs-file-pdf'></i>
                            <i v-else-if="itm && itm.fileType && itm.fileType === 'text/html'" class='bx bxs-file-html'></i>
                            <i v-else-if="itm && itm.fileType && itm.fileType === 'text/csv'" class='bx bxs-file-txt'></i>
                            <i v-else-if="itm && itm.fileType && itm.fileType === 'image/jpeg'" class='bx bxs-file-image' ></i>
                        </span></div>
                        </div>
                      </template>
                      <template v-else>
                        <div style="padding: 10px 0px 0px 5px;">No files to show</div>
                      </template>
                    </div>
                  </div>
                  <div class="job-view-right-block">
                    <div class="job-view-title">Notification</div>
                    <div class="job-view-notifications">
                      <div style="padding: 10px 0px 0px 5px;">No new notifications</div>
<!--                      <div class="job-view-notification_item active">-->
<!--                        <div class="job-view-notification_item-title">Back Order - Acceptance</div>-->
<!--                        <div class="job-view-notification_item-info"><span>McGraths</span><span class="job-view-notification_item-info-icon-notification"><i class='bx bx-bell active' ></i></span></div>-->
<!--                      </div>-->
<!--                      <div class="job-view-notification_item">-->
<!--                        <div class="job-view-notification_item-title">Update Quote-Completed</div>-->
<!--                        <div class="job-view-notification_item-info"><span>McGraths</span><span class="job-view-notification_item-info-icon-notification active"><i class='bx bx-bell' ></i></span></div>-->
<!--                      </div>-->
                    </div>
                  </div>
                  <div class="job-view-right-block">
                    <div class="job-view-title">Message</div>
                    <div class="job-view-notifications">
                      <template v-if="messagesRfqs && messagesRfqs.length">
                        <div class="job-view-notifications-last">
                          <div v-for="itm in messagesRfqs" @click="redirectToRfq(itm)" class="job-view-notification_item" :class="{'active': !itm.viewedOn}">
                            <div class="job-view-notification_item-title"><div>{{itm.companyFrom}}<span class="numb"><span v-if="itm.QPNumber"> - {{itm.QPNumber}}</span> - {{formatDateTime2(itm.createdOn)}}</span></div></div>
                            <div class="job-view-notification_item-info">{{itm.message}} <span class="job-view-notification_item-info-icon-message">
<!--                          <i class='bx bx-message-rounded-dots active' ></i>-->
                        </span></div>
                          </div>
                        </div>
                      </template>
                      <template v-else>
                        <div style="padding: 10px 0px 0px 5px;" >No new messages</div>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </tab>
          <template slot="nav-item-right">
            <div class="tabs-nav-right-search">
              <search-filter
                  v-if="isShowSearch1"
                  type="estimates"
                  v-model="searchFilter1"
                  :filters="computedSearchFilters1"
                  :limitLength="50"
                  :filterValue="filter1"
                  :debug="false"
                  height="41px"
                  @change="onSearchFilterChange1"
                  aria-expanded="false"
                  data-toggle="dropdown"
              />
              <!--                        <b-form-input v-model="filter" type="text" class="search-input" placeholder="Search or Filter results"-->
              <!--                                      debounce="500"></b-form-input>-->
            </div>
          </template>
        </tabs>
      </div>
    <div v-if="!isShowPdf" class="job-view-body">
      <tabs
          :custom-counts="computedCustomCountsOrder"
      >
        <div class="job-view-body-bottom-border"></div>
        <tab name="Parts Orders" id="order">
          <div class="d-flex table-and-notification-wrapper">
            <div class="col-md-6 table parts-order-table">
              <b-table
                  :items="filterData(orders)"
                  :fields="orderFields"
                  :filter="filter2"
                  :filter-function="filterTable2"
                  @row-clicked="clickOrderRow"
              >
                <template #cell(deliveryType)="data">
                  {{ formatDeliveryType(data.value) }}
                  <span v-if="data.item.onBackOrder" style="color: red"><br v-if="data.value"/>Back Order</span>
                </template>
                <template #cell(dateDue)="data">
                  <template v-if="!data.value">Next available run</template>
                  <template v-else-if="isToday(data.value)">
                    <span style="color: #0cb458">Today</span>
                  </template>
                  <template v-else-if="isTomorrow(data.value)">
                    <span style="color: #f0710f">Tomorrow</span>
                  </template>
                  <template v-else>
                    {{ data.value | formatDate('DD/MM/YYYY || hh:mmA') }}
                  </template>
                </template>
              </b-table>
            </div>
            <div class="job-view-right">
              <div class="d-flex">
                <div class="job-view-right-block">
                  <div class="job-view-title">Files</div>
                  <div class="job-view-notifications">
                    <template v-if="jobData.orderFiles && jobData.orderFiles.length">
                      <div class="job-view-notification_item clickable" @click="downloadFile(itm)" v-for="(itm) in jobData.orderFiles">
                        <div class="job-view-notification_item-title">
                          <span v-if="itm.type === 'scheme'">Parts Diagram</span>
                          <span v-else-if="itm && itm.fileType && itm.fileType === 'application/pdf'">
                            <span v-if="itm.name.includes('NonReturnable')">Non-Returnable pdf</span>
                            <span v-else>Invoice PDF</span>
                          </span>
                          <span v-else-if="itm && itm.fileType && itm.fileType === 'text/html'">Quote HTML</span>
                          <span v-else-if="itm && itm.fileType && itm.fileType === 'text/csv'">Quote CSV</span>
                          <span v-else-if="itm && itm.fileType && itm.fileType === 'image/jpeg'">Image</span>
                        </div>
                        <div class="job-view-notification_item-info">{{itm.name}}<span class="job-view-notification_item-info-icon-file">
                          <i v-if="itm && itm.fileType && itm.fileType === 'application/pdf'" class='bx bxs-file-pdf'></i>
                          <i v-else-if="itm && itm.fileType && itm.fileType === 'text/html'" class='bx bxs-file-html'></i>
                          <i v-else-if="itm && itm.fileType && itm.fileType === 'text/csv'" class='bx bxs-file-txt'></i>
                          <i v-else-if="itm && itm.fileType && itm.fileType === 'image/jpeg'" class='bx bxs-file-image' ></i>
                        </span></div>
                      </div>
                    </template>
                    <template v-else>
                      <div style="padding: 10px 0px 0px 5px;">No files to show</div>
                    </template>
                  </div>
                </div>
                <div class="job-view-right-block">
                  <div class="job-view-title">Notification</div>
                  <div class="job-view-notifications">
                    <div v-if="_isEmpty(ordersActivity)" style="padding: 10px 0px 0px 5px;">No new notifications</div>
<!--                    <div class="job-view-notification_item active">-->
<!--                      <div class="job-view-notification_item-title">Back Order - Acceptance</div>-->
<!--                      <div class="job-view-notification_item-info"><span>McGraths</span><span class="job-view-notification_item-info-icon-notification"><i class='bx bx-bell active' ></i></span></div>-->
<!--                    </div>-->
                    <notifications v-else :activity="ordersActivity"></notifications>
                  </div>
                </div>
                <div class="job-view-right-block">
                  <div class="job-view-title">Message</div>
                  <div class="job-view-notifications">
                    <template v-if="messagesOrders && messagesOrders.length">
                      <div v-for="itm in messagesOrders" @click="redirectToRfq(itm)" class="job-view-notification_item" :class="{'active': !itm.viewedOn}">
                        <div class="job-view-notification_item-title"><div>{{itm.companyFrom}}<span class="numb"><span v-if="itm.QPNumber"> - {{itm.QPNumber}}</span>{{getOrderNumber(itm.orderId)}} - {{formatDateTime2(itm.createdOn)}}</span></div></div>
                        <div class="job-view-notification_item-info">{{itm.message}} <span class="job-view-notification_item-info-icon-message">
<!--                        <i class='bx bx-message-rounded-dots active' ></i>-->
                      </span></div>
                      </div>
                    </template>
                    <template v-else>
                      <div style="padding: 10px 0px 0px 5px;">No new messages</div>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </tab>
        <template slot="nav-item-right">
          <div class="tabs-nav-right-search">
            <search-filter
                v-if="isShowSearch2"
                type="estimates"
                v-model="searchFilter2"
                :filters="computedSearchFilters2"
                :limitLength="50"
                :filterValue="filter2"
                @change="onSearchFilterChange2"
                :limit-length="isSupplierFilterActive2 ? 10 : 50"
                :isMyltipleOptions="true"
                :isMyltiple="true"
                :optionsLength="5"
                :debug="false"
                height="41px"
                aria-expanded="false"
                data-toggle="dropdown"
            />
            <!--                        <b-form-input v-model="filter" type="text" class="search-input" placeholder="Search or Filter results"-->
            <!--                                      debounce="500"></b-form-input>-->
          </div>
        </template>
      </tabs>
    </div>
    <div v-if="!isShowPdf" class="job-view-body">
      <tabs
          :custom-counts="computedCustomCountsCredit"
      >
        <div class="job-view-body-bottom-border"></div>
        <tab name="Credits Management" id="credit">
          <div class="d-flex table-and-notification-wrapper">
            <div class="col-md-6 credit-management-table table">
              <b-table
                  :items="credits"
                  :fields="creditsFields"
                  :filter="filter3"
                  @row-clicked="onCreditRowClick"
              >
                <template #cell(dateCreated)="data">
                  {{ data.value | formatDate }}
                </template>
                <template v-slot:cell(status)="data">
                  <span v-if="isStatusUnderReview(data.item)" class="color_review">Under Review</span>
                  <span v-else-if="isStatusPartialApproved(data.item)"
                        class="color_partial_approved">Partial Approved</span>
                  <span v-else-if="isStatusApproved(data.item)" class="color_approved">Approved</span>
                  <span v-else-if="isStatusDenied(data.item)" class="color_denied">Denied</span>
                  <span v-else-if="isStatusCancelled(data.item)" class="color_cancelled">Cancelled</span>
                </template>
              </b-table>
            </div>
            <div class="job-view-right">
              <div class="d-flex">
                <div class="job-view-right-block">
                  <div class="job-view-title">Files</div>
                  <div class="job-view-notifications">
                    <template v-if="jobData.creditFiles && jobData.creditFiles.length">
                      <div @click="downloadFile(itm)" class="job-view-notification_item clickable" v-for="(itm) in jobData.creditFiles">
                        <div class="job-view-notification_item-title">
                          <span v-if="itm.type === 'scheme'">Parts Diagram</span>
                          <span v-else-if="itm && itm.fileType && itm.fileType === 'application/pdf'">Quote PDF</span>
                          <span v-else-if="itm && itm.fileType && itm.fileType === 'text/html'">Quote HTML</span>
                          <span v-else-if="itm && itm.fileType && itm.fileType === 'text/csv'">Quote CSV</span>
                          <span v-else-if="itm && itm.fileType && itm.fileType === 'image/jpeg'">Image</span>
                        </div>
                        <div class="job-view-notification_item-info">{{itm.name}}<span class="job-view-notification_item-info-icon-file">
                          <i v-if="itm && itm.fileType && itm.fileType === 'application/pdf'" class='bx bxs-file-pdf'></i>
                          <i v-else-if="itm && itm.fileType && itm.fileType === 'text/html'" class='bx bxs-file-html'></i>
                          <i v-else-if="itm && itm.fileType && itm.fileType === 'text/csv'" class='bx bxs-file-txt'></i>
                          <i v-else-if="itm && itm.fileType && itm.fileType === 'image/jpeg'" class='bx bxs-file-image' ></i>
                        </span></div>
                      </div>
                    </template>
                    <template v-else>
                      <div style="padding: 10px 0px 0px 5px;">No files to show</div>
                    </template>
                  </div>
                </div>
                <div class="job-view-right-block">
                  <div class="job-view-title">Notification</div>
                  <div class="job-view-notifications">
                    <div style="padding: 10px 0px 0px 5px;">No new notifications</div>
<!--                    <div class="job-view-notification_item active">-->
<!--                      <div class="job-view-notification_item-title">Back Order - Acceptance</div>-->
<!--                      <div class="job-view-notification_item-info"><span>McGraths</span><span class="job-view-notification_item-info-icon-notification"><i class='bx bx-bell active' ></i></span></div>-->
<!--                    </div>-->
<!--                    <div class="job-view-notification_item">-->
<!--                      <div class="job-view-notification_item-title">Update Quote-Completed</div>-->
<!--                      <div class="job-view-notification_item-info"><span>McGraths</span><span class="job-view-notification_item-info-icon-notification active"><i class='bx bx-bell' ></i></span></div>-->
<!--                    </div>-->
                  </div>
                </div>
                <div class="job-view-right-block">
                  <div class="job-view-title">Message</div>
                  <div class="job-view-notifications">
                    <template v-if="messagesCredit && messagesCredit.length">
                      <div class="job-view-notifications-last">
                        <div v-for="itm in messagesCredit" @click="redirectToRfq(itm)" class="job-view-notification_item" :class="{'active': !itm.viewedOn}">
                          <div class="job-view-notification_item-title"><div>{{itm.companyFrom}}<span class="numb"><span v-if="itm.QPNumber"> - {{itm.QPNumber}}</span> - {{formatDateTime2(itm.createdOn)}}</span></div></div>
                          <div class="job-view-notification_item-info">{{itm.message}} <span class="job-view-notification_item-info-icon-message">
<!--                        <i class='bx bx-message-rounded-dots active' ></i>-->
                      </span></div>
                        </div>
                      </div>
                    </template>
                    <template v-else>
                      <div style="padding: 10px 0px 0px 5px;">No new messages</div>
                    </template>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </tab>
        <template slot="nav-item-right">
          <div class="tabs-nav-right-search">
            <b-form-input v-model="filter3" type="text" class="search-input" placeholder="Search or Filter results" debounce="500"></b-form-input>
          </div>
        </template>
      </tabs>
    </div>

    <pdf-viewer v-if="isShowPdf" :documentHeight="documentHeight" :documentWidth="documentWidth" :documentPath="selectedUrl" @closePdf="isShowPdf = false"></pdf-viewer>
  </div>
</template>

<style>
.job-view-tooltip .tooltip-inner {
  border-radius: 0px 10px 10px 10px !important;
}
</style>
<style scoped>
@media (max-width: 1340px) {
  .table-and-notification-wrapper {
    flex-direction: column;
  }
  .table-and-notification-wrapper .table {
    max-width: none !important;
  }

  .table-and-notification-wrapper .job-view-right {
    padding-left: 15px;
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

.job-view-body >>> .nav-tabs.nav-tabs-2{
  padding-right: 0;
}

.job-view-body >>> .tabs-component{
  max-width: 520px;
}
.job-view-body-bottom-border{
  border-bottom: 10px solid #e3e4e7;
}
.job-view-right{
  width: 100%;
}
.job-view-right-block{
  flex: 0 1 33.333%;
}
.job-view-title{
  font-size: 18px;
  font-weight: bold;
  margin-left: 5px;
  border-bottom: 5px solid #7bbec0;
  display: inline-block;
  width: auto;
}
.job-view-notifications{
  height: 200px;
  overflow-y: scroll;
  margin-top: 5px;
}
.job-view-notifications::-webkit-scrollbar-track{
  background-color: #dadada;
  border-radius: 5px;
}
.job-view-notifications-last{
  border: 0;
}
.job-view-right .job-view-right-block:last-child .job-view-notifications::-webkit-scrollbar-track{
  background-color: #ffffff;
}
.job-view-right .job-view-right-block:last-child .job-view-notifications::-webkit-scrollbar-thumb{
  background-color: #979797;
}
.job-view-notification_item{
  min-height: 50px;
  border: 2px solid #D7D7D7FF;
  border-radius: 5px;
  margin: 5px 5px;
  font-size: 11px;
  padding: 5px 10px;
  position: relative;
  cursor: pointer;
}
.job-view-notification_item:hover {
  border: 2px solid #bdbdbd;
}
.job-view-notification_item-title{
  font-weight: 700;
}
.job-view-notification_item-info{
  font-weight: 500;
  padding-right: 8px;
}
.rfq-table >>> th:nth-child(1){
  width: 70px;
}
.rfq-table >>> th:nth-child(2){
  width: 89px;
}
.rfq-table >>> th:nth-child(3){
  width: 50px;
}

.rfq-table >>> th:nth-child(4){
  width: 79px;
}
.rfq-table >>> th:nth-child(7){
  width: 50px;
}
.parts-order-table >>> th:nth-child(1){
  width: 70px;
}
.parts-order-table >>> th:nth-child(2){
  width: 70px;
}
.credit-management-table >>> th:nth-child(4){
  width: 70px;
}
.credit-management-table >>> th:nth-child(5){
  width: 50px;
}
.credit-management-table >>> th:nth-child(6){
  width: 50px;
}
.job-view-notification_item-info .bx{
  font-size: 15px;
  position: absolute;
  right: 2px;
  bottom: 2px;
  color: #626262;
}
.job-view-notification_item-info .bx-bell.active,.bx-message-rounded-dots.active{
  color: #29babf;
}
.tabs-nav-right-search{
  float: left !important;
}
.table >>> .number{
  font-weight: bold;
}
.job-view-notification_item.active{
  border: 2px solid #7bbec0
}

.job-view-notification_item.active:hover{
  border: 2px solid #3E8385FF
}
.tabs-nav-right-search >>> .search-filter__input{
  z-index: 1 !important;
}
.tabs-nav-right-search >>> .search-filter__box{
  position: static;
}
.tabs-nav-right-search >>> .search-filter__li{
  z-index: 1 !important;
}

.tabs-nav-right-search >>> .search-filter__box__with__dropdown .search-filter__input{
  z-index: 999 !important;
}
.tabs-nav-right-search >>> .search-filter__box__with__dropdown .search-filter__box{
  position: static;
}
.tabs-nav-right-search >>> .search-filter__box__with__dropdown .search-filter__li{
  z-index: 999 !important;
}


</style>
