<template>
  <div class="repairer-credit-page">
    <div class="page-header">
      <div class="d-flex justify-content-between">
        <div class="">
          <h4>Credit Management</h4>
          <ol class="breadcrumbs">
            <li class="breadcrumb-item">
              <router-link :to="{ name: 'RepairerDashboard'}">Dashboard</router-link>
            </li>
            <li class="breadcrumb-item active">View Credit Management</li>
          </ol>
        </div>
        <div class="search-filter-in-header">
          <search-filter
              type="estimates"
              v-model="searchFilter2"
              :filterValue="searchFilter2"
              :isSearch="true"
              @onEnter="onEnter"
              :placeholder="'Search'"
              :additionalMenu="additionalMenu"
              @selectAdditional="redirectToRfqView"
              :debug="false"
              height="41px"
              aria-expanded="false"
              data-toggle="dropdown"
          />
        </div>
      </div>
    </div>
    <div class="repairer-credit-body">
      <tabs @changed="tabChanged" ref="repairer-credit-table" cache-lifetime="0" :options="{useUrlFragment: false}"
            :custom-counts="computedCustomCountForTab">
        <tab name="All" id="All" :class-name="'tab-content-table'">
          <div class="use-pagination-block-bottom">
            <b-table
                ref="allTable"
                responsive
                hover
                :items="gettersAllCredits"
                :fields="fields"
                :filter="filter"
                :current-page="gettersCreditAllCurrentPage"
                :per-page="gettersPerPage"
                @filtered="value => allCreditsFiltered = value.length"
                @row-contextmenu="onCreditRightClick"
                @row-clicked="onCreditRowClick"
            >
              <template v-slot:head()="data">
                <div v-if="!data.field.sortable">{{ data.label }}</div>
                <div v-if="data.field.sortable" class="table-sorting">{{ data.label }}<i></i></div>
              </template>
              <template #cell(dateCreated)="data">
                {{ data.value | formatDate }}
              </template>
              <template v-slot:cell(amount)="data">
                <span v-if="Number(data.value)">-</span>
                {{ data.value | formatMoney }}
              </template>
              <template v-slot:cell(partNumber)="data">
                <div v-html="data.value.split(',').join('<br>')"></div>
              </template>
              <template v-slot:cell(amountApproved)="data">
                <span v-if="isStatusApproved(data.item)">
                   <span v-if="Number(data.item.amountApproved)">-</span>
                  {{ data.item.amountApproved | formatMoney }}
                </span>
                <span v-else-if="isStatusPartialApproved(data.item)">
                   <span v-if="Number(data.item.amountApproved)">-</span>
                  {{ data.item.amountApproved | formatMoney }}
                </span>
              </template>

              <template v-slot:cell(status)="data">
                <span v-if="isStatusUnderReview(data.item)" class="color_review">Under Review</span>
                <span v-else-if="isStatusPartialApproved(data.item)"
                      class="color_partial_approved">Partial Approved</span>
                <span v-else-if="isStatusApproved(data.item)" class="color_approved">Approved</span>
                <span v-else-if="isStatusDenied(data.item)" class="color_denied">Denied</span>
                <span v-else-if="isStatusCancelled(data.item)" class="color_cancelled">Cancelled</span>
              </template>
              <template v-slot:cell(supplierName)="data">
                <template v-if="data.item.id === selectedItem">
                  <template>
                    <span>{{ data.value }}</span>
                    <div class="mt-3 ml-2">
                      <span v-if="data.item.status === 'Denied'">Supplier - Reason for Credit Denial: </span>
                      <template v-else-if="data.item.subStatus">
                        <span v-if="data.item.subStatus === 'Cancellation'">Repairer - Reason for cancellation: </span>
                        <span v-else-if="data.item.subStatus === 'Return'">Repairer - Reason for return: </span>
                      </template>
                    </div>
                    <div class="deny_reason mt-1 ml-2">
                                <textarea v-if="showDenyReasonBox && !data.item.reasonDenied"
                                          class="deny-reason-input"
                                          v-model="denyReason"
                                          @change="denyCredit"
                                          placeholder="Write Your reason for repairer here…">
                                </textarea>
                      <template v-else-if="data.item.status === 'Denied'">
                        {{ data.item.reasonDenied }}
                      </template>
                      <template v-else-if="data.item.subStatus">
                        {{ data.item.subStatusText }}
                      </template>
                    </div>
                  </template>
                </template>
                <span v-else>{{ data.value }}</span>
              </template>
            </b-table>
          </div>
          <block-pagination
              :role="'Credits'"
              :count="gettersAllCredits.length"
              :countFiltered="filter ? allCreditsFiltered : gettersAllCredits.length"
              :currentPage="gettersCreditAllCurrentPage"
              @changedCurrentPage="value => $store.commit('repairer/credit/repairerCreditAllCurrentPage', value)"
              :perPage="gettersPerPage"
              @changedPerPage="value => $store.commit('repairer/credit/repairerCreditPerPage', value)"
          >
          </block-pagination>
        </tab>
        <tab name="Under Review" id="UnderReview" :class-name="'tab-content-table'">
          <div class="use-pagination-block-bottom">
            <b-table
                ref="underTable"
                responsive
                hover
                :items="gettersUnderReviewCredits"
                :fields="fields"
                :filter="filter"
                :current-page="gettersCreditUnderCurrentPage"
                :per-page="gettersPerPage"
                @filtered="value => underReviewCreditsFiltered = value.length"
                @row-contextmenu="onCreditRightClick"
                @row-clicked="onCreditRowClick"
            >
              <template #cell(dateCreated)="data">
                {{ data.value | formatDate }}
              </template>
              <template v-slot:cell(amount)="data">
                <span v-if="Number(data.value)">-</span>
                {{ data.value | formatMoney }}
              </template>
              <template v-slot:cell(partNumber)="data">
                <div v-html="data.value.split(',').join('<br>')"></div>
              </template>
              <template v-slot:head()="data">
                <div v-if="!data.field.sortable">{{ data.label }}</div>
                <div v-if="data.field.sortable" class="table-sorting">{{ data.label }}<i></i></div>
              </template>
              <template v-slot:cell(amountApproved)="data">

              </template>
              <template v-slot:cell(status)="data">
                <span class="color_review">Credit Under Review</span>
              </template>
            </b-table>
          </div>
          <block-pagination
              :role="'Credits'"
              :count="gettersUnderReviewCredits.length"
              :countFiltered="filter ? underReviewCreditsFiltered : gettersUnderReviewCredits.length"
              :currentPage="gettersCreditUnderCurrentPage"
              @changedCurrentPage="value => $store.commit('repairer/credit/repairerCreditUnderCurrentPage', value)"
              :perPage="gettersPerPage"
              @changedPerPage="value => $store.commit('repairer/credit/repairerCreditPerPage', value)"
          >
          </block-pagination>
        </tab>
        <tab name="Approved" id="Approved" :class-name="'tab-content-table'">
          <div class="use-pagination-block-bottom">
            <b-table
                ref="approvedTable"
                responsive
                hover
                :items="gettersApprovedCredits"
                :fields="fields"
                :filter="filter"
                :current-page="gettersCreditApprovedCurrentPage"
                :per-page="gettersPerPage"
                @filtered="value => approvedCreditsFiltered = value.length"
                @row-contextmenu="onCreditRightClick"
                @row-clicked="onCreditRowClick"
            >
              <template #cell(dateCreated)="data">
                {{ data.value | formatDate }}
              </template>
              <template v-slot:cell(amount)="data">
                <span v-if="Number(data.value)">-</span>
                {{ data.value | formatMoney }}
              </template>
              <template v-slot:cell(partNumber)="data">
                <div v-html="data.value.split(',').join('<br>')"></div>
              </template>
              <template v-slot:head()="data">
                <div v-if="!data.field.sortable">{{ data.label }}</div>
                <div v-if="data.field.sortable" class="table-sorting">{{ data.label }}<i></i></div>
              </template>
              <template v-slot:cell(amountApproved)="data">
                <span v-if="Number(data.item.amountApproved)">-</span>
                <span >{{ data.item.amountApproved | formatMoney }}</span>
              </template>
              <template v-slot:cell(status)="data">
                <span class="color_approved">Credit Approved</span>
              </template>
            </b-table>
          </div>
          <block-pagination
              :role="'Credits'"
              :count="gettersApprovedCredits.length"
              :countFiltered="filter ? approvedCreditsFiltered : gettersApprovedCredits.length"
              :currentPage="gettersCreditApprovedCurrentPage"
              @changedCurrentPage="value => $store.commit('repairer/credit/repairerCreditApprovedCurrentPage', value)"
              :perPage="gettersPerPage"
              @changedPerPage="value => $store.commit('repairer/credit/repairerCreditPerPage', value)"
          >
          </block-pagination>
        </tab>
        <tab name="Denied" id="Denied" :class-name="'tab-content-table'">
          <div class="use-pagination-block-bottom">
            <b-table
                ref="deniedTable"
                responsive
                hover
                :items="gettersDeniedCredits"
                :fields="fields"
                :filter="filter"
                :current-page="gettersCreditDeniedCurrentPage"
                :per-page="gettersPerPage"
                @row-contextmenu="onCreditRightClick"
                @row-clicked="onCreditRowClick"
                @filtered="value => deniedCreditsFiltered = value.length"
            >
              <template #cell(dateCreated)="data">
                {{ data.value | formatDate }}
              </template>
              <template v-slot:cell(amount)="data">
                <span v-if="Number(data.value)">-</span>
                {{ data.value | formatMoney }}
              </template>
              <template v-slot:cell(partNumber)="data">
                <div v-html="data.value.split(',').join('<br>')"></div>
              </template>
              <template v-slot:head()="data">
                <div v-if="!data.field.sortable">{{ data.label }}</div>
                <div v-if="data.field.sortable" class="table-sorting">{{ data.label }}<i></i></div>
              </template>
              <template v-slot:cell(status)="data">
                <span class="color_denied">Credit Denied</span>
              </template>
              <template v-slot:cell(amountApproved)="data">

              </template>
              <template v-slot:cell(supplierName)="data">
                <template v-if="data.item.id === selectedItem">
                  <template>
                    <span>{{ data.value }}</span>
                    <div class="mt-3 ml-2">
                      <span v-if="data.item.status === 'Denied'">Supplier - Reason for Credit Denial: </span>
                      <template v-else-if="data.item.subStatus">
                        <span v-if="data.item.subStatus === 'Cancellation'">Repairer - Reason for cancellation: </span>
                        <span v-else-if="data.item.subStatus === 'Return'">Repairer - Reason for return: </span>
                      </template>
                    </div>
                    <div class="deny_reason mt-1 ml-2">
                                <textarea v-if="showDenyReasonBox && !data.item.reasonDenied"
                                          class="deny-reason-input"
                                          v-model="denyReason"
                                          @change="denyCredit"
                                          placeholder="Write Your reason for repairer here…">
                                </textarea>
                      <template v-else-if="data.item.status === 'Denied'">
                        {{ data.item.reasonDenied }}
                      </template>
                      <template v-else-if="data.item.subStatus">
                        {{ data.item.subStatusText }}
                      </template>
                    </div>
                  </template>
                </template>
                <span v-else>{{ data.value }}</span>
              </template>
            </b-table>
          </div>
          <block-pagination
              :role="'Credits'"
              :count="gettersDeniedCredits.length"
              :countFiltered="filter ? deniedCreditsFiltered : gettersDeniedCredits.length"
              :currentPage="gettersCreditDeniedCurrentPage"
              @changedCurrentPage="value => $store.commit('repairer/credit/repairerCreditDeniedCurrentPage', value)"
              :perPage="gettersPerPage"
              @changedPerPage="value => $store.commit('repairer/credit/repairerCreditPerPage', value)"
          >
          </block-pagination>
        </tab>
        <tab name="Cancelled" id="Cancelled" :class-name="'tab-content-table'">
          <div class="use-pagination-block-bottom">
            <b-table
                ref="cancelledTable"
                responsive
                hover
                :items="gettersCancelledCredits"
                :fields="fields"
                :filter="filter"
                :current-page="gettersCreditCancelledCurrentPage"
                :per-page="gettersPerPage"
                @row-contextmenu="onCreditRightClick"
                @row-clicked="onCreditRowClick"
                @filtered="value => cancelledCreditsFiltered = value.length"
            >
              <template #cell(dateCreated)="data">
                {{ data.value | formatDate }}
              </template>
              <template v-slot:cell(amount)="data">
                <span v-if="Number(data.value)">-</span>
                {{ data.value | formatMoney }}
              </template>
              <template v-slot:cell(partNumber)="data">
                <div v-html="data.value.split(',').join('<br>')"></div>
              </template>
              <template v-slot:head()="data">
                <div v-if="!data.field.sortable">{{ data.label }}</div>
                <div v-if="data.field.sortable" class="table-sorting">{{ data.label }}<i></i></div>
              </template>
              <template v-slot:cell(status)="data">
                <span class="color_cancelled">Credit Cancelled</span>
              </template>
              <template v-slot:cell(amountApproved)="data">
              </template>
            </b-table>
          </div>
          <block-pagination
              :role="'Credits'"
              :count="gettersCancelledCredits.length"
              :countFiltered="filter ? cancelledCreditsFiltered : gettersCancelledCredits.length"
              :currentPage="gettersCreditCancelledCurrentPage"
              @changedCurrentPage="value => $store.commit('repairer/credit/repairerCreditCancelledCurrentPage', value)"
              :perPage="gettersPerPage"
              @changedPerPage="value => $store.commit('repairer/credit/repairerCreditPerPage', value)"
          >
          </block-pagination>
        </tab>
        <template slot="nav-item-right">
          <div class="tabs-nav-right-search pl-3">
            <search-filter
                type="estimates"
                v-model="searchFilter"
                :filterValue="filter"
                @change="onSearchFilterChange"
                :placeholder="'Filter By'"
                :debug="false"
                :isShowSearIcon="false"
                height="41px"
                aria-expanded="false"
                data-toggle="dropdown"
            />
<!--            <b-form-input v-model="filter" type="text" class="form-control search-input"-->
<!--                          placeholder="Search or Filter results"-->
<!--                          debounce="500"></b-form-input>-->
          </div>
        </template>
      </tabs>
      <vue-context
          ref="menu"
          @close="contextClose"
          @open="contextOpen"
          class="context-menu-right-click">
        <template slot-scope="child">
          <b-button-toolbar key-nav aria-label="">
            <b-button-group vertical class="credit-management__context-menu">
              <template v-if="child && child.data">
                <template v-if="currentTab === 'Denied'">
                  <b-button @click="rightMenuViewReason(child.data)" class="text-left font-12 font-inter--semibold"
                            variant="outline-primary" block>View Reason - Credit Denied
                  </b-button>
                  <b-button @click="rightMenuRemoveCredit(child.data)" class="text-left font-12 font-inter--semibold"
                            variant="outline-primary" block>Remove Credit Request
                  </b-button>
                  <b-button @click="rightMenuResubmitCredit(child.data)" class="text-left font-12 font-inter--semibold"
                            variant="outline-primary" block> Re-submit Request Credit
                  </b-button>
                </template>
                <b-button @click="$router.push('/r/credit/view/' + child.data.id)" class="text-left font-12 font-inter--semibold"
                          variant="outline-primary" block> View Credit
                </b-button>
                <b-button @click="openInNewTab(child.data.id)" class="text-left font-12 font-inter--semibold"
                          variant="outline-primary" block> Open in New Tab
                </b-button>
              </template>
            </b-button-group>
          </b-button-toolbar>
        </template>
      </vue-context>
    </div>
  </div>
</template>
<script>
/*eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import { VueContext } from 'vue-context'
import Axios from 'axios'
import BlockPagination from '../../../components/utility/block-pagination'
import _ from "lodash";
import SearchFilter from "@/components/utility/search-filter/search-filter.vue";

export default {
  name: 'CreditManagement',
  data: function () {
    return {
      showDenyReasonBox: false,
      contextOpenEvent: null,
      selectedItem: null,
      currentTab: '',
      filter: '',
      searchFilter: {},
      searchFilter2: '',
      allCreditsFiltered: 0,
      underReviewCreditsFiltered: 0,
      approvedCreditsFiltered: 0,
      deniedCreditsFiltered: 0,
      cancelledCreditsFiltered: 0,
      fields: [
        { label: 'Supplier', key: 'supplierName', sortable: true, tdClass: 'clickable supplier-name font-weight-bold', thClass: '' },
        { label: 'Credit Request Nbr', key: 'number', sortable: true, tdClass: 'clickable credit-nbr', thClass: '' },
        { label: 'Credit Nbr', key: 'manualNumber', sortable: true, tdClass: 'clickable credit-nbr font-weight-bold', thClass: '' },
        { label: 'Order Nbr', key: 'orderNumber', sortable: true, tdClass: 'clickable order-nbr font-weight-bold', thClass: '' },
        { label: 'Qty Rtn', key: 'qtyRtn', sortable: true, tdClass: 'clickable part-nbr', thClass: '' },
        { label: 'Credit Note Amt', key: 'amount', sortable: true, tdClass: 'clickable amount font-weight-bold', thClass: '' },
        { label: 'Make', key: 'make', sortable: true, tdClass: 'clickable car-rego font-weight-bold', thClass: '' },
        { label: 'Car Rego', key: 'rego', sortable: true, tdClass: 'clickable car-rego', thClass: '' },
        { label: 'Date Created', key: 'dateCreated', sortable: true, tdClass: 'clickable date-created font-weight-bold', thClass: '' },
        { label: 'Amount Approved', key: 'amountApproved', sortable: true, tdClass: 'clickable amount font-weight-bold', thClass: '' },
        { label: 'Credit Status', key: 'status', sortable: true, tdClass: 'clickable status font-weight-bold text-nowrap', thClass: '' },
      ],

    }
  },
  mounted () {
    if (!this.filter) {
      this.filter = this.gettersFilter
    }

  },
  watch: {
    filter (val) {
      this.$store.commit('repairer/credit/repairerCreditFilter', val)
    },
  },
  methods: {
    ...mapActions({
      changeCreditGroup: 'repairer/credit/changeCreditGroup',
    }),
    redirectToRfqView(itm) {
      if (itm.isJobView) {
        this.$router.push({name: 'RepairerJobView', params: {job_id: itm.value}});
      } else {
        this.$router.push({name: 'CreditManagementView', params: {creditId: itm.value}});
      }
    },
    onEnter() {
      setTimeout(() => {
        let credit = _.find(this.additionalMenu, (r) => {
          return r.isJobView
        })
        if (credit && !_.isEmpty(this.searchFilter2)) {
          this.$router.push({name: 'RepairerJobView', params: {job_id: credit.value}});
        }
      }, 500)
    },
    onSearchFilterChange (filters) {
      this.searchFilter = filters
      console.log('filters',filters)
      if (filters && filters.search) {
        this.filter = filters.search;
      } else {
        this.filter = '';
      }
    },
    openInNewTab (id) {
      window.open(window.location.origin + '/r/credit/view/' + id, '_blank')
      this.$refs.menu.close()
    },
    tabChanged (selectedTab) {
      console.log('Tab changed to:' + selectedTab.tab.name);
      this.currentTab = selectedTab.tab.name;
    },
    rightMenuViewReason: function (credit) {
      if (!credit.reasonDenied) {
        this.selectedItem = credit.id
        this.showDenyReasonBox = true
      }
    },
    rightMenuRemoveCredit: function (credit) {
      NProgress.start()
      let url = '/ir/repairer/credit/' + credit.id + '/remove'
      Axios.post(url, { creditId: credit.id })
          .then(response => {
            NProgress.done()
            if (response.data.status) {
              this.$store.dispatch('repairer/credit/init', {}, { root: true })
              this.$toast.success('Credit has been removed')

            }
          })
          .catch(error => {
            console.log(error)
            this.$toast.error('An error occurred in remove this credit')

          }).finally(() => {
        NProgress.done()
      })
    },
    rightMenuResubmitCredit: function (credit) {
      NProgress.start()
      let url = '/ir/repairer/credit/' + credit.id + '/resubmit'
      Axios.post(url, { creditId: credit.id })
          .then(response => {
            NProgress.done()
            if (response.data.status) {
              this.$store.dispatch('repairer/credit/init', {}, { root: true })
              this.$toast.success('Request Credit has been sent')
            }
          })
          .catch(error => {
            console.log(error)
            this.$toast.error('An error occurred in re-submit this credit')

          }).finally(() => {
        NProgress.done()
      })
//POST /ir/repairer/credit/<id>/resubmit
//                {
//                  creditId:<id>
//                }
    },
    contextOpen: function (event) {
      this.contextOpenEvent = event
    },
    contextClose: function () {
      let event = this.contextOpenEvent
      if (event) {
        let row = event.target.parentElement
        let rows = row.parentElement.rows
        while (!rows) {
          row = row.parentElement
          rows = row.parentElement.rows
        }
        if (rows) {
          for (let i = 0; i < rows.length; i++) {
            let elem = rows[i]
            elem.style.backgroundColor = ''
          }
        }
      }
    },
    onCreditRightClick: function (row, index, event) {
      event.preventDefault()
      let tableRow = event.target.parentElement
      let rows = tableRow.parentElement.rows
      while (!rows) {
        tableRow = tableRow.parentElement
        rows = tableRow.parentElement.rows
      }
      if (rows) {
        for (let i = 0; i < rows.length; i++) {
          let elem = rows[i]
          elem.style.backgroundColor = ''
        }
      }
      tableRow.style.backgroundColor = 'rgb(233,248,249)'

      this.$refs.menu.open(event, row, 0, 0)
    },
    isStatusApproved: function (item) {
      return item.status === 'Approved'
    },
    isStatusPartialApproved: function (item) {
      return item.status === 'PartialApproved'
    },
    isStatusUnderReview: function (item) {
      return item.status === 'UnderReview'
    },
    isStatusDenied: function (item) {
      return item.status === 'Denied'
    },
    isStatusCancelled: function (item) {
      return item.status === 'Cancelled'
    },
    viewStatus: function (status) {
      if (status === 'Approved') {
        return 'Approved'
      } else if (status === 'PartialApproved') {
        return 'Partial Approved'
      } else if (status === 'UnderReview') {
        return 'Under Review'
      } else if (status === 'Denied') {
        return 'Denied'
      } else if (status === 'Cancelled') {
        return 'Cancelled'
      } else {
        return ''
      }
    },
    setAsAll: function (id) {
      this.changeCreditGroup({ type: 'All', 'credit_id': id })
    },
    setAsReview: function (id) {
      this.changeCreditGroup({ type: 'Review', 'credit_id': id })
    },
    setAsApproved: function (id) {
      this.changeCreditGroup({ type: 'Approved', 'credit_id': id })
    },
    setAsDenied: function (id) {
      this.changeCreditGroup({ type: 'Denied', 'credit_id': id })
    },
    setAsCancelled: function (id) {
      this.changeCreditGroup({ type: 'Cancelled', 'credit_id': id })
    },
    onCreditRowClick: function (item, index, event) {
      this.$router.push('/r/credit/view/' + item.id)
      return
      if (event && event.target && event.target.classList.contains('action')) {
        return
      }
      this.selectedItem = item.id
    }
  },
  computed: {
    ...mapGetters({
      gettersAllCredits: 'repairer/credit/getAllCredits',
      gettersUnderReviewCredits: 'repairer/credit/getUnderReviewCredits',
      gettersApprovedCredits: 'repairer/credit/getApprovedCredits',
      gettersDeniedCredits: 'repairer/credit/getDeniedCredits',
      gettersCancelledCredits: 'repairer/credit/getCancelledCredits',
      gettersCreditAllCurrentPage: 'repairer/credit/getCreditAllCurrentPage',
      gettersCreditCancelledCurrentPage: 'repairer/credit/getCreditCancelledCurrentPage',
      gettersCreditUnderCurrentPage: 'repairer/credit/getCreditUnderCurrentPage',
      gettersCreditApprovedCurrentPage: 'repairer/credit/getCreditApprovedCurrentPage',
      gettersCreditDeniedCurrentPage: 'repairer/credit/getCreditDeniedCurrentPage',
      gettersPerPage: 'repairer/credit/getPerPage',
      gettersFilter: 'repairer/credit/getFilter'
    }),
    additionalMenu() {
      if (_.isEmpty(this.searchFilter2)) {
        return []
      }
      let data = _.filter(this.gettersAllCredits, (r) => {
        return _.startsWith(_.trim(_.toLower(r.number)), _.trim(_.toLower(this.searchFilter2)))
      })
      let result = [];
      _.forEach(data, (itm) => {
        let label = itm.number
        if (itm.supplierName) {
          label +=  ' - '
        }

        if (itm.supplierName) {
          label += itm.supplierName + ' '
        }
        result.push({
          value: itm.id,
          label: label,  number: itm.number,
          rfqId: itm.rfqId,
          jobViewId: itm.jobViewId
        })
      })
      result = _.orderBy(result, ['label'], ['asc']).slice(0, 5)
      let addedJobViewIds=[]
      let jobViewOptions = []
      _.forEach(result, (r) => {
            if (!_.includes(addedJobViewIds, r.jobViewId)) {
              let l = 'JOB VIEW'
              if (r.number) {
                l = r.number + ' - JOB VIEW'
              }
              if (r.rfqId != r.jobViewId) {
                if (result.length == 1) {
                  jobViewOptions.push({
                    value: r.rfqId,
                    label: l,
                    isJobView: true
                  })
                  addedJobViewIds.push(r.number)
                }
              } else {
                jobViewOptions.push({
                  value: r.rfqId,
                  label: l,
                  isJobView: true
                })
                addedJobViewIds.push(r.number)
              }
            }
      })
      _.forEach(jobViewOptions, (j) => {
        let index = _.findIndex(result, (r) => {
          return Number(r.jobViewId) === Number(j.value)
        })
        if (index !== -1) {
          result.splice(index, 0, j)
        }
      })
      return result
    },
    computedCustomCountForTab () {
      let counts = {
        All: 0,
        UnderReview: 0,
        Approved: 0,
        Denied: 0,
        Cancelled: 0,
      }
      counts.All = this.gettersAllCredits.length
      counts.UnderReview = this.gettersUnderReviewCredits.length
      counts.Approved = this.gettersApprovedCredits.length
      counts.Denied = this.gettersDeniedCredits.length
      counts.Cancelled = this.gettersCancelledCredits.length
      return counts
    },
  },
  components: {
    VueContext,
    BlockPagination,
    SearchFilter
  },
}
</script>

<style lang="scss">
.credit-management {
  &__context-menu {
    .btn {
      border: none;
      padding: 15px 15px 11px 15px;
      transition-duration: 150ms;
    }

    .theme-repairer & .btn:hover {
      color: #070707;
    }
  }
}

.repairer-credit-page {
  .v-context {
    width: auto !important;
    border: none;
    box-shadow: rgba(27, 30, 56, 0.15);
  }
  .amount {
    text-align: end;
    padding-right: 3rem;
  }
}
</style>

<style>
.credit-management__context-menu button:hover {
  background-color: rgba(46,169,255,0.1) !important;
}
</style>
